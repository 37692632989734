import { runApiCall } from './api-config';

function formatData(data) {
    return {
        client_id: data.clientId,
        name: data.name,
        user_name: data.userName,
        type: data.type,
        user_type: data.userType,
    };
}

export async function create(data) {
    const res = await runApiCall('POST', 'asset_users/', formatData(data));
    return res.data;
}

export async function update(assetUserId, data) {
    const res = await runApiCall('PATCH', 'asset_users/' + assetUserId, formatData(data));
    return res.data;
}

export async function remove(assetUserId) {
    const res = await runApiCall('DELETE', 'asset_users/' + assetUserId);
    return res.data;
}

export async function listForClient(clientId) {
    const res = await runApiCall('GET', 'clients/' + clientId + '/asset_users/');
    return res.data;
}

export async function detailView(assetUserId, puCalcFy) {
    const res = await runApiCall('GET', 'views/asset-user-detail/' + assetUserId, undefined, {
        pu_calc_fy: puCalcFy ? puCalcFy : 'latest',
    });
    return res.data;
}
