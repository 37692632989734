export function ucFirst(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function nameMatchesSearch(search, name) {
    if(typeof search !== 'string' || typeof name !== 'string') {
        return true;
    }

    search = search.toLowerCase().trim();
    name = name.toLowerCase().trim();

    return search.split(' ').every(word => {
        return name.indexOf(word) > -1;
    });
}
