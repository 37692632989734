import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const MainNav = (props) => {
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const clientSection = pathParts[3] || null;

    /*
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to={ "/clients/" + props.client.client_id + "/settings/"}>
                                    <span className="icon icon-sound-mix"></span>
                                    Settings
                                </Link>
                            </li>
    */
    return (
        <div className="with-iconav">
            <nav className="iconav">
                <Link
                    className="iconav-brand font-weight-bold"
                    to="/clients/">
                    {
                        props.clientIsLoading
                            ? '...'
                            : props.client ? props.client.acronym : 'PIC'
                    }
                </Link>
                <div className="iconav-slider">
                    {props.client ?
                        <ul className="nav iconav-nav flex-md-column">
                            <li className="nav-item">
                                <Link
                                    className={`nav-link${clientSection === null ? ' active' : ''}`}
                                    to={ "/clients/" + props.client.client_id }>
                                    <span className="icon icon-browser"></span>
                                    Dashboard
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link${clientSection === 'assets' ? ' active' : ''}`}
                                    to={ "/clients/" + props.client.client_id + "/assets/" + (props.assetDetailView ? props.assetDetailView.asset.asset_id : '')}>
                                    <span className="icon icon-layers"></span>
                                    Assets
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link${clientSection === 'asset_users' ? ' active' : ''}`}
                                    to={ "/clients/" + props.client.client_id + "/asset_users/" + (props.assetUserDetailView ? props.assetUserDetailView.asset_user.asset_user_id : '')}>
                                    <span className="icon icon-documents"></span>
                                    Asset Users
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link${clientSection === 'research2' ? ' active' : ''}`}
                                    to={ "/clients/" + props.client.client_id + "/research2/"}>
                                    <span className="icon icon-lab-flask"></span>
                                    Research
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link${clientSection === 'issues' ? ' active' : ''}`}
                                    to={ "/clients/" + props.client.client_id + "/issues/" + (props.issueDetailView ? props.issueDetailView.issue.issue_id : '')}>
                                    <span className="icon icon-credit"></span>
                                    Issues
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link${clientSection === 'notebook' ? ' active' : ''}`}
                                    to={ "/clients/" + props.client.client_id + "/notebook/" }>
                                    <span className="icon icon-book"></span>
                                    Notebook
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link${clientSection === 'settings' ? ' active' : ''}`}
                                    to={ "/clients/" + props.client.client_id + "/settings/" }>
                                    <span className="icon icon-cog"></span>
                                    Settings
                                </Link>
                            </li>
                        </ul>
                    : ''}
                </div>
            </nav>
        </div>
    );
}

const mapStateToProps = state => (
    {
        client: state.client.client,
        clientIsLoading: state.client.clientIsLoading,
        clientHasErrored: state.client.clientHasErrored,
        clientErrorMessage: state.client.clientErrorMessage,
        assetDetailView: state.assets.detailView,
        assetUserDetailView: state.asset_users.detailView,
        issueDetailView: state.issues.detailView,
    }
);

export default connect(mapStateToProps)(MainNav);
