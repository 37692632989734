import { runApiCall } from './api-config';

export async function create(data) {
    const res = await runApiCall('POST', 'notes/', data);
    return res.data;
}

export async function list(clientId) {
    const res = await runApiCall('GET', 'clients/' + clientId + '/notes/');
    return res.data;
}
