import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import * as ModelUtil from '../../lib/ModelUtil';

class ProjectListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    toggleExpansion = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {        
        if(this.state.expanded) {
            return this.renderExpanded();
        }
        else {
            return this.renderCollapsed();
        }
    }

    renderCollapsed() {
        return this.renderProjectRow();
    }

    renderExpanded() {
        const p = this.props.project;
        const tmp = this.props.tmp;

        let rows = [];
        rows.push(this.renderProjectRow());

        const weightingNumeraire = ModelUtil.numeraireForNumeraireId(p.pu_calc.weighting_numeraire_id, this.props.numeraires);

        rows.push(
            <tr key={`project-headers-${this.props.project.project_id}`}>
                <td className="pl-3">
                    &nbsp;
                </td>
                <td>&nbsp;</td>
                <td className="text-uppercase text-muted">Beneficiary</td>
                <td className="text-right text-uppercase text-muted" colSpan={3}>Weighting</td>
                <td colSpan={5}>&nbsp;</td>
            </tr>
        );

        p.benefits.forEach(b => {
            let size = b.beneficiary.total_size;
            if (b.beneficiary.usage_numeraire_id === weightingNumeraire.numeraire_id) {
                size = b.beneficiary.usage_denominator;
            }

            const projectFactor = size / p.pu_calc.total_beneficiaries_size;
            const line4Cost = b.pu_calc.sk_line4_factor * p.pu_calc.cost * projectFactor;
            const line5Cost = b.pu_calc.sk_line5_factor * p.pu_calc.cost * projectFactor;
            const puCost = b.pu_calc.pu_factor * p.pu_calc.cost * projectFactor;

            rows.push(
                <tr key={`project-benefit-${b.project_benefit_id}`}>
                    <td className="pl-3">
                        &nbsp;
                    </td>
                    <td>&nbsp;</td>
                    <td>
                        <Link to={ "/clients/" + this.props.client.client_id + "/assets/" + b.beneficiary.asset_id }>
                            { b.beneficiary.name }
                        </Link>
                    </td>
                    <td colSpan={2} className="text-muted text-right">
                        { numeral(size).format('0,000') } / { numeral(p.pu_calc.total_beneficiaries_size).format('0,000') } { weightingNumeraire ? weightingNumeraire.symbol : '' }
                    </td>
                    <td className="text-muted text-right">
                        { numeral(projectFactor).format('0.00%') }
                    </td>
                    {
                        !tmp ? (
                            <td className="text-muted text-right">
                                { numeral(b.pu_calc.sk_line4_factor).format('0,0.00%') }
                            </td>
                        ): null
                    }
                    {
                        !tmp ? (
                            <td className="text-muted text-right">
                                { numeral(line4Cost).format('$0,000.00') }
                            </td>
                        ): null
                    }
                    {
                        !tmp ? (
                            <td className="text-muted text-right">
                                { numeral(b.pu_calc.sk_line5_factor).format('0,0.00%') }
                            </td>
                        ): null
                    }
                    {
                        !tmp ? (
                            <td className="text-muted text-right">
                                { numeral(line5Cost).format('$0,000.00') }
                            </td>
                        ): null
                    }
                    {
                        tmp ? (
                            <td className="text-muted text-right">
                                { numeral(b.pu_calc.pu_factor).format('0.00%') }
                            </td>
                        ): null
                    }
                    {
                        tmp ? (
                            <td className="text-muted text-right">
                                { numeral(puCost).format('$0,000.00') }
                            </td>
                        ): null
                    }
                    <td>&nbsp;</td>
                </tr>
            );
        });

        return rows;
    }

    renderProjectRow() {
        const p = this.props.project;
        const tmp = this.props.tmp;
        const issue = this.props.issueDetailView.issue;
        const equityAdjustedPU = issue.legacy_equity
            ? Math.max(0, p.pu_calc.pu_factor - p.pu_calc.equity_factor)
            : p.pu_calc.pu_factor;

        return (
            <tr key={`project-${p.project_id}`}>
                <td className="pl-3">
                    {
                        p.benefits.length > 1
                            ? (
                                <span onClick={this.toggleExpansion} className="clickable">
                                    <i className={"icon icon-chevron-" + (this.state.expanded ? 'down' : 'right')}></i>
                                </span>
                            ) : ''
                    }
                </td>
                <td>{ p.name }</td>
                <td>
                    {
                        p.benefits.length > 1
                            ? <span className="font-italic">{p.benefits.length} beneficiaries</span>
                            : ''
                    }
                    {
                        p.benefits.length === 1
                            ? (
                                <Link to={ "/clients/" + this.props.client.client_id + "/assets/" + p.benefits[0].beneficiary.asset_id }>
                                    { p.benefits[0].beneficiary.name }
                                </Link>
                            ) : ''
                    }
                    {
                        p.benefits.length === 0
                            ? (
                                <span>
                                    No Beneficiaries:&nbsp;
                                    <a onClick={() => { this.props.showProjectForm(p); }}>
                                        Add One
                                    </a>
                                </span>
                            ) : ''
                    }
                </td>

                <td className="text-right">
                    { numeral(p.pu_calc.proceeds).format('$0,000.00') }
                </td>
                <td className="text-right">
                    { numeral(p.pu_calc.equity).format('$0,000.00') }
                </td>
                <td className="text-right">
                    { numeral(p.pu_calc.cost).format('$0,000.00') }
                </td>
                {
                    !tmp ? (
                        <td className="text-right">
                            { numeral(p.pu_calc.sk_line4_factor).format('0,0.00%') }
                        </td>
                    ): null
                }
                {
                    !tmp ? (
                        <td className="text-right">
                            { numeral(p.pu_calc.sk_line4_cost).format('$0,000.00') }
                        </td>
                    ): null
                }
                {
                    !tmp ? (
                        <td className="text-right">
                            { numeral(p.pu_calc.sk_line5_factor).format('0,0.00%') }
                        </td>
                    ): null
                }
                {
                    !tmp ? (
                        <td className="text-right">
                            { numeral(p.pu_calc.sk_line5_cost).format('$0,000.00') }
                        </td>
                    ): null
                }
                {
                    tmp ? (
                        <td className="text-right">
                            { numeral(p.pu_calc.pu_factor).format('0.00%') }
                        </td>
                    ): null
                }
                {
                    tmp && issue.legacy_equity ? (
                        <td className="text-right">
                            { numeral(equityAdjustedPU).format('0.00%') }
                        </td>
                    ): null
                }
                {
                    tmp ? (
                        <td className="text-right">
                            { numeral(p.pu_calc.pu_cost).format('$0,000.00') }
                        </td>
                    ): null
                }
                <td className="text-center">
                    <a onClick={() => { this.props.showProjectForm(p); }}>
                        <i className="icon icon-edit" />
                    </a>
                </td>
            </tr>
        );
    }
}

ProjectListing.propTypes = {
    tmp: PropTypes.bool.isRequired,
    showProjectForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        numeraires: state.numeraires.numeraires,
        issueDetailView: state.issues.detailView,
    }
);

export default connect(mapStateToProps)(ProjectListing);