import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import SpaceUseForm from './components/SpaceUseForm';

class SpaceUseModalForm extends Component {
    toggle = () => {
        if(this.props.show)
            this.props.onHide();
    }

    render() {
        return (
            <Modal isOpen={this.props.show} toggle={this.toggle} backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">
                        {
                            (this.props.use)
                                ? 'Edit Space Use'
                                : 'Add Space Use'
                        }
                    </span>
                </ModalHeader>
                <SpaceUseForm
                    use={this.props.use}
                    assets={this.props.assets}
                    assetUsers={this.props.assetUsers}
                    numeraires={this.props.numeraires}
                    onHide={this.toggle} 
                    preselectedAssetUserId={this.props.preselectedAssetUserId}
                    preselectedAssetId={this.props.preselectedAssetId} />
            </Modal>
        );
    }
}

SpaceUseModalForm.propTypes = {
    show: PropTypes.bool.isRequired,
    use: PropTypes.object,
    clientId: PropTypes.number.isRequired,
    assets: PropTypes.array,
    assetUsers: PropTypes.array,
    numeraires: PropTypes.array,
    onHide: PropTypes.func,
    preselectedAssetUserId: PropTypes.number,
    preselectedAssetId: PropTypes.number,
};

export default SpaceUseModalForm;
