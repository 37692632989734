import { runApiCall } from '../api/api-config';

const LIST_IS_LOADING = 'numeraires/LIST_IS_LOADING';
const LIST_HAS_LOADED = 'numeraires/LIST_HAS_LOADED';
const LIST_HAS_ERRORED = 'numeraires/LIST_HAS_ERRORED';

const initialState = {
    numeraires: [],
    numerairesListIsLoading: false,
    numerairesListHasErrored: false,
    numerairesListErrorMessage: null,
};

export default function numerairesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LIST_IS_LOADING:
            return {
                ...state,
                numeraires: [],
                numerairesListIsLoading: true,
                numerairesListHasErrored: false,
                numerairesListErrorMessage: null,
            };
        case LIST_HAS_LOADED:
            return {
                ...state,
                numeraires: action.numeraires,
                numerairesListIsLoading: false,
                numerairesListHasErrored: false,
                numerairesListErrorMessage: null,
            };
        case LIST_HAS_ERRORED:
            return {
                ...state,
                numeraires: [],
                numerairesListIsLoading: false,
                numerairesListHasErrored: true,
                numerairesListErrorMessage: action.errorMessage,
            };
        default:
            return state;
    }
}

export function numerairesListIsLoading() {
    return { type: LIST_IS_LOADING };
}

export function numerairesListHasLoaded(numeraires) {
    return { type: LIST_HAS_LOADED, numeraires: numeraires };
}

export function numerairesListHasErrored(errorMessage) {
    return { type: LIST_HAS_ERRORED, errorMessage: errorMessage };
}

export function loadNumeraires() {
    return dispatch => {
        dispatch(numerairesListIsLoading());

        runApiCall('GET', 'numeraires/')
            .then(response => {
                dispatch(numerairesListHasLoaded(response.data.numeraires));
            })
            .catch(error => {
                dispatch(numerairesListHasErrored(error));
            });
    }
}
