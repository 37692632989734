import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import numeral from 'numeral';
import moment from 'moment';
import * as _ from 'lodash';
import ProjectsTable from './ProjectsTable';
import SourcesUsesTable from './SourcesUsesTable';
import DataExceptionListing from '../DataExceptionElements/DataExceptionListing';
import NetSaleProceedsTable from './NetSaleProceedsTable';
import TMPTable from './TMPTable';
import RefundingBlendingTable from './RefundingBlendingTable';
import * as IssueActionCreators from '../../ducks/issues';
import * as ModalActionCreators from '../../ducks/modals';
import { downloadApiFile } from '../../api/api-config';
import FullTMPTable from './FullTMPTable';
import IssueSummary from './IssueSummary';
import RedemptionSchedule from './RedemptionSchedule';
import * as ModelUtil from '../../lib/ModelUtil';
import NDBrowser from '../NDBrowser/NDBrowser';
import Notepad from '../../apps/Notepad/Notepad';
import IssueSKInput from './IssueSKInput';
import { Ellipsis } from 'react-css-spinners';
import IssueEditPUProceedsForm from '../IssueForms/IssueEditPUProceedsForm';

class IssueDetail extends Component {
    tmpTabs = ['full-tmp-pbu', 'refunding-blending'];

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'summary',
            downloadingFile: false,
        };
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });

            if (this.tmpTabs.indexOf(tab) > -1 && !this.props.fullTMPCalc && !this.props.fullTMPCalcIsLoading && !this.props.fullTMPCalcHasErrored) {
                this.props.loadFullTMPCalc(this.props.issueDetailView.issue.issue_id, this.props.puCalcFy);
            }
        }
    }

    componentDidMount() {
        this.setFirstTabState();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.issueDetailView
            && prevProps.issueDetailView.issue.issue_id !== this.props.issueDetailView.issue.issue_id
            && this.tmpTabs.indexOf(this.state.activeTab) > -1
            && (!this.props.fullTMPCalc || this.props.fullTMPCalc.issue_id !== this.props.issueDetailView.issue.issue_id)
            && !this.props.fullTMPCalcIsLoading
            && !this.props.fullTMPCalcHasErrored
        ) {
            this.props.loadFullTMPCalc(this.props.issueDetailView.issue.issue_id, this.props.puCalcFy);
        }

        this.setFirstTabState();
    }

    setFirstTabState() {
        if(this.props.issueDetailView) {
            const issue = this.props.issueDetailView.issue;
            const issueSKExcludedFactor = issue.pu_calc.sk_excluded_factor ? issue.pu_calc.sk_excluded_factor : 0;
            const issueIsFullySKExcluded = (_.round(issueSKExcludedFactor, 2) === 1.00);

            if(issueIsFullySKExcluded && this.state.activeTab === 'projects') {
                this.setState({ activeTab: 'tmp-pu'});
            }
        }
    }

    render() {
        if (!this.props.issueDetailView) {
            return '';
        }

        const client = this.props.client;
        const issue = this.props.issueDetailView.issue;

        const issueHasRefundings = ModelUtil.issueHasRefundings(this.props.issueDetailView);

        const issueSKExcludedFactor = issue.pu_calc.sk_excluded_factor ? issue.pu_calc.sk_excluded_factor : 0;

        const issueIsFullySKExcluded = (_.round(issueSKExcludedFactor, 2) === 1.00) || !!issue.is_governmental;

        const issueIsPre03 = (issue.dated_date && issue.dated_date < '2003-01-01');

        let exceptionListings;
        if(this.props.issueDetailView.data_exceptions) {
            exceptionListings = this.props.issueDetailView.data_exceptions.map((exception, i) => {
                return <DataExceptionListing exception={exception} client={this.props.client} key={"exception" + i} hideLink={true} />
            });
        }

        //<a href={ API_HOST + 'reports/issue_sk/' + issue.issue_id + '/' + this.props.puCalcFy } target="_blank">Schedule K PDF Report</a>

        return (
            <div>
                <div className="row sticky-top bg-white pt-3 pb-3" style={{ top: '58px' }}>
                    <div className="col-xl-5 col-sm-3">
                        <h3 className="font-weight-bold">{ issue.short_name }</h3>
                        <h5 className="text-muted">
                            Delivery Date { moment(issue.dated_date).format('M/D/YYYY') }
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <a onClick={() => { this.props.showIssueForm(issue); }}>
                                <i className="icon icon-edit" />
                            </a>
                        </h5>
                        <div>
                            {
                                this.state.downloadingFile
                                    ? <Ellipsis color="#90a9b7" size={22} />
                                    : (
                                        <button
                                            className="btn btn-link"
                                            style={{fontSize: '1.2rem', padding: 0}}
                                            onClick={async () => {
                                                this.setState({ downloadingFile: true });
                                                await downloadApiFile('reports/issue_sk/' + issue.issue_id + '/' + this.props.puCalcFy); 
                                                this.setState({ downloadingFile: false });
                                            }}>SK Private Use Analysis PDF Report</button>
                                    )
                            }
                        </div>
                    </div>
                    <div className="col-xl-7 col-sm-9 text-muted">
                        <div className="row">
                            {
                                !issueIsFullySKExcluded
                                    ? (
                                        <div className="statcard p-2 border text-right col-md-3">
                                            <span className="statcard-desc">SK LINE 4 PBU %</span>
                                            <h3 className="statcard-number">
                                                { numeral(issue.pu_calc.sk_line4_equity_adjusted_factor).format('0.00%') }
                                            </h3>
                                        </div>
                                    ): <div className="col-md-3">&nbsp;</div>
                            }
                            {
                                !issueIsFullySKExcluded
                                    ? (
                                        <div className="statcard p-2 border text-right col-md-3">
                                            <span className="statcard-desc">SK LINE 5 PBU %</span>
                                            <h3 className="statcard-number">
                                                { numeral(issue.pu_calc.sk_line5_equity_adjusted_factor).format('0.00%') }
                                            </h3>
                                        </div>
                                    ): <div className="col-md-3">&nbsp;</div>
                            }
                            {
                                !issueIsFullySKExcluded
                                    ? (
                                        <div className="statcard p-2 border text-right col-md-3">
                                            <span className="statcard-desc">SK LINE 6 PBU %</span>
                                            <h3 className="statcard-number">
                                                { numeral(issue.pu_calc.sk_line4_equity_adjusted_factor + issue.pu_calc.sk_line5_equity_adjusted_factor).format('0.00%') }
                                            </h3>
                                        </div>
                                    ): <div className="col-md-3">&nbsp;</div>
                            }
                            <div className="statcard p-2 border text-right statcard-highlight col-md-3">
                                <span className="statcard-desc">TMP PBU %</span>
                                <h3 className="statcard-number">
                                    { numeral(issue.pu_calc.total_equity_adjusted_factor).format('0.00%') }
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        {
                            exceptionListings && exceptionListings.length > 0
                                ? (
                                    <div className="mb-5">
                                        <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border card-bg font-weight-bold text-muted text-uppercase">
                                            Data Exceptions
                                        </div>
                                        <ul className="list-group border-0">
                                            { exceptionListings }
                                        </ul>
                                    </div>
                                ) : <Fragment />
                        }
                        <Nav tabs className="card-bg font-weight-bold">
                            <NavItem>
                                <NavLink
                                    className={`rounded-0 text-muted${(this.state.activeTab === 'summary' ? ' active' : '')}`}
                                    onClick={() => { this.toggleTab('summary')}} >
                                    SUMMARY
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeTab === 'sources-uses' ? 'active' : '')}
                                    onClick={() => { this.toggleTab('sources-uses'); }}
                                >
                                    SOURCES/USES
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeTab === 'private-use-proceeds' ? 'active' : '')}
                                    onClick={() => { this.toggleTab('private-use-proceeds'); }}
                                >
                                    PRIVATE USE PROCEEDS
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeTab === 'redemption-schedule' ? 'active' : '')}
                                    onClick={() => { this.toggleTab('redemption-schedule'); }}
                                >
                                    REDEMPTION SCH.
                                </NavLink>
                            </NavItem>
                            {
                                issueHasRefundings
                                    ? (
                                        <NavItem>
                                            <NavLink
                                                className={"rounded-0 text-muted " + (this.state.activeTab === 'refunding-blending' ? 'active' : '')}
                                                onClick={() => { this.toggleTab('refunding-blending'); }}
                                            >
                                                REF. BLENDING
                                            </NavLink>
                                        </NavItem>
                                    )  : <Fragment />
                            }
                            {
                                !issueIsFullySKExcluded
                                    ? (
                                        <NavItem>
                                            <NavLink
                                                className={"rounded-0 text-muted " + (this.state.activeTab === 'projects' ? 'active' : '')}
                                                onClick={() => { this.toggleTab('projects'); }}
                                            >
                                                SK PBU %
                                            </NavLink>
                                        </NavItem>
                                    )  : <Fragment />
                            }
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeTab === 'tmp-pu' ? 'active' : '')}
                                    onClick={() => { this.toggleTab('tmp-pu'); }}
                                >
                                    TMP PBU %
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeTab === 'full-tmp-pbu' ? 'active' : '')}
                                    onClick={() => { this.toggleTab('full-tmp-pbu'); }}
                                >
                                    FULL TMP PBU
                                </NavLink>
                            </NavItem>
                            {
                                !issueIsPre03
                                    ? (
                                        <NavItem>
                                            <NavLink
                                                className={"rounded-0 text-muted " + (this.state.activeTab === 'schedule-k' ? 'active' : '')}
                                                onClick={() => { this.toggleTab('schedule-k'); }}>
                                                SCHEDULE K
                                            </NavLink>
                                        </NavItem>
                                    )  : <Fragment />
                            }
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeTab === 'nd-browser' ? 'active' : '')}
                                    onClick={() => { this.toggleTab('nd-browser'); }}>
                                    NETDOCS WS
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="summary">
                                <div className="col-12 text-right mt-2 mb-2">
                                    <a onClick={() => { this.props.showIssueForm(issue); }}>
                                        <i className="icon icon-edit" /> Edit Issue
                                    </a>
                                </div>
                                <div className="row">
                                    <div className="col-10">
                                        <IssueSummary issueDetailView={this.props.issueDetailView} />
                                    </div>
                                    <div className="col-2">
                                        <Notepad
                                            entityType={"Issue"}
                                            entityId={issue.issue_id}
                                            listHeight={200} />
                                    </div>
                                </div>
                            </TabPane>

                            <TabPane tabId="sources-uses">
                                <div className="row">
                                    <div className="col-10 h-100">
                                        <SourcesUsesTable issue={issue} showEditEscrowDetailsForm={this.props.showEditEscrowDetailsForm} />
                                    </div>
                                    <div className="col-2">
                                        <Notepad
                                            entityType={"Issue"}
                                            entitySection={"sources-uses"}
                                            entityId={issue.issue_id}
                                            listHeight={200} />
                                    </div>
                                </div>
                            </TabPane>

                            <TabPane tabId="private-use-proceeds">
                                <div className="row">
                                    <div className="col-12 mt-2 mb-2">
                                        <IssueEditPUProceedsForm />
                                        <a onClick={() => { this.props.showIssuePUProceedsModal(); }}>
                                            <span className="icon icon-pencil"></span>&nbsp;
                                            Edit Private Use Proceeds
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-10">
                                        <NetSaleProceedsTable issue={issue} showEditCostHistoryForm={this.props.showEditCostHistoryForm} />
                                    </div>
                                    <div className="col-2">
                                        <Notepad
                                            entityType={"Issue"}
                                            entitySection={"pu-proceeds"}
                                            entityId={issue.issue_id}
                                            listHeight={200} />
                                    </div>
                                </div>
                            </TabPane>

                            <TabPane tabId="redemption-schedule">
                                <div className="row">
                                    <div className="col-12 text-right mt-2 mb-2">
                                        <a onClick={() => { this.props.showEditRedemptionScheduleModal(); }}>
                                            <span className="icon icon-pencil"></span>&nbsp;
                                            Edit Redemption Schedule
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-10">
                                        <RedemptionSchedule />
                                    </div>
                                    <div className="col-2">
                                        <Notepad
                                            entityType={"Issue"}
                                            entitySection={"redemption-schedule"}
                                            entityId={issue.issue_id}
                                            listHeight={200} />
                                    </div>
                                </div>
                            </TabPane>

                            {
                                issueHasRefundings
                                    ? (
                                        <TabPane tabId="refunding-blending">
                                            <div className="row">
                                                <div className="col-12 text-right mt-2 mb-2">
                                                    <a onClick={() => { this.props.showPUOverrideForm(); }}>
                                                        <span className="icon icon-pencil"></span>&nbsp;
                                                        Edit PBU Overrides
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-10">
                                                    <RefundingBlendingTable />
                                                </div>
                                                <div className="col-2">
                                                    <Notepad
                                                        entityType={"Issue"}
                                                        entitySection={"refunding-blending"}
                                                        entityId={issue.issue_id}
                                                        listHeight={200} />
                                                </div>
                                            </div>
                                        </TabPane>
                                    )  : <Fragment />
                            }

                            {
                                !issueIsFullySKExcluded
                                    ? ( <TabPane tabId="projects">
                                            <div className="col-12 text-right mt-2 mb-2">
                                                <a onClick={() => { this.props.showProjectExpendituresModal(); }}>
                                                    <i className="icon icon-edit" />&nbsp;
                                                    Edit Project Expenditures
                                                </a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                                <a onClick={() => { this.props.showProjectForm(); }}>+ Add Project</a>
                                            </div>
                                            <div className="row">
                                                <div className="col-10">
                                                    <ProjectsTable
                                                        projects={issue.projects} 
                                                        issue={issue}
                                                        showProjectForm={this.props.showProjectForm} />
                                                </div>
                                                <div className="col-2">
                                                    <Notepad
                                                        entityType={"Issue"}
                                                        entitySection={"pbu-sk"}
                                                        entityId={issue.issue_id}
                                                        listHeight={200} />
                                                </div>
                                            </div>
                                            
                                        </TabPane>
                                    )  : <Fragment />
                            }

                            <TabPane tabId="tmp-pu">
                                <div className="col-12 text-right mt-2 mb-2">
                                    <a onClick={() => { this.props.showProjectExpendituresModal(); }}>
                                        <i className="icon icon-edit" />&nbsp;
                                        Edit Project Expenditures
                                    </a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                    <a onClick={() => { this.props.showProjectForm(); }}>+ Add Project</a>
                                </div>
                                <div className="row">
                                    <div className="col-10">
                                        <TMPTable
                                            issue={issue}
                                            showProjectForm={this.props.showProjectForm} />
                                    </div>
                                    <div className="col-2">
                                        <Notepad
                                            entityType={"Issue"}
                                            entitySection={"pbu-tmp"}
                                            entityId={issue.issue_id}
                                            listHeight={200} />
                                    </div>
                                </div>
                            </TabPane>
                            
                            <TabPane tabId="full-tmp-pbu">
                                <div className="row">
                                    <div className="col-12 text-right mt-2 mb-2">
                                        <a onClick={() => { this.props.showPUOverrideForm(); }}>
                                            <span className="icon icon-pencil"></span>&nbsp;
                                            Edit PBU Overrides
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-7">
                                        <FullTMPTable />
                                    </div>
                                    <div className="col-2 offset-3">
                                        <Notepad
                                            entityType={"Issue"}
                                            entitySection={"full-tmp"}
                                            entityId={issue.issue_id}
                                            listHeight={200} />
                                    </div>
                                </div>
                            </TabPane>
                            
                            {
                                !issueIsPre03
                                    ? (
                                        <TabPane tabId="schedule-k">
                                            <div className="pt-2">
                                                <IssueSKInput key={issue.issue_id} />
                                            </div>
                                        </TabPane>
                                    )  : <Fragment />
                            }

                            <TabPane tabId="nd-browser">
                                <div className="row mt-2">
                                    <div className="col-10">
                                        {
                                            client.blx_services_company_number && issue.client_matter_number
                                                ? <NDBrowser
                                                    companyNumber={client.blx_services_company_number}
                                                    clientMatterNumber={issue.client_matter_number} />
                                                : <Fragment />
                                        }
                                    </div>
                                    <div className="col-2">
                                        <Notepad
                                            entityType={"Issue"}
                                            entitySection={"documents"}
                                            entityId={issue.issue_id}
                                            listHeight={200} />
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        );
    }
}

IssueDetail.propTypes = {
    issueDetailView: PropTypes.object.isRequired,
    showProjectForm: PropTypes.func.isRequired,
    showProjectImporterModal: PropTypes.func.isRequired,
    showIssueForm: PropTypes.func.isRequired,
    showEditEscrowDetailsForm: PropTypes.func.isRequired,
    showPUOverrideForm: PropTypes.func.isRequired,
    client: PropTypes.object
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        puCalcFy: state.client.puCalcFy,
        fullTMPCalcIsLoading: state.issues.fullTMPCalcIsLoading,
        fullTMPCalcHasErrored: state.issues.fullTMPCalcHasErrored,
        fullTMPCalc: state.issues.fullTMPCalc,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadFullTMPCalc: (issueId, puCalcFy) => dispatch(IssueActionCreators.loadFullTMPCalc(issueId, puCalcFy)),
        showIssuePUProceedsModal: () => dispatch(ModalActionCreators.showIssuePUProceeds()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueDetail);