import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import * as ModelUtil from '../../lib/ModelUtil';

const ResearchActivityListing = (props) => {
    const fy = props.fy;
    const container = props.container;

    const sizeNumeraireId = container.usage_numeraire_id 
        ? container.usage_numeraire_id : props.asset.size_numeraire_id;
    
    const sizeNumeraire = ModelUtil.numeraireForNumeraireId(sizeNumeraireId, props.numeraires);

    const detail = container.research_detail && container.research_detail[fy] ? container.research_detail[fy] : null;
    
    let totalRev = 0;
    let line4Factor = 0;
    let line5Factor = 0;
    let size = 0;
    
    if(detail) {
        totalRev = numeral(detail.total_revenue).value();
        
        if(totalRev !== 0) {
            line4Factor = numeral(detail.line4_revenue).value() / totalRev;
            line5Factor = numeral(detail.line5_revenue).value() / totalRev;
        }
        
        size = numeral(detail.size).value();
    }

    return (
        <tr>
            <td className="pl-3">{ container.space_id ? container.name : (<i>General Asset</i>) }</td>
            <td className="text-right">{detail ? numeral(detail.line4_revenue).format('$0,000.00') : ''}</td>
            <td className="text-right">{detail ? numeral(detail.line5_revenue).format('$0,000.00') : ''}</td>
            <td className="text-right">{detail ? numeral(detail.total_revenue).format('$0,000.00') : ''}</td>
            <td className="text-right">
                {numeral(size).format('0,000.00')} {sizeNumeraire.symbol}
            </td>
            <td className="text-right">
                {numeral(size * line4Factor).format('0,000.00')} {sizeNumeraire.symbol}
            </td>
            <td className="text-right">
                {numeral(size * line5Factor).format('0,000.00')} {sizeNumeraire.symbol}
            </td>
        </tr>
    );
}

ResearchActivityListing.propTypes = {
    fy: PropTypes.number.isRequired,
    asset: PropTypes.object.isRequired,
    container: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
};

export default ResearchActivityListing;