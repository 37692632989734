import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import moment from 'moment';
import numeral from 'numeral';
import IssueFormFields from './IssueFormFields';
import * as IssueActionCreators from '../../ducks/issues';

class IssueModalForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            issue: null,
            show: false,
            submittingForm: false,
            fieldValues: {},
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            show: props.show,
            issue: props.issue,
            fieldValues: {
                clientId: props.client.client_id,
                client_matter_number: props.issue && props.issue.client_matter_number ? props.issue.client_matter_number : '',
                short_name: props.issue ? props.issue.short_name : '',
                dated_date: props.issue && props.issue.dated_date ? moment(props.issue.dated_date).format('MM/DD/YYYY') : '',
                maturity_date: props.issue && props.issue.maturity_date ? moment(props.issue.maturity_date).format('MM/DD/YYYY') : '',
                par_amount: props.issue && props.issue.par_amount ? numeral(props.issue.par_amount).format('0,000.00') : '',
                original_premium: props.issue && props.issue.original_premium ? numeral(props.issue.original_premium).format('0,000.00') : '',
                issuer_name: props.issue ? props.issue.issuer_name : '',
                issuer_ein: props.issue ? props.issue.issuer_ein : '',
                trans_1: props.issue && props.issue.trans_1 ? props.issue.trans_1 : '',
                trans_2: props.issue && props.issue.trans_2 ? props.issue.trans_2 : '',
                trans_3: props.issue && props.issue.trans_3 ? props.issue.trans_3 : '',
                trans_4: props.issue && props.issue.trans_4 ? props.issue.trans_4 : '',
                trans_5: props.issue && props.issue.trans_5 ? props.issue.trans_5 : '',
                trans_6: props.issue && props.issue.trans_6 ? props.issue.trans_6 : '',
                legacy_equity: props.issue ? props.issue.legacy_equity : 0,
                governmental: props.issue ? props.issue.governmental : 0,
                box_link_os: props.issue ? props.issue.box_link_os : '',
                box_link_tc: props.issue ? props.issue.box_link_tc : '',
                box_link_8038: props.issue ? props.issue.box_link_8038 : '',
            }
        });
    }

    toggle = (reloadData) => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding)
            this.props.onHide(reloadData);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let fieldValues = this.state.fieldValues;
        fieldValues[name] = value;
        
        this.setState({ fieldValues: fieldValues });
    }

    saveFunc = (data) => {
        this.toggle();
        this.setState({ submittingForm: false });

        if (typeof this.props.onSave === 'function') {
            this.props.onSave(data.issue);
        }
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ submittingForm: true });

        if(!this.props.issue) {
            this.props.createIssue(this.state.fieldValues).then(this.saveFunc);
        }
        else {
            this.props.updateIssue(this.props.issue.issue_id, this.state.fieldValues).then(this.saveFunc);
        }
    }

    handleDeleteClick = (event) => {
        let confirmed = window.confirm('Are you sure you want to delete ' + this.state.issue.short_name + '?');

        if(confirmed) {
            this.setState({ submittingForm: true });

            this.props.removeIssue(this.props.issue.issue_id).then(this.saveFunc);
        }
    }

    render() {
        return (
            <Modal isOpen={this.state.show} toggle={() => { this.toggle(false); }} backdrop="static" className="modal-xl">
                <ModalHeader toggle={() => { this.toggle(false); }}>
                    <span className="font-weight-bold">
                        {
                            (this.state.issue)
                                ? 'Edit ' + this.state.issue.short_name
                                : 'Add Issue'
                        }
                    </span>
                </ModalHeader>
                <form onSubmit={this.handleFormSubmit}>
                    <div className="p-3">
                        <IssueFormFields
                            handleInputChange={this.handleInputChange}
                            puFys={this.props.client.pu_fys}
                            fieldValues={this.state.fieldValues} />
                    </div>
                    {
                        (this.props.issue) ? 
                            <div className="pt-0 pb-2 pl-1">
                                <button type="button" className="btn btn-link" onClick={this.handleDeleteClick}>
                                    <span className="icon icon-circle-with-cross"></span>&nbsp;
                                    Delete {this.props.issue.short_name}
                                </button>
                            </div>
                            : ''
                    }
                    <div className="modal-actions">
                        <button type="button" className="btn-link modal-action" onClick={() => { this.props.onHide(false); }}>
                            Cancel
                        </button>
                        <button type="submit" className="btn-link modal-action font-weight-bold">
                            {
                                this.state.submittingForm
                                    ? 'Loading...'
                                    : this.props.issue
                                        ? 'Save Changes'
                                        : 'Add Issue'
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

IssueModalForm.propTypes = {
    issue: PropTypes.object,
    onHide: PropTypes.func,
    client: PropTypes.object.isRequired,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        createIssue: (issueData) => dispatch(IssueActionCreators.create(issueData)),
        updateIssue: (issueId, issueData) => dispatch(IssueActionCreators.update(issueId, issueData)),
        removeIssue: (issueId) => dispatch(IssueActionCreators.remove(issueId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueModalForm);
