import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import moment from 'moment';
import TitleBar from '../components/TitleBar';
import * as ClientActionCreators from '../ducks/client';
import * as CommonStateActions from '../ducks/common';
import * as UserActionCreators from '../ducks/users';
import * as ClientApi from '../api/ClientApi';
import ClientFormFields from '../components/ClientForms/ClientFormFields';

const ClientSettings = props => {
    const [dataIsSaving, setDataIsSaving] = useState(false);
    const [dataHasSaved, setDataHasSaved] = useState(false);
    const [dataSavingErrorText, setDataSavingErrorText] = useState(false);

    if (
        (!props.client || props.client.client_id !== parseInt(props.match.params.number, 10))
        && !props.clientIsLoading
        && !props.clientHasErrored) {
        props.loadClientAndDependencies(props.match.params.number);
    }

    if(
        !props.users
        && !props.usersListIsLoading
        && !props.usersListHasErrored
    ) {
        props.loadUsersList();
    }

    if(!props.client || !props.users) {
        return '';
    }

    const onSubmit = data => {
        setDataIsSaving(true);
        setDataHasSaved(false);
        setDataSavingErrorText(null);

        ClientApi
            .update(props.client.client_id, data)
            .then(() => {
                setDataIsSaving(false);
                setDataHasSaved(true);
                setDataSavingErrorText(null);
                props.loadClientAndDependencies(props.client.client_id);
            })
            .catch(error => {
                setDataIsSaving(false);
                setDataHasSaved(false);
                setDataSavingErrorText(error);
            });
    }
    
    return (
        <div>
            <TitleBar pageTitle="Client Settings" clientName={props.client.name} />
            <div className="clear-title-bar">
                <div className="row pt-3">
                    <div className="col-6">
                        <ClientFormFields
                            client={props.client}
                            users={props.users}
                            onSubmit={onSubmit}>

                            <div className="text-right">
                                {
                                    dataHasSaved
                                        ? <span className="text-primary"><i className="icon icon-check"></i> Saved!&nbsp;&nbsp;&nbsp;</span>
                                        : <Fragment />
                                }
                                {
                                    dataSavingErrorText
                                        ? <span className="text-error"><i className="icon icon-cross"></i> Error saving client: { dataSavingErrorText }&nbsp;&nbsp;&nbsp;</span>
                                        : <Fragment />
                                }
                                <button className="btn btn-success rounded-0" type="submit">
                                    { dataIsSaving ? 'Saving...' : 'Save Client' }
                                </button>
                            </div>
                        </ClientFormFields>
                    </div>
                </div>
            </div>
        </div>
    );
}

ClientSettings.propTypes = {
    client: PropTypes.object,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        clientIsLoading: state.client.clientIsLoading,
        clientHasErrored: state.client.clientHasErrored,
        clientErrorMessage: state.client.clientErrorMessage,
        users: state.users.list,
        usersListIsLoading: state.users.listIsLoading,
        usersListHasErrored: state.users.listHasErrored,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadClientAndDependencies: (clientId) => CommonStateActions.loadClientAndDependencies(dispatch, clientId),
        loadClient: (clientId) => ClientActionCreators.loadClient(dispatch, clientId),
        loadUsersList: () => dispatch(UserActionCreators.loadUsersList()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientSettings);
