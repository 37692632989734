import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import AssetForm from './AssetForm';
import AssetFormFields from './AssetFormFields';
import * as AssetActionCreators from '../../ducks/assets';

class AssetModalForm extends AssetForm {
    constructor(props) {
        super(props);

        this.state = {
            asset: null,
            show: false,
            submittingForm: false,
            fieldValues: {},
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            show: props.show,
            asset: props.asset,
            fieldValues: {
                clientId: props.client.client_id,
                name: props.asset ? props.asset.name : '',
                assetCode: props.asset ? props.asset.asset_code : '',
                isUtilityPlant: props.asset ? props.asset.is_utility_plant : 0,
                totalSize:
                    props.asset ? props.asset.total_size : '',
                commonSize:
                    props.asset ? props.asset.common_size : '',
                sizeNumeraireId:
                    props.asset ? props.asset.size_numeraire_id :
                        (typeof props.numeraires[0] !== 'undefined' ? props.numeraires[0].numeraire_id : ''),
                totalSizeDetail: props.asset ? (props.asset.total_size_detail ? props.asset.total_size_detail : {}) : {},
                commonSizeDetail: props.asset ? (props.asset.common_size_detail ? props.asset.common_size_detail : {}) : {},
                daysInOperationOverride: props.asset ? (props.asset.days_in_operation_override ? props.asset.days_in_operation_override : {}) : {},
                usageNumeraireId: props.asset && props.asset.usage_numeraire_id ? props.asset.usage_numeraire_id : '',
                usageDenominator: props.asset && props.asset.usage_denominator ? props.asset.usage_denominator : '',
            }
        });
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding)
            this.props.onHide();
    }

    render() {
        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">
                        {
                            (this.state.asset)
                                ? 'Edit ' + this.state.asset.name
                                : 'Add Asset'
                        }
                    </span>
                </ModalHeader>
                <form onSubmit={this.handleFormSubmit}>
                    <div className="p-3">
                        <AssetFormFields
                            handleInputChange={this.handleInputChange}
                            handleSizeDetailInputChange={this.handleSizeDetailInputChange}
                            handleDaysInOperationInputChange={this.handleDaysInOperationInputChange}
                            numeraires={this.props.numeraires}
                            showSizeDetailInputs={true}
                            showDaysInOperationInputs={true}
                            puFys={this.props.client.pu_fys}
                            fieldValues={this.state.fieldValues} />
                    </div>
                    {
                        (this.props.asset) ? 
                            <div className="pt-0 pb-2 pl-1">
                                <button type="button" className="btn btn-link" onClick={this.handleDeleteClick}>
                                    <span className="icon icon-circle-with-cross"></span>&nbsp;
                                    Delete {this.props.asset.name}
                                </button>
                            </div>
                            : ''
                    }
                    <div className="modal-actions">
                        <button type="button" className="btn-link modal-action" onClick={this.props.onHide}>
                            Cancel
                        </button>
                        <button type="submit" className="btn-link modal-action font-weight-bold">
                            {
                                this.state.submittingForm
                                    ? 'Loading...'
                                    : this.props.asset
                                        ? 'Save Changes'
                                        : 'Add Asset'
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

AssetModalForm.propTypes = {
    asset: PropTypes.object,
    numeraires: PropTypes.array.isRequired,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
    client: PropTypes.object.isRequired,

};

const mapStateToProps = state => (
    {
        numeraires: state.numeraires.numeraires,
        client: state.client.client,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        createAsset: (assetData) => dispatch(AssetActionCreators.create(assetData)),
        updateAsset: (assetId, assetData) => dispatch(AssetActionCreators.update(assetId, assetData)),
        removeAsset: (assetId) => dispatch(AssetActionCreators.remove(assetId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetModalForm);