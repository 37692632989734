import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import numeral from 'numeral';
import moment from 'moment';
import { connect } from 'react-redux';
import 'rc-tooltip/assets/bootstrap_white.css';

import * as DateFunctions from '../../lib/DateFunctions';
import * as ModelUtil from '../../lib/ModelUtil';

const UseListing = (props) => {
    if (!props.assets) {
        return '';
    }
    
    const container = ModelUtil.containerForUse(props.use, props.assets);
    
    let usageNumeraireId;
    if (props.use.usage_numeraire_id) {
        usageNumeraireId = props.use.usage_numeraire_id;
    }
    else if (container.usage_numeraire_id) {
        usageNumeraireId = container.usage_numeraire_id;
    }
    else {
        usageNumeraireId = props.asset.size_numeraire_id;
    }

    let sizeNumeraireId = container.usage_numeraire_id 
        ? container.usage_numeraire_id : props.asset.size_numeraire_id;

    const usageNumeraire = ModelUtil.numeraireForNumeraireId(usageNumeraireId, props.numeraires);
    const sizeNumeraire = ModelUtil.numeraireForNumeraireId(sizeNumeraireId, props.numeraires);
    
    let baseCellClass = (props.firstCell ? 'top-dotted-border ' : 'border-0 ');

    return (
        <tr>
            <td className="pl-2">
                {
                    props.firstCell && props.userUseCount > 1
                        ? (
                            <span onClick={props.toggleExpansion} className="clickable text-secondary font-weight-bold">
                                <i className="icon icon-chevron-down"></i>
                            </span>
                        ) : ''
                }
            </td>
            {
                props.firstCell
                ? (
                    <td className={baseCellClass} rowSpan={props.userUseCount ? props.userUseCount : 1}>
                        <span onClick={props.toggleExpansion} className={"text-secondary font-weight-bold" + (props.userUseCount > 1 ? " clickable" : "")}>
                            { props.user.name }
                        </span>
                        &nbsp;&nbsp;
                        <a onClick={() => { props.showAssetUserForm(props.user); }}>
                            <i className="icon icon-edit" />
                        </a>
                    </td>
                ) 
                : null
            }
            <td className={baseCellClass}>
                {container.space_id ? container.name : <i>General Asset</i>}
            </td>
            <td className={baseCellClass + "text-right"}>
                {props.use.start_date ? moment(props.use.start_date).format('MM/DD/YY') : ''}
            </td>
            <td className={baseCellClass + "text-right"}>
                {props.use.end_date ? moment(props.use.end_date).format('MM/DD/YY') : ''}
            </td>
            <td className={baseCellClass + "text-right"}>
                { numeral(props.use.pu_calc.gross_size).format('0,00.00') }&nbsp;
                {sizeNumeraire.symbol}
            </td>
            <td className={baseCellClass + "text-right"}>
                {
                    props.use.pu_calc.time_factor === 1
                        ? <span className="text-muted">{ DateFunctions.numDaysDescription(props.use.pu_calc.time_numerator) }</span>
                        : (
                            <Tooltip
                                placement="top"
                                overlay={
                                    numeral(props.use.pu_calc.time_numerator).format('0,00') + " / " +
                                    numeral(props.use.pu_calc.time_denominator).format('0,00') + " days - " + 
                                    numeral(props.use.pu_calc.time_factor).format('0.00 %')
                                }>
                                <span className="dotted-underline">{ DateFunctions.numDaysDescription(props.use.pu_calc.time_numerator) }</span>
                            </Tooltip>
                        )
                }
            </td>
            <td className={baseCellClass + "text-right"}>
                {
                    props.use.pu_calc.bad_usage_factor === 1 || props.use.pu_calc.bad_usage_factor === 0
                        ? <span className="text-muted">{numeral(props.use.pu_calc.bad_usage_factor).format('0.00 %')}</span> //numeral(props.use.pu_calc.usage_factor).format('0.00 %')
                        : (
                            <Tooltip
                                placement="top"
                                overlay={
                                    numeral(props.use.pu_calc.usage_detail.bad).format('0,00') + " / " +
                                    numeral(props.use.pu_calc.usage_detail.total).format('0,00') + " " + usageNumeraire.symbol
                                }>
                                <span className="dotted-underline">{numeral(props.use.pu_calc.bad_usage_factor).format('0.00 %')}</span>
                            </Tooltip>
                        )
                }
            </td>
            <td className={baseCellClass + "text-right"}>
                {numeral(props.use.pu_calc.sk_line4_usage_size).format('0,0.00')} {sizeNumeraire.symbol}
            </td>
            <td className={baseCellClass + "text-right"}>
                {numeral(props.use.pu_calc.sk_line5_usage_size).format('0,0.00')} {sizeNumeraire.symbol}
            </td>
            <td className={baseCellClass + "text-right"}>
                {numeral(props.use.pu_calc.sk_line4_usage_size + props.use.pu_calc.sk_line5_usage_size).format('0,0.00')} {sizeNumeraire.symbol}
            </td>
            <td className={baseCellClass + "text-right"}>
                <a onClick={() => { props.showUseForm(props.use); }}>
                    <i className="icon icon-edit" />
                </a>
            </td>
        </tr>
    );
}

UseListing.propTypes = {
    use: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    firstCell: PropTypes.element,
    showUseForm: PropTypes.func.isRequired,
    showAssetUserForm: PropTypes.func.isRequired,
    toggleExpansion: PropTypes.func,
};

const mapStateToProps = state => (
    {
        numeraires: state.numeraires.numeraires,
        assets: state.assets.assets,
    }
);

export default connect(mapStateToProps)(UseListing);