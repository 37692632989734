import axios from 'axios';
import { authProvider } from '../lib/AuthProvider';
import { saveAs } from 'file-saver';

let apiHost;
const apiVersion = 'v1';

const hostname = window && window.location && window.location.hostname;

if(hostname === 'pic.blxportal.com' || hostname === 'pic.blx.digital') {
    apiHost = 'https://picapi.blx.digital';
}
else{
    apiHost = 'http://localhost:8080';
    //apiHost = 'http://pic-api.test'
    //apiHost = 'https://picapi.blx.digital';
}

export const API_BASE = `${apiHost}/${apiVersion}/`;
export const API_HOST = `${apiHost}/`;

export async function runApiCall(method, route, data = undefined, params = undefined) {
    return await runHttpCall(method, `${apiVersion}/${route}`, data, params);   
}

export async function runHttpCall(method, route, data = undefined, params = undefined, responseType = undefined) {
    let token;
    try {
        token = await authProvider.getAccessToken();
    }
    catch(e) {
        console.error('AAD Token Error', e);
        throw e.errorMessage;
    }

    try {
        const res = await axios({
            method,
            url: `${API_HOST}${route}`,
            data,
            headers: {
                Authorization: `Bearer ${token.accessToken}`
            },
            params,
            responseType
        });

        return res;
    }
    catch(e) {
        // TODO: less intrusive error handling
        if(e.response && e.response.data && e.response.data.errors) {
            alert(`Error: ${e.response.data.errors.map(err => err.message).join(' ')}`);
        }
        else {
            alert(e);
        }
        throw e;
    }
}

export async function downloadApiFile(route, filename = undefined) {
    const response = await runHttpCall('GET', route, undefined, undefined, 'blob');
    if(response && response.data) {
        const fname = filename ? filename : (response.headers['content-disposition'].split('filename=')[1]).replace(/\"/g, '');
        saveAs(response.data, fname);
        return true;
    }
};