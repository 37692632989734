import { runApiCall } from './api-config';

function formatData(data) {
    return {
        ...data,
        client_id: data.clientId,
        fy_net_sale_proceeds_detail: data.nspDetail ? JSON.stringify(data.nspDetail) : null,
    };
}

export async function create(data) {
    const res = await runApiCall('POST', 'issues/', formatData(data));
    return res.data;
}

export async function update(issueId, data) {
    const res = await runApiCall('PATCH', 'issues/' + issueId, formatData(data));
    return res.data;
}

export async function remove(issueId) {
    const res = await runApiCall('DELETE', 'issues/' + issueId);
    return res.data;
}

export async function updateSources(issueId, data) {
    const res = await runApiCall('PATCH', 'issues/' + issueId + '/sources/', data);
    return res.data;
}

export async function updateUses(issueId, data) {
    const res = await runApiCall('PATCH', 'issues/' + issueId + '/uses/', data);
    return res.data;
}

export async function updateUseCostHistory(issueId, useId, data) {
    const res = await runApiCall('PATCH', 'issues/' + issueId + '/uses/' + useId + '/cost_history/', data);
    return res.data;
}

export async function updateUseRefundings(issueId, useId, data) {
    const res = await runApiCall('PATCH', 'issues/' + issueId + '/uses/' + useId + '/refundings/', data);
    return res.data;
}

export async function updatePUOverride(issueId, data) {
    const res = await runApiCall('PATCH', 'issues/' + issueId + '/pu_override/', data);
    return res.data;
}

export async function updateAnalystSkAnswers(issueId, data) {
    const res = await runApiCall('PATCH', 'issues/' + issueId + '/analyst_sk_answers/', data);
    return res.data;
}

export async function listForClient(clientId) {
    const res = await runApiCall('GET', 'clients/' + clientId + '/issues/');
    return res.data;
}

export async function issueDetailView(issueId, puCalcFy) {
    const res = await runApiCall('GET', 'views/issue-detail/' + issueId, undefined, {
        pu_calc_fy: puCalcFy ? puCalcFy : 'latest',
    });
    return res.data;
}

export async function fullTMPCalc(issueId, puCalcFy, onSuccess, onFailure) {
    const res = await runApiCall('GET', 'views/issue-full-tmp-pu/' + issueId, undefined, {
        pu_calc_fy: puCalcFy ? puCalcFy : 'latest',
    });
    return res.data;
}

export async function createUse(data) {
    const res = await runApiCall('POST', 'issue_uses/', formatData(data));
    return res.data;
}

export async function updateUse(id, data) {
    const res = await runApiCall('PATCH', 'issue_uses/' + id, formatData(data));
    return res.data;
}

export async function deleteUse(id) {
    const res = await runApiCall('DELETE', 'issue_uses/' + id);
    return res.data;
}

export async function createSource(data) {
    const res = await runApiCall('POST', 'issue_sources/', formatData(data));
    return res.data;
}

export async function updateSource(id, data) {
    const res = await runApiCall('PATCH', 'issue_sources/' + id, formatData(data));
    return res.data;
}

export async function deleteSource(id) {
    const res = await runApiCall('DELETE', 'issue_sources/' + id);
    return res.data;
}