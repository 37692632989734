import * as SpaceApi from '../api/SpaceApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Common from './common';

const CREATE = 'spaces/CREATE';
const REMOVE = 'spaces/REMOVE';
const UPDATE = 'spaces/UPDATE';

const initialState = {

};

export default function spacesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case CREATE:
            return state;
        case REMOVE:
            return state;
        case UPDATE:
            return state;
        default:
            return state;
    }
}

export function create(spaceData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        SpaceApi.create(spaceData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadAssetList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function update(spaceId, spaceData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        SpaceApi.update(spaceId, spaceData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadAssetList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function remove(spaceId) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        SpaceApi.remove(spaceId)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadAssetList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function importStructured(importData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        SpaceApi.importCommitStructured(importData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadAssetList(dispatch, state);
                Common.reloadDataImportList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}