import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import moment from 'moment';
import * as ModelUtil from '../../lib/ModelUtil';
import ProjectFormFields from './ProjectFormFields';
import { ucFirst } from '../../lib/TextFunctions';
import * as ProjectActionCreators from '../../ducks/projects';

class ProjectModalForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            project: null,
            show: false,
            submittingForm: false,
            fieldValues: {},
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            show: props.show,
            project: props.project,
            fieldValues: {
                clientId: props.issue.client_id,
                issueId: props.issue.issue_id,
                name: props.project ? props.project.name : '',
                blending_mode: props.project ? props.project.blending_mode : 'weighted-average',
                costDetail: props.project ? (props.project.fy_cost_detail ? props.project.fy_cost_detail : {}) : {},
                equityDetail: props.project ? (props.project.fy_equity_detail ? props.project.fy_equity_detail : {}) : {},
                benefits: props.project ? this.flattenBenefits(props.project.benefits) : [],
                deleteBenefits: [],
            }
        });
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding)
            this.props.onHide();
    }

    handleAddBenefit = (event) => {
        const target = event.target;
        const value = target.value;
        const arr = value.split('-');
        const type = arr[0];
        const id = arr[1];

        const beneficiary = 
            type === 'space' ? ModelUtil.spaceForSpaceIdFromAssets(id, this.props.assets) : ModelUtil.assetForAssetId(id, this.props.assets);

        let fieldValues = this.state.fieldValues;
        fieldValues.benefits[fieldValues.benefits.length] = {
            project_benefit_id: null,
            in_service_date: null,
            beneficiary_name: beneficiary.name,
            beneficiary_type: "App\\" + ucFirst(type),
            beneficiary_id: id,
        };

        fieldValues.newBeneficiaryId = '';

        this.setState({ fieldValues: fieldValues });
    }

    handleBenefitInputChange = (event, benefit) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let benefits;
        let fieldValues = this.state.fieldValues;
        
        benefits = fieldValues.benefits.map((b) => {
            if (b === benefit) {
                b[name] = value;
            }
            
            return b;
        });

        fieldValues.benefits = benefits;

        this.setState({ fieldValues: fieldValues });
    }

    handleDeleteBenefit = (benefit) => {
        let benefits;
        let fieldValues = this.state.fieldValues;
        
        benefits = fieldValues.benefits.filter((b) => {
            return b !== benefit;
        });

        fieldValues.benefits = benefits;

        if (benefit.project_benefit_id) {
            fieldValues.deleteBenefits[fieldValues.deleteBenefits.length] = benefit.project_benefit_id;
        }

        this.setState({ fieldValues: fieldValues });
    }

    flattenBenefits = (benefits) => {
        let flat = benefits.map((benefit) => {
            return {
                project_benefit_id: benefit.project_benefit_id,
                in_service_date: benefit.in_service_date ? moment(benefit.in_service_date).format('MM/DD/YYYY') : null,
                beneficiary_name: benefit.beneficiary.name,
                beneficiary_type: benefit.beneficiary_type,
                beneficiary_id: benefit.beneficiary_id,
            };
        });

        return flat;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let fieldValues = this.state.fieldValues;
        fieldValues[name] = value;
        
        this.setState({ fieldValues: fieldValues });
    }
    
    handleCostDetailInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const arr = name.split('-');
        const fy = arr[1];
                        
        let fieldValues = this.state.fieldValues;
        
        let costDetail = this.state.fieldValues.costDetail;
        costDetail[fy] = value;

        fieldValues.costDetail = costDetail;
        
        this.setState({ fieldValues: fieldValues });
    }

    handleEquityDetailInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const arr = name.split('-');
        const fy = arr[1];
                        
        let fieldValues = this.state.fieldValues;
        
        let equityDetail = this.state.fieldValues.equityDetail;
        equityDetail[fy] = value;

        fieldValues.equityDetail = equityDetail;
        
        this.setState({ fieldValues: fieldValues });
    }

    saveFunc = (data) => {
        this.toggle();
        this.setState({ submittingForm: false });

        if (typeof this.props.onSave === 'function') {
            this.props.onSave(data.project);
        }
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ submittingForm: true });

        if(!this.props.project) {
            this.props.createProject(this.state.fieldValues).then(this.saveFunc);
        }
        else {
            this.props.updateProject(this.props.project.project_id, this.state.fieldValues).then(this.saveFunc);
        }
    }

    handleDeleteClick = (event) => {
        let confirmed = window.confirm('Are you sure you want to delete ' + this.state.project.name + '?');

        if(confirmed) {
            this.setState({ submittingForm: true });

            this.props.removeProject(this.props.project.project_id).then(this.saveFunc);
        }
    }

    render() {
        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} className="modal-xl" backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">
                        {
                            (this.state.project)
                                ? 'Edit ' + this.state.project.name
                                : 'Add Project'
                        }
                    </span>
                </ModalHeader>
                <form onSubmit={this.handleFormSubmit}>
                    <div className="p-3">
                        <ProjectFormFields
                            handleInputChange={this.handleInputChange}
                            handleAddBenefit={this.handleAddBenefit}
                            handleDeleteBenefit={this.handleDeleteBenefit}
                            handleBenefitInputChange={this.handleBenefitInputChange}
                            handleCostDetailInputChange={this.handleCostDetailInputChange}
                            handleEquityDetailInputChange={this.handleEquityDetailInputChange}
                            showCostDetailInputs={true}
                            puFys={this.props.client.pu_fys}
                            fieldValues={this.state.fieldValues}
                            assets={this.props.assets} />
                    </div>
                    {
                        (this.props.project) ? 
                            <div className="pt-0 pb-2 pl-1">
                                <button type="button" className="btn btn-link" onClick={this.handleDeleteClick}>
                                    <span className="icon icon-circle-with-cross"></span>&nbsp;
                                    Delete {this.props.project.name}
                                </button>
                            </div>
                            : ''
                    }
                    <div className="modal-actions">
                        <button type="button" className="btn-link modal-action" onClick={this.props.onHide}>
                            Cancel
                        </button>
                        <button type="submit" className="btn-link modal-action font-weight-bold">
                            {
                                this.state.submittingForm
                                    ? 'Loading...'
                                    : this.props.project
                                        ? 'Save Changes'
                                        : 'Add Project'
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

ProjectModalForm.propTypes = {
    project: PropTypes.object,
    onHide: PropTypes.func,
    issue: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    asset: state.assets.assets,
    client: state.client.client,
    numeraires: state.numeraires.numeraires,
});

const mapDispatchToProps = dispatch => ({
    createProject: (d) => dispatch(ProjectActionCreators.create(d)),
    updateProject: (id, d) => dispatch(ProjectActionCreators.update(id, d)),
    removeProject: (id) => dispatch(ProjectActionCreators.remove(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectModalForm);
