import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import moment from 'moment';
import numeral from 'numeral';
import VacancyFormFields from './VacancyFormFields';
import * as AssetActionCreators from '../../ducks/assets';
import * as VacancyApi from '../../api/VacancyApi';
import * as ModelUtil from '../../lib/ModelUtil';

class VacancyModalForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            use: null,
            selectedAsset: null,
            selectedSpace: null,
            show: false,
            submittingForm: false,
            fieldValues: {
                spaceId: '',
                size: '',
                startDate: '',
                endDate: '',
                usesEntireSpace: true,
            },
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        let space, asset;

        if (props.use) {
            space = ModelUtil.spaceForUse(props.use, props.assets);
            asset = ModelUtil.assetForUse(props.use, props.assets);
        }
        else if (props.asset) {
            asset = props.asset;
        }
        else if (props.space) {
            asset = ModelUtil.assetForAssetId(props.space.asset_id, props.assets);
        }

        this.setState({
            show: props.show,
            use: props.use,
            selectedAsset: asset,
            selectedSpace: space,
            fieldValues: {
                assetId: asset ? asset.asset_id : '',
                spaceId: space ? space.space_id : '',
                size: props.use && props.use.size ? numeral(props.use.size).format('0,0.00') : '',
                startDate: props.use && props.use.start_date ? moment(props.use.start_date).format('MM/DD/YYYY') : '',
                endDate: props.use && props.use.end_date ? moment(props.use.end_date).format('MM/DD/YYYY') : '',
                usesEntireSpace: props.use && props.use.size ? false : true,
            }
        });
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding)
            this.props.onHide();
    }

    selectNewAsset = (asset) => {
        asset.spaces = [];

        this.setState({
            selectedAsset: asset,
        });
    }

    selectNewSpace = (space) => {
        let asset = this.props.assets.find((item) => {
            return item.asset_id === space.asset_id;
        });
        
        space.uses = [];

        let fieldValues = this.state.fieldValues;
        fieldValues.spaceId = space.space_id;
        fieldValues.assetId = space.asset_id;

        this.setState({
            fieldValues: fieldValues,
            selectedSpace: space,
            selectedAsset: asset,
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(name === 'assetId') {
            if(value) {
                let asset;
                asset = this.props.assets.find((item) => {
                    return item.asset_id === parseInt(value, 0);
                });

                this.setState({ selectedAsset: asset });
            }
            else {
                this.setState({ selectedAsset: null, });
            }
        }

        let fieldValues = this.state.fieldValues;
        fieldValues[name] = value;

        if(name === 'usesEntireSpace' && value) {
            fieldValues.size = '';
        }

        this.setState({ fieldValues: fieldValues });
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ submittingForm: true });
        
        let saveFunc = (data) => {
            this.setState({ submittingForm: false });
            this.toggle();

            if (typeof this.props.onSave === 'function') {
                this.props.onSave(data.space);
            }

            this.props.loadAssetDetailView(this.props.asset.asset_id, this.props.puCalcFy);
        };

        if(!this.state.use) {
            VacancyApi.create(this.state.fieldValues).then(saveFunc);
        }
        else {
            VacancyApi.update(this.state.use.asset_use_id, this.state.fieldValues).then(saveFunc);
        }
    }

    handleDeleteClick = (event) => {
        let confirmed = window.confirm('Are you sure you want to delete this vacancy?');

        if(confirmed) {
            this.setState({ submittingForm: true });

            VacancyApi.remove(this.state.use.asset_use_id).then(data => {
                this.setState({ submittingForm: false });
                this.toggle();

                this.props.loadAssetDetailView(this.props.asset.asset_id, this.props.puCalcFy);
            });
        }
    }

    render() {
        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">
                        {
                            (this.state.use)
                                ? 'Edit Vacancy'
                                : 'Add Vacancy'
                        }
                    </span>
                </ModalHeader>
                <form onSubmit={this.handleFormSubmit}>
                    <div className="p-3">
                        <VacancyFormFields
                            handleInputChange={this.handleInputChange}
                            numeraires={this.props.numeraires}
                            client={this.props.client}
                            selectNewAsset={this.selectNewAsset}
                            selectNewSpace={this.selectNewSpace}
                            assets={this.props.assets} 
                            selectedAsset={this.state.selectedAsset} 
                            fieldValues={this.state.fieldValues} />
                    </div>
                    {
                        (this.state.use) ? 
                            <div className="pt-0 pb-2 pl-1">
                                <button type="button" className="btn btn-link" onClick={this.handleDeleteClick}>
                                    <span className="icon icon-circle-with-cross"></span>&nbsp;
                                    Delete Vacancy
                                </button>
                            </div>
                            : ''
                    }
                    <div className="modal-actions">
                        <button type="button" className="btn-link modal-action" onClick={this.props.onHide}>
                            Cancel
                        </button>
                        <button type="submit" className="btn-link modal-action font-weight-bold">
                            {
                                this.state.submittingForm
                                    ? 'Loading...'
                                    : this.props.use
                                        ? 'Save Changes'
                                        : 'Add Vacancy'
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

VacancyModalForm.propTypes = {
    client: PropTypes.object.isRequired,
    space: PropTypes.object,
    asset: PropTypes.object,
    assets: PropTypes.array,
    numeraires: PropTypes.array.isRequired,
    loadAssetDetailView: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
};

const mapStateToProps = state => (
    {
        numeraires: state.numeraires.numeraires,
        client: state.client.client,
        puCalcFy: state.client.puCalcFy,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadAssetDetailView: (id, fy) => dispatch(AssetActionCreators.loadAssetDetailView(id, fy)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VacancyModalForm);