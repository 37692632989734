import { Component } from 'react';

class TableForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableContent: [[]],
            saving: false,
            justSaved: false,
            show: props.show,
        };
    }

    componentDidUpdate(props) {
        if(props.show !== this.state.show) {
            this.setState({ show: props.show });
        }
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding && typeof this.props.onHide === 'function') {
            this.props.onHide();
        }
    }

    handleTableChange = (event, source) => {
        if(!event) {
            return;
        }

        let tableContent = this.state.tableContent;

        event.map((change) => {
            let i = change[0];
            let j = change[1];

            if(!tableContent[i]) {
                tableContent[i] = [];
            }

            tableContent[i][j] = change[3];

            return null;
        });

        this.setState({ tableContent: tableContent });
    }

    hasRecordsInTable = () => {
        return this.state.tableContent.length > 0 && this.state.tableContent[0].length > 0;
    }

    clearForm = () => {
        this.setState({
            tableContent: [[]],
            saving: false,
            justSaved: false,
        });
    }
}

export default TableForm;