import * as UserApi from '../api/UserApi';

const LIST_IS_LOADING = 'users/LIST_IS_LOADING';
const LIST_HAS_LOADED = 'users/LIST_HAS_LOADED';
const LIST_HAS_ERRORED = 'users/LIST_HAS_ERRORED';

const initialState = {
    list: null,
    listIsLoading: false,
    listHasErrored: false,
    listErrorMessage: null,
};

export default function usersReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LIST_IS_LOADING:
            return {
                ...state,
                listIsLoading: true,
                listHasErrored: false,
                listErrorMessage: null,
            };
        case LIST_HAS_LOADED:
            return {
                ...state,
                list: action.list,
                listIsLoading: false,
                listHasErrored: false,
                listErrorMessage: null,
            };
        case LIST_HAS_ERRORED:
            return {
                ...state,
                list: null,
                listIsLoading: false,
                listHasErrored: true,
                listErrorMessage: action.errorMessage,
            };
        default:
            return state;
    }
}

export function usersListIsLoading() {
    return { type: LIST_IS_LOADING };
}

export function usersListHasLoaded(list) {
    return { type: LIST_HAS_LOADED, list: list };
}

export function usersListHasErrored(errorMessage) {
    return { type: LIST_HAS_ERRORED, errorMessage: errorMessage };
}

export function loadUsersList() {
    return (dispatch, getState) => {
        const state = getState();
        if (state.users.listIsLoading) {
            return;
        }

        dispatch(usersListIsLoading());

        UserApi.list()
            .then(data => {
                dispatch(usersListHasLoaded(data.users));
            })
            .catch(error => {
                dispatch(usersListHasErrored(error));
            });
    }
}
