import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

const SpaceListing = (props) => {
    const isSubspace = !!props.space.parent_space_id;

    let assetSizeNumeraire = props.numeraires.find((n) => {
        return (n.numeraire_id === props.asset.size_numeraire_id);
    });

    let listing = [];

    listing[listing.length] = (
        <tr key={props.space.space_id} className={isSubspace ? "text-muted" : ""}>
            <td className="pl-3">
                <div className={props.parentIsSubspace ? "pl-4" : (isSubspace ? "pl-2" : "pl-0")}>
                    { props.space.name } { props.space.space_code ? ' (' + props.space.space_code + ')' : '' }
                </div>
            </td>
            <td className="text-right">
                { props.space.total_size ? `${ numeral(props.space.total_size).format('0,0.00') } ${ assetSizeNumeraire.symbol }` : '' }
            </td>
            <td className="text-center">
                <a onClick={() => { props.showSpaceForm(props.space); }}>
                    <i className="icon icon-edit" />
                </a>
            </td>
        </tr>
    );

    let subspaceListings = props.asset.spaces
        .filter((space) => {
            return (space.parent_space_id === props.space.space_id);
        })
        .map((subspace) => (
            <SpaceListing 
                key={subspace.space_id}
                asset={props.asset}
                space={subspace}
                numeraires={props.numeraires}
                showSpaceForm={props.showSpaceForm}
                parentIsSubspace={isSubspace} />
        ));
    
    return [
        ...listing,
        ...subspaceListings
    ];
}

SpaceListing.propTypes = {
    asset: PropTypes.object.isRequired,
    space: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    showSpaceForm: PropTypes.func.isRequired,
    parentIsSubspace: PropTypes.bool.isRequired,
};

export default SpaceListing;