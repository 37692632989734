import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Constants from '../lib/Constants';

import TitleBar from '../components/TitleBar';

import IssueDetail from '../components/IssueDetail/IssueDetail';
import IssueModalForm from '../components/IssueForms/IssueModalForm';
import ProjectModalForm from '../components/ProjectForms/ProjectModalForm';
import ProjectImporterModal from '../components/ProjectImporterModal/ProjectImporterModal';

import * as IssueActionCreators from '../ducks/issues';
import * as CommonStateActions from '../ducks/common';
import IssueEditUseCostHistoryModal from '../components/IssueForms/IssueUseCostHistoryModal';

import EscrowDetailsModal from '../components/IssueForms/EscrowDetailsModal';
import IssueEditPUOverrideModal from '../components/IssueForms/IssueEditPUOverrideModal';
import ProjectExpendituresModalForm from '../components/ProjectForms/ProjectsExpendituresModalForm';
import IssueEditRedemptionScheduleModal from '../components/IssueForms/IssueEditRedemptionScheduleModal';
import { Fragment } from 'react/cjs/react.production.min';

class ClientIssues extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingIssueForm: false,
            editingIssue: null,
            showingProjectForm: false,
            editingProject: null,
            searchTerm: null,
            showingProjectImporterModal: false,
            showingEditCostHistoryModal: false,
            editingCostHistoryUse: null,
            showingEscrowDetailsModal: false,
            editingEscrowUse: null,
            showingPUOverrideModal: false,
            showingProjectExpendituresModal: false,
            showingRedemptionScheduleModal: false,
            entityListCollapsed: false,
        };
    }

    handleSearchInputChange = (event) => {
        const target = event.target;
        const value = target.value;

        this.setState({ searchTerm: value });
    }

    componentDidMount() {
        const urlClientId = parseInt(this.props.match.params.client_id, 10);
        const urlIssueId = this.props.match.params.issue_id ? parseInt(this.props.match.params.issue_id, 10) : null;

        if(!this.props.client || this.props.client.client_id !== urlClientId) {
            this.props.loadClientAndDependencies(urlClientId);
        }

        if(urlIssueId && (!this.props.issueDetailView || this.props.issueDetailView.issue.issue_id !== urlIssueId)) {
            this.props.loadIssueDetailView(urlIssueId, this.props.puCalcFy);
        }
        this.setPageTitle();
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.issue_id !== prevProps.match.params.issue_id) {
            this.props.loadIssueDetailView(this.props.match.params.issue_id, this.props.puCalcFy);
        }
        this.setPageTitle();
    }

    setPageTitle() {
        let title = `${Constants.pageTitleBase} ${Constants.pageTitleSeparator} Issues`;
        if (this.props.issueDetailView) {
            title += ` ${Constants.pageTitleSeparator} ${this.props.issueDetailView.issue.short_name}`;
        }
        document.title = title;
    }

    operativePuCalcFy() {
        if (!this.props.client || !this.props.puCalcFy) {
            return null;
        }
        let fy;
        if (this.props.puCalcFy === 'latest') {
            fy = this.props.client.pu_fys[0];
        }
        else {
            fy = this.props.puCalcFy;
        }
        return parseInt(fy, 10);
    }

    showIssueForm = (issue) => {
        this.setState({ showingIssueForm: true, editingIssue: issue ? issue : null });
    }

    hideIssueForm = () => {
        this.setState({ showingIssueForm: false, editingIssue: null });
    }

    showProjectForm = (project) => {
        this.setState({ showingProjectForm: true, editingProject: project ? project : null });
    }

    hideProjectForm = () => {
        this.setState({ showingProjectForm: false, editingProject: null });
    }

    showProjectImporterModal = () => {
        this.setState({ showingProjectImporterModal: true });
    }

    hideProjectImporterModal = () => {
        this.setState({ showingProjectImporterModal: false });
    }

    showEditCostHistoryModal = (use) => {
        this.setState({ showingEditCostHistoryModal: true, editingCostHistoryUse: use });
    }

    hideEditCostHistoryModal = () => {
        this.setState({ showingEditCostHistoryModal: false, editingCostHistoryUse: null });
    }

    showEscrowDetailsModal = (escrowUse) => {
        this.setState({ showingEscrowDetailsModal: true, editingEscrowUse: escrowUse });
    }

    hideEscrowDetailsModal = () => {
        this.setState({ showingEscrowDetailsModal: false, editingEscrowUse: null });
    }


    showPUOverrideModal = () => {
        this.setState({ showingPUOverrideModal: true });
    }

    hidePUOverrideModal = () => {
        this.setState({ showingPUOverrideModal: false });
    }

    showProjectExpendituresModal = () => {
        this.setState({ showingProjectExpendituresModal: true });
    }

    hideProjectExpendituresModal = () => {
        this.setState({ showingProjectExpendituresModal: false });
    }

    showRedemptionScheduleModal = () => {
        this.setState({ showingRedemptionScheduleModal: true });
    }

    hideRedemptionScheduleModal = () => {
        this.setState({ showingRedemptionScheduleModal: false });
    }

    toggleEntityList = () => {
        this.setState({ entityListCollapsed: !this.state.entityListCollapsed });
    }

    render() {
        if (!this.props.issues || !this.props.client || !this.props.numeraires || this.props.numeraires.length === 0) {
            return '';
        }

        /*
                

                    clear-entity-list-column
                */

        let issues = this.props.issues;

        if(this.state.searchTerm) {
            let term = this.state.searchTerm.toLowerCase();

            issues = issues.filter((issue, index) => {
                if(issue.short_name.toLowerCase().includes(term)) {
                    return true;
                }
                else {
                    return false;
                }
            });
        }

        let selectedIssueId = parseInt(this.props.match.params.issue_id, 10);

        let selectedIssue = this.props.issueDetailView ? this.props.issueDetailView.issue : null;

        /*
        
        */

        let issueListings = issues.map((issue) => {
            return (
                <li className="list-group-item p-0" key={ issue.issue_id }>
                    <div style={{ float: 'right', zIndex: 3 }} className="p-2">
                        { 
                            (issue.box_link_os || issue.box_link_tc || issue.box_link_8038) && (<div className="btn-group">
                                <button className="btn btn-sm btn-secondary" disabled><i className="icon icon-text-document" /></button>
                                { issue.box_link_os && (<a href={issue.box_link_os} target="_blank" className="btn btn-sm btn-secondary">OS/Othr</a>) }
                                { issue.box_link_tc && (<a href={issue.box_link_tc} target="_blank" className="btn btn-sm btn-secondary">TC</a>) }
                                { issue.box_link_8038 && (<a href={issue.box_link_8038} target="_blank" className="btn btn-sm btn-secondary">8038</a>) }
                            </div>)
                        }
                    </div>
                    <Link
                        className={ "entity-list-item nested" + (issue.issue_id === selectedIssueId ? " active" : '')}
                        to={ "/clients/" + this.props.client.client_id + "/issues/" + issue.issue_id }>
                        <div className="font-weight-bold">{ issue.short_name }</div>
                        <div className="text-muted">{ moment(issue.dated_date).format('M/D/YYYY') }</div>
                    </Link>
                </li>
            );
        });

        return (
            <div>
                <TitleBar pageTitle="Issues" clientName={this.props.client.name} />

                <IssueModalForm
                    show={this.state.showingIssueForm}
                    onHide={this.hideIssueForm}
                    loadIssues={this.props.loadIssues}
                    numeraires={this.props.numeraires}
                    issue={this.state.editingIssue}
                    client={this.props.client}
                    showNSPInputs={true} />

                {
                    this.props.issueDetailView
                        ? <ProjectModalForm
                            show={this.state.showingProjectForm}
                            onHide={this.hideProjectForm}
                            numeraires={this.props.numeraires}
                            project={this.state.editingProject}
                            client={this.props.client}
                            assets={this.props.assets}
                            issue={selectedIssue}
                            showCostInputs={true} />
                        : ''
                }

                <IssueEditRedemptionScheduleModal
                    show={this.state.showingRedemptionScheduleModal}
                    onHide={this.hideRedemptionScheduleModal} />

                <ProjectExpendituresModalForm
                    show={this.state.showingProjectExpendituresModal}
                    onHide={this.hideProjectExpendituresModal} />

                <ProjectImporterModal
                    show={this.state.showingProjectImporterModal}
                    onHide={this.hideProjectImporterModal} />
                
                <IssueEditUseCostHistoryModal
                    show={this.state.showingEditCostHistoryModal}
                    onHide={this.hideEditCostHistoryModal}
                    issue={this.props.issueDetailView}
                    use={this.state.editingCostHistoryUse} />
                
                <EscrowDetailsModal
                    show={this.state.showingEscrowDetailsModal}
                    onHide={this.hideEscrowDetailsModal}
                    client={this.props.client}
                    escrowUse={this.state.editingEscrowUse} />
                
                <IssueEditPUOverrideModal
                    show={this.state.showingPUOverrideModal}
                    onHide={this.hidePUOverrideModal} />
                
                

                <div className="clear-title-bar">
                    {
                        !this.state.entityListCollapsed
                            ? (
                                <div className="entity-list-column hide" style={{ width: '17%', marginLeft: '-15px' }}>
                                    <ul className="list-group rounded-0 list-group-flush p-0">
                                        <li className="list-group-item rounded-0 entity-search-item p-2 d-flex w-100 justify-content-between">
                                            <input
                                                className="form-control rounded-0"
                                                type="text"
                                                placeholder="Search Issues" 
                                                onChange={ this.handleSearchInputChange } />
                                            
                                            <a className="btn btn-outline" onClick={() => { this.showIssueForm(); }}>
                                                <i className="icon icon-plus" /> Add Issue
                                            </a>
                                            <button className="entity-list-toggle-button text-center" onClick={ () => { this.toggleEntityList() } }>
                                                <i className="icon icon-chevron-left" />
                                            </button>
                                        </li>
                                        <li className="list-group-item rounded-0 p-2">
                                            <a className="btn btn-outline" onClick={() => { this.showProjectImporterModal(); }}>
                                                <i className="icon icon-upload" /> Import Projects
                                            </a>
                                        </li>
                                        { issueListings }
                                    </ul>
                                </div>
                            ) : <Fragment />
                    }
                    {
                        /*
                        <button className="entity-list-toggle-button" >
                                        
                                    </button>
                        */
                        this.state.entityListCollapsed
                            ? (
                                <div className="entity-list-collapsed text-center" onClick={ () => { this.toggleEntityList() } }>
                                    <i className="icon icon-chevron-right" />
                                    
                                </div>
                            ) : <Fragment />
                    }
                    <div className={ this.state.entityListCollapsed ? "clear-entity-list-collapsed" : "clear-entity-list-column" }>
                        <div className="pl-3 pr-3 pb-3">
                            {
                                this.props.issueDetailView 
                                    ? <IssueDetail
                                        showProjectForm={this.showProjectForm}
                                        showProjectImporterModal={this.showProjectImporterModal}
                                        showIssueForm={this.showIssueForm}
                                        showEditCostHistoryForm={this.showEditCostHistoryModal}
                                        showEditEscrowDetailsForm={this.showEscrowDetailsModal}
                                        showPUOverrideForm={this.showPUOverrideModal}
                                        showProjectExpendituresModal={this.showProjectExpendituresModal}
                                        showEditRedemptionScheduleModal={this.showRedemptionScheduleModal}
                                        issueDetailView={this.props.issueDetailView} /> 
                                    : this.state.issueDetailIsLoading ? 'Loading...' : '' 
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ClientIssues.propTypes = {
    client: PropTypes.object,
    issues: PropTypes.array,
    numeraires: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = state => (
    {
        assets: state.assets.assets,
        client: state.client.client,
        puCalcFy: state.client.puCalcFy,
        clientIsLoading: state.client.clientIsLoading,
        clientHasErrored: state.client.clientHasErrored,
        clientErrorMessage: state.client.clientErrorMessage,
        issues: state.issues.list,
        issueDetailView: state.issues.detailView,
        numeraires: state.numeraires.numeraires,
        notes: state.notes.notes,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadClientAndDependencies: (clientId) => CommonStateActions.loadClientAndDependencies(dispatch, clientId),
        loadIssueDetailView: (issueId, puCalcFy) => dispatch(IssueActionCreators.loadIssueDetailView(issueId, puCalcFy)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientIssues);
