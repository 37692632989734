import * as AssetUserApi from '../api/AssetUserApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Common from './common';

const LIST_IS_LOADING = 'asset_users/LIST_IS_LOADING';
const LIST_HAS_LOADED = 'asset_users/LIST_HAS_LOADED';
const LIST_HAS_ERRORED = 'asset_users/LIST_HAS_ERRORED';

const DETAIL_VIEW_IS_LOADING = 'asset_users/DETAIL_VIEW_IS_LOADING';
const DETAIL_VIEW_HAS_LOADED = 'asset_users/DETAIL_VIEW_HAS_LOADED';
const DETAIL_VIEW_HAS_ERRORED = 'asset_users/DETAIL_VIEW_HAS_ERRORED';

const CREATE = 'asset_users/CREATE';
const REMOVE = 'asset_users/REMOVE';
const UPDATE = 'asset_users/UPDATE';

const SWITCH_CLIENT = 'asset_users/SWITCH_CLIENT';

const initialState = {
    list: null,
    listIsLoading: false,
    listHasErrored: false,
    listErrorMessage: null,
    detailView: null,
    detailViewIsLoading: false,
    detailViewHasErrored: false,
    detailViewErrorMessage: null,
};

export default function assetUsersReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LIST_IS_LOADING:
            return {
                ...state,
                listIsLoading: true,
                listHasErrored: false,
                listErrorMessage: null,
            };
        case LIST_HAS_LOADED:
            return {
                ...state,
                list: action.users,
                listIsLoading: false,
                listHasErrored: false,
                listErrorMessage: null,
            };
        case LIST_HAS_ERRORED:
            return {
                ...state,
                list: null,
                listIsLoading: false,
                listHasErrored: true,
                listErrorMessage: action.errorMessage,
            };
        case DETAIL_VIEW_IS_LOADING:
            return {
                ...state,
                detailViewIsLoading: true,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case DETAIL_VIEW_HAS_LOADED:
            return {
                ...state,
                detailView: action.detailView,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case DETAIL_VIEW_HAS_ERRORED:
            return {
                ...state,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: true,
                detailViewErrorMessage: action.errorMessage,
            };
        case SWITCH_CLIENT:
            return {
                ...state,
                list: null,
                listIsLoading: false,
                listHasErrored: false,
                listErrorMessage: null,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case CREATE:
            return state;
        case REMOVE:
            return {
                ...state,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case UPDATE:
            return state;
        default:
            return state;
    }
}

export function assetUsersListIsLoading() {
    return { type: LIST_IS_LOADING };
}

export function assetUsersListHasLoaded(users) {
    return { type: LIST_HAS_LOADED, users: users };
}

export function assetUsersListHasErrored(errorMessage) {
    return { type: LIST_HAS_ERRORED, errorMessage: errorMessage };
}

export function assetUserDetailViewIsLoading() {
    return { type: DETAIL_VIEW_IS_LOADING };
}

export function assetUserDetailViewHasLoaded(detailView) {
    return { type: DETAIL_VIEW_HAS_LOADED, detailView: detailView };
}

export function assetUserDetailViewHasErrored(errorMessage) {
    return { type: DETAIL_VIEW_HAS_ERRORED, errorMessage: errorMessage };
}

export function switchClient() {
    return { type: SWITCH_CLIENT };
}

export function loadAssetUsers(clientId) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.asset_users.listIsLoading) {
            return;
        }

        dispatch(assetUsersListIsLoading());
        dispatch(showLoading());

        AssetUserApi.listForClient(clientId)
            .then(data => {
                dispatch(assetUsersListHasLoaded(data.asset_users));
                dispatch(hideLoading());
            })
            .catch(error => {
                dispatch(assetUsersListHasErrored(error));
                dispatch(hideLoading());
            });
    }
}

export function loadAssetUserDetailView(id, puCalcFy) {
    return (dispatch) => {
        dispatch(assetUserDetailViewIsLoading());
        dispatch(showLoading());

        AssetUserApi.detailView(id, puCalcFy)
            .then(data => {
                dispatch(assetUserDetailViewHasLoaded(data));
                dispatch(hideLoading());
            })
            .catch(error => {
                dispatch(assetUserDetailViewHasErrored(error));
                dispatch(hideLoading());
            });
    }
}

export function create(assetUserData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        AssetUserApi.create(assetUserData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, getState());
                dispatch(loadAssetUsers(data.asset_user.client_id));
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function update(assetUserId, assetUserData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        assetUserId = parseInt(assetUserId, 10);

        AssetUserApi.update(assetUserId, assetUserData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, getState());
                dispatch(loadAssetUsers(data.asset_user.client_id));
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function remove(assetUserId) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        assetUserId = parseInt(assetUserId, 10);

        AssetUserApi.remove(assetUserId)
            .then(data => {
                dispatch(hideLoading());
                dispatch({ type: REMOVE });
                if (state.client && state.client.client) {
                    Common.reloadDetailViews(dispatch, getState());
                    dispatch(loadAssetUsers(state.client.client.client_id));
                }
                
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}
