import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import * as AssetActionCreators from '../../ducks/assets';

class MergeAssetsModalForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            submittingForm: false,
            sourceAssetId: null,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            show: props.show,
            asset: props.asset,
            submittingForm: false,
            sourceAssetId: null,
        });
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding)
            this.props.onHide();
    }
    
    switchSourceAssetId = (event) => {
        this.setState({ sourceAssetId: event.target.value }, () => { console.log(this.state); });
    }

    saveFunc = (data) => {
        this.toggle();
        this.setState({ submittingForm: false });

        if (typeof this.props.onSave === 'function') {
            this.props.onSave(data.asset);
        }
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        
        if(this.state.sourceAssetId) {
            this.setState({ submittingForm: true });
            this.props.mergeAssets(this.state.sourceAssetId, this.props.targetAssetId).then(this.saveFunc);
        }
    }

    render() {
        const assetOptions = this.props.assets
            .filter(a => {
                return a.asset_id !== this.props.targetAssetId;
            })
            .map(a => (
                <option key={a.asset_id} value={a.asset_id}>
                    {a.name}
                </option>
            ));
        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">
                        Merge Assets
                    </span>
                </ModalHeader>
                <form onSubmit={this.handleFormSubmit}>
                    <div className="p-3">
                        <p className="text-danger">WARNING: This action is not reversible</p>
                        <p>Asset to merge into { this.props.targetAssetName }:</p>
                        <select
                            className="custom-select rounded-0 w-100"
                            name="usageNumeraireId"
                            onChange={this.switchSourceAssetId}>
                            <option key="none"></option>
                            {assetOptions}
                        </select>
                    </div>
                    <div className="modal-actions">
                        <button type="button" className="btn-link modal-action" onClick={this.props.onHide}>
                            Cancel
                        </button>
                        <button type="submit" className="btn-link modal-action font-weight-bold">
                            {
                                this.state.submittingForm
                                    ? 'Loading...'
                                    : 'Merge Assets'
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

MergeAssetsModalForm.propTypes = {
    targetAssetId: PropTypes.number.isRequired,
    targetAssetName: PropTypes.string.isRequired,
    assets: PropTypes.array.isRequired,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
};

const mapStateToProps = state => (
    {
        assets: state.assets.assets,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        mergeAssets: (sourceAssetId, targetAssetId) => dispatch(AssetActionCreators.merge(sourceAssetId, targetAssetId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MergeAssetsModalForm);