import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import numeral from 'numeral';
import moment from 'moment';
import { connect } from 'react-redux';
import 'rc-tooltip/assets/bootstrap_white.css';

import { ucFirst } from '../../lib/TextFunctions';
import * as DateFunctions from '../../lib/DateFunctions';
import * as ModelUtil from '../../lib/ModelUtil';

const NeutralUseListing = (props) => {
    const container = ModelUtil.containerForUse(props.use, props.assets);
    
    let usageNumeraireId;
    if (props.use.usage_numeraire_id) {
        usageNumeraireId = props.use.usage_numeraire_id;
    }
    else if (container.usage_numeraire_id) {
        usageNumeraireId = container.usage_numeraire_id;
    }
    else {
        usageNumeraireId = props.asset.size_numeraire_id;
    }

    let sizeNumeraireId = container.usage_numeraire_id 
        ? container.usage_numeraire_id : props.asset.size_numeraire_id;

    const usageNumeraire = ModelUtil.numeraireForNumeraireId(usageNumeraireId, props.numeraires);
    const sizeNumeraire = ModelUtil.numeraireForNumeraireId(sizeNumeraireId, props.numeraires);
    
    let baseCellClass = (props.firstCell ? 'top-dotted-border ' : 'border-0 ');
    
    return (
        <tr>
            <td className={baseCellClass + "pl-3"}>
                { ucFirst(props.use.type) }
            </td>
            <td className={baseCellClass}>
                {container.space_id ? container.name : <i>General Asset</i>}
            </td>
            <td className={baseCellClass + "text-right"}>
                {moment(props.use.start_date).format('MM/DD/YY')}
            </td>
            <td className={baseCellClass + "text-right"}>
                {moment(props.use.end_date).format('MM/DD/YY')}
            </td>
            <td className={baseCellClass + "text-right"}>
                { numeral(props.use.pu_calc.gross_size).format('0,00.00') }&nbsp;
                {usageNumeraire.symbol}
            </td>
            <td className={baseCellClass + "text-right"}>
                {
                    props.use.pu_calc.time_factor === 1
                        ? <span className="text-muted">{ DateFunctions.numDaysDescription(props.use.pu_calc.time_numerator) }</span>
                        : (
                            <Tooltip
                                placement="top"
                                overlay={
                                    numeral(props.use.pu_calc.time_numerator).format('0,00') + " / " +
                                    numeral(props.use.pu_calc.time_denominator).format('0,00') + " days - " + 
                                    numeral(props.use.pu_calc.time_factor).format('0.00 %')
                                }>
                                <span className="dotted-underline">{ DateFunctions.numDaysDescription(props.use.pu_calc.time_numerator) }</span>
                            </Tooltip>
                        )
                }
            </td>
            <td className={baseCellClass + "text-right"}>
                {numeral(props.use.pu_calc.usage_size).format('0,0.00')} {sizeNumeraire.symbol}
            </td>
            <td className={baseCellClass + "text-right"}>
                <a onClick={() => { props.showVacancyForm(props.use); }}>
                    <i className="icon icon-edit" />
                </a>
            </td>
        </tr>
    );
}
//<a onClick={() => { props.showUseForm(props.use); }}><i className="icon icon-edit" /></a>
NeutralUseListing.propTypes = {
    use: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    assets: PropTypes.array.isRequired,
    numeraires: PropTypes.array.isRequired,
    firstCell: PropTypes.element,
    showVacancyForm: PropTypes.func.isRequired,
};


const mapStateToProps = state => (
    {
        numeraires: state.numeraires.numeraires,
        assets: state.assets.assets,
    }
);

export default connect(mapStateToProps)(NeutralUseListing);