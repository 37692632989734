import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Constants from '../lib/Constants';
import * as CommonStateFunctions from '../ducks/common';
import * as ModelUtil from '../lib/ModelUtil';
import TitleBar from '../components/TitleBar';
import EntityList from '../components/Notebook/EntityList';
import Notepad from '../apps/Notepad/Notepad';

const ClientNotebook = props => {    
    const client = props.client;
    const routeClientId = parseInt(props.match.params.client_id, 10);
    const routeEntityTableName = props.match.params.entity_type;
    const routeEntityType = ModelUtil.entityTypeForTableName(props.match.params.entity_type);
    const routeEntityId = props.match.params.entity_id ? parseInt(props.match.params.entity_id, 10) : null;

    let routeEntity = null;
    if(routeEntityTableName === 'assets') {
        routeEntity = ModelUtil.assetForAssetId(routeEntityId, props.assets);
    }
    else if(routeEntityTableName === 'issues') {
        routeEntity = ModelUtil.issueForIssueId(routeEntityId, props.issues);
        if(routeEntity) {
            routeEntity.name = routeEntity.short_name;
        }
    }
    else if(routeEntityTableName === 'asset_users') {
        routeEntity = ModelUtil.assetUserForAssetUserId(routeEntityId, props.asset_users);
    }

    let title = `${Constants.pageTitleBase} ${Constants.pageTitleSeparator} Notebook`;
    //if (this.props.assetDetailView) {
    //    title += ` ${Constants.pageTitleSeparator} ${this.props.assetDetailView.asset.name}`;
    //}
    document.title = title;

    if (
        (!client || client.client_id !== routeClientId)
        && !props.clientIsLoading
        && !props.clientHasErrored
        && !props.notesListIsLoading
        && !props.notesListHasErrored
    ) {
        props.loadClientAndDependencies(routeClientId);
        return <Fragment />;
    }

    if(!client) {
        return <Fragment />;
    }

    return (
        <Fragment>
            <TitleBar pageTitle="Notebook" clientName={client.name} />
            <div className="clear-title-bar">
                <div className="entity-list-column" style={{ width: '17%', marginLeft: '-15px' }}>
                    <EntityList
                        selectedEntityType={routeEntityType}
                        selectedEntityId={routeEntityId} />
                </div>

                <div className="clear-entity-list-column">
                    <div className="pb-3 pr-3 pl-3 pt-2">
                        {
                            routeEntity
                                ? (
                                    <h3 className="font-weight-bold">
                                        <Link to={ `/clients/${client.client_id}/${routeEntityTableName}/${routeEntityId}` }>{ routeEntity.name }</Link>
                                    </h3>
                                ) : <Fragment />
                        }
                        {
                            routeEntityType === 'Research'
                                ? (
                                    <h3 className="font-weight-bold">
                                        Research Notes
                                    </h3>
                                ) : <Fragment />
                        }
                        {
                            routeEntityType === 'All'
                                ? (
                                    <h3 className="font-weight-bold">
                                        All Client Notes
                                    </h3>
                                ) : <Fragment />
                        }
                        {
                            !routeEntityType
                                ? (
                                    <h3 className="font-weight-bold">
                                        General Notes
                                    </h3>
                                ) : <Fragment />
                        }
                        <Notepad
                            entityType={routeEntityType === 'All' ? null : routeEntityType}
                            entityId={routeEntityType === 'All' ? null : routeEntityId}
                            showAllEntities={routeEntityType === 'All'}
                            listHeight={600} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        clientIsLoading: state.client.clientIsLoading,
        clientHasErrored: state.client.clientHasErrored,
        clientErrorMessage: state.client.clientErrorMessage,
        notes: state.notes.notes,
        notesListIsLoading: state.notes.notesListIsLoading,
        notesListHasErrored: state.notes.notesListHasErrored,
        notesListErrorMessage: state.notes.notesListErrorMessage,
        assets: state.assets.assets,
        asset_users: state.asset_users.list,
        issues: state.issues.list,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadClientAndDependencies: (clientId) => CommonStateFunctions.loadClientAndDependencies(dispatch, clientId),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientNotebook);