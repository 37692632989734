import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import ProjectListing from './ProjectListing';
import ProjectsTableSummaryBlock from './ProjectsTableSummaryBlock';

const ProjectsTable = (props) => {
    if(!props.issue) {
        return '';
    }

    let totalProceedsSpent = 0;
    let totalEquitySpent = 0;
    let totalProjectsCost = 0;

    const projectRows = props.projects.map((p, i) => {
        totalProceedsSpent += numeral(p.pu_calc.proceeds).value();
        totalEquitySpent += numeral(p.pu_calc.equity).value();
        totalProjectsCost += numeral(p.pu_calc.cost).value();

        return <ProjectListing project={p} key={i} tmp={false} showProjectForm={props.showProjectForm} />
    });
    
   const escrowRows = props.issue.pu_calc.refunded_issue_pu_details
        .filter((puDetails) => {
            return !puDetails.is_sk_excluded;
        })
        .map((puDetails) => {
            const use = props.issue.uses.find((u) => {
                return u.issue_use_id === puDetails.issue_use_id;
            });
            const refundedIssue = props.issues.find((i) => {
                return i.issue_id === puDetails.refunded_issue_id;
            });
            const skIncludedFactor = 1 - puDetails.sk_excluded_factor;

            const proceedsSpent = puDetails.proceeds_expended * skIncludedFactor;
            const equitySpent = puDetails.equity_expended * skIncludedFactor;
            const projectCost = puDetails.sk_cost;

            totalProceedsSpent += proceedsSpent;
            totalEquitySpent += equitySpent;
            totalProjectsCost += projectCost;
                        
            return (
                <tr key={`use-${puDetails.issue_use_id}`}>
                    <td className="pl-3">&nbsp;</td>
                    <td>
                        { use.name }
                    </td>
                    <td>
                        <Link to={ "/clients/" + props.issue.client_id + "/issues/" + puDetails.refunded_issue_id }>
                            { refundedIssue.short_name }
                        </Link>
                    </td>
                    <td className="text-right">
                        { numeral(proceedsSpent).format('$0,000.00') }
                    </td>
                    <td className="text-right">
                        { numeral(equitySpent).format('$0,000.00') }
                    </td>
                    <td className="text-right">
                        { numeral(projectCost).format('$0,000.00') }
                    </td>
                    <td className="text-right">
                        { numeral(puDetails.line4_factor).format('0,0.00%') }
                    </td>
                    <td className="text-right">
                        { numeral(puDetails.line4_cost).format('$0,000.00') }
                    </td>
                    <td className="text-right">
                        { numeral(puDetails.line5_factor).format('0,0.00%') }
                    </td>
                    <td className="text-right">
                        { numeral(puDetails.line5_cost).format('$0,000.00') }
                    </td>
                    <td>&nbsp;</td>
                </tr>
            );
        });
    
    let coiRows = [];
    const skIncludedFactor = 1 - numeral(props.issue.pu_calc.sk_excluded_factor).value();
    const coiUses = props.issue.uses.filter((u) => {
        return u.is_coi;
    });

    coiUses.forEach((u) => {
        const ch = u.cost_history.find((h) => {
            return h.as_of_date === props.issue.pu_calc.fy_end_date;
        });

        if (ch) {
            const allProceedsExpended = _.round(numeral(ch.proceeds_expended).value() + numeral(ch.earnings_expended).value(), 2) * skIncludedFactor;

            totalProceedsSpent += allProceedsExpended;
            totalProjectsCost += allProceedsExpended;

            if (allProceedsExpended !== 0) {
                coiRows.push(
                    <tr key={`use-${u.issue_use_id}`}>
                        <td className="pl-3">&nbsp;</td>
                        <td>{ u.name }</td>
                        <td>&nbsp;</td>
                        <td className="text-right">${ numeral(allProceedsExpended).format('0,000.00') }</td>
                        <td className="text-right">$0.00</td>
                        <td className="text-right">${ numeral(allProceedsExpended).format('0,000.00') }</td>
                        <td className="text-right">0.00%</td>
                        <td className="text-right">$0.00</td>
                        <td className="text-right">0.00%</td>
                        <td className="text-right">$0.00</td>
                        <td>&nbsp;</td>
                    </tr>
                );
            }
        }
    });
    

    // proceeds spent, equity spent, total project cost, line 4 %, line 4 cost, line 5 %, line 5 cost, total %, total cost
    return (
        <table className="table table-data table-hover">
            <thead>
                <tr className="bottom-dotted-border">
                    <th style={{width: '2%'}} className="pl-3">&nbsp;</th>
                    <th style={{width: '15%'}}>Project</th>
                    <th style={{width: '16%'}}>Beneficiaries</th>
                    <th style={{width: '11%'}} className="text-right">
                        Tot. Proc. Spent a/o
                        <br />{ moment(props.issue.pu_calc.fy_end_date).format('MM/DD/YY') }
                    </th>
                    <th style={{width: '11%'}} className="text-right">
                        Tot. Eq. Spent a/o
                        <br />{ moment(props.issue.pu_calc.fy_end_date).format('MM/DD/YY') }
                    </th>
                    <th style={{width: '11%'}} className="text-right">
                        Total Project Cost a/o
                        <br />{ moment(props.issue.pu_calc.fy_end_date).format('MM/DD/YY') }
                    </th>
                    <th style={{width: '8%'}} className="text-right">SK Line 4 %</th>
                    <th style={{width: '8%'}} className="text-right">SK Line 4 Cost</th>
                    <th style={{width: '8%'}} className="text-right">SK Line 5 %</th>
                    <th style={{width: '8%'}} className="text-right">SK Line 5 Cost</th>
                    <th style={{width: '2%'}}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                { projectRows }
                { escrowRows }
                { coiRows }
            </tbody>
            <tfoot>
                <tr className="text-right text-muted font-weight-bold">
                    <td>&nbsp;</td>
                    <td colSpan="2">
                        Total
                    </td>
                    <td className="text-right">
                        { numeral(totalProceedsSpent).format('$0,000.00') }
                    </td>
                    <td className="text-right">
                        { numeral(totalEquitySpent).format('$0,000.00') }
                    </td>
                    <td className="text-right">
                        { numeral(totalProjectsCost).format('$0,000.00') }
                    </td>
                    <td>&nbsp;</td>
                    <td className="text-right">
                        { numeral(props.issue.pu_calc.sk_line4_cost).format('$0,000.00') }
                    </td>
                    <td>&nbsp;</td>
                    <td className="text-right">
                        { numeral(props.issue.pu_calc.sk_line5_cost).format('$0,000.00') }
                    </td>
                    <td>&nbsp;</td>
                </tr>

                <ProjectsTableSummaryBlock
                    label="Capital Project Private Use Cost Figure:"
                    value={props.issue.pu_calc.projects_pu_cost}
                    numberFormat="$0,000.00"
                    colCount={10}
                    paddingTop={3} />
                
                <ProjectsTableSummaryBlock
                    label="Refunding Private Use Cost Figure:"
                    value={props.issue.pu_calc.sk_line4_refundings_pu_cost + props.issue.pu_calc.sk_line5_refundings_pu_cost}
                    numberFormat="$0,000.00"
                    colCount={10}
                    paddingTop={0} />
                
                <ProjectsTableSummaryBlock
                    label="Total Capital Project Cost:"
                    value={props.issue.pu_calc.capex_cost}
                    numberFormat="$0,000.00"
                    colCount={10}
                    paddingTop={3} />
                
                <ProjectsTableSummaryBlock
                    label="Total Qualified Equity:"
                    value={props.issue.pu_calc.qualified_equity_amount}
                    numberFormat="$0,000.00"
                    colCount={10}
                    paddingTop={0} />
                
                <ProjectsTableSummaryBlock
                    label="Qualified Equity % of Project Cost:"
                    value={props.issue.pu_calc.qualified_equity_factor}
                    numberFormat="0.00%"
                    colCount={10}
                    paddingTop={0} />
                
                <ProjectsTableSummaryBlock
                    label="Capital Project Private Use %:"
                    value={props.issue.pu_calc.projects_pu_factor}
                    numberFormat="0.00%"
                    colCount={10}
                    paddingTop={0} />
                
                <ProjectsTableSummaryBlock
                    label="Capital Project Private Use % Allocable to the Bonds:"
                    value={props.issue.pu_calc.projects_equity_adjusted_factor}
                    numberFormat="0.00%"
                    colCount={10}
                    paddingTop={0} />
                
                <ProjectsTableSummaryBlock
                    label="Net Sale Proceeds:"
                    value={props.issue.pu_calc.sk_net_sale_proceeds}
                    numberFormat="$0,000.00"
                    colCount={10}
                    paddingTop={3} />

                <ProjectsTableSummaryBlock
                    label="Schedule K Non Gov't Private Use (Part III, Line 4):"
                    value={props.issue.pu_calc.sk_line4_equity_adjusted_factor}
                    numberFormat="0.00%"
                    colCount={10}
                    paddingTop={3} />
                
                <ProjectsTableSummaryBlock
                    label="Schedule K Non Gov't Private Use (Part III, Line 5):"
                    value={props.issue.pu_calc.sk_line5_equity_adjusted_factor}
                    numberFormat="0.00%"
                    colCount={10}
                    paddingTop={0} />
                
                <ProjectsTableSummaryBlock
                    label="Schedule K Non Gov't Private Use (Part III, Line 6):"
                    value={props.issue.pu_calc.sk_line4_equity_adjusted_factor + props.issue.pu_calc.sk_line5_equity_adjusted_factor}
                    numberFormat="0.00%"
                    colCount={10}
                    paddingTop={0} />
            </tfoot>
        </table>
    );
}

ProjectsTable.propTypes = {
    projects: PropTypes.array.isRequired,
    issue: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired,
    showProjectForm: PropTypes.func,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        numeraires: state.numeraires.numeraires,
        issues: state.issues.list,
    }
);

export default connect(mapStateToProps)(ProjectsTable);