import React from 'react';
import PropTypes from 'prop-types';

const UtilityFormFields = (props) => {
    return (
        <div>
            <div className="form-group row">
                <label className="col-3 col-form-label text-muted text-uppercase">Name</label>
                <div className="col-9">
                    <input
                        type="text"
                        className="form-control rounded-0"
                        onChange={props.handleInputChange}
                        value={props.fieldValues.name}
                        name="name" />
                </div>
            </div>

            <div className="form-group row">
                <label className="col-3 col-form-label text-muted text-uppercase">% of Facility</label>
                <div className="col-9">
                    <input
                        type="text"
                        className="form-control rounded-0"
                        onChange={props.handleInputChange}
                        value={props.fieldValues.plant_factor}
                        name="plant_factor" />
                </div>
            </div>

            <div className="form-group row">
                <label className="col-3 col-form-label text-muted text-uppercase">Usage Measurement</label>
                <div className="col-9">
                    <select
                        className="custom-select rounded-0 w-100"
                        name="user_size_as_usage_factor"
                        value={props.fieldValues.user_size_as_usage_factor}
                        onChange={props.handleInputChange}>
                        <option value="1">User Size</option>
                        <option value="0">Manual</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

UtilityFormFields.propTypes = {
    handleInputChange: PropTypes.func.isRequired,
    fieldValues: PropTypes.object.isRequired,
}

export default UtilityFormFields;
