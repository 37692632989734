const axios = require('axios');
const moment = require('moment');
const ndProxyUrl = `https://netdocs-api.blxgroup.com`;

class NetDocs {
    static async clientWorkspaces(clientId) {
        const response = await axios.get(`${ndProxyUrl}/clients/${clientId}/workspaces/`);

        if(!response.data.success) {
            throw new Error(`NetDocuments error: ${response.data.error}`);
        }

        return response.data.data;
    }

    static async workspaceTree(clientId, clientMatterNumber) {
        const response = await axios.get(`${ndProxyUrl}/clients/${clientId}/workspaces/${clientMatterNumber}`, {
            params: {
                full_tree: true
            }
        });

        if(!response.data.success) {
            throw new Error(`NetDocuments error: ${response.data.error}`);
        }

        return response.data.data;
    }

    static async searchFolders(clientMatterNumber, type, name) {
        const response = await axios.get(ndProxyUrl + '/folders/search', {
            params: {
                client_matter_number: clientMatterNumber,
                type: type,
                name: name
            }
        });

        if(!response.data.success) {
            throw new Error(`NetDocuments error: ${response.data.error}`);
        }

        return response.data.data;
    }

    static async documentContent(docId) {
        const response = await axios.get(ndProxyUrl + `/documents/${docId}`);
        
        if(!response.data.success) {
            throw new Error(`NetDocuments error: ${response.data.error}`);
        }

        return response.data.data.base64;
    }

    static async documentPdfPreview(docId) {
        const response = await axios.get(ndProxyUrl + `/documents/preview/${docId}`);
        
        if(!response.data.success) {
            throw new Error(`NetDocuments error: ${response.data.error}`);
        }

        return response.data.data.file;
    }

    static async saveDocument(name, base64File, containerId, type, subtype) {
        const response = await axios.post(ndProxyUrl + '/documents/', {
            name: name.split('.').slice(0, -1).join('.'),
            extension: name.split('.').pop(),
            destination: containerId,
            type: type,
            subtype: subtype ? subtype : "N/A",
            file: base64File
        });

        if(!response.data.success) {
            throw new Error(`NetDocuments error: ${response.data.error}`);
        }

        return response.data.data;
    }

    static async createFolder(name, parentContainerId) {
        const response = await axios.post(ndProxyUrl + '/folders/', {
            name: name,
            parent_container_id: parentContainerId
        });

        if(!response.data.success) {
            throw new Error(`NetDocuments error: ${response.data.error}`);
        }

        return response.data.data;
    }

    static async findOrCreateResponsesFolder(dmsClientMatterNumber, compDate) {
        const compDateStr = moment(compDate).format('YYYY-MM-DD');
        const responsesFolderName = `${compDateStr} Computation Client Responses`;
        //dmsClientMatterNumber = 'JBC'; // TESTING MATTER

        // check if matter already has a responses folder for this comp. date
        // if not, create one
        let responsesFolder;
        let responsesFolders = await NetDocs.searchFolders(dmsClientMatterNumber, null, responsesFolderName);
        if(responsesFolders.length < 1) {
            // find matter's investment/debt records folder
            let invDebtFolders = await NetDocs.searchFolders(dmsClientMatterNumber, 'INV_DEBT', 'investment-debt records');
            if(invDebtFolders.length < 1) {
                throw new Error(`Cannot find NetDocuments Investment-Debt Records folder for ${dmsClientMatterNumber}`);
            }

            responsesFolder = NetDocs.createFolder(responsesFolderName, invDebtFolders[0].nd_container_id);
        }
        else {
            responsesFolder = responsesFolders[0];
        }

        return responsesFolder;
    }
}

export default NetDocs;