import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import * as _ from 'lodash';

const calcTotals = (usesAmounts) => {
    let totals = {};
    usesAmounts.forEach((u) => {
        for (let key in u.amounts) {
            if (typeof totals[key] === 'undefined') {
                totals[key] = 0;
            }

            totals[key] += numeral(u.amounts[key]).value();
        }
    });
    return totals;
}

const issueUseAmountsToRow = (props, amounts, use, title)  => {
    const key = title ? 'title-' + title.replace(' ', '-') : use.issue_use_id;

    let refundedIssue;
    if (use) {
        refundedIssue = use.refunded_issue_id ? props.issues.find((i) => {
            return i.issue_id === use.refunded_issue_id;
        }) : null;
    }

    return (
        <tr key={key} className={ title ? 'font-weight-bold' : '' }>
            <td>
                { use ? use.name : title }&nbsp;
                {
                    refundedIssue ? (
                        <Link to={ "/clients/" + refundedIssue.client_id + "/issues/" + refundedIssue.issue_id }>
                            - { refundedIssue.short_name }
                        </Link>
                    ) : ''
                }
            </td>
            <td className="text-right">
                { numeral(amounts.proceeds_expended).format('0,000.00') }
            </td>
            <td className="text-right">
                { numeral(amounts.earnings_expended).format('0,000.00') }
            </td>
            <td className="text-right">
                { numeral(amounts.proceeds_unexpended).format('0,000.00') }
            </td>
            <td className="text-right">
                { numeral(amounts.earnings_unexpended).format('0,000.00') }
            </td>
            <td className="text-right">
                { numeral(amounts.total_proceeds_expended).format('0,000.00') }
            </td>
            <td className="text-right">
                { numeral(amounts.taxable_proceeds_expended).format('0,000.00') }
            </td>
            <td className="text-right">
                { numeral(amounts.qualified_equity_expended).format('0,000.00') }
            </td>
            <td className="text-right">
                { numeral(amounts.other_proceeds_unexpended).format('0,000.00') }
            </td>
            <td className="text-right">
                { numeral(amounts.prior_proceeds_expended).format('0,000.00') }
            </td>
            <td className="text-right">
                { numeral(amounts.total_amount).format('0,000.00') }
            </td>
        </tr>
    );
}

const adjustAmountsByFactor = (amounts, factor) => {
    return _.mapValues(amounts, (val) => {
        return val * factor;
    });
}

const NetSaleProceedsTable = (props) => {
    if (!props.issue) {
        return 'No issue';
    }

    const issue = props.issue;

    let uses = issue.uses.map((use) => {
        const compDateCostHistory = use.cost_history.find((h) => {
            return h.as_of_date === issue.pu_calc.fy_end_date;
        });

        if (!compDateCostHistory) {
            return {
                use,
                amounts: {
                    proceeds_expended: 0,
                    earnings_expended: 0,
                    proceeds_unexpended: 0,
                    earnings_unexpended: 0,
                    total_proceeds_expended: 0,
                    taxable_proceeds_expended: 0,
                    qualified_equity_expended: 0,
                    prior_proceeds_expended: 0,
                    other_proceeds_unexpended: 0,
                    total_amount: 0
                }
            };
        }
        
        const totalProceedsExpended = _.round(numeral(compDateCostHistory.proceeds_expended).value() + numeral(compDateCostHistory.earnings_expended).value(), 2);

        const totalAmount = _.round(
            numeral(compDateCostHistory.proceeds_expended).value()
            + numeral(compDateCostHistory.earnings_expended).value()
            + numeral(compDateCostHistory.proceeds_unexpended).value()
            + numeral(compDateCostHistory.earnings_unexpended).value()
            + numeral(compDateCostHistory.taxable_proceeds_expended).value()
            + numeral(compDateCostHistory.qualified_equity_expended).value()
            + numeral(compDateCostHistory.prior_proceeds_expended).value()
            + numeral(compDateCostHistory.other_proceeds_unexpended).value()
        , 2);

        return {
            use,
            amounts: {
                proceeds_expended: compDateCostHistory.proceeds_expended,
                earnings_expended: compDateCostHistory.earnings_expended,
                proceeds_unexpended: compDateCostHistory.proceeds_unexpended,
                earnings_unexpended: compDateCostHistory.earnings_unexpended,
                total_proceeds_expended: totalProceedsExpended,
                taxable_proceeds_expended: compDateCostHistory.taxable_proceeds_expended,
                qualified_equity_expended: compDateCostHistory.qualified_equity_expended,
                other_proceeds_unexpended: compDateCostHistory.other_proceeds_unexpended,
                prior_proceeds_expended: compDateCostHistory.prior_proceeds_expended,
                total_amount: totalAmount
            },
        };
    });

    uses = uses.filter((use) => {
        return use.amounts.total_amount !== 0;
    });

    const issueSKExcludedFactor = issue.pu_calc.sk_excluded_factor ? issue.pu_calc.sk_excluded_factor : 0;

    const issueIsFullySKExcluded = (_.round(issueSKExcludedFactor, 2) === 1.00);

    const projectUses = uses.filter((r) => {
            if(issueIsFullySKExcluded) {
                return !r.use.is_neutral;
            }

            return !r.use.is_neutral 
                && (!r.use.sk_excluded_factor || r.use.sk_excluded_factor < 1 || r.use.is_coi)
                && (!r.use.is_coi || issueSKExcludedFactor < 1);
        })
        .map((r) => {
            let amountFactor = 1;
            
            if(!issueIsFullySKExcluded) {
                amountFactor = r.use.is_coi && issueSKExcludedFactor > 0
                    ? (1 - issueSKExcludedFactor)
                    : r.use.is_coi ? 1 : (1 - numeral(r.use.sk_excluded_factor).value());
            }
            
            return {
                ...r,
                amounts: amountFactor !== 1 ? adjustAmountsByFactor(r.amounts, amountFactor) : r.amounts,
            };
        });

    const projectUseRows = projectUses
        .map((r) => {
            return issueUseAmountsToRow(props, r.amounts, r.use);
        });

    const skExcludedUses = uses
        .filter((r) => {
            if(issueIsFullySKExcluded) {
                return false;
            }

            return (
                (!r.use.is_coi && r.use.sk_excluded_factor && r.use.sk_excluded_factor > 0)
                || (r.use.is_coi && issueSKExcludedFactor > 0)
            );
        })
        .map((r) => {
            const amountFactor = r.use.is_coi && issueSKExcludedFactor > 0 ? issueSKExcludedFactor : r.use.sk_excluded_factor;

            return {
                ...r,
                amounts: adjustAmountsByFactor(r.amounts, amountFactor),
            };
        });

    const skExcludedUseRows = skExcludedUses
        .map((r) => {
            return issueUseAmountsToRow(props, r.amounts, r.use);
        });

    const neutralUses = uses.filter((r) => {
        return r.use.is_neutral;
    });

    const neutralUseRows = neutralUses.map((r) => {
        return issueUseAmountsToRow(props, r.amounts, r.use);
    });

    const dateStr = moment(issue.pu_calc.fy_end_date).format('MM/DD/YY');

    return (
        <table className="table table-condensed table-hover grid-style-table">
            <thead>
                <tr className="bottom-dotted-border">
                    <th style={{ width: '20%' }}>Use</th>
                    <th style={{ width: '8%' }} className="text-right">
                        Bond<br />
                        Proceeds<br />
                        Spent @<br />
                        { dateStr }
                    </th>
                    <th style={{ width: '8%' }} className="text-right">
                        Earnings on<br />
                        Bond Proceeds<br />
                        Spent @<br />
                        { dateStr }
                    </th>
                    <th style={{ width: '8%' }} className="text-right">
                        Bond<br />
                        Proceeds<br />
                        Unspent @<br />
                        { dateStr }
                    </th>
                    <th style={{ width: '8%' }} className="text-right">
                        Earnings on<br />
                        Bond Proceeds<br />
                        Unspent @<br /> 
                        { dateStr }
                    </th>
                    <th style={{ width: '8%' }} className="text-right">
                        Total Bond<br />
                        Proceeds<br />
                        Spent @<br />
                        { dateStr }
                    </th>
                    <th style={{ width: '8%' }} className="text-right">
                        Taxable<br />
                        Bond Proceeds<br />
                        Spent @<br />
                        { dateStr }
                    </th>
                    
                    <th style={{ width: '8%' }} className="text-right">
                        Borrower's<br />
                        Equity<br />
                        Spent @<br />
                        { dateStr }
                    </th>
                    <th style={{ width: '8%' }} className="text-right">
                        Other Proceeds<br />
                        Unspent @<br />
                        { dateStr }
                    </th>
                    <th style={{ width: '8%' }} className="text-right">
                        Prior Bond<br />
                        Proceeds<br />
                        Spent @<br />
                        { dateStr }
                    </th>
                    <th style={{ width: '8%' }} className="text-right">
                        &nbsp;<br />
                        Total<br />
                        Proceeds @<br />
                        { dateStr }
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr className='border-0'><td colSpan={11}>&nbsp;</td></tr>
                <tr className='border-0'>
                    <td className="font-weight-bold font-italic" colSpan={11}>Project Costs</td>
                </tr>
                {
                    projectUseRows
                }
                {
                    issueUseAmountsToRow(props, calcTotals(projectUses), null, 'Total Project Costs')
                }
                {
                    !issueIsFullySKExcluded && skExcludedUseRows.length > 0
                        ? <tr className='border-0'><td colSpan={11}>&nbsp;</td></tr>
                        : <Fragment />
                }
                {
                    !issueIsFullySKExcluded && skExcludedUseRows.length > 0
                        ? <tr className='border-0'><td className="font-weight-bold font-italic" colSpan={11}>Costs Excluded from Schedule K</td></tr>
                        : <Fragment />
                }
                {
                    !issueIsFullySKExcluded && skExcludedUseRows.length > 0
                        ? skExcludedUseRows
                        : <Fragment />
                }
                {
                    !issueIsFullySKExcluded && skExcludedUseRows.length > 0
                        ? issueUseAmountsToRow(props, calcTotals(skExcludedUses), null, 'Total Costs Excluded from Schedule K')
                        : <Fragment />
                }
                <tr className='border-0'><td colSpan={11}>&nbsp;</td></tr>
                <tr className='border-0'>
                    <td className="font-weight-bold font-italic" colSpan={11}>Neutral Costs</td>
                </tr>
                {
                    neutralUseRows
                }
                {
                    issueUseAmountsToRow(props, calcTotals(neutralUses), null, 'Total Neutral Costs')
                }
                <tr className='border-0'><td colSpan={11}>&nbsp;</td></tr>
                {
                    issueUseAmountsToRow(props, calcTotals(uses), null, 'Total')
                }
            </tbody>
        </table>
    );
}

NetSaleProceedsTable.propTypes = {
    issue: PropTypes.object.isRequired,
    issues: PropTypes.array,
    showEditCostHistoryForm: PropTypes.func.isRequired
};

const mapStateToProps = state => (
    {
        issues: state.issues.list
    }
);

export default connect(mapStateToProps)(NetSaleProceedsTable);