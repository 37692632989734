import React from 'react';

const DaysInOperationFieldSet = (props) => {
    return (
        <div className="input-group rounded-0">
            <div className="rounded-0 input-group-prepend" style={{width: 15 + '%'}}>
                <span className="input-group-text rounded-0 bg-white border-0 text-left pl-0">FY{props.fy}</span>
            </div>
            <input
                className="form-control rounded-0"
                type="text"
                name={props.fy + "-days-in-operation"}
                value={props.daysInOperation ? props.daysInOperation : ''}
                disabled={props.disabled}
                fy={props.fy}
                onChange={props.onInputChange} 
                autoComplete="off" />
        </div>
    );
}

export default DaysInOperationFieldSet;
