import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UsesTable from './UsesTable';
import Notepad from '../../apps/Notepad/Notepad';

class AssetUserDetail extends Component {
    render() {
        if (!this.props.assetUserDetailView) {
            return '';
        }

        const user = this.props.assetUserDetailView.asset_user;

        return (
            <div>
                <div className="row sticky-top bg-white pt-3 pb-3" style={{ top: '58px' }}>
                    <div className="col-xl-7 col-sm-5">
                        <h3 className="font-weight-bold">{ user.name }</h3>
                        <h5 className="text-muted">
                            { user.type ? user.type.charAt(0).toUpperCase() + user.type.slice(1) : 'Unknown' } User
                            <a onClick={() => { this.props.showAssetUserForm(user); }}>
                                <i className="icon icon-edit" />
                            </a>
                        </h5>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-9">
                        <div className="d-flex justify-content-between pt-2 pb-2 pr-3 pl-3 bottom-solid-border w-100 card-bg">
                            <div className="text-muted font-weight-bold">
                                ASSET USES
                            </div>
                            <div>
                                <a onClick={() => { this.props.showUseForm(null, user.asset_user_id); }}>+ Add Use</a>
                            </div>
                        </div>
                        <UsesTable
                            user={user}
                            showUseForm={this.props.showUseForm}
                            showAssetUserForm={this.props.showAssetUserForm} />
                    </div>

                    <div className="col-3">
                        <div className="d-flex justify-content-between pt-2 pb-2 pr-3 pl-3 bottom-solid-border w-100 card-bg">
                            <div className="text-muted font-weight-bold">
                                NOTES
                            </div>
                        </div>
                        <Notepad
                            entityType={"AssetUser"}
                            entityId={user.asset_user_id} />
                    </div>
                </div>
            </div>
        );
    }
}

AssetUserDetail.propTypes = {
    assetUserDetailView: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    showUseForm: PropTypes.func.isRequired,
    showAssetUserForm: PropTypes.func.isRequired,
};

export default AssetUserDetail;