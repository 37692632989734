import React from 'react';

const BenefitFieldSet = (props) => {
    if (props.hidden)
        return '';
    
    return (
        <tr key={props.benefit.project_benefit_id + "-" + props.benefit.beneficiary_id}>
            <td>{props.benefit.beneficiary_name}</td>
            <td>
                <input
                    type="text"
                    name="in_service_date"
                    value={props.benefit.in_service_date ? props.benefit.in_service_date : ''}
                    onChange={props.onInputChange}
                    autoComplete="off" />
            </td>
            <td>
                <a onClick={() => {props.handleDeleteBenefit(props.benefit);}}>
                    <span className="icon icon-circle-with-cross"></span>
                </a>
            </td>
        </tr>
    );
}

export default BenefitFieldSet;
