import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { HotTable } from '@handsontable/react';

class DataTableModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableContent: [[]],
            show: false,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ show: props.show, tableContent: props.tableContent });
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding && typeof this.props.onHide === 'function')
            this.props.onHide();
    }

    handleTableChange = (event, source) => {
        if(!event) {
            return;
        }

        let tableContent = this.state.tableContent;

        event.map((change) => {
            let i = change[0];
            let j = change[1];

            if(!tableContent[i]) {
                tableContent[i] = [];
            }

            tableContent[i][j] = change[3];

            return null;
        });

        this.setState({ tableContent: tableContent });
    }

    render() {
        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} className="modal-xl" backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">{this.props.title}</span>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-12">
                            <div style={{height: '600px', overflowY: 'hidden'}}>
                                <HotTable
                                    root={ "hotImportUses" }
                                    data={ this.state.tableContent }
                                    contextMenu={true}
                                    minRows={10}
                                    afterChange={this.handleTableChange} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

DataTableModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
    tableContent: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
};

export default DataTableModal;