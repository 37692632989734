import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import numeral from 'numeral';
import * as DateFunctions from '../../lib/DateFunctions';
import * as IssueActionCreators from '../../ducks/issues';

class IssueUseCostHistoryModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            submittingForm: false,
            fieldValues: {},
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        let costHistory = [];
        if(props.use && props.use.cost_history) {
            costHistory = props.use.cost_history.map((h) => {
                for(let key in h) {
                    if(key.indexOf('expended') > -1 && h[key]) {
                        h[key] = numeral(h[key]).format('0,000.00');
                    }
                }
                return h;
            });
        }

        this.setState({
            show: props.show,
            fieldValues: {
                costHistory: costHistory
            }
        });
    }

    toggle = (reloadData) => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding)
            this.props.onHide(reloadData);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const asOfDate = name.substring(0, 10);
        const key = name.substring(11);

        let fieldValues = this.state.fieldValues;

        let foundAsOfDate = false;
        
        fieldValues.costHistory = fieldValues.costHistory.map((ch) => {
            if(ch.as_of_date === asOfDate) {
                foundAsOfDate = true;
                ch[key] = value;
            }
            
            return ch;
        });

        if(!foundAsOfDate) {
            let ch = {
                as_of_date: asOfDate
            };
            ch[key] = value;

            fieldValues.costHistory.push(ch);
        }
        
        this.setState({ fieldValues: fieldValues });
    }

    saveFunc = (data) => {
        this.toggle();
        this.setState({ submittingForm: false });

        if (typeof this.props.onSave === 'function') {
            this.props.onSave(data.use);
        }
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ submittingForm: true });

        this.props.updateUseCostHistory(
            this.props.use.issue_id,
            this.props.use.issue_use_id,
            {
                cost_history: this.state.fieldValues.costHistory
            }
        ).then(this.saveFunc);
    }

    render() {
        if (!this.props.use || !this.props.issue) {
            return '';
        }
        const use = this.props.use;
        /*
        amounts: {
                proceeds_expended: compDateCostHistory.proceeds_expended,
                earnings_expended: compDateCostHistory.earnings_expended,
                proceeds_unexpended: proceedsUnexpended,
                earnings_unexpended: earningsUnexpended,
                total_proceeds_expended: totalProceedsExpended,
                taxable_proceeds_expended: compDateCostHistory.taxable_proceeds_expended,
                qualified_equity_expended: compDateCostHistory.qualified_equity_expended,
                other_proceeds_unexpended: compDateCostHistory.other_proceeds_unexpended,
                total_amount: totalAmount
            }
        */
        const historyDates = 
            this.props.client.pu_fys.map((fy) => {
                return (DateFunctions.clientFyEnd(this.props.client, fy)).format('YYYY-MM-DD');
            })
            .sort()
            .reverse();
        
        const historyRows = historyDates.map((dtStr) => {
            const ch = this.state.fieldValues.costHistory.find((h) => {
                return h.as_of_date === dtStr;
            });

            return (
                <tr key={ "history-" + dtStr }>
                    <td>{ use.name }</td>
                    <td>{ dtStr }</td>
                    <td>
                        <input
                            type="text"
                            style={{ width: '100%' }}
                            className="text-right form-control rounded-0"
                            name={ `${dtStr}-proceeds_expended` }
                            onChange={ this.handleInputChange }
                            value={ ch && ch.proceeds_expended ? ch.proceeds_expended : '' }
                            autoComplete="off" />
                    </td>
                    <td>
                        <input
                            type="text"
                            style={{ width: '100%' }}
                            className="text-right form-control rounded-0"
                            name={ `${dtStr}-earnings_expended` }
                            onChange={ this.handleInputChange }
                            value={ ch && ch.earnings_expended ? ch.earnings_expended : '' }
                            autoComplete="off" />
                    </td>
                    <td>
                        <input
                            type="text"
                            style={{ width: '100%' }}
                            className="text-right form-control rounded-0"
                            name={ `${dtStr}-proceeds_earnings_unexpended` }
                            onChange={ this.handleInputChange }
                            value={ ch && ch.proceeds_earnings_unexpended ? ch.proceeds_earnings_unexpended : '' }
                            autoComplete="off" />
                    </td>
                    <td>
                        <input
                            type="text"
                            style={{ width: '100%' }}
                            className="text-right form-control rounded-0"
                            name={ `${dtStr}-taxable_proceeds_expended` }
                            onChange={ this.handleInputChange }
                            value={ ch && ch.taxable_proceeds_expended ? ch.taxable_proceeds_expended : '' }
                            autoComplete="off" />
                    </td>
                    <td>
                        <input
                            type="text"
                            style={{ width: '100%' }}
                            className="text-right form-control rounded-0"
                            name={ `${dtStr}-qualified_equity_expended` }
                            onChange={ this.handleInputChange }
                            value={ ch && ch.qualified_equity_expended ? ch.qualified_equity_expended : '' }
                            autoComplete="off" />
                    </td>
                    <td>
                        <input
                            type="text"
                            style={{ width: '100%' }}
                            className="text-right form-control rounded-0"
                            name={ `${dtStr}-other_proceeds_unexpended` }
                            onChange={ this.handleInputChange }
                            value={ ch && ch.other_proceeds_unexpended ? ch.other_proceeds_unexpended : '' }
                            autoComplete="off" />
                    </td>
                </tr>
            );
        });

        return (
            <Modal isOpen={this.state.show} toggle={() => { this.toggle(false); }} className="modal-xl" backdrop="static">
                <ModalHeader toggle={() => { this.toggle(false); }}>
                    <span className="font-weight-bold">
                        Edit Cost History for { use.name }
                    </span>
                </ModalHeader>
                <form onSubmit={this.handleFormSubmit}>
                    <div className="p-3">
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '11%', verticalAlign: 'bottom' }}>Use</th>
                                    <th style={{ width: '11%', verticalAlign: 'bottom' }}>As of</th>
                                    <th style={{ width: '13%', verticalAlign: 'bottom' }} className="text-right">
                                        Proceeds<br />
                                        Expended
                                    </th>
                                    <th style={{ width: '13%', verticalAlign: 'bottom' }} className="text-right">
                                        Earnings<br />
                                        Expended
                                    </th>
                                    <th style={{ width: '13%', verticalAlign: 'bottom' }} className="text-right">
                                        Proceeds/Earnings<br />
                                        Unexpended
                                    </th>
                                    <th style={{ width: '13%', verticalAlign: 'bottom' }} className="text-right">
                                        Taxable<br />
                                        Proceeds<br />
                                        Expended
                                    </th>
                                    <th style={{ width: '13%', verticalAlign: 'bottom' }} className="text-right">
                                        Qualified<br />
                                        Equity<br />
                                        Expended
                                    </th>
                                    <th style={{ width: '13%', verticalAlign: 'bottom' }} className="text-right">
                                        Other Sources<br />
                                        Proceeds<br />
                                        Unexpended
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { historyRows }
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-actions">
                        <button type="button" className="btn-link modal-action" onClick={() => { this.props.onHide(false); }}>
                            Cancel
                        </button>
                        <button type="submit" className="btn-link modal-action font-weight-bold">
                            {
                                this.state.submittingForm
                                    ? 'Loading...'
                                    : 'Save Changes'
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

IssueUseCostHistoryModal.propTypes = {
    use: PropTypes.object,
    issue: PropTypes.object,
    onHide: PropTypes.func,
    client: PropTypes.object,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        updateUseCostHistory: (issueId, useId, costHistoryData) => dispatch(IssueActionCreators.updateUseCostHistory(issueId, useId, costHistoryData)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueUseCostHistoryModal);
