import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import NetDocs from './netdocs';
import FileTreeView from './FileTreeView';

class NDBrowser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingWorkspace: false,
            submittingForm: false,
            workspaceTree: [],
            workspaceMatterNumber: null,
            workspaceLoadErrored: false,
            selectedFileId: null,
            loadingDocumentContents: false,
            documentLoadErrored: false,
            documentContent: null,
            loadingClientWorkspaces: false,
            clientWorkspacesLoadErrored: false,
            clientWorkspaces: [],
        };
    }

    componentDidMount() {
        if(this.shouldLoadWorkspace()) {
            this.loadWorkspace();
            this.loadClientWorkspaces();
        }
    }

    componentDidUpdate() {
        if(this.shouldLoadWorkspace()) {
            this.loadWorkspace();
            this.loadClientWorkspaces();
        }
    }

    documentLeaf = (document) => {
        return {
            title: document.name,
            key: document.nd_document_id,
            isLeaf: true,
        };
    }

    workspaceTreeData = (workspaceContents) => {
        return workspaceContents.map(i => {
            return i.nd_document_id ? this.documentLeaf(i) : this.folderTreeData(i);
        });
    }

    folderTreeData = (folder) => {
        const children = !folder.contents
            ? []
            : folder.contents.map(i => {
                return i.nd_document_id ? this.documentLeaf(i) : this.folderTreeData(i);
            });
        
        return {
            title: folder.name,
            key: `folder-${folder.nd_container_id}`,
            children
        };
    }

    shouldLoadWorkspace() {
        return (
            this.props.companyNumber && this.props.clientMatterNumber
            && (
                !this.state.workspaceTree
                || _.isEmpty(this.state.workspaceTree)
                || this.state.workspaceMatterNumber !== this.ndClientMatter()
            ) 
            && !this.state.loadingWorkspace && !this.state.workspaceLoadErrored
            && !this.state.loadingClientWorkspaces && !this.state.clientWorkspacesLoadErrored
        );
    }

    ndClientMatter = () => {
        if(typeof this.props.clientMatterNumber === 'undefined'){
            return;
        }

        return this.props.clientMatterNumber.substring(this.props.clientMatterNumber.length > 3 ? 2 : 0);
    }

    loadWorkspace = () => {
        const ndClientMatter = this.ndClientMatter();

        if(this.state.loadingWorkspace || this.state.workspaceLoadErrored || (ndClientMatter === this.state.workspaceMatterNumber)) {
            return;
        }

        this.setState({ loadingWorkspace: true, workspaceLoadErrored: false, workspaceMatterNumber: null, workspaceTree: [] });
        NetDocs.workspaceTree(this.props.companyNumber, ndClientMatter)
            .then((data) => {
                this.setState({ loadingWorkspace: false, workspaceTree: data, workspaceMatterNumber: ndClientMatter });
            })
            .catch((error) => {
                this.setState({ loadingWorkspace: false, workspaceMatterNumber: null, workspaceLoadErrored: true });
                alert(error);
            });
    }

    loadClientWorkspaces = () => {
        const clientId = (this.state.clientWorkspaces && this.state.clientWorkspaces[0]) ? this.state.clientWorkspaces[0].client_id : null;

        if(this.state.loadingClientWorkspaces || this.state.clientWorkspacesLoadErrored || (clientId === this.props.companyNumber)) {
            return;
        }

        this.setState({ loadingClientWorkspaces: true, clientWorkspacesLoadErrored: false, clientWorkspaces: [] });
        NetDocs.clientWorkspaces(this.props.companyNumber)
            .then(data => {
                this.setState({ loadingClientWorkspaces: false, clientWorkspacesLoadErrored: false, clientWorkspaces: data });
            })
            .catch(error => {
                this.setState({ loadingClientWorkspaces: false, clientWorkspacesLoadErrored: true, clientWorkspaces: [] });
                alert(error);
            });
    }

    thisWorkspaceFromClientWorkspaces = () => {
        if(!this.state.clientWorkspaces) {
            return;
        }

        return this.state.clientWorkspaces.find(w => w.matter_number === this.ndClientMatter()); 
    }

    handleFileSelect = (selectedItem) => {
        if(!selectedItem || selectedItem.nd_container_id) {
            return;
        }

        this.setState({ selectedFileId: selectedItem.nd_document_id });

        this.loadFileContents(selectedItem.nd_document_id);
    }

    loadFileContents = (documentId) => {
        if(!documentId || this.state.loadingDocumentContents) {
            return;
        }

        this.setState({loadingDocumentContents: true, documentLoadErrored: false, documentContent: null});
        
        NetDocs.documentPdfPreview(documentId)
            .then((data) => {
                this.setState({loadingDocumentContents: false, documentContent: data});
            })
            .catch((error) => {
                this.setState({loadingDocumentContents: false, documentLoadErrored: true});
            })
    }

    base64ToBlob = (base64) => {
        const binStr = window.atob(base64);
        const len = binStr.length;
        let bytes = new Uint8Array(len);
        for(let i = 0; i < len; i++) {
            bytes[i] = binStr.charCodeAt(i);
        }
        return new Blob([bytes], { type: "application/pdf" });
    }

    documentContentURI = () => {
        return encodeURIComponent(URL.createObjectURL(this.base64ToBlob(this.state.documentContent)));
    }

    render() {
        if(!this.props.companyNumber || !this.props.clientMatterNumber) {
            return <Fragment />;
        }

        if(this.state.workspaceLoadErrored || this.state.clientWorkspacesLoadErrored) {
            return <div className="alert alert-error" role="alert">Error loading NetDocuments workspace</div>;
        }

        if(
            this.state.loadingWorkspace
            || this.state.loadingClientWorkspaces
            || !this.state.clientWorkspaces
            || this.state.clientWorkspaces.length === 0
        ) {
            return 'Loading NetDocuments workspace...';
        }
        
        const ws = this.thisWorkspaceFromClientWorkspaces();

        return (
            <div>
                <div className="row mb-2">
                    <div className="col-9 font-weight-bold">
                        NetDocuments Workspace: {ws.name}
                    </div>
                    <div className="col-3 text-right">
                        <a className="btn btn-default btn-sm rounded-0" href={`https://eu.netdocuments.com/neWeb2/workspace/${ws.nd_env_id}/docs/summary`} target="_blank" rel="noopener noreferrer">
                            View in NetDocuments
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-5">
                        <FileTreeView contents={this.state.workspaceTree} onFileSelect={this.handleFileSelect} />
                    </div>
                    <div className="col-7" style={{'height': '500px'}}>
                        {
                            this.state.loadingDocumentContents
                                ? 'Loading document...'
                                : <Fragment />
                        }
                        {
                            !this.state.loadingDocumentContents && this.state.documentContent
                                ? (
                                    <iframe 
                                        src={`/pdfjs/web/viewer.html?file=${this.documentContentURI()}`}
                                        width="100%"
                                        height="100%"
                                        title={ this.state.selectedFileId }>    
                                    </iframe>
                                ) : <Fragment />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

NDBrowser.propTypes = {
    companyNumber: PropTypes.string.isRequired,
    clientMatterNumber: PropTypes.string.isRequired,
};

export default NDBrowser;
