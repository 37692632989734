import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import numeral from 'numeral';
import _ from 'lodash';

import Constants from '../lib/Constants';
import TitleBar from '../components/TitleBar';
import Notepad from '../apps/Notepad/Notepad';

import * as AssetActionCreators from '../ducks/assets';
import * as CommonStateActions from '../ducks/common';

class ClientResearch2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableContent: [],
            selectedAssetId: null,
            showingAssetsList: true,
            campuswideLine4: '',
            campuswideLine5: '',
            campuswideTotal: '',
            saving: false,
            justSaved: false,
            deletedAssetIds: [],
        };

        this.hotTableComponent = React.createRef();
    }

    componentDidMount() {
        const clientId = parseInt(this.props.match.params.client_id, 10);
        if (!this.props.client || this.props.client.client_id !== clientId) {
            this.props.loadClientAndDependencies(clientId);
        }
        this.setTableContentFromAssets();
        this.setPageTitle();
    }

    setPageTitle() {
        document.title = `${Constants.pageTitleBase} ${Constants.pageTitleSeparator} Research`;
    }

    componentDidUpdate(prevProps) {
        //console.log(prevProps.assets, this.props.assets, _.isEqual(prevProps.assets, this.props.assets));
        if(prevProps.client !== this.props.client
            || prevProps.puCalcFy !== this.props.puCalcFy
            || !_.isEqual(prevProps.assets, this.props.assets)) {
            this.setTableContentFromAssets();
        }
    }

    setTableContentFromAssets = () => {
        if(!Array.isArray(this.props.assets)) {
            return;
        }

        const fy = this.operativePuCalcFy();
        if(!fy) {
            return;
        }
        
        let tableContent = [];
        this.props.assets.forEach((a) => {
            if(this.entityHasResearchDetailForFy(a, fy)) {
                const detail = a.research_detail[fy];
                const line4 = numeral(detail.line4_revenue).value();
                const line5 = numeral(detail.line5_revenue).value();
                const total = numeral(detail.total_revenue).value();
                const factor = total !== 0 ? (line4 + line5) / total : 0;

                tableContent.push([
                    a.name,
                    detail.size ? detail.size : '',
                    line4 ? line4 : '',
                    line5 ? line5 : '',
                    total ? total : '',
                    factor ? factor : '',
                ]);
            }

            /*
            NOTE: Leaving dormant for now, may reintroduce functionality later

            if(!Array.isArray(a.spaces)) {
                return;
            }

            a.spaces.forEach((s) => {
                if(this.entityHasResearchDetailForFy(s, fy)) {
                    const detail = s.research_detail[fy];
                    
                    tableContent.push([
                        a.name,
                        detail.size ? detail.size : '',
                        detail.line4_revenue ? detail.line4_revenue : '',
                        detail.line5_revenue ? detail.line5_revenue : '',
                        detail.total_revenue ? detail.total_revenue : '',
                    ]);
                }
            });
            */
        });

        this.setState({ tableContent: tableContent });
    }

    // TODO: move these to ModelUtil
    entityHasResearchDetailForFy = (entity, fy) => {
        return entity.research_detail && typeof entity.research_detail === 'object' && entity.research_detail[fy];
    }

    assetOrAssetSpacesHaveResearchDetailForFy = (asset, fy) => {
        if(this.entityHasResearchDetailForFy(asset, fy)) {
            return true;
        }

        if(!Array.isArray(asset.spaces)) {
            return false;
        }

        return !!asset.spaces.find((s) => {
            return this.entityHasResearchDetailForFy(s, fy);
        })
    }

    operativePuCalcFy() {
        if (!this.props.client || !this.props.puCalcFy) {
            return null;
        }
        let fy;
        if (this.props.puCalcFy === 'latest') {
            fy = this.props.client.pu_fys[0];
        }
        else {
            fy = this.props.puCalcFy;
        }
        return parseInt(fy, 10);
    }

    handleTableChange = (event, source) => {
        if(!event) {
            return;
        }

        this.setState({ justSaved: false });

        let tableContent = this.state.tableContent;

        event.map((change) => {
            let i = change[0];
            let j = change[1];

            if(!tableContent[i]) {
                tableContent[i] = [];
            }

            tableContent[i][j] = change[3];

            const total = numeral(tableContent[i][4]).value();
            if(total !== 0) {
                tableContent[i][5] = (numeral(tableContent[i][2]).value() + numeral(tableContent[i][3]).value()) / total;
            }
            else {
                tableContent[i][5] = null;
            }

            return null;
        });

        this.setState({ tableContent: tableContent });
    }

    toggleAssetInTable = (asset, remove) => {
        if(!asset) {
            return;
        }

        this.setState({ justSaved: false });

        let addedRowIdx = null;
        let tableContent = this.state.tableContent;
        let deletedAssetIds = this.state.deletedAssetIds;

        if(remove) {
            tableContent = tableContent.filter((row) => {
                return (row[0] !== asset.name);
            });

            deletedAssetIds.push(asset.asset_id);
        }
        else {
            tableContent.push([
                asset.name,
                '',
                this.hasCampuswideRevenue() ? this.state.campuswideLine4 : '',
                this.hasCampuswideRevenue() ? this.state.campuswideLine5 : '',
                this.hasCampuswideRevenue() ? this.state.campuswideTotal : '',
            ]);

            addedRowIdx = tableContent.length - 1;

            deletedAssetIds = deletedAssetIds.filter((id) => { return id !== asset.asset_id });
        }

        this.setState({ tableContent: tableContent, deletedAssetIds: deletedAssetIds, selectedAssetId: null }, () => {
            if(addedRowIdx !== null) {
                this.hotTableComponent.current.hotInstance.selectCell(addedRowIdx, 1);
            }
        });
    }

    selectAllAssets = () => {
        this.props.assets.forEach(a => {
            if(!this.assetIsInTable(a.name)) {
                this.toggleAssetInTable(a);
            }
        });
    }

    handleAssetSelectorChange = (event) => {
        this.setState({ selectedAssetId: event.target.value });
    }

    handleInputChange = (event) => {
        this.setState({ justSaved: false });
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let prevValue;

        switch(name) {
            case 'campuswide_line4_revenue':
                prevValue = this.state.campuswideLine4;
                this.setState({ campuswideLine4: value }, () => {
                    this.changeAllRevenueCellsWithValue(2, prevValue, value);
                });
                break;
            case 'campuswide_line5_revenue':
                prevValue = this.state.campuswideLine5;
                this.setState({ campuswideLine5: value }, () => {
                    this.changeAllRevenueCellsWithValue(3, prevValue, value);
                });
                break;
            case 'campuswide_total_revenue':
                prevValue = this.state.campuswideTotal;
                this.setState({ campuswideTotal: value }, () => {
                    this.changeAllRevenueCellsWithValue(4, prevValue, value);
                });
                break;
            default:
                break;
        }
    }

    changeAllRevenueCellsWithValue(cellIdx, prevValue, newValue) {
        let tableContent = this.state.tableContent;
        prevValue = numeral(prevValue).value();
        
        tableContent = tableContent.map((row) => {
            let rowIsEmptyOrCampusWide = (
                this.textIsEmpty(row[2])
                && this.textIsEmpty(row[3])
                && this.textIsEmpty(row[4])
            );
            
            if(!rowIsEmptyOrCampusWide) {
                switch(cellIdx) {
                    case 2:
                        rowIsEmptyOrCampusWide = (
                            this.textIsNumericValue(row[2], prevValue)
                            && this.textIsNumericValue(row[3], this.state.campuswideLine5)
                            && this.textIsNumericValue(row[4], this.state.campuswideTotal)
                        );
                        break;
                    case 3:
                        rowIsEmptyOrCampusWide = (
                            this.textIsNumericValue(row[2], this.state.campuswideLine4)
                            && this.textIsNumericValue(row[3], prevValue)
                            && this.textIsNumericValue(row[4], this.state.campuswideTotal)
                        );
                        break;
                    case 4:
                        rowIsEmptyOrCampusWide = (
                            this.textIsNumericValue(row[2], this.state.campuswideLine4)
                            && this.textIsNumericValue(row[3], this.state.campuswideLine5)
                            && this.textIsNumericValue(row[4], prevValue)
                        );
                        break;
                    default:
                        rowIsEmptyOrCampusWide = false;
                        break;
                }
            }
            
            if(rowIsEmptyOrCampusWide) {
                row[cellIdx] = newValue;
                const total = numeral(row[4]).value();
                if(row[4] && total !== 0) {
                    row[5] = (numeral(row[2]).value() + numeral(row[3]).value()) / total;
                }
                else {
                    row[5] = null;
                }
            }

            return row;
        });
        
        this.setState({ tableContent: tableContent });
    }

    textIsEmpty(text) {
        return !text || text === '';
    }

    textIsNumericValue(text, value) {
        return numeral(text).value() === numeral(value).value();
    }

    toggleAssetsList = () => {
        this.setState({ showingAssetsList: !this.state.showingAssetsList });
    }

    hasCampuswideRevenue = () => {
        return this.state.campuswideLine4 !== ''
            || this.state.campuswideLine5 !== ''
            || this.state.campuswideTotal !== '';
    }

    assetIdForAssetName = (name) => {
        const asset = this.props.assets.find((a) => {
            return a.name === name;
        });

        return asset ? asset.asset_id : null;
    }

    assetResearchDetailData = () => {
        return this.state.tableContent
            .filter((row) => {
                return row[0];
            })
            .map((row) => {
                const assetId = this.assetIdForAssetName(row[0]);
                return {
                    asset_id: assetId,
                    asset_name: row[0],
                    fy: this.operativePuCalcFy(),
                    size: row[1],
                    line4_revenue: row[2],
                    line5_revenue: row[3],
                    total_revenue: row[4],
                };
            });
    }

    save = () => {
        this.setState({ saving: true });
        this.props.saveResearchDetail({
            assets_research: this.assetResearchDetailData(),
            deleted_asset_ids: this.state.deletedAssetIds,
            client_id: this.props.client.client_id,
            fy: this.operativePuCalcFy(),
        })
            .then(() => {
                this.setState({ saving: false, justSaved: true });
            })
            .catch((error) => {
                this.setState({ saving: false, justSaved: false });
                alert(error);
            });        
    }

    assetIsInTable = (assetName) => {
        return !!this.state.tableContent.find((row) => {
            return row[0] === assetName;
        });
    }

    render() {
        if (!this.props.client || !this.props.numeraires || !this.props.assets)
            return '';

        const fy = this.operativePuCalcFy();

        const assetsList = this.props.assets.map((a) => {
            return (
                <div key={`toggle-asset-${a.asset_id}`}>
                    <div className="form-group">
                        <input
                            type="checkbox"
                            name={`toggle-asset-${a.asset_id}`}
                            id={`toggle-asset-${a.asset_id}`}
                            onChange={(event) => { this.toggleAssetInTable(a, !event.target.checked)}}
                            checked={ this.assetIsInTable(a.name) } />
                        <label className="form-check-label" htmlFor={`toggle-asset-${a.asset_id}`}>
                            &nbsp;{ a.name }
                        </label>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <TitleBar pageTitle="Corporate-Sponsored and Non-Profit Research" clientName={this.props.client.name} />
                
                <div className="clear-title-bar">
                    <div className="p-3">
                        <div className="row">
                            <div className="col-6 pb-3">
                                <button className="btn btn-secondary rounded-0" onClick={() => { this.toggleAssetsList(); }}>
                                    <i className="icon icon-progress-one"></i>&nbsp;
                                    Assets List
                                </button>                                
                            </div>
                            <div className="col-6 text-right">
                                {
                                    this.state.justSaved
                                        ? <span className="text-primary"><i className="icon icon-check"></i> Saved!&nbsp;&nbsp;&nbsp;</span>
                                        : ''
                                }
                                <button className="btn btn-success rounded-0" disabled={this.state.saving || this.state.justSaved} onClick={this.save}>
                                    <i className="icon icon-save"></i>&nbsp;
                                    { this.state.saving ? 'Saving...' : 'Save Research' }
                                </button>
                            </div>
                        </div>
                        
                        <div className="row">
                            {
                                this.state.showingAssetsList
                                    ? (
                                        <div className="col-2">
                                            <div className="pt-2 pb-2 pl-3 pr-3 bottom-solid-border w-100 card-bg text-muted font-weight-bold d-flex justify-content-between">
                                                <div>ASSETS</div>
                                                <div className="text-right">
                                                    <button className="btn btn-link p-0" onClick={() => { this.selectAllAssets(); }}>
                                                        Select All
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="p-2" style={{overflowY: 'auto', maxHeight: '700px'}}>
                                                { assetsList }
                                            </div>
                                        </div>
                                    )
                                    : ''
                            }

                            <div className={ this.state.showingAssetsList ? 'col-8' : 'col-10' }>
                                <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border w-100 card-bg text-muted font-weight-bold">
                                    RESEARCH DETAILS
                                </div>
                                <div className="p-2 mb-4 mt-2">
                                    <div className="row">
                                        <label className="col-2 col-form-label text-muted text-uppercase font-weight-bold">Campus-Wide Revenue</label>
                                        <div className="col-10">
                                            <div className="input-group rounded-0">
                                                <div className="input-group-prepend rounded-0">
                                                    <span className="input-group-text rounded-0">FY{fy} Line 4 Rev.</span>
                                                </div>
                                                <input
                                                    className="form-control rounded-0 text-right"
                                                    type="text"
                                                    name="campuswide_line4_revenue"
                                                    onChange={this.handleInputChange}
                                                    value={this.state.campuswideLine4}
                                                    autoComplete="off" />
                                                <div className="input-group-prepend rounded-0">
                                                    <span className="input-group-text rounded-0">FY{fy} Line 5 Rev.</span>
                                                </div>
                                                <input
                                                    className="form-control rounded-0 text-right"
                                                    type="text"
                                                    name="campuswide_line5_revenue"
                                                    onChange={this.handleInputChange}
                                                    value={this.state.campuswideLine5}
                                                    autoComplete="off" />
                                                <div className="input-group-prepend rounded-0">
                                                    <span className="input-group-text rounded-0">FY{fy} Total Rev.</span>
                                                </div>
                                                <input
                                                    className="form-control rounded-0 text-right"
                                                    type="text"
                                                    name="campuswide_total_revenue"
                                                    onChange={this.handleInputChange}
                                                    value={this.state.campuswideTotal}
                                                    autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{height: '500px', overflowY: 'hidden'}}>
                                    <HotTable
                                        root={ "hotEditResearch" }
                                        ref={ this.hotTableComponent }
                                        data={ this.state.tableContent }
                                        contextMenu={ true }
                                        stretchH="all"
                                        columns={[
                                            { type: 'text', width: 150 },
                                            { type: 'numeric', numericFormat: { pattern: '0,0.00' }, correctFormat: true, className: "htRight", width: 150 },
                                            { type: 'numeric', numericFormat: { pattern: '0,0.00' }, correctFormat: true, className: "htRight", width: 150 },
                                            { type: 'numeric', numericFormat: { pattern: '0,0.00' }, correctFormat: true, className: "htRight", width: 150 },
                                            { type: 'numeric', numericFormat: { pattern: '0,0.00' }, correctFormat: true, className: "htRight", width: 150 },
                                            { type: 'numeric', numericFormat: { pattern: '0.00%' }, correctFormat: true, className: "htRight", width: 150, readOnly: true }
                                        ]}
                                        colHeaders={[
                                            'Asset',
                                            `FY${fy} Research Size`,
                                            `FY${fy} Line 4 Revenue`,
                                            `FY${fy} Line 5 Revenue`,
                                            `FY${fy} Total Revenue`,
                                            `FY${fy} Research Factor`
                                        ]}
                                        rowHeaders={ true }
                                        minRows={ 0 }
                                        //afterPaste={ (event, source) => { console.log('paste', event, source); } }
                                        afterChange={ this.handleTableChange }
                                        licenseKey={Constants.hotLicenseKey} />
                                </div>
                            </div>
                            
                            <div className="col-2">
                                <Notepad
                                    entityType={"Research"}
                                    listHeight={200} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ClientResearch2.propTypes = {
    client: PropTypes.object,
    numeraires: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = state => (
    {
        assets: state.assets.assets,
        assetsListIsLoading: state.assets.listIsLoading,
        assetsListHasErrored: state.assets.listHasErrored,
        client: state.client.client,
        clientIsLoading: state.client.clientIsLoading,
        clientHasErrored: state.client.clientHasErrored,
        clientErrorMessage: state.client.clientErrorMessage,
        numeraires: state.numeraires.numeraires,
        notes: state.notes.notes,
        puCalcFy: state.client.puCalcFy,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadClientAndDependencies: (clientId) => CommonStateActions.loadClientAndDependencies(dispatch, clientId),
        saveResearchDetail: (data) => dispatch(AssetActionCreators.saveResearchDetail(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientResearch2);
