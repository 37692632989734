import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import 'rc-tooltip/assets/bootstrap_white.css';

import * as ModelUtil from '../../lib/ModelUtil';

const UseListing = (props) => {
    if (!props.assets) {
        return '';
    }

    const container = ModelUtil.containerForUse(props.use, props.assets);
    const asset = ModelUtil.assetForUse(props.use, props.assets);
    
    const sizeNumeraireId = container.usage_numeraire_id 
        ? container.usage_numeraire_id : asset.size_numeraire_id;

    const sizeNumeraire = ModelUtil.numeraireForNumeraireId(sizeNumeraireId, props.numeraires);
    
    let baseCellClass = (props.firstCell ? 'top-dotted-border ' : 'border-0 ');
    
    return (
        <tr>
            <td className="pl-3">
                { props.use.contract_name }
            </td>
            <td className={baseCellClass}>
                <Link to={ "/clients/" + asset.client_id + "/assets/" + asset.asset_id }>
                    { asset.name }
                </Link>
            </td>
            <td className={baseCellClass}>
                {container.space_id ? container.name : <i>General Asset</i>}
            </td>
            <td className={baseCellClass + "text-right"}>
                {moment(props.use.start_date).format('MM/DD/YY')}
            </td>
            <td className={baseCellClass + "text-right"}>
                {moment(props.use.end_date).format('MM/DD/YY')}
            </td>
            <td className={baseCellClass + "text-right"}>
                {
                    numeral(props.use.pu_calc.gross_size).format('0,00.00')
                }&nbsp;
                {sizeNumeraire.symbol}
            </td>
            <td className={baseCellClass + "text-right"}>
                {numeral(props.use.pu_calc.usage_size).format('0,0.00')} {sizeNumeraire.symbol}
            </td>
            <td className={baseCellClass + "text-right"}>
                <a onClick={() => { props.showUseForm(props.use); }}>
                    <i className="icon icon-edit" />
                </a>
            </td>
        </tr>
    );
}
//<a onClick={() => { props.showUseForm(props.use); }}><i className="icon icon-edit" /></a>
UseListing.propTypes = {
    use: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    assets: PropTypes.array,
    numeraires: PropTypes.array.isRequired,
    firstCell: PropTypes.element,
    showUseForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => (
    {
        assets: state.assets.assets,
        numeraires: state.numeraires.numeraires
    }
);

export default connect(mapStateToProps)(UseListing);