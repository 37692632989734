import React from 'react';
import numeral from 'numeral';
import * as ModelUtil from './ModelUtil';

export function assetOptions(assets, numeraires) {
    return assets.map((asset) => {
        const sizeNumeraire = ModelUtil.numeraireForNumeraireId(asset.size_numeraire_id, numeraires);

        return (
            <option key={asset.asset_id} value={asset.asset_id}>
                {asset.name} -- { numeral(asset.total_size).format('0,000') } { sizeNumeraire ? sizeNumeraire.symbol : '' }
            </option>
        );
    });
}

export function assetUserOptions(users) {
    return users.map((user) =>
        <option key={user.asset_user_id} value={user.asset_user_id}>
            {user.name}
        </option>
    );
}

export function spaceOptions(spaces, assets, numeraires, excludeSpaceId, parentSpace) {
    let options = [];
    spaces = spaces.filter((space) => {
        return excludeSpaceId ? (space.space_id !== excludeSpaceId) : true;
    });

    spaces.forEach((space) => {
        if (parentSpace && space.parent_space_id !== parentSpace.space_id) {
            return;
        }
        if (!parentSpace && space.parent_space_id) {
            return;
        }

        const asset = ModelUtil.assetForAssetId(space.asset_id, assets);
        let sizeNumeraireId;
        if (space.parent_space_id) {
            sizeNumeraireId = parentSpace.usage_numeraire_id ? parentSpace.usage_numeraire_id : asset.size_numeraire_id;
        }
        else {
            sizeNumeraireId = asset.size_numeraire_id;
        }
        
        const sizeNumeraire = ModelUtil.numeraireForNumeraireId(sizeNumeraireId, numeraires);

        options.push(
            <option key={space.space_id} value={space.space_id}>
                { parentSpace ? '--' : '' }
                { parentSpace && parentSpace.parent_space_id ? '--' : '' }
                {space.name} -- { numeral(space.total_size).format('0,000') } { sizeNumeraire.symbol }
            </option>
        );

        Array.prototype.push.apply(options, spaceOptions(spaces, assets, numeraires, excludeSpaceId, space));
    });

    return options;
}

export function numeraireOptions(numeraires, prependText) {
    return numeraires.map((numeraire) =>
        <option key={numeraire.numeraire_id} value={numeraire.numeraire_id}>
            {prependText ? prependText : ''} {numeraire.name} ({numeraire.symbol})
        </option>
    );
}

export function researchActivityOptions(researchActivities) {
    return researchActivities.map((activity) => {
        return (
            <option key={activity.research_activity_id} value={activity.research_activity_id}>
                {activity.name}
            </option>
        );
    });
}