import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import * as AssetActionCreators from '../../ducks/assets';

class AssetImporterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableContent: [[]],
            importing: false,
            justImported: false,
            show: false,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ show: props.show });
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding && typeof this.props.onHide === 'function')
            this.props.onHide();
    }

    handleTableChange = (event, source) => {
        if(!event) {
            return;
        }

        let tableContent = this.state.tableContent;

        event.map((change) => {
            let i = change[0];
            let j = change[1];

            if(!tableContent[i]) {
                tableContent[i] = [];
            }

            tableContent[i][j] = change[3];

            return null;
        });

        this.setState({ tableContent: tableContent });
    }

    structureAssetObjects = (tableContent) => {
        return tableContent.map((row) => {
            return {
                asset_name: row[0],
                asset_code: row[1],
                total_size: row[2],
                common_size: row[3],
                size_numeraire: row[4],
                usage_numeraire: row[5],
                usage_denominator: row[6]
            };
        });
    }

    currentFy = () => {
        return this.props.puCalcFy && this.props.puCalcFy !== 'latest' ? this.props.puCalcFy : this.props.client.pu_fys[0];
    }

    hasAssetsInTable = () => {
        return this.state.tableContent.length > 0 && this.state.tableContent[0].length > 0;
    }

    importAssets = () => {
        if(this.hasAssetsInTable() && !this.state.importing) {
            this.setState({ importing: true });

            this.props
                .importAssets({
                    client_id: this.props.client.client_id,
                    assets: this.structureAssetObjects(this.state.tableContent),
                    inputData: this.state.tableContent,
                })
                .then(() => {
                    this.setState({ importing: false, justImported: true });
                    this.toggle();
                    if (typeof this.props.onHide === 'function') {
                        this.props.onHide();
                    }
                });
        }
    }

    clearForm = () => {
        this.setState({
            tableContent: [[]],
            importing: false,
            justImported: false,
        });
    }

    render() {
        const numeraireOptions = this.props.numeraires.map((numeraire) => {
            return numeraire.symbol;
        });

        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} className="modal-xl" backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">Import Assets</span>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-12">
                            <div style={{height: '550px', overflowY: 'hidden'}}>
                                <HotTable
                                    root={ "hotImportUses" }
                                    data={ this.state.tableContent }
                                    contextMenu={true}
                                    columns={[
                                        { type: 'text', width: 120 },
                                        { type: 'text', width: 120 },
                                        { type: 'numeric', numericFormat: { pattern: '0,0' }, correctFormat: true, className: "htRight" },
                                        { type: 'numeric', numericFormat: { pattern: '0,0' }, correctFormat: true, className: "htRight" },
                                        { type: 'dropdown', source: numeraireOptions, width: 105 },
                                        { type: 'dropdown', source: numeraireOptions, width: 140 },
                                        { type: 'numeric', numericFormat: { pattern: '0,0' }, correctFormat: true, className: "htRight", width: 150 },
                                    ]}
                                    colHeaders={[
                                        'Asset Name',
                                        'Asset Code',
                                        'Total Size',
                                        'Common Size',
                                        'Size Numeraire',
                                        'Usage Numeraire (opt.)',
                                        'Usage Denominator (opt.)'
                                    ]}
                                    rowHeaders={ true }
                                    minRows={ 20 }
                                    //afterPaste={ (event, source) => { console.log('paste', event, source); } }
                                    afterChange={this.handleTableChange} />
                            </div>
                        </div>
                    </div>
                    <div className="text-right">
                        {
                            this.hasAssetsInTable()
                                ? (<button className="btn btn-success rounded-0" onClick={this.importAssets}>
                                    {
                                        this.state.importing
                                            ? 'Importing...'
                                            : 'Import Assets'
                                    }
                                </button>)
                                : '' 
                        }
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

AssetImporterModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        puCalcFy: state.client.puCalcFy,
        numeraires: state.numeraires.numeraires
    }
);

const mapDispatchToProps = dispatch => {
    return {
        importAssets: (importData) => dispatch(AssetActionCreators.importStructured(importData)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetImporterModal);