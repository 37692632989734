import { runApiCall } from './api-config';

function formatData(data) {
    return {
        asset_id: data.assetId ? data.assetId : null,
        space_id: data.spaceId ? data.spaceId : null,
        asset_user_id: data.assetUserId,
        type: 'contract',
        contract_type: data.contractType,
        contract_name: data.contractName ? data.contractName : null,
        size: data.size ? data.size : null,
        start_date: data.startDate,
        end_date: data.endDate,
        usage_numeraire_id: data.usageNumeraireId ? data.usageNumeraireId : null,
        fy_usage_detail: data.usageNumeraireId ? JSON.stringify(data.fyUsageDetail) : null,
    };
}

export async function update(useId, data) {
    const res = await runApiCall('PATCH', 'uses/' + useId, formatData(data));
    return res.data;
}

export async function create(data) {
    const res = await runApiCall('POST', 'uses/', formatData(data));
    return res.data;
}

export async function remove(useId) {
    const res = await runApiCall('DELETE', 'uses/' + useId);
    return res.data;
}

export async function importCommitStructured(data, onSuccess, onFailure) {
    const res = await runApiCall('POST', 'uses/import/commit_structured/', {
        uses: data.uses,
        fy: data.fy,
        client_id: data.client_id,
        input_data: data.input_data ? JSON.stringify(data.input_data) : null,
    });
    return res.data;
}
