import React from 'react';
import PropTypes from 'prop-types';

const AssetUserFormFields = (props) => {
    return (
        <div>
            <div className="form-group row">
                <label className="col-3 col-form-label text-muted text-uppercase">Name</label>
                <div className="col-9">
                    <input
                        type="text"
                        className="form-control rounded-0"
                        onChange={props.handleInputChange}
                        value={ props.fieldValues ? props.fieldValues.name : '' }
                        name="name"
                        autoComplete="off" />
                </div>
            </div>
            
            <div className="form-group row mt-4">
                <label className="col-3 col-form-label text-muted text-uppercase">Type</label>
                <div className="col-9">
                    <select
                        className="custom-select form-control rounded-0 w-100"
                        name="type"
                        onChange={props.handleInputChange}
                        value={ props.fieldValues ? props.fieldValues.type : 'private' }>
                        <option value="private">Private/Non-Exempt</option>
                        <option value="client">Client</option>
                        <option value="non-profit">Other Non-Profit</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

AssetUserFormFields.propTypes = {
    handleInputChange: PropTypes.func.isRequired,
    fieldValues: PropTypes.object
};

export default AssetUserFormFields;
