import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { debounce as _debounce } from 'lodash';

import * as IssueActionCreators from '../../ducks/issues';

const formatNumber = params => {
    const number = params.value;
    if(typeof number == 'undefined' || number === null) {
        return number;
    }
    return numeral(number).format('0,000.00');
};

const parseNumber = number => {
    if(typeof number == 'undefined' || number === null) {
        return 0;
    }
    return numeral(number).value();
};

const SourcesUsesTable = props => {
    const gridRef = useRef();
    
    const addSourceRow = () => {
        /*
        if (!gridRef?.current?.api) {
            return;
        }

        gridRef.current.api.applyTransaction({ add: [{
            type: "Sources",
            name: "New source",
            proceeds_amount: 0,
            prior_proceeds_amount: 0,
            other_amount: 0,
            total: 0,
        }]});
        */
        props.createSource({
            name: "New source",
            issue_id: props.issue.issue_id,
            proceeds_amount: 0,
            prior_proceeds_amount: 0,
            other_amount: 0,
            total: 0,
        });
    };

    const addUseRow = () => {
        /*
        if (!gridRef?.current?.api) {
            return;
        }
        
        gridRef.current.api.applyTransaction({ add: [{
            type: "Uses",
            name: "New use",
            proceeds_amount: 0,
            prior_proceeds_amount: 0,
            other_amount: 0,
            total: 0,
        }]});
        */
        props.createUse({
            name: "New use",
            issue_id: props.issue.issue_id,
            proceeds_amount: 0,
            prior_proceeds_amount: 0,
            other_amount: 0,
            total: 0,
        });
    };

    const saveData = event => {
        if(!event?.data || event?.data?.systemGenerated) {
            return;
        }
        console.log('will save data', event, props.issue);

        if(event.data.isParAmount) {
            props.updateIssue(props.issue.issue_id, { par_amount: parseNumber(event.data.production) });
        }
        else if(event.data.isOriginalPremium) {
            props.updateIssue(props.issue.issue_id, { original_premium: parseNumber(event.data.production) });
        }
        else if(event.data.type == "Uses" && event.data.issue_use_id) {
            props.updateUse(event.data.issue_use_id, event.data);
        }
        else if(event.data.type == "Uses" && !event.data.issue_use_id) {
            props.createUse({ ...event.data, issue_id: props.issue.issue_id });
        }
        else if(event.data.type == "Sources" && event.data.issue_source_id) {
            props.updateSource(event.data.issue_source_id, event.data);
        }
        else if(event.data.type == "Sources" && !event.data.issue_source_id) {
            props.createSource({ ...event.data, issue_id: props.issue.issue_id });
        }
    };

    const deleteRow = event => {

    };

    const debouncedSaveData = useCallback(_debounce(saveData, 500), []);

    const netProduction = 
        (props.issue.par_amount ? parseFloat(props.issue.par_amount) : 0)
        + (props.issue.original_premium ? parseFloat(props.issue.original_premium) : 0);
    
    const initialRowData = [
        {
            type: "Sources",
            name: "Par Amount",
            npEditable: true,
            production: (props.issue.par_amount ? parseFloat(props.issue.par_amount) : 0),
            isParAmount: true,
        },
        {
            type: "Sources",
            name: "Original Issue Premium/Discount",
            npEditable: true,
            production: (props.issue.original_premium ? parseFloat(props.issue.original_premium) : 0),
            isOriginalPremium: true,
        },
        {
            type: "Sources",
            name: "Net Production",
            systemGenerated: true,
            proceeds_amount: netProduction,
            total: netProduction,
        },
    ];

    props.issue.sources.forEach(source => {
        const proceeds_amount = parseNumber(source.proceeds_amount);
        const prior_proceeds_amount = parseNumber(source.prior_proceeds_amount);
        const other_amount = parseNumber(source.other_amount);
        initialRowData.push({
            issue_source_id: source.issue_source_id,
            type: "Sources",
            name: source.name,
            proceeds_amount,
            prior_proceeds_amount,
            other_amount,
            total: proceeds_amount + prior_proceeds_amount + other_amount,
        });
    });

    props.issue.uses.forEach(use => {
        const proceeds_amount = parseNumber(use.proceeds_amount);
        const prior_proceeds_amount = parseNumber(use.prior_proceeds_amount);
        const other_amount = parseNumber(use.other_amount);
        initialRowData.push({
            issue_use_id: use.issue_use_id,
            type: "Uses",
            name: use.name,
            proceeds_amount,
            prior_proceeds_amount,
            other_amount,
            total: proceeds_amount + prior_proceeds_amount + other_amount,
        });
    });

    //console.log('sources uses', props.issue.sources, props.issue.uses);

    return (
        <>
            <div className="mb-2">
                <button className="btn btn-sm btn-link" onClick={addSourceRow}><i className="icon icon-plus"></i> Add Source</button>
                <button className="btn btn-sm btn-link" onClick={addUseRow}><i className="icon icon-plus"></i> Add Use</button>
            </div>
            <div className="ag-theme-balham" style={{width: '100%', clear: 'both', height: '100%'}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={initialRowData}
                    groupIncludeFooter
                    groupDisplayType="groupRows"
                    groupDefaultExpanded={-1}
                    enterMovesDown
                    enterMovesDownAfterEdit
                    suppressAggFuncInHeader
                    editType="fullRow"
                    onRowValueChanged={debouncedSaveData}
                    domLayout="autoHeight">
                    <AgGridColumn
                        field="type"
                        headerName=""
                        pinned
                        hide
                        rowGroup />
                    <AgGridColumn
                        field="name"
                        headerName=""
                        width={300}
                        editable={params => !params.data?.npEditable && !params.data?.systemGenerated} />
                    <AgGridColumn
                        field="production"
                        headerName=""
                        width={175}
                        editable={params => !!params.data?.npEditable}
                        cellClass="text-right"
                        headerClass="text-right"
                        valueFormatter={formatNumber} />
                    <AgGridColumn
                        field="proceeds_amount"
                        headerName="Bond Proceeds"
                        width={175}
                        editable={params => !params.data?.systemGenerated && !params.data?.npEditable}
                        aggFunc="sum"
                        cellClass="text-right"
                        headerClass="text-right"
                        valueFormatter={formatNumber} />
                    <AgGridColumn
                        field="prior_proceeds_amount"
                        headerName="Prior Bond Proceeds"
                        width={175}
                        editable={params => !params.data?.systemGenerated && !params.data?.npEditable}
                        aggFunc="sum"
                        cellClass="text-right"
                        headerClass="text-right"
                        valueFormatter={formatNumber} />
                    <AgGridColumn
                        field="other_amount"
                        headerName="Other Proceeds"
                        width={175}
                        editable={params => !params.data?.systemGenerated && !params.data?.npEditable}
                        aggFunc="sum"
                        cellClass="text-right"
                        headerClass="text-right"
                        valueFormatter={formatNumber} />
                    <AgGridColumn
                        field="total"
                        headerName="Total"
                        width={175}
                        aggFunc="sum"
                        cellClass="text-right"
                        headerClass="text-right"
                        valueFormatter={formatNumber} />
                </AgGridReact>
            </div>
        </>
    );
}

SourcesUsesTable.propTypes = {
    issue: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
    return {
        updateIssue: (issueId, data) => dispatch(IssueActionCreators.update(issueId, data)),
        createUse: data => dispatch(IssueActionCreators.createUse(data)),
        updateUse: (issueUseId, data) => dispatch(IssueActionCreators.updateUse(issueUseId, data)),
        createSource: data => dispatch(IssueActionCreators.createSource(data)),
        updateSource: (issueSourceId, data) => dispatch(IssueActionCreators.updateSource(issueSourceId, data)),
    };
}

export default connect(undefined, mapDispatchToProps)(SourcesUsesTable);
