import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import * as SpaceActionCreators from '../../ducks/spaces';
import SpaceForm from './SpaceForm';
import SpaceFormFields from './SpaceFormFields';

class SpaceModalForm extends SpaceForm {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            submittingForm: false,
            fieldValues: {},
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            show: props.show,
            fieldValues: {
                asset_id: props.space ? props.space.asset_id : (props.asset ? props.asset.asset_id : null),
                parent_space_id: props.space ? props.space.parent_space_id : null,
                name: props.space ? props.space.name : null,
                space_code: props.space ? props.space.space_code : null,
                total_size: props.space ? props.space.total_size : null,
                common_size: props.space ? props.space.common_size : null,
                usage_numeraire_id: props.space ? props.space.usage_numeraire_id : null,
                usage_denominator: props.space ? props.space.usage_denominator : null,
                total_size_detail: props.space ? (props.space.total_size_detail ? props.space.total_size_detail : {}) : {},
                common_size_detail: props.space ? (props.space.common_size_detail ? props.space.common_size_detail : {}) : {},
            }
        });
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding)
            this.props.onHide();
    }

    render() {
        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} autoFocus={true} backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">
                        {
                            (this.props.space)
                                ? 'Edit ' + this.props.space.name
                                : 'Add Space'
                        }
                    </span>
                </ModalHeader>
                <form onSubmit={this.handleFormSubmit}>
                    <div className="p-3">
                        <SpaceFormFields
                            assets={this.props.assets}
                            client={this.props.client}
                            fieldValues={this.state.fieldValues}
                            handleInputChange={this.handleInputChange}
                            handleSizeDetailInputChange={this.handleSizeDetailInputChange}
                            includeAddAssetPopoverForm={true}
                            numeraires={this.props.numeraires}
                            showSizeDetailInputs={true} 
                            space={this.props.space} />
                    </div>
                    {
                        (this.props.space) ? 
                            <div className="pt-0 pb-2 pl-1">
                                <button type="button" className="btn btn-link" onClick={this.handleDeleteClick}>
                                    <span className="icon icon-circle-with-cross"></span>&nbsp;
                                    Delete {this.props.space.name}
                                </button>
                            </div>
                            : ''
                    }
                    <div className="modal-actions">
                        <button type="button" className="btn-link modal-action" onClick={this.props.onHide}>
                            Cancel
                        </button>
                        <button type="submit" className="btn-link modal-action font-weight-bold">
                            {
                                this.state.submittingForm
                                    ? 'Loading...'
                                    : this.props.space
                                        ? 'Save Changes'
                                        : 'Add Space'
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

SpaceModalForm.propTypes = {
    client: PropTypes.object,
    space: PropTypes.object,
    assets: PropTypes.array,
    numeraires: PropTypes.array,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
};

const mapStateToProps = state => (
    {
        numeraires: state.numeraires.numeraires,
        client: state.client.client,
        assets: state.assets.assets,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        createSpace: (data) => dispatch(SpaceActionCreators.create(data)),
        updateSpace: (id, data) => dispatch(SpaceActionCreators.update(id, data)),
        removeSpace: (id) => dispatch(SpaceActionCreators.remove(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceModalForm);