import * as AssetApi from '../api/AssetApi';
import * as UtilityUseApi from '../api/UtilityUseApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Common from './common';

const LIST_IS_LOADING = 'assets/LIST_IS_LOADING';
const LIST_HAS_LOADED = 'assets/LIST_HAS_LOADED';
const LIST_HAS_ERRORED = 'assets/LIST_HAS_ERRORED';

const DETAIL_VIEW_IS_LOADING = 'assets/DETAIL_VIEW_IS_LOADING';
const DETAIL_VIEW_HAS_LOADED = 'assets/DETAIL_VIEW_HAS_LOADED';
const DETAIL_VIEW_HAS_ERRORED = 'assets/DETAIL_VIEW_HAS_ERRORED';

const SWITCH_CLIENT = 'assets/SWITCH_CLIENT';

const CREATE = 'assets/CREATE';
const REMOVE = 'assets/REMOVE';
const UPDATE = 'assets/UPDATE';

const initialState = {
    assets: null,
    assetsListIsLoading: false,
    assetsListHasErrored: false,
    assetsListErrorMessage: null,
    detailView: null,
    detailViewIsLoading: false,
    detailViewHasErrored: false,
    detailViewErrorMessage: null,
};

export default function assetsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LIST_IS_LOADING:
            return {
                ...state,
                //assets: null,
                assetsListIsLoading: true,
                assetsListHasErrored: false,
                assetsListErrorMessage: null,
            };
        case LIST_HAS_LOADED:
            return {
                ...state,
                assets: action.assets,
                assetsListIsLoading: false,
                assetsListHasErrored: false,
                assetsListErrorMessage: null,
            };
        case LIST_HAS_ERRORED:
            return {
                ...state,
                assets: null,
                assetsListIsLoading: false,
                assetsListHasErrored: true,
                assetsListErrorMessage: action.errorMessage,
            };
        case DETAIL_VIEW_IS_LOADING:
            return {
                ...state,
                detailViewIsLoading: true,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case DETAIL_VIEW_HAS_LOADED:
            return {
                ...state,
                detailView: action.detailView,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case DETAIL_VIEW_HAS_ERRORED:
            return {
                ...state,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: true,
                detailViewErrorMessage: action.errorMessage,
            };
        case SWITCH_CLIENT:
            return {
                ...state,
                assets: null,
                assetsListIsLoading: false,
                assetsListHasErrored: false,
                assetsListErrorMessage: null,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case CREATE:
            return state;
        case REMOVE:
            return {
                ...state,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case UPDATE:
            return state;
        default:
            return state;
    }
}

export function assetsListIsLoading() {
    return { type: LIST_IS_LOADING };
}

export function assetsListHasLoaded(assets) {
    return { type: LIST_HAS_LOADED, assets: assets };
}

export function assetsListHasErrored(errorMessage) {
    return { type: LIST_HAS_ERRORED, errorMessage: errorMessage };
}

export function assetDetailViewIsLoading() {
    return { type: DETAIL_VIEW_IS_LOADING };
}

export function assetDetailViewHasLoaded(detailView) {
    return { type: DETAIL_VIEW_HAS_LOADED, detailView: detailView };
}

export function assetDetailViewHasErrored(errorMessage) {
    return { type: DETAIL_VIEW_HAS_ERRORED, errorMessage: errorMessage };
}

export function switchClient() {
    return { type: SWITCH_CLIENT };
}

export function loadAssets(clientId) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.assets.assetsListIsLoading) {
            return;
        }

        dispatch(assetsListIsLoading());
        dispatch(showLoading());

        AssetApi.listForClient(clientId)
            .then(data => {
                dispatch(assetsListHasLoaded(data.assets));
                dispatch(hideLoading());
            })
            .catch(error => {
                dispatch(assetsListHasErrored(error));
                dispatch(hideLoading());
            });
    };
}

export function loadAssetDetailView(assetId, puCalcFy) {
    return (dispatch) => {
        dispatch(assetDetailViewIsLoading());
        dispatch(showLoading());
        
        AssetApi.detailView(assetId, puCalcFy ? puCalcFy : 'latest')
            .then(data => {
                dispatch(assetDetailViewHasLoaded(data));
                dispatch(hideLoading());
            })
            .catch(error => {
                dispatch(assetDetailViewHasErrored(error));
                dispatch(hideLoading());
            });
    };
}

export function create(assetData) {
    return (dispatch) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        AssetApi.create(assetData)
            .then(data => {
                dispatch(hideLoading());
                dispatch(loadAssets(data.asset.client_id));
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function update(assetId, assetData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        assetId = parseInt(assetId, 10);

        AssetApi.update(assetId, assetData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                dispatch(loadAssets(data.asset.client_id));
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function remove(assetId) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        assetId = parseInt(assetId, 10);

        AssetApi.remove(assetId)
            .then(data => {
                dispatch(hideLoading());
                dispatch({ type: REMOVE });
                if (state.client && state.client.client) {
                    dispatch(loadAssets(state.client.client.client_id));
                }
                
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function importStructured(importData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        AssetApi.importCommitStructured(importData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadAssetList(dispatch, state);
                Common.reloadDataImportList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function saveResearchDetail(researchData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        AssetApi.saveResearchDetail(researchData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadAssetList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function merge(sourceAssetId, targetAssetId) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        AssetApi.merge(sourceAssetId, targetAssetId)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadAssetList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function createUtility(utilityData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        AssetApi.createUtility(utilityData, utilityData.asset_id)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}


export function updateUtility(utilityData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        AssetApi.updateUtility(utilityData.utility_id, utilityData, utilityData.asset_id)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function removeUtility(assetId, utilityId) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        AssetApi.removeUtility(utilityId, assetId)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function importUtilityUsesStructured(importData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        UtilityUseApi.importCommitStructured(importData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadAssetList(dispatch, state);
                Common.reloadAssetUserList(dispatch, state);
                Common.reloadDataImportList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}
