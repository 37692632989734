const SHOW_ISSUE_PU_PROCEEDS = 'modals/SHOW_ISSUE_PU_PROCEEDS';
const HIDE_ISSUE_PU_PROCEEDS = 'modals/HIDE_ISSUE_PU_PROCEEDS';
const TOGGLE_ISSUE_PU_PROCEEDS = 'modals/TOGGLE_ISSUE_PU_PROCEEDS';

const initialState = {
  issuePUProceedsIsVisible: false,
};

export default function modalFormsReducer(state = initialState, action = {}) {
  switch(action.type) {
    case SHOW_ISSUE_PU_PROCEEDS:
      return {
        ...state,
        issuePUProceedsIsVisible: true,
      };
    case HIDE_ISSUE_PU_PROCEEDS:
      return {
        ...state,
        issuePUProceedsIsVisible: false,
      };
    case TOGGLE_ISSUE_PU_PROCEEDS:
      return {
        ...state,
        issuePUProceedsIsVisible: !state.issuePUProceedsIsVisible,
      };
    default:
      return state;
  }
}

export function showIssuePUProceeds() {
  return { type: SHOW_ISSUE_PU_PROCEEDS };
}

export function hideIssuePUProceeds() {
  return { type: HIDE_ISSUE_PU_PROCEEDS };
}

export function toggleIssuePUProceeds() {
  return { type: TOGGLE_ISSUE_PU_PROCEEDS };
}