import { Component } from 'react';

class AssetForm extends Component {
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let fieldValues = this.state.fieldValues;
        fieldValues[name] = value;
        
        this.setState({ fieldValues: fieldValues });
    }
    
    handleSizeDetailInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const arr = name.split('-');
        const fy = arr[1];
        const type = arr[2];
                
        let fieldValues = this.state.fieldValues;
        
        if(type === 'total') {
            let totalSizeDetail = this.state.fieldValues.totalSizeDetail;
            totalSizeDetail[fy] = value;

            fieldValues.totalSizeDetail = totalSizeDetail;
        }
        
        if(type === 'common') {
            let commonSizeDetail = this.state.fieldValues.commonSizeDetail;
            commonSizeDetail[fy] = value;
            
            fieldValues.commonSizeDetail = commonSizeDetail;
        }
        
        this.setState({ fieldValues: fieldValues });
    }

    handleDaysInOperationInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const arr = name.split('-');
        const fy = arr[0];
                
        let fieldValues = this.state.fieldValues;
        
        let daysInOperationOverride = this.state.fieldValues.daysInOperationOverride;
        daysInOperationOverride[fy] = value;

        fieldValues.daysInOperationOverride = daysInOperationOverride;
        
        this.setState({ fieldValues: fieldValues });
    }

    saveFunc = (data) => {
        this.toggle();
        this.setState({ submittingForm: false });

        if (typeof this.props.onSave === 'function') {
            this.props.onSave(data.asset);
        }
    };

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ submittingForm: true });

        if(!this.state.asset) {
            this.props.createAsset(this.state.fieldValues).then(this.saveFunc);
        }
        else {
            this.props.updateAsset(this.props.asset.asset_id, this.state.fieldValues).then(this.saveFunc);
        }
    }

    handleDeleteClick = () => {
        let confirmed = window.confirm('Are you sure you want to delete ' + this.state.asset.name + '?');

        if(confirmed) {
            this.setState({ submittingForm: true });

            this.props.removeAsset(this.props.asset.asset_id).then(this.saveFunc);
        }
    }
}

export default AssetForm;