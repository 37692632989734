import React from 'react';
import PropTypes from 'prop-types';
import ProjectBenefitListing from './ProjectBenefitListing';
import _ from 'lodash';

const AssetProjectsTable = (props) => {
    let projectRows = [];

    if(props.assetView.project_benefits && Array.isArray(props.assetView.project_benefits)) {
        const benefits = _.sortBy(props.assetView.project_benefits, 'project.issue.dated_date');

        projectRows = benefits.map((b) => (
            <ProjectBenefitListing
                key={`project-benefit-${b.project_benefit_id}`}
                fy={props.fy}
                numeraires={props.numeraires}
                projectBenefit={b} />
        ));
    }

    return (
        <table className="table table-data">
            <thead>
                <tr className="bottom-dotted-border">
                    <th style={{width: '15%'}} className="text-left pl-3">Issue</th>
                    <th style={{width: '15%'}} className="text-left">Project</th>
                    <th style={{width: '14%'}}>FY{props.fy} Proceeds Spent</th>
                    <th style={{width: '14%'}}>FY{props.fy} Equity Spent</th>
                    <th style={{width: '14%'}}>FY{props.fy} Project Cost</th>
                </tr>
            </thead>
            <tbody>
                { projectRows }
            </tbody>
        </table>
    );
}

AssetProjectsTable.propTypes = {
    fy: PropTypes.number.isRequired,
    assetView: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
};

export default AssetProjectsTable;