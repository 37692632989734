import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import * as IssueActionCreators from '../../ducks/issues';
import numeral from 'numeral';
import _ from 'lodash';
import Constants from '../../lib/Constants';

class IssueEditPUOverrideModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableContent: [[]],
            importing: false,
            justImported: false,
            show: false,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        let tableContent = [[]];
        let existingOverrides = {};
        if(props.issueDetailView && props.issueDetailView.issue.pu_override) {
            props.issueDetailView.issue.pu_override.forEach(o => {
                existingOverrides[o.fy] = o;
            });
        }

        if(props.issueDetailView) {
            tableContent = props.issueDetailView.fys_outstanding.map((fy) => {
                const existing = existingOverrides[fy];

                return [
                    fy,
                    existing && existing.line4_factor != null ? numeral(existing.line4_factor).format('0.0000%') : null,
                    existing && existing.line5_factor != null ? numeral(existing.line5_factor).format('0.0000%') : null,
                    existing && existing.tmp_factor_no_coi != null ? numeral(existing.tmp_factor_no_coi).format('0.0000%') : null,
                    existing && existing.tmp_factor != null ? numeral(existing.tmp_factor).format('0.0000%') : null,
                    existing ? existing.id : null,
                ];
            });
        }

        this.setState({ show: props.show, tableContent: tableContent });
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding && typeof this.props.onHide === 'function')
            this.props.onHide();
    }

    handleTableChange = (event, source) => {
        if(!event) {
            return;
        }

        let tableContent = this.state.tableContent;

        event.map((change) => {
            let i = change[0];
            let j = change[1];

            if(!tableContent[i]) {
                tableContent[i] = [];
            }

            tableContent[i][j] = change[3];

            return null;
        });

        this.setState({ tableContent: tableContent });
    }

    structureOverrideObjects = (tableContent) => {
        return tableContent
            .filter((row) => {
                return (
                    !_.isNil(row[1])
                    || !_.isNil(row[2])
                    || !_.isNil(row[3])
                    || !_.isNil(row[4])
                    || !_.isNil(row[5])
                );
            })
            .map((row, i) => {
                return {
                    fy: row[0],
                    line4_factor: row[1],
                    line5_factor: row[2],
                    tmp_factor_no_coi: row[3],
                    tmp_factor: row[4],
                    id: row[5],
                };
            });
    }

    importOverrides = () => {
        if(!this.state.importing) {
            this.setState({ importing: true });

            this.props
                .updateOverrides(this.props.issueDetailView.issue.issue_id, {
                    pu_override: this.structureOverrideObjects(this.state.tableContent)
                })
                .then(() => {
                    this.setState({ importing: false, justImported: true });
                    this.toggle();
                    if (typeof this.props.onHide === 'function') {
                        this.props.onHide();
                    }
                })
                .catch((error) => {
                    this.setState({ importing: false, justImported: false });
                    alert(error);
                });
        }
    }

    clearForm = () => {
        this.setState({
            tableContent: [[]],
            importing: false,
            justImported: false,
        });
    }

    render() {
        if (!this.props.issues || !this.props.issueDetailView) {
            return '';
        }

        const issue = this.props.issueDetailView.issue;

        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} className="modal-lg" backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">Edit PBU Overrides for { issue.short_name }</span>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-12">
                            <div style={{height: '350px', overflowY: 'hidden'}}>
                                <HotTable
                                    root={ "hotImportUses" }
                                    data={ this.state.tableContent }
                                    contextMenu={true}
                                    minRows={10}
                                    columns={[
                                        { type: 'text', width: 70, className: "htCenter", readOnly: true },
                                        { type: 'numeric', numericFormat: { pattern: '0.0000%' }, correctFormat: true, className: "htRight" },
                                        { type: 'numeric', numericFormat: { pattern: '0.0000%' }, correctFormat: true, className: "htRight" },
                                        { type: 'numeric', numericFormat: { pattern: '0.0000%' }, correctFormat: true, className: "htRight" },
                                        { type: 'numeric', numericFormat: { pattern: '0.0000%' }, correctFormat: true, className: "htRight" },
                                        { type: 'text' }
                                    ]}
                                    colHeaders={[
                                        'FY',
                                        'Line 4 PBU %',
                                        'Line 5 PBU %',
                                        'TMP PBU % (ex-COI)',
                                        'TMP PBU % (incl-COI)',
                                    ]}
                                    rowHeaders={false}
                                    afterChange={this.handleTableChange}
                                    licenseKey={Constants.hotLicenseKey}
                                    hiddenColumns={{ columns: [5], indicators: false }} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-actions">
                    <button type="button" className="btn-link modal-action" onClick={this.props.onHide}>
                        Cancel
                    </button>
                    <button className="btn-link modal-action font-weight-bold" onClick={this.importOverrides}>
                        {
                            this.state.importing
                                ? 'Saving...'
                                : 'Edit Overrides'
                        }
                    </button>
                </div>
            </Modal>
        );
    }
}

IssueEditPUOverrideModal.propTypes = {
    onHide: PropTypes.func
};

const mapStateToProps = state => (
    {
        issues: state.issues.list,
        issueDetailView: state.issues.detailView,
        puCalcFy: state.client.puCalcFy,
        client: state.client.client,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        updateOverrides: (issueId, data) => dispatch(IssueActionCreators.updatePUOverride(issueId, data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueEditPUOverrideModal);