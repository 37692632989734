import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { HotTable } from '@handsontable/react';
import { connect } from 'react-redux';

import { isEqual as _isEqual } from 'lodash'

import * as AssetActionCreators from '../../ducks/assets';
import Constants from '../../lib/Constants';
import * as ModelUtil from '../../lib/ModelUtil';

class UtilityPlantDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableContent: [],
            saving: false,
            justSaved: false,
        };
    }

    componentDidMount() {
        if(!this.props.assetDetailView || !this.props.assets) {
            return;
        }

        this.setTableContentFromProps();
    }

    componentDidUpdate(prevProps) {
        if(!this.props.assetDetailView || !this.props.assets) {
            return;
        }

        if(!prevProps.assetDetailView || prevProps.assetDetailView.asset.asset_id !== this.props.assetDetailView.asset.asset_id) {
            this.setTableContentFromProps();
        }
        else if(!prevProps.assets && this.props.assets) {
            this.setTableContentFromProps();
        }
        else if(!_isEqual(prevProps.assetDetailView.asset.utilities, this.props.assetDetailView.asset.utilities)) {
            this.setTableContentFromProps();
        }
    }

    setTableContentFromProps = () => {
        // TODO: also pull asset PU from new route
        const utilities = this.props.assetDetailView.asset.utilities;
        const tableContent = this.props.assets
            .filter((a) => {
                return a.asset_id !== this.props.assetDetailView.asset.asset_id;
            })
            .map((a) => {

                let row = [a.asset_id, a.name];

                let assetNetSize, assetLine4, assetLine5;
                let plantLine4 = 0;
                let plantLine5 = 0;

                utilities.forEach((u) => {
                    const use = this.findUtilityUseForAsset(u.uses, a.asset_id);
                    if(use) {
                        assetNetSize = use.user.pu_calc.pu_net_size;
                        assetLine4 = use.user.pu_calc.sk_line4_factor;
                        assetLine5 = use.user.pu_calc.sk_line5_factor;
                        
                        if(typeof u.pu_calc.use_pu_contributions[use.utility_use_id] !== 'undefined') {
                            const contribution = u.pu_calc.use_pu_contributions[use.utility_use_id];
                            plantLine4 += contribution.line4 ? contribution.line4 * u.plant_factor : 0;
                            plantLine5 += contribution.line5 ? contribution.line5 * u.plant_factor : 0;
                        }
                    }
                    
                    row.push(use ? (u.user_size_as_usage_factor ? true : use.usage_factor) : null);
                });

                if(typeof assetNetSize !== 'undefined'
                    && typeof assetLine4 !== 'undefined'
                    && typeof assetLine5 !== 'undefined') {
                    
                    row.push(assetNetSize);
                    row.push(assetLine4);
                    row.push(assetLine5);
                    row.push(plantLine4);
                    row.push(plantLine5);
                }

                return row;
            });

        this.setState({ tableContent });
    }

    findUtilityUseForAsset(utilityUses, assetId) {
        const fy = parseInt(this.currentFy(), 10);
        assetId = parseInt(assetId, 10);

        return utilityUses.find((u) => {
            return u.user_id === assetId && u.user_type === 'App\\Asset' && u.fy === fy;
        });
    }

    currentFy = () => {
        return ModelUtil.effectivePUCalcFYEndDateForClient(this.props.puCalcFy, this.props.client).format('Y');
    }

    handleTableChange = (event) => {
        if(!event) {
            return;
        }

        let tableContent = this.state.tableContent;

        event.map((change) => {
            let i = change[0];
            let j = change[1];

            if(!tableContent[i]) {
                tableContent[i] = [];
            }

            let newValue = change[3];
            if(typeof newValue === 'string') {
                newValue = numeral(newValue).value();
            }

            tableContent[i][j] = change[3];

            return null;
        });

        this.setState({ tableContent: tableContent, justSaved: false });
    }

    selectAllAssetsForUtility = (utilityIdx) => {
        let tableContent = this.state.tableContent;
        tableContent = tableContent.map((row) => {
            row[utilityIdx + 2] = true;
            return row;
        });
        this.setState({ tableContent, justSaved: false });
    }

    unselectAllAssetsForUtility = (utilityIdx) => {
        let tableContent = this.state.tableContent;
        tableContent = tableContent.map((row) => {
            row[utilityIdx + 2] = false;
            return row;
        });
        this.setState({ tableContent, justSaved: false });
    }

    allUtilityAssetsSelected = (utilityIdx) => {
        return !this.state.tableContent.find((row) => {
            return !row[utilityIdx + 2];
        });
    }

    structureUseObjects = (tableContent) => {
        let uses = [];
        let deletedUses = [];
        tableContent.forEach((row) => {
            this.props.assetDetailView.asset.utilities.forEach((utility, i) => {
                const use = this.findUtilityUseForAsset(utility.uses, row[0]);

                if(row[2 + i]) {
                    uses.push({
                        utility_use_id: use ? use.utility_use_id : null,
                        utility_id: utility.utility_id,
                        fy: this.currentFy(),
                        asset_id: row[0],
                        usage: row[2 + i],
                    });
                }
                else if(use){ // mark use for deletion 
                    deletedUses.push(use.utility_use_id);
                }
            });
        });
        return {
            utility_uses: uses,
            deleted_uses: deletedUses
        };
    }

    saveUses = () => {
        const data = this.structureUseObjects(this.state.tableContent);
        
        if((data.utility_uses || data.deleted_uses) && !this.state.saving) {
            this.setState({ saving: true });

            this.props
                .importUtilityUses({
                    ...data,
                    fy: this.currentFy(),
                    client_id: this.props.client.client_id,
                })
                .then(() => {
                    this.setState({ saving: false, justSaved: true });
                })
                .catch((error) => {
                    this.setState({ saving: false });
                    alert(error);
                });
        }
    }

    render() {
        if (!this.props.assetDetailView) {
            return '';
        }

        const asset = this.props.assetDetailView.asset;

        let columns = [
            { type: 'text', readOnly: true, hidden: true },
            { type: 'text', width: 200, readOnly: true, numericFormat: { pattern: '0.00%', culture: 'en-US' } },
        ];
        let columnHeaders = [
            'Asset ID',
            'User Asset',
        ];
        const hiddenColumns = {
            columns: [0],
            indicators: false
        };

        asset.utilities.forEach((utility) => {
            columns.push({
                width: 120,
                type: utility.user_size_as_usage_factor ? 'checkbox' : 'numeric',
                numericFormat: {
                    pattern: '0.00%',
                    culture: 'en-US'
                },
                className: utility.user_size_as_usage_factor ? 'htCenter' : 'htRight',
            });

            columnHeaders.push(`FY${this.currentFy().toString().substr(2)} ${utility.name}`);
        });

        columnHeaders.push('Size');
        columns.push({ type: 'numeric', width: 80, readOnly: true, numericFormat: { pattern: '0,000', culture: 'en-US' } });
        columnHeaders.push('Asset L4 %');
        columns.push({ type: 'numeric', width: 80, readOnly: true, numericFormat: { pattern: '0.00%', culture: 'en-US' } });
        columnHeaders.push('Asset L5 %');
        columns.push({ type: 'numeric', width: 80, readOnly: true, numericFormat: { pattern: '0.00%', culture: 'en-US' } });
        columnHeaders.push('Plant L4 %');
        columns.push({ type: 'numeric', width: 80, readOnly: true, numericFormat: { pattern: '0.00%', culture: 'en-US' } });
        columnHeaders.push('Plant L5 %');
        columns.push({ type: 'numeric', width: 80, readOnly: true, numericFormat: { pattern: '0.00%', culture: 'en-US' } });

        const utilityRows = asset.utilities.map((utility, utilityIdx) => {
            const plantLine4 = numeral(utility.pu_calc.sk_line4_factor).value() * numeral(utility.plant_factor).value();
            const plantLine5 = numeral(utility.pu_calc.sk_line5_factor).value() * numeral(utility.plant_factor).value();
            const allSelected = this.allUtilityAssetsSelected(utilityIdx);
            
            return (
                <tr key={utility.utility_id}>
                    <td className="pl-3">{ utility.name }</td>
                    <td>{ utility.user_size_as_usage_factor ? 'User Size' : 'Manual' }</td>
                    <td className="text-right">{ numeral(utility.plant_factor).format('0.00%') }</td>
                    <td className="text-right">{ numeral(utility.pu_calc.sk_line4_factor).format('0.00%') }</td>
                    <td className="text-right">{ numeral(utility.pu_calc.sk_line5_factor).format('0.00%') }</td>
                    <td className="text-right">{ numeral(plantLine4).format('0.00%') }</td>
                    <td className="text-right">{ numeral(plantLine5).format('0.00%') }</td>
                    <td className="text-center">
                        {
                            utility.user_size_as_usage_factor && !allSelected
                                ? <a onClick={() => { this.selectAllAssetsForUtility(utilityIdx); }}>Select All</a>
                                : ''
                        }
                        {
                            utility.user_size_as_usage_factor && allSelected
                                ? <a onClick={() => { this.unselectAllAssetsForUtility(utilityIdx); }}>Unselect All</a>
                                : ''
                        }
                    </td>
                    <td className="text-center">
                        <a onClick={() => { this.props.showUtilityForm(utility); }}>
                            <i className="icon icon-edit" />
                        </a>
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <div className="row sticky-top bg-white pt-3 pb-3" style={{ top: '58px' }}>
                    <div className="col-xl-7 col-sm-5">
                        <h3 className="font-weight-bold">{ asset.name }</h3>
                        <h5 className="text-muted">
                            <a onClick={() => { this.props.showAssetForm(asset); }}>
                                <i className="icon icon-edit" />
                            </a>
                        </h5>
                    </div>
                    <div className="col-xl-5 col-sm-7 text-muted">
                        <div className="row">
                            <div className="statcard p-2 border text-right col-md-4">
                                <span className="statcard-desc">LINE 4 PRIVATE USE</span>
                                <h3 className="statcard-number">
                                    { numeral(asset.pu_calc.sk_line4_factor).format('0.00%') }
                                </h3>
                            </div>
                            <div className="statcard p-2 border text-right col-md-4">
                                <span className="statcard-desc">LINE 5 PRIVATE USE</span>
                                <h3 className="statcard-number">
                                    { numeral(asset.pu_calc.sk_line5_factor).format('0.00%') }
                                </h3>
                            </div>
                            <div className="statcard p-2 border text-right statcard-highlight col-md-4">
                                <span className="statcard-desc">FY PRIVATE USE</span>
                                <h3 className="statcard-number">
                                    { numeral(asset.pu_calc.pu_factor).format('0.00%') }
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-9">
                        <div className="d-flex justify-content-between pt-2 pb-2 pr-3 pl-3 bottom-solid-border w-100 card-bg">
                            <div className="text-muted font-weight-bold">
                                UTILITIES
                            </div>
                            <div>
                                <a onClick={() => { this.props.showUtilityForm(); }}>+ Add Utility</a>
                            </div>
                        </div>

                        <table className="table table-data table-hover">
                            <thead>
                                <tr className="bottom-dotted-border">
                                    <th style={{width: '17%'}} className="pl-3">Utility</th>
                                    <th style={{width: '12%'}}>Usage</th>
                                    <th style={{width: '13%'}} className="text-right">Plant %</th>
                                    <th style={{width: '12%'}} className="text-right">L4 %</th>
                                    <th style={{width: '12%'}} className="text-right">L5 %</th>
                                    <th style={{width: '12%'}} className="text-right">Plant L4 %</th>
                                    <th style={{width: '12%'}} className="text-right">Plant L5 %</th>
                                    <th style={{width: '8%'}}>&nbsp;</th>
                                    <th style={{width: '2%'}}>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                { utilityRows }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-3 text-right">
                        {
                            this.state.justSaved
                                ? <span className="text-primary"><i className="icon icon-check"></i> Saved!&nbsp;&nbsp;&nbsp;</span>
                                : ''
                        }
                        <button className="btn btn-success rounded-0" disabled={this.state.saving || this.state.justSaved} onClick={this.saveUses}>
                            <i className="icon icon-save"></i>&nbsp;
                            { this.state.saving ? 'Saving...' : 'Save Users' }
                        </button>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <div style={{height: '550px', overflowY: 'hidden'}}>
                            <HotTable
                                root={ "hotUtilityUsers" }
                                data={ this.state.tableContent }
                                contextMenu={ false }
                                columns={ columns }
                                colHeaders={ columnHeaders }
                                rowHeaders={ true }
                                hiddenColumns={hiddenColumns}
                                fixedColumnsLeft={2 + asset.utilities.length}
                                afterChange={ this.handleTableChange }
                                licenseKey={Constants.hotLicenseKey} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

UtilityPlantDetail.propTypes = {
    assets: PropTypes.array.isRequired,
    assetDetailView: PropTypes.object.isRequired,
    showUtilityForm: PropTypes.func.isRequired,
    showAssetForm: PropTypes.func.isRequired,
    puCalcFy: PropTypes.string.isRequired,
    client: PropTypes.object.isRequired,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        puCalcFy: state.client.puCalcFy,
        assets: state.assets.assets,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        importUtilityUses: (importData) => dispatch(AssetActionCreators.importUtilityUsesStructured(importData)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UtilityPlantDetail);