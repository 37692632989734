import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as DateFunctions from '../../lib/DateFunctions';

const ClientFormFields = props => {
    const { register, handleSubmit, watch, errors } = useForm({
        defaultValues: !props.client 
            ? {
                fy_end_day: 31,
                first_pu_fy: DateFunctions.currentYear(),
                last_pu_fy: DateFunctions.currentYear()
            }
            : {
                ...props.client
            }
    });

    const fyEndMonth = watch('fy_end_month');
    let fyEndMonthDays = DateFunctions.daysInMonth(fyEndMonth ? fyEndMonth : '01');
    
    let fyEndDayOptions = [];
    if (fyEndMonthDays) {
        for (let i = 1; i <= fyEndMonthDays; i++) {
            fyEndDayOptions[fyEndDayOptions.length] = (
                <option value={i} key={i}>{i}</option>
            );
        }
    }

    let puFyOptions = [];
    const currentYear = DateFunctions.currentYear();
    
    for (let i = 1980; i <= currentYear; i++) {
        puFyOptions[puFyOptions.length] = (
            <option value={i} key={i}>{i}</option>
        );
    }

    const userOptions = (props.users || []).map(user => (
        <option value={user.id} key={user.id}>{ user.first_name } { user.last_name }</option>
    ));

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <div className="p-3">
                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">Name</label>
                    <div className="col-10">
                        <input
                            ref={register}
                            type="text"
                            className="form-control rounded-0"
                            name="name"
                            autoComplete="off" />
                    </div>                    
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">Acronym</label>
                    <div className="col-10">
                        <input
                            ref={register}
                            type="text"
                            className="form-control rounded-0"
                            name="acronym"
                            autoComplete="off" />
                    </div>                    
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">Org. Name</label>
                    <div className="col-10">
                        <input
                            ref={register}
                            type="text"
                            className="form-control rounded-0"
                            name="organization_name"
                            autoComplete="off" />
                    </div>                    
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">Org. EIN</label>
                    <div className="col-10">
                        <input
                            ref={register}
                            type="text"
                            className="form-control rounded-0"
                            name="organization_ein"
                            autoComplete="off" />
                    </div>                    
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">FY End</label>
                    <div className="col-5">
                        <select
                            ref={register}
                            className="form-control custom-select rounded-0"
                            name="fy_end_month">
                            <option value="1">01 - January</option>
                            <option value="2">02 - February</option>
                            <option value="3">03 - March</option>
                            <option value="4">04 - April</option>
                            <option value="5">05 - May</option>
                            <option value="6">06 - June</option>
                            <option value="7">07 - July</option>
                            <option value="8">08 - August</option>
                            <option value="9">09 - September</option>
                            <option value="10">10 - October</option>
                            <option value="11">11 - November</option>
                            <option value="12">12 - December</option>
                        </select>
                    </div>
                    <div className="col-5">
                        <select
                            ref={register}
                            className="form-control custom-select rounded-0"
                            name="fy_end_day">
                            {fyEndDayOptions}
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">First FY</label>
                    <div className="col-10">
                        <select
                            ref={register}
                            className="form-control custom-select rounded-0"
                            name="first_pu_fy">
                            {puFyOptions}
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">Last FY</label>
                    <div className="col-10">
                        <select
                            ref={register}
                            className="form-control custom-select rounded-0"
                            name="last_pu_fy">
                            {puFyOptions}
                        </select>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">Reviewer</label>
                    <div className="col-10">
                        <select
                            ref={register}
                            className="form-control custom-select rounded-0"
                            name="reviewer_user_id">
                            <option value=""></option>
                            {userOptions}
                        </select>
                    </div>                    
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">Healthcare Client</label>
                    <div className="col-10 pt-2">
                        <input
                            ref={register}
                            type="checkbox"
                            name="is_healthcare"
                            id="is_healthcare" />
                    </div>                    
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">BLX Services Company Number</label>
                    <div className="col-10">
                        <input
                            ref={register}
                            type="text"
                            className="form-control rounded-0"
                            name="blx_services_company_number"
                            autoComplete="off" />
                    </div>                    
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">DC Issues Box Folder Share Link</label>
                    <div className="col-10">
                        <input
                            ref={register}
                            type="text"
                            className="form-control rounded-0"
                            name="dc_box_folder_issues"
                            autoComplete="off" />
                    </div>                    
                </div>

                <div className="form-group row">
                    <label className="col-2 col-form-label text-muted text-uppercase">DC Assets Box Folder Share Link</label>
                    <div className="col-10">
                        <input
                            ref={register}
                            type="text"
                            className="form-control rounded-0"
                            name="dc_box_folder_assets"
                            autoComplete="off" />
                    </div>                    
                </div>
            </div>
            { props.children }
        </form>
    );
}

ClientFormFields.propTypes = {
    client: PropTypes.object,
    users: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ClientFormFields;
