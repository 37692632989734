import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import numeral from 'numeral';

import UtilityFormFields from './UtilityFormFields';
import * as AssetActionCreators from '../../ducks/assets';

class UtilityModalForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            utility: null,
            show: false,
            submittingForm: false,
            fieldValues: {},
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        if(props.assetDetailView) {
            this.setState({
                show: props.show,
                fieldValues: {
                    client_id: props.client.client_id,
                    utility_id: props.utility ? props.utility.utility_id : null,
                    name: props.utility ? props.utility.name : '',
                    plant_factor: props.utility ? numeral(props.utility.plant_factor).format('0,00.00%') : '',
                    user_size_as_usage_factor: props.utility ? props.utility.user_size_as_usage_factor : 1,
                    asset_id: props.assetDetailView.asset.asset_id,
                }
            });
        }
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding)
            this.props.onHide();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let fieldValues = this.state.fieldValues;
        fieldValues[name] = value;
        
        this.setState({ fieldValues: fieldValues });
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ submittingForm: true });

        let saveFunc = (data) => {
            this.setState({ submittingForm: false });
            this.toggle();
        };

        if(!this.props.utility) {
            this.props.createUtility(this.state.fieldValues)
                .then((data) => {
                    saveFunc(data);
                })
                .catch((error) => {
                    alert(error);
                });  
        }
        else {
            this.props.updateUtility(this.state.fieldValues)
                .then((data) => {
                    saveFunc(data);
                })
                .catch((error) => {
                    alert(error);
                }); 
        }
    }

    handleDeleteClick = (event) => {
        let confirmed = window.confirm('Are you sure you want to delete ' + this.props.utility.name + '?');

        if(confirmed) {
            this.setState({ submittingForm: true });

            this.props.removeUtility(this.props.assetDetailView.asset.asset_id, this.props.utility.utility_id)
                .then(() => {
                    this.setState({ submittingForm: false });
                    this.toggle();
                })
                .catch((error) => {
                    alert(error);
                });                
        }
    }

    render() {
        if(!this.props.assetDetailView) {
            return '';
        }

        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">
                        {
                            (this.props.utility)
                                ? 'Edit ' + this.props.utility.name
                                : 'Add Utility'
                        }
                    </span>
                </ModalHeader>
                <form onSubmit={this.handleFormSubmit}>
                    <div className="p-3">
                        <UtilityFormFields
                            handleInputChange={this.handleInputChange}
                            fieldValues={this.state.fieldValues} />
                    </div>
                    {
                        (this.props.utility) ? 
                            <div className="pt-0 pb-2 pl-1">
                                <button type="button" className="btn btn-link" onClick={this.handleDeleteClick}>
                                    <span className="icon icon-circle-with-cross"></span>&nbsp;
                                    Delete {this.props.utility.name}
                                </button>
                            </div>
                            : ''
                    }
                    <div className="modal-actions">
                        <button type="button" className="btn-link modal-action" onClick={this.props.onHide}>
                            Cancel
                        </button>
                        <button type="submit" className="btn-link modal-action font-weight-bold">
                            {
                                this.state.submittingForm
                                    ? 'Loading...'
                                    : this.props.utility
                                        ? 'Save Changes'
                                        : 'Add Utility'
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

UtilityModalForm.propTypes = {
    utility: PropTypes.object,
    assetDetailView: PropTypes.object,
    onHide: PropTypes.func,
    client: PropTypes.object.isRequired,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        createUtility: (data) => dispatch(AssetActionCreators.createUtility(data)),
        updateUtility: (data) => dispatch(AssetActionCreators.updateUtility(data)),
        removeUtility: (assetId, utilityId) => dispatch(AssetActionCreators.removeUtility(assetId, utilityId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UtilityModalForm);