import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import numeral from 'numeral';
import Constants from '../../lib/Constants';
import * as ProjectActionCreators from '../../ducks/projects';
import * as ModelUtil from '../../lib/ModelUtil';
import TableForm from '../TableForm';

class ProjectsExpendituresModalForm extends TableForm {
    constructor(props) {
        super(props);
        this.state = { ...this.state, userSelectedFy: null };
    }

    componentDidUpdate(prevProps) {        
        if(this.props.show !== this.state.show) {
            this.setState({ show: this.props.show, userSelectedFy: null });
        }
        
        if(
            this.issue()
            && (
                !prevProps.issueDetailView
                || prevProps.issueDetailView.issue.issue_id !== this.issue().issue_id
                || !this.state.tableContent
            )
        ) {
            this.setTableContentFromIssueDetailView();
        }
    }

    setTableContentFromIssueDetailView = () => {
        const content = !this.issue() || !this.issue().projects ? []
            : this.issue().projects.map(p => {
                let ret = [
                    p.project_id,
                    p.name,
                    !p.benefits ? '' : p.benefits.reduce((carry, b) => {
                        carry.push(b.beneficiary.name);
                        return carry;
                    }, []).join('; '),
                ];

                this.props.puFys.forEach(fy => {
                    ret.push(
                        p.fy_cost_detail && p.fy_cost_detail[fy]
                            ? numeral(p.fy_cost_detail[fy]).format('0,000.00')
                            : null
                    );
                    ret.push(
                        p.fy_equity_detail && p.fy_equity_detail[fy]
                            ? numeral(p.fy_equity_detail[fy]).format('0,000.00')
                            : null
                    );
                
                });
                return ret;
            });

        this.setState({ tableContent: content });
    }

    issue = () => {
        return (
            this.props.issueDetailView
            && this.props.issueDetailView.issue
        ) ? this.props.issueDetailView.issue : null;
    }

    selectedFy = () => {
        return this.state.userSelectedFy ? this.state.userSelectedFy : ModelUtil.effectivePUCalcFYForClientFromProps(this.props);
    }

    structureExpenditureObjects = () => {
        return this.state.tableContent.map((row) => {
            return {
                issue_id: this.issue().issue_id,
                project_id: row[0],
                project_name: row[1],
                //proceeds_spent: row[3],
                //equity_spent: row[4],
                fy_cost_detail: this.props.puFys.reduce((carry, fy, i) => {
                    carry[fy] = row[3 + (i * 2)];
                    return carry;
                }, {}),
                fy_equity_detail: this.props.puFys.reduce((carry, fy, i) => {
                    carry[fy] = row[3 + (i * 2) + 1];
                    return carry;
                }, {}),
            };
        });
    }

    saveExpenditures = () => {
        if(this.hasRecordsInTable() && !this.state.saving) {
            this.setState({ saving: true });
            
            this.props
                .updateExpenditureData({
                    projects: this.structureExpenditureObjects(),
                })
                .then(() => {
                    this.setState({ saving: false, justSaved: true });
                    this.toggle();
                    if(typeof this.props.onHide === 'function') {
                        this.props.onHide();
                    }
                })
                .catch((error) => {
                    this.setState({ saving: false });
                    alert(error);
                });
        }
    }

    changeProceedsEquityFY = (event) => {
        this.setState({ userSelectedFy: parseInt(event.target.value, 10) });
    }

    render() {
        const fyOptions = !this.props.puFys ? [] : this.props.puFys.map(fy => (
            <option value={fy} key={fy}>FY{fy} Proceeds/Equity</option>
        ));

        let fyColumns = [];
        let fyColumnHeaders = [];
        let hiddenColumns = [];
        hiddenColumns.push(0);
        const firstFyColIdx = 3;
        if(Array.isArray(this.props.puFys)) {
            this.props.puFys.forEach((fy, fyI) => {
                fyColumns.push({
                    type: 'numeric',
                    numericFormat: { pattern: '0,00.00' },
                    correctFormat: true,
                    className: 'htRight',
                });
                fyColumns.push({
                    type: 'numeric',
                    numericFormat: { pattern: '0,00.00' },
                    correctFormat: true,
                    className: 'htRight',
                });
                fyColumnHeaders.push(`Tot. Proceeds Spent a/o FY${fy.toString().slice(-2)}`);
                fyColumnHeaders.push(`Tot. Equity Spent a/o FY${fy.toString().slice(-2)}`);

                if(fy !== this.selectedFy()) {
                    hiddenColumns.push(firstFyColIdx + (fyI * 2));
                    hiddenColumns.push(firstFyColIdx + (fyI * 2) + 1);
                }
            });
        }

        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} className="modal-xl" backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">Edit Project Expenditures</span>
                </ModalHeader>
                <ModalBody>
                    <div className="row mb-3">
                        <div className="col-3">
                            <select className="custom-select rounded-0 w-100 form-control" onChange={this.changeProceedsEquityFY} value={this.selectedFy()}>
                                { fyOptions }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div style={{height: '550px', overflowY: 'hidden'}}>
                                <HotTable
                                    root={ "hotImportProjects" }
                                    data={ this.state.tableContent }
                                    contextMenu={true}
                                    columns={[
                                        { type: 'text', readOnly: true },
                                        { type: 'text', width: 325, readOnly: true },
                                        { type: 'text', width: 325, readOnly: true },
                                        ...fyColumns
                                    ]}
                                    colHeaders={[
                                        'Project ID',
                                        'Project Name',
                                        'Beneficiaries',
                                        ...fyColumnHeaders
                                    ]}
                                    rowHeaders={ true }
                                    afterChange={ this.handleTableChange }
                                    hiddenColumns={{ columns: hiddenColumns }}
                                    licenseKey={Constants.hotLicenseKey} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-actions">
                        <button type="button" className="btn-link modal-action" onClick={this.toggle}>
                            Cancel
                        </button>
                        <button type="submit" className="btn-link modal-action font-weight-bold" onClick={this.saveExpenditures}>
                            {
                                this.state.saving
                                    ? 'Saving...'
                                    : 'Save Expenditures'
                            }
                        </button>
                    </div>
            </Modal>
        );
    }
}

ProjectsExpendituresModalForm.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        puCalcFy: state.client.puCalcFy,
        puFys: state.client.client.pu_fys,
        issueDetailView: state.issues.detailView,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        updateExpenditureData: (importData) => dispatch(ProjectActionCreators.updateExpenditureData(importData)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsExpendituresModalForm);