import * as ProjectApi from '../api/ProjectApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Common from './common';

const CREATE = 'projects/CREATE';
const REMOVE = 'projects/REMOVE';
const UPDATE = 'projects/UPDATE';

const initialState = {

};

export default function projectsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case CREATE:
            return state;
        case REMOVE:
            return state;
        case UPDATE:
            return state;
        default:
            return state;
    }
}

export function create(projectData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        ProjectApi.create(projectData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, getState());
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function update(projectId, projectData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        ProjectApi.update(projectId, projectData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, getState());
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function remove(projectId) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        ProjectApi.remove(projectId)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, getState());
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function importStructured(importData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        ProjectApi.importCommitStructured(importData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadAssetList(dispatch, state);
                Common.reloadIssueList(dispatch, state);
                Common.reloadDataImportList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function updateExpenditureData(importData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        ProjectApi.updateExpenditureData(importData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                // TODO: will want to reload these when we support adding assets/issues through the expenditure import
                //Common.reloadAssetList(dispatch, state);
                //Common.reloadIssueList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}
