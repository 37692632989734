import React, { useState } from 'react';
import { connect } from 'react-redux';
import './TitleBar.css';
import * as ClientActionCreators from '../ducks/client';
import { downloadApiFile } from '../api/api-config';
import { Ellipsis } from 'react-css-spinners';
import * as ModelUtil from '../lib/ModelUtil';

const TitleBar = (props) => {
    let [downloadingFile, setDownloadingFile] = useState(false);
    /*
    <div className="dashhead-toolbar-item align-middle">
        <a className="btn btn-outline-danger rounded-0" href={ API_HOST + 'reports/client_asset_use/' + props.clientId + '/' + props.puCalcFy } target="_blank" rel="noopener noreferrer">PDF Report</a>
    </div>
    */
    let puFyOptions = props.clientPuFys.map((fy, index) =>
        <option key={fy} value={fy}>
            FY{fy} Private Use Calculation
        </option>
    );

    return (
        <div className="row fixed-top title-bar">
            <div className="col-12" style={{paddingLeft: 15 + 'px'}}>
                <div className="dashhead">
                    <div className="dashhead-titles">
                        <h6 className="dashhead-subtitle font-weight-bold">
                            {props.clientName ? props.clientName : ''}
                            <span className="font-weight-normal"> -- SW RELEASE 2024.04.18.1</span> <a href="https://blx.digital/whatsnew/pic" target="_blank">What's New</a>
                        </h6>
                        <h5 className="dashhead-title font-weight-bold">
                            {props.pageTitle}
                        </h5>
                    </div>
                    
                    {
                        props.clientId ?
                            <div className="dashhead-toolbar mt-0 pr-3">
                                {
                                    props.client?.dc_key && (
                                        <>
                                            <div className="dashhead-toolbar-item" style={{fontSize: "1.1em"}}>
                                                <strong>Data collection link: </strong>
                                                <a href={`https://data.blxpic.com/pic/${ props.client.dc_key }/${ ModelUtil.effectivePUCalcFYForClientFromProps(props) }`} target="_blank">
                                                    https://data.blxpic.com/pic/{ props.client.dc_key }/{ ModelUtil.effectivePUCalcFYForClientFromProps(props) }
                                                </a>
                                            </div>
                                            <span className="dashhead-toolbar-divider hidden-xs"></span>
                                        </>
                                    )
                                }
                                <div className="dashhead-toolbar-item align-middle">
                                    <select
                                        className="custom-select rounded-0"
                                        defaultValue={ props.puCalcFy ? props.puCalcFy.toString() : ''}
                                        onChange={
                                            (event) => {
                                                props.setPuFyAndLoadClient(event.target.value, props.clientId);
                                            }
                                        }
                                        style={{height: 32 + 'px'}}>
                                        { puFyOptions }
                                    </select>
                                </div>
                                <span className="dashhead-toolbar-divider hidden-xs"></span>
                                <div className="dashhead-toolbar-item align-middle">
                                    {
                                        downloadingFile
                                            ? <div style={{width: '85px', textAlign: 'center'}}><Ellipsis color="#90a9b7" size={32} /></div>
                                            : (
                                                <button
                                                    className="btn btn-outline-success rounded-0"
                                                    onClick={async () => {
                                                        setDownloadingFile(true);
                                                        await downloadApiFile('excel/client_basic/' + props.clientId + '/' + props.puCalcFy);
                                                        setDownloadingFile(false);
                                                    }}>
                                                        Excel Export
                                                </button>
                                            )
                                    }
                                </div>
                            </div>
                            : ''
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => (
    {
        puCalcFy: state.client.puCalcFy,
        clientId: state.client.client ? state.client.client.client_id : null,
        client: state.client.client,
        clientPuFys: state.client.client ? state.client.client.pu_fys : [],
    }
);

const mapDispatchToProps = dispatch => {
    return {
        setPuFyAndLoadClient: (fy, clientId) => dispatch(ClientActionCreators.setPuFyAndLoadClient(fy, clientId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TitleBar);
