import { runApiCall } from './api-config';

function formatData(data) {
    return {
        client_id: data.clientId,
        name: data.name,
        size_numeraire_id: data.sizeNumeraireId,
        is_utility_plant: data.isUtilityPlant,
        total_size_detail: data.totalSizeDetail ? JSON.stringify(data.totalSizeDetail) : null,
        common_size_detail: data.commonSizeDetail ? JSON.stringify(data.commonSizeDetail) : null,
        days_in_operation_override: data.daysInOperationOverride ? JSON.stringify(data.daysInOperationOverride) : null,
        total_size: data.totalSize,
        common_size: data.commonSize,
        asset_code: data.assetCode,
        usage_numeraire_id: data.usageNumeraireId ? data.usageNumeraireId : null,
        usage_denominator: data.usageDenominator ? data.usageDenominator : null,
    };
}

export async function create(data) {
    const res = await runApiCall('POST', 'assets/', formatData(data));
    return res.data;
}

export async function update(assetId, data) {
    const res = await runApiCall('PATCH', 'assets/' + assetId, formatData(data));
    return res.data;
}

export async function remove(assetId) {
    const res = await runApiCall('DELETE', 'assets/' + assetId);
    return res.data;
}

export async function listForClient(clientId) {
    const res = await runApiCall('GET', 'clients/' + clientId + '/assets/');
    return res.data;
}

export async function detailView(assetId, puCalcFy) {
    const res = await runApiCall('GET', 'views/asset-detail/' + assetId, undefined, {
        pu_calc_fy: puCalcFy ? puCalcFy : 'latest',
    });
    return res.data;
}

export async function importCommitStructured(data) {
    const res = await runApiCall('POST', 'assets/import/commit_structured/', {
        client_id: data.client_id,
        assets: data.assets,
        input_data: data.inputData ? JSON.stringify(data.inputData) : null,
    });
    return res.data;
}

export async function saveResearchDetail(data) {
    const res = await runApiCall('POST', 'assets/import/research_detail/', data);
    return res.data;
}

export async function merge(sourceAssetId, targetAssetId) {
    const res = await runApiCall('PATCH', 'assets/merge/', {
        source_asset_id: sourceAssetId,
        target_asset_id: targetAssetId
    });
    return res.data;
}

export async function createUtility(data, assetId) {
    const res = await runApiCall('POST', 'assets/' + assetId + '/utilities/', data);
    return res.data;
}

export async function updateUtility(utilityId, data, assetId) {
    const res = await runApiCall('PATCH', 'assets/' + assetId + '/utilities/' + utilityId, data);
    return res.data;
}

export async function removeUtility(utilityId, assetId) {
    const res = await runApiCall('DELETE', 'assets/' + assetId + '/utilities/' + utilityId);
    return res.data;
}
