import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import * as ModelUtil from '../../lib/ModelUtil';
import AssetExcludedUseTable from './AssetExcludedUseTable';
import AssetNeutralUsesTable from './AssetNeutralUsesTable';
import AssetProjectsTable from './AssetProjectsTable';
import AssetResearchTable from './AssetResearchTable';
import AssetSpacesTable from './AssetSpacesTable';
import AssetUsesTable from './AssetUsesTable';
import Notepad from '../../apps/Notepad/Notepad';
import DataExceptionListing from '../DataExceptionElements/DataExceptionListing';
import MergeAssetsModalFrom from '../AssetForms/MergeAssetsModalForm';

class AssetDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingAllFyUses: false,
            activeUseTab: 'contract-uses',
            activeMetadataTab: 'notes',
            showingMergeForm: false,
        };
    }

    toggleUseTab = (tab) => {
        if (this.state.activeUseTab !== tab) {
            this.setState({ activeUseTab: tab });
        }
    }

    toggleMetadataTab = (tab) => {
        if (this.state.activeMetadataTab !== tab) {
            this.setState({ activeMetadataTab: tab });
        }
    }

    toggleShowAllFyUses = () => {
        this.setState({ showingAllFyUses: !this.state.showingAllFyUses });
    }

    showMergeForm = () => {
        this.setState({ showingMergeForm: true });
    }

    hideMergeForm = () => {
        this.setState({ showingMergeForm: false });
    }

    render() {
        if (!this.props.assetDetailView) {
            return '';
        }

        let asset = this.props.assetDetailView.asset;

        const assetSizeNumeraire = ModelUtil.numeraireForNumeraireId(asset.size_numeraire_id, this.props.numeraires);
        const assetUsageNumeraire = ModelUtil.numeraireForNumeraireId(asset.usage_numeraire_id, this.props.numeraires);

        let longTermUses = this.props.assetDetailView.uses.filter((use) => {
            if (!use.start_date || !use.end_date) {
                return true;
            }
            return (moment(use.end_date).diff(use.start_date, 'days')) >= 100;
        });

        let shortTermUses = this.props.assetDetailView.uses.filter((use) => {
            return (moment(use.end_date).diff(use.start_date, 'days')) < 100;
        });

        let exceptionListings;
        if(this.props.assetDetailView.data_exceptions) {
            exceptionListings = this.props.assetDetailView.data_exceptions.map((exception, i) => {
                return <DataExceptionListing exception={exception} client={this.props.client} key={"exception" + i} hideLink={true} />
            });
        }

        return (
            <div>
                <MergeAssetsModalFrom
                    targetAssetId={asset.asset_id}
                    targetAssetName={asset.name}
                    show={this.state.showingMergeForm}
                    onHide={this.hideMergeForm} />
                <div className="row sticky-top bg-white pt-3 pb-3" style={{ top: '58px' }}>
                    <div className="col-xl-5 col-sm-3">
                        <h3 className="font-weight-bold">
                            { asset.name }
                            { asset.asset_code ? ' (' + asset.asset_code + ')' : '' }
                        </h3>
                        <h5 className="text-muted">
                            Total Size { numeral(asset.pu_calc.reported_total_size).format('0,000') } { assetSizeNumeraire.symbol }&nbsp;&nbsp;|&nbsp;&nbsp;
                            Common Size { numeral(asset.pu_calc.reported_common_size).format('0,000') } { assetSizeNumeraire.symbol }&nbsp;&nbsp;
                            {
                                assetUsageNumeraire
                                    ? `| Usage Denominator ${ numeral(asset.usage_denominator).format('0,000') } ${ assetUsageNumeraire.symbol } `
                                    : ''
                            }
                            <a onClick={() => { this.props.showAssetForm(asset); }}>
                                <i className="icon icon-edit" />
                            </a>
                        </h5>
                        <div>
                            <a onClick={() => { this.showMergeForm(); }}>
                                <i className="icon icon-merge" /> Merge with other asset
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-7 col-sm-9 text-muted">
                        <div className="row">
                            <div className="statcard p-2 border col-md-5">
                                <span className="statcard-desc">WEIGHTED BAD USE</span>
                                <h3 className="statcard-number">
                                    { numeral(asset.pu_calc.bad_usage_size).format('0,000.00') } { assetSizeNumeraire.symbol }
                                    {
                                        assetUsageNumeraire
                                            ? ` / ${ numeral(asset.pu_calc.bad_usage).format('0,000.00') } ${ assetUsageNumeraire.symbol }`
                                            : ''
                                    }
                                </h3>
                            </div>
                            <div className="statcard p-2 border col-md-5">
                                <span className="statcard-desc">NET ASSET SIZE</span>
                                <h3 className="statcard-number">
                                    { numeral(asset.pu_calc.pu_net_size).format('0,000.00') } { assetSizeNumeraire.symbol }
                                    {
                                        assetUsageNumeraire
                                            ? ` / ${ numeral(asset.usage_denominator).format('0,000.00') } ${ assetUsageNumeraire.symbol }`
                                            : ''
                                    }
                                </h3>
                            </div>
                            <div className="statcard p-2 border text-right statcard-highlight col-md-2">
                                <span className="statcard-desc">FY PU</span>
                                <h3 className="statcard-number">
                                    { numeral(asset.pu_calc.pu_factor).format('0.00%') }
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-10">
                        {
                            exceptionListings && exceptionListings.length > 0
                                ? (
                                    <div className="mb-5">
                                        <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border card-bg font-weight-bold text-muted text-uppercase">
                                            Data Exceptions
                                        </div>
                                        <ul className="list-group border-0">
                                            { exceptionListings }
                                        </ul>
                                    </div>
                                ) : ''
                        }
                        <Nav tabs className="card-bg font-weight-bold">
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeUseTab === 'contract-uses' ? 'active' : '')}
                                    onClick={() => { this.toggleUseTab('contract-uses'); }}
                                >
                                    CONTRACT USES
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeUseTab === 'short-term-uses' ? 'active' : '')}
                                    onClick={() => { this.toggleUseTab('short-term-uses'); }}
                                >
                                    SHORT-TERM USES
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeUseTab === 'excluded-use' ? 'active' : '')}
                                    onClick={() => { this.toggleUseTab('excluded-use'); }}
                                >
                                    OVERLAPPING USE
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeUseTab === 'research-activities' ? 'active' : '')}
                                    onClick={() => { this.toggleUseTab('research-activities'); }}
                                >
                                    RESEARCH
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeUseTab === 'neutral-uses' ? 'active' : '')}
                                    onClick={() => { this.toggleUseTab('neutral-uses'); }}
                                >
                                    COMMON / VACANT SPACE
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeUseTab === 'projects' ? 'active' : '')}
                                    onClick={() => { this.toggleUseTab('projects'); }}
                                >
                                    PROJECTS / ISSUES
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeUseTab}>
                            <TabPane tabId="contract-uses">
                                <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border text-right">
                                    <input
                                        type="checkbox"
                                        name="showAllFyUses"
                                        id="showAllFyUses"
                                        checked={this.state.showingAllFyUses}
                                        onChange={this.toggleShowAllFyUses} />
                                    <label className="form-check-label" htmlFor="showAllFyUses">
                                        &nbsp;Show Uses from All FYs
                                    </label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                        onClick={() => { this.props.showUseImporterModal(); }}>
                                        <span className="icon icon-upload"></span>&nbsp;
                                        Import Uses
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a onClick={() => { this.props.showUseForm(); }}>+ Add Use</a>
                                </div>
                                <AssetUsesTable
                                    asset={asset}
                                    uses={longTermUses}
                                    showUseForm={this.props.showUseForm}
                                    showAssetUserForm={this.props.showAssetUserForm}
                                    showAllFyUses={this.state.showingAllFyUses}
                                    shortTerm={false} />
                            </TabPane>
                            <TabPane tabId="short-term-uses">
                                <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border text-right">
                                    <input
                                        type="checkbox"
                                        name="showAllFyUses1"
                                        id="showAllFyUses1"
                                        checked={this.state.showingAllFyUses}
                                        onChange={this.toggleShowAllFyUses} />
                                    <label className="form-check-label" htmlFor="showAllFyUses1">
                                        &nbsp;Show Uses from All FYs
                                    </label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                        onClick={() => { this.props.showUseImporterModal(); }}>
                                        <span className="icon icon-upload"></span>&nbsp;
                                        Import Uses
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a onClick={() => { this.props.showUseForm(); }}>+ Add Use</a>
                                </div>
                                <AssetUsesTable
                                    asset={asset}
                                    uses={shortTermUses}
                                    showUseForm={this.props.showUseForm}
                                    showAssetUserForm={this.props.showAssetUserForm}
                                    showAllFyUses={this.state.showingAllFyUses}
                                    shortTerm={true} />
                            </TabPane>
                            <TabPane tabId="excluded-use">
                                <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border text-right">
                                    &nbsp;
                                </div>
                                <AssetExcludedUseTable asset={asset} />
                            </TabPane>
                            <TabPane tabId="research-activities">
                                <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border text-right">
                                    <Link to={ "/clients/" + this.props.client.client_id + "/research2/" }>
                                        <i className="icon icon-pencil"></i>&nbsp;Edit Research
                                    </Link>
                                </div>
                                <AssetResearchTable
                                    fy={this.props.fy}
                                    asset={asset}
                                    numeraires={this.props.numeraires} />
                            </TabPane>
                            <TabPane tabId="neutral-uses">
                                <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border text-right">
                                    <a onClick={() => { this.props.showVacancyForm(); }}>+ Add Vacancy</a>
                                </div>
                                <AssetNeutralUsesTable
                                    asset={asset}
                                    uses={this.props.assetDetailView.uses}
                                    showVacancyForm={this.props.showVacancyForm}
                                    showAssetForm={this.props.showAssetForm}
                                    showAllFyUses={this.state.showingAllFyUses} />
                            </TabPane>
                            <TabPane tabId="projects">
                                <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border text-right">
                                    <AssetProjectsTable
                                        fy={this.props.fy}
                                        assetView={asset}
                                        numeraires={this.props.numeraires} />
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>

                    <div className="col-2">
                        <Nav tabs className="card-bg font-weight-bold">
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeMetadataTab === 'notes' ? 'active' : '')}
                                    onClick={() => { this.toggleMetadataTab('notes'); }}
                                >
                                    NOTES
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={"rounded-0 text-muted " + (this.state.activeMetadataTab === 'spaces' ? 'active' : '')}
                                    onClick={() => { this.toggleMetadataTab('spaces'); }}
                                >
                                    SPACES
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeMetadataTab}>
                            <TabPane tabId="notes">
                                <Notepad
                                    entityType={"Asset"}
                                    entityId={asset.asset_id}
                                    listHeight={200} />
                            </TabPane>
                            <TabPane tabId="spaces">
                                <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border text-right">
                                    <a onClick={() => { this.props.showSpaceForm(); }}>+ Add Space</a>
                                </div>
                                <AssetSpacesTable
                                    asset={asset}
                                    numeraires={this.props.numeraires}
                                    showSpaceForm={this.props.showSpaceForm} />
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        );
    }
}

AssetDetail.propTypes = {
    fy: PropTypes.number.isRequired,
    assetDetailView: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired,
    showUseForm: PropTypes.func.isRequired,
    showVacancyForm: PropTypes.func.isRequired,
    showAssetUserForm: PropTypes.func.isRequired,
    showAssetForm: PropTypes.func.isRequired,
    showSpaceForm: PropTypes.func.isRequired,
    showUseImporterModal: PropTypes.func.isRequired,
};

export default AssetDetail;