import React, { Fragment, useState, useRef } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { connect } from 'react-redux';
import * as IssueActionCreators from '../../ducks/issues';
import * as ModelFormsActionCreators from '../../ducks/modals';
import moment from 'moment';
import { keyBy as _keyBy } from 'lodash';
import Constants from '../../lib/Constants';
import ModalWrapper from '../ModalWrapper';
import * as ModelUtil from '../../lib/ModelUtil';
import numeral from 'numeral';

const costHistoryTypes = [
    { key: 'proceeds_expended', label: 'Bond Proceeds Spent' },
    { key: 'earnings_expended', label: 'Earnings on Bond Proceeds Spent' },
    { key: 'proceeds_unexpended', label: 'Bond Proceeds Unspent' },
    { key: 'earnings_unexpended', label: 'Earnings on Bond Proceeds Unspent' },
    { key: 'taxable_proceeds_expended', label: 'Taxable Bond Proceeds Spent' },
    { key: 'qualified_equity_expended', label: 'Borrower\'s Equity Spent' },
    { key: 'other_proceeds_unexpended', label: 'Other Proceeds Unspent' },
    { key: 'prior_proceeds_expended', label: 'Prior Bond Proceeds Spent' },
];

const expenseTypeOptions = [
    { key: 'capex', label: 'New Money Project' },
    { key: 'working-capital', label: 'Working Capital' },
    { key: 'escrow', label: 'Refunding' },
    { key: 'capitalized-interest', label: 'Capitalized Interest' },
    { key: 'coi', label: 'Costs of Issuance / Underwriter\'s Discount' },
    //{ key: 'ud', label: 'Costs of Issuance and Underwriter\'s Discount' },
    { key: 'credit-enhancement', label: 'Credit Enhancement / Bond Insurance Premiums' },
    { key: 'reserve', label: 'Debt Service Reserve' },
    { key: 'other', label: 'Other' },
    { key: 'other-neutral', label: 'Other Neutral' },
    { key: 'other-sk-excluded', label: 'Other SK Excluded' },
];

const expenseTypeKeys = expenseTypeOptions.map(o => o.key);
const expenseTypeLabels = expenseTypeOptions.map(o => o.label);

const costHistoryValueGetter = (params, chAsOfDate) => {
    const ch = (params.data.cost_history || []).find(h => h.as_of_date === chAsOfDate);
    return !ch ? null : (ch[params.colDef.field] || null);
};

const costHistoryValueSetter = (params, chAsOfDate) => {
    let found = false;
    if(!params.data.cost_history) {
        params.data.cost_history = [];
    }
    for(const ch of params.data.cost_history) {
        if(ch.as_of_date === chAsOfDate) {
            found = true;
            ch[params.colDef.field] = params.newValue;
        }
    }
    if(!found) {
        const hist = {
            issue_use_id: params.data.issue_use_id,
            as_of_date: chAsOfDate
        };
        hist[params.colDef.field] = params.newValue;
        params.data.cost_history.push(hist);
    }
    return true;
}

const IssueEditPUProceedsForm = props => {
    const [showingFy, setShowingFy] = useState(false);
    const [saving, setSaving] = useState(false);
    const gridRef = useRef();

    if(!props.modalIsVisible) {
        return <Fragment />;
    }

    const saveUses = () => {
        const rows = [];
        gridRef.current.api.forEachNode((rowNode) => {
            rows.push(rowNode.data);
        });
        console.log('save uses', rows);
        
        if(!saving) {
            setSaving(true);

            props.updateUses(props.issueDetailView.issue.issue_id, {
                uses: rows
            })
            .then(() => {
                setSaving(false);
                props.toggleModal();
            })
            .catch((error) => {
                setSaving(false);
                alert(error);
            });
        }
    };

    if(!showingFy && props.puCalcFy) {
        setShowingFy(parseInt(ModelUtil.effectivePUCalcFYForClientFromProps(props), 10));
    }

    const gotoLastFy = () => { setShowingFy(showingFy - 1); };
    const gotoNextFy = () => { setShowingFy(parseInt(showingFy, 10) + 1); }

    const addUseRow = () => {
        if (!gridRef?.current?.api) {
            return;
        }

        gridRef.current.api.applyTransaction({ add: [{
            issue_id: props.issueDetailView.issue.issue_id,
            name: null,
            expense_type: null,
            cost_history: null,
        }]});
        gridRef.current.api.startEditingCell({
            rowIndex: gridRef.current.api.getLastDisplayedRow(),
            colKey: "name"
        });
    };

    const redrawRows = () => {
        if (!gridRef?.current?.api) {
            return;
        }
        gridRef.current.api.redrawRows();
    }

    const chAsOfDate = moment(ModelUtil.effectivePUCalcFYEndDateForClient(showingFy, props.client)).format('YYYY-MM-DD');

    const rowData = (props.issueDetailView?.issue?.uses || []);
    
    const actionButtons = (<>
        <button type="button" className="btn-link modal-action" onClick={props.hideModal}>
            Cancel
        </button>
        <button className="btn-link modal-action font-weight-bold" onClick={ saveUses }>
            {
                saving
                    ? 'Saving...'
                    : 'Edit Private Use Proceeds'
            }
        </button>
    </>);

    return (
        <ModalWrapper
            className={'modal-xxl'}
            title={'Edit Private Use Proceeds'}
            toggle={props.toggleModal}
            modalIsVisible={props.modalIsVisible}
            actionButtons={actionButtons}>
            <div>
                <div className="float-left" style={{ width: 320 }}>
                    <button className="btn btn-link" onClick={addUseRow}><i className="icon icon-plus"></i> Add Use</button>
                </div>
                <div className="float-left">
                    {
                        props.client.pu_fys.find(f => f == (showingFy + 1))
                            ? <button className="btn btn-link" onClick={gotoNextFy}><i className="icon icon-arrow-left"></i> FY{showingFy + 1}</button> 
                            : <Fragment />
                    }
                </div>
                <div className="float-right text-right">
                    {
                        props.client.pu_fys.find(f => f == (showingFy - 1))
                            ? <button className="btn btn-link" onClick={gotoLastFy}>FY{showingFy - 1} <i className="icon icon-arrow-right"></i></button>
                            : <Fragment />
                    }
                </div>
            </div>
            <div className="ag-theme-balham" style={{height: 400, width: '100%', clear: 'both'}}>
                <AgGridReact
                    rowData={rowData}
                    defaultColDef={{ editable: true, suppressMenu: true }}
                    enableRangeSelection={true}
                    ref={gridRef}
                    headerHeight={50}
                    enterMovesDown={true}
                    enterMovesDownAfterEdit={true}>
                    <AgGridColumn field="name" headerName="Name" pinned={true} width={150} />
                    <AgGridColumn
                        field="expense_type"
                        headerName="Expense Type"
                        pinned={true}
                        width={175}
                        cellEditor="agRichSelectCellEditor"
                        cellEditorParams={{ values: expenseTypeKeys }}
                        valueFormatter={ params => expenseTypeLabels[expenseTypeKeys.indexOf(params.value)] || params.value }
                        onCellValueChanged={ redrawRows } />
                    <AgGridColumn headerName={`FY${showingFy}`} headerClass="text-center">
                        {
                            costHistoryTypes.map(type => (
                                <AgGridColumn
                                    key={type.key}
                                    headerName={type.label}
                                    width={123}
                                    field={type.key}
                                    cellClass={params => {
                                        if (type.key === 'other_proceeds_unexpended' && params.data?.expense_type !== 'reserve') {
                                            return ['text-right', 'deemphasized-table-cell'];
                                        }
                                        return ['text-right'];
                                    }}
                                    headerClass="text-right"
                                    valueGetter={params => costHistoryValueGetter(params, chAsOfDate)}
                                    valueSetter={params => costHistoryValueSetter(params, chAsOfDate)}
                                    valueFormatter={ params => params.value ? numeral(params.value).format('0,000.00') : null } />
                            ))
                        }
                    </AgGridColumn>
                </AgGridReact>
            </div>
        </ModalWrapper>
    );
};

const mapStateToProps = state => (
    {
        issueDetailView: state.issues.detailView,
        issues: state.issues.list,
        puCalcFy: state.client.puCalcFy,
        client: state.client.client,
        modalIsVisible: state.modals.issuePUProceedsIsVisible,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        updateUses: (issueId, data) => dispatch(IssueActionCreators.updateUses(issueId, data)),
        toggleModal: () => dispatch(ModelFormsActionCreators.toggleIssuePUProceeds()),
        hideModal: () => dispatch(ModelFormsActionCreators.hideIssuePUProceeds()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueEditPUProceedsForm);