import { runApiCall } from './api-config';

export async function listForClient(clientId) {
    const res = await runApiCall('GET', 'clients/' + clientId + '/data_imports/');
    return res.data;
}

export async function rollback(dataImportId, rollbackData) {
    const res = await runApiCall('PATCH', 'data_imports/' + dataImportId + '/rollback', rollbackData);
    return res.data;
}

export async function unrollback(dataImportId, rollbackData) {
    const res = await runApiCall('PATCH', 'data_imports/' + dataImportId + '/unrollback', rollbackData);
    return res.data;
}