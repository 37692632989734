import * as DataImportApi from '../api/DataImportApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Common from './common';

const LIST_IS_LOADING = 'data_imports/LIST_IS_LOADING';
const LIST_HAS_LOADED = 'data_imports/LIST_HAS_LOADED';
const LIST_HAS_ERRORED = 'data_imports/LIST_HAS_ERRORED';

const DETAIL_VIEW_IS_LOADING = 'data_imports/DETAIL_VIEW_IS_LOADING';
const DETAIL_VIEW_HAS_LOADED = 'data_imports/DETAIL_VIEW_HAS_LOADED';
const DETAIL_VIEW_HAS_ERRORED = 'data_imports/DETAIL_VIEW_HAS_ERRORED';

const ROLLBACK = 'research_activities/ROLLBACK';
const UNROLLBACK = 'research_activities/UNROLLBACK';

const SWITCH_CLIENT = 'research_activities/SWITCH_CLIENT';

const initialState = {
    list: null,
    listIsLoading: false,
    listHasErrored: false,
    listErrorMessage: null,
    detailView: null,
    detailViewIsLoading: false,
    detailViewHasErrored: false,
    detailViewErrorMessage: null,
};

export default function dataImportsReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LIST_IS_LOADING:
            return {
                ...state,
                listIsLoading: true,
                listHasErrored: false,
                listErrorMessage: null,
            };
        case LIST_HAS_LOADED:
            return {
                ...state,
                list: action.list,
                listIsLoading: false,
                listHasErrored: false,
                listErrorMessage: null,
            };
        case LIST_HAS_ERRORED:
            return {
                ...state,
                list: null,
                listIsLoading: false,
                listHasErrored: true,
                listErrorMessage: action.errorMessage,
            };
        case DETAIL_VIEW_IS_LOADING:
            return {
                ...state,
                detailViewIsLoading: true,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case DETAIL_VIEW_HAS_LOADED:
            return {
                ...state,
                detailView: action.detailView,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case DETAIL_VIEW_HAS_ERRORED:
            return {
                ...state,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: true,
                detailViewErrorMessage: action.errorMessage,
            };
        case SWITCH_CLIENT:
            return {
                ...state,
                list: null,
                listIsLoading: false,
                listHasErrored: false,
                listErrorMessage: null,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case ROLLBACK:
            return state;
        case UNROLLBACK:
            return state;
        default:
            return state;
    }
}

export function dataImportsListIsLoading() {
    return { type: LIST_IS_LOADING };
}

export function dataImportsListHasLoaded(list) {
    return { type: LIST_HAS_LOADED, list: list };
}

export function dataImportsListHasErrored(errorMessage) {
    return { type: LIST_HAS_ERRORED, errorMessage: errorMessage };
}

export function dataImportsDetailViewIsLoading() {
    return { type: DETAIL_VIEW_IS_LOADING };
}

export function dataImportsDetailViewHasLoaded(detailView) {
    return { type: DETAIL_VIEW_HAS_LOADED, detailView: detailView };
}

export function dataImportsDetailViewHasErrored(errorMessage) {
    return { type: DETAIL_VIEW_HAS_ERRORED, errorMessage: errorMessage };
}

export function switchClient() {
    return { type: SWITCH_CLIENT };
}

export function loadDataImports(clientId) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.data_imports.listIsLoading) {
            return;
        }

        dispatch(dataImportsListIsLoading());
        dispatch(showLoading());

        DataImportApi.listForClient(clientId)
            .then(data => {
                dispatch(dataImportsListHasLoaded(data.data_imports));
                dispatch(hideLoading());
            })
            .catch(error => {
                dispatch(dataImportsListHasErrored(error));
                dispatch(hideLoading());
            });
    }
}

export function rollback(dataImportId, rollbackData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        DataImportApi.rollback(dataImportId, rollbackData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadLists(dispatch, state);
                dispatch(loadDataImports(state.client.client.client_id));
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function unrollback(dataImportId, rollbackData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        DataImportApi.unrollback(dataImportId, rollbackData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadLists(dispatch, state);
                dispatch(loadDataImports(state.client.client.client_id));
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}
