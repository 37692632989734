import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';

import { filter as _filter, get as _get, map as _map } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import * as IssueActionCreators from '../../../ducks/issues';
import Constants from '../../../lib/Constants';
import * as ModelUtil from '../../../lib/ModelUtil';

import Part6List from './Part6List';

const valueIsSet = value => {
    return value !== null && value !== undefined && value !== '';
};

const formatNumeric = value => {
    return !valueIsSet(value) ? '' : Math.round(value);
};

const cleanNumeric = value => {
    return !valueIsSet(value) ? null : numeral(value).value();
};

const formatDate = value => {
    return !valueIsSet(value) ? '' : moment(value).format('MM/DD/YYYY');
};

const cleanDate = value => {
    return !valueIsSet(value) ? null : moment(value, 'MM/DD/YYYY').format('YYYY-MM-DD');
};

const formatYesNo = value => {
    return !valueIsSet(value) ? '' : (value === true ? 'Yes' : 'No');
};

const cleanYesNo = value => {
    return !valueIsSet(value) ? null : (value === 'Yes' ? true : false);
};

const formatValueForQuestionType = (value, question = undefined, type = undefined) => {
    if(type === 'numeric' || (question && question.numeric)) {
        return formatNumeric(value);
    }
    else if(type === 'yesno' || (question && question.yesno)) {
        return formatYesNo(value);
    }
    else if(type === 'date' || (question && question.date)) {
        return formatDate(value);
    }
    else {
        return value || '';
    }
};

const cleanValueForQuestionType = (value, question = undefined, type = undefined) => {
    if(type === 'numeric' || (question && question.numeric)) {
        return cleanNumeric(value);
    }
    else if(type === 'yesno' || (question && question.yesno)) {
        return cleanYesNo(value);
    }
    else if(type === 'date' || (question && question.date)) {
        return cleanDate(value);
    }
    else {
        return value || null;
    }
};

const part6PromptsForIssue = issue => {
    const refundingDates = issue.uses
        .filter(use => use.refundings && use.refundings.length > 0)
        .reduce((carry, use) => {
            return carry.concat(use.refundings.map(r => r.issue?.dated_date));
        }, [])
        .filter(date => !!date)
        .sort()
        .map(date => moment(date).format('MM/DD/YYYY'));
    
    return [
        {
            question: '2-3',
            include: true,
            text: 'Part II, Line 3 - The total proceeds shown in Part II, Line 3 differs from the Issue Price shown in Part I, (e) due to interest earnings on invested proceeds.'
        },
        {
            question: '2-15',
            include: (refundingDates.length > 0),
            text: `Part II, Line 15 - The bonds refunded by ${issue.short_name} were issued on ${refundingDates.join(', ')}.`
        },
        {
            question: '3-7',
            include: true,
            text: 'Part III, line 7 - As provided in Treasury Regulation Section 1.141-4(c)(2)(i)(B), the amount of private payments taken into account under the private security or payment test may not exceed the amount of private business use and/or unrelated trade or business use. Accordingly, the amount of private payments for the reporting period does not exceed the amount stated in Part III, Line 6. The organization has not undertaken an analysis of the private security or payment test with respect to the bonds, as the level of private business use and/or unrelated trade or business use reported in Part III, Line 6 is not in excess of amounts permitted under Section 145 of the Code.'
        },
        {
            question: '3-9',
            include: false,
            text: 'Part III, Line 9 - The organization has put in place written procedures to ensure that all nonqualified bonds of the issue are remediated in accordance with the requirements under Regulations sections 1.141-12 and 1.145-2, however such procedures were put in place after the reporting period consistent with this Schedule K.'
        },
        {
            question: '4-2c',
            include: false,
            text: `Part 4, Line 2C - the rebate computation for ${issue.short_name} was completed on [MM/DD/YYYY].`
        },
        {
            question: '4-7',
            include: false,
            text: 'Part IV, Line 7 - The organization has put in place written policies and procedures to monitor the requirements of section 148, however such procedures were put in place after the reporting period consistent with this Schedule K.'
        },
        {
            question: '5-1',
            include: false,
            text: 'Part V - The organization has put in place written policies and procedures to undertake corrective action, however such procedures were put in place after the reporting period consistent with this Schedule K.'
        },
    ];
};

const reformatLegacyPart6Data = part6 => {
    if(Array.isArray(part6)) {
        return part6;
    }
    if(typeof part6 != 'object'){
        return [];
    }

    return _map(part6, (text, key) => {
        if(key.includes('-include-')) {
            return null;
        }
        if(key == 'part6-text-other') {
            return {
                question: null,
                include: null,
                text
            };
        }
        const question = key.split('-').slice(-2).join('-');
        const include = part6[`part6-include-${question}`] !== undefined ? part6[`part6-include-${question}`] : true;
        return {
            question,
            include,
            text
        };
    }).filter(item => !!item);
};

const mergePart6PromptsWithAnalystData = (issue, fy) => {
    const analystData = reformatLegacyPart6Data(issue?.sk_analyst_answers?.[fy]?.part6);

    let data;
    if (analystData && analystData.length > 0) { // if analyst data has been saved for this fiscal year, use it
        data = analystData;
    }
    else {
        const prompts = part6PromptsForIssue(issue);
        const lastFyAnalystData = reformatLegacyPart6Data(issue?.sk_analyst_answers?.[fy - 1]?.part6);

        data = lastFyAnalystData.concat(prompts.filter(prompt => (
            lastFyAnalystData.find(lad => lad.question == prompt.question) == undefined
        )));
    }

    return data.map((item, index) => {
        item.key = item.question ?? `other-${index}`;
        return item;
    });
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getRowId = params => params.data.key;

const getAnswerTypes = (issue, question, fy, priorSKCalc = {}) => {
    let value = null;
    if(issue && question.autofillEntity === 'issue' && question.autofillKey) {
        value = _get(issue, question.autofillKey, null);
    }
    const analystValue = _get(issue, `sk_analyst_answers.${fy}.${question.part}-${question.key}`);
    const formattedSystemAnswer = formatValueForQuestionType(value, question);
    const formattedAnalystValue = formatValueForQuestionType(analystValue, question);
    const priorFyAnalystValue = formatValueForQuestionType(_get(issue, `sk_analyst_answers.${fy - 1}.${question.part}-${question.key}`), question);
    const priorFySystemAnswer = formatValueForQuestionType(_get(priorSKCalc, `${question.part}-${question.key}`), question);

    return {
        analystValue,
        formattedSystemAnswer,
        formattedAnalystValue,
        operativeAnswer: analystValue !== undefined ? formattedAnalystValue : formattedSystemAnswer,
        priorFyAnswer: priorFyAnalystValue ? priorFyAnalystValue : priorFySystemAnswer,
    };
};

let saveTimeoutId;

const saveDelay = 500;

const IssueSKInput = props => {
    const newPart6ItemInput = useRef(null);
    const gridRef = useRef();
    const [ justSaved, setJustSaved ] = useState(false);
    const [ saving, setSaving ] = useState(false);

    const fy = ModelUtil.effectivePUCalcFYForClientFromProps(props);

    const [ part6Items, setPart6Items ] = useState([]);
    useEffect(() => {
        setPart6Items(mergePart6PromptsWithAnalystData(props.issue, fy));
    }, [setPart6Items, fy]);

    const onPart6TextChange = event => {
        const question = event.target.dataset.question;
        const index = event.target.dataset.index;
        const key = part6Items[index].key;

        part6Items[index] = {
            question: event.target.dataset.question,
            include: true,
            text: event.target.value,
            key,
        };

        setPart6Items(part6Items);

        if(question){
            document.getElementById(`part6-include-${key}`).checked = true;
        }
        setJustSaved(false);
    };

    const onPart6FieldBlur = () => {
        clearTimeout(saveTimeoutId);
        saveTimeoutId = setTimeout(saveAnswers, saveDelay);
    }

    const onAddPart6OtherItem = () => {
        if(!newPart6ItemInput?.current?.value?.trim()) {
            return;
        }
        
        setPart6Items([{
            question: null,
            include: null,
            text: newPart6ItemInput.current.value || '',
            key: `other-${part6Items.length}`,
        }].concat(part6Items));
        newPart6ItemInput.current.value = '';
        clearTimeout(saveTimeoutId);
        saveTimeoutId = setTimeout(saveAnswers, saveDelay);
    };

    const onDeletePart6OtherItem = deleteKey => {
        setPart6Items(part6Items.filter((value, key) => key != deleteKey));
        clearTimeout(saveTimeoutId);
        saveTimeoutId = setTimeout(saveAnswers, saveDelay);
    }

    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        setPart6Items(reorder(
            part6Items,
            result.source.index,
            result.destination.index
        ));
        setJustSaved(false);
        clearTimeout(saveTimeoutId);
        saveTimeoutId = setTimeout(saveAnswers, saveDelay);
    };

    const [rowData, setRowData] = useState(Constants.skQuestions.map(q => {
        const { operativeAnswer, formattedSystemAnswer, priorFyAnswer } = getAnswerTypes(props.issue, q, fy, props.priorSKCalc);
        return {
            part: q.part,
            partName: `Part ${q.part}`,
            key: q.key,
            question: q.question,
            operativeAnswer,
            systemAnswer: formattedSystemAnswer,
            type: q.date ? 'date' : (q.yesno ? 'yesno' : q.numeric ? 'numeric' : (q.percentage ? 'percentage' : null)),
            fyNotes: _get(props.issue, `sk_analyst_answers.${fy}.${q.part}-${q.key}-notes`),
            generalNotes: _get(props.issue, `sk_analyst_answers.general-notes.${q.part}-${q.key}`),
            priorFyAnswer,
            blankForSKNoPU: q.blankForSKNoPU,
        };
    }));

    const [ deemphasizedCells, setDeemphasizedCells ] = useState(Constants.skQuestions.reduce((carry, q) => {
        if(q.blankForSKNoPU && props.issue?.pu_calc?.sk_no_pu) {
            return carry.concat(`${q.part}-${q.key}`);
        }
        else if(!q.onNo) {
            return carry;
        }
        const { operativeAnswer } = getAnswerTypes(props.issue, q, fy);
        if(operativeAnswer == 'No') {
            return carry.concat(q.onNo.deemphasize);
        }
        return carry;
    }, []));

    const onTableCellValueChanged = params => {
        const qDef = Constants.skQuestions.find(q => q.part == params.data?.part && q.key == params.data?.key);
        if(!qDef){
            return;
        }
        if(qDef.onNo && params.newValue == 'No') {
            const toAdd = qDef.onNo.deemphasize.filter(d => !deemphasizedCells.includes(d));
            setDeemphasizedCells(deemphasizedCells.concat(toAdd));
        }
        else if(qDef.onNo && (params.newValue == 'Yes' || params.newValue == '')) {
            setDeemphasizedCells(deemphasizedCells.filter(c => !qDef.onNo.deemphasize.includes(c)));
        }
        setJustSaved(false);
        clearTimeout(saveTimeoutId);
        saveTimeoutId = setTimeout(saveAnswers, saveDelay);
    }

    const saveAnswers = async () => {
        setJustSaved(false);
        const fyAnswers = {};
        const generalNotes = {};
        gridRef.current.api.forEachNode(node => {
            if(!node.data) {
                return;
            }
            let value = `${node.data.operativeAnswer}`.trim() === '' ? null : node.data.operativeAnswer;
            let sysValue = `${node.data.systemAnswer}`.trim() === '' ? null : node.data.systemAnswer;
            if(value || (!value && sysValue)) {
                value = cleanValueForQuestionType(value, undefined, node.data.type);
                sysValue = cleanValueForQuestionType(sysValue, undefined, node.data.type);
            }
            if(value !== sysValue) {
                fyAnswers[`${node.data.part}-${node.data.key}`] = value;
            }
            if(node.data.fyNotes) {
                fyAnswers[`${node.data.part}-${node.data.key}-notes`] = node.data.fyNotes;
            }
            if(node.data.generalNotes) {
                generalNotes[`${node.data.part}-${node.data.key}`] = node.data.generalNotes;
            }
        });
        
        fyAnswers.part6 = Array.from(document.querySelectorAll('.part6-item-textarea'), t => {
            const question = t.dataset?.question || null;
            return {
                question,
                include: question ? document.querySelector(`#part6-include-${question}`)?.checked : null,
                text: t.value,
            };
        });
        setSaving(true);
        try{
            await props.updateAnalystSkAnswers(props.issue.issue_id, {
                fy: ModelUtil.effectivePUCalcFYForClientFromProps(props),
                answers: fyAnswers,
                general_notes: generalNotes,
            });
            setJustSaved(true);
            setSaving(false);
        }
        catch(e) {
            setSaving(false);
            alert(e);
        }
    };

    if(!props.issue) {
        return 'No issue';
    }
    
    return (
        <Fragment>
            <div className="row sticky-top" style={{ zIndex: '10000', top: 140 }}>
                <div className="col-12 text-right">
                    {
                        justSaved
                            ? <span className="text-primary"><i className="icon icon-check"></i> Saved!&nbsp;&nbsp;&nbsp;</span>
                            : <Fragment />
                    }
                    &nbsp;&nbsp;
                    {
                        saving
                            ? <span className="text-secondary">Saving...&nbsp;&nbsp;&nbsp;</span>
                            : <Fragment />
                    }
                    <button className="btn btn-success rounded-0" disabled={saving || justSaved} onClick={() => saveAnswers()}>
                        <i className="icon icon-save"></i>&nbsp;
                        { saving ? 'Saving...' : 'Save Schedule K' }
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col-8">
                    <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border card-bg font-weight-bold text-muted text-uppercase mb-2">
                        Parts I-V
                    </div>
                    <div className="ag-theme-balham" style={{height: 800, width: '100%', clear: 'both'}}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            defaultColDef={{ suppressMenu: true }}
                            enableRangeSelection
                            enterMovesDown
                            enterMovesDownAfterEdit
                            groupDisplayType="groupRows"
                            groupRowRendererParams={{
                                suppressCount: true
                            }}
                            getRowId={getRowId}
                            onCellValueChanged={onTableCellValueChanged}>
                            <AgGridColumn
                                field="partName"
                                headerName=""
                                width={35}
                                rowGroup
                                pinned
                                hide />
                            <AgGridColumn
                                field="key"
                                headerName="Key"
                                pinned
                                width={40} />
                            <AgGridColumn
                                field="question"
                                headerName="Question"
                                pinned
                                wrapText
                                autoHeight
                                width={250} />
                            <AgGridColumn
                                field="operativeAnswer"
                                headerName="Answer"
                                autoHeight
                                width={175}
                                editable
                                pinned
                                cellEditorSelector={params => {
                                    if(params.data?.type == 'yesno'){
                                        return {
                                            component: 'agRichSelectCellEditor',
                                            //component: 'agSelectCellEditor',
                                            params: {
                                                values: ['', 'Yes', 'No']
                                            },
                                            popup: true,
                                        };
                                    }

                                    return {
                                        component: 'agTextCellEditor'
                                    };
                                }}
                                cellClass={params => {
                                    const classes = ['font-weight-bold', 'h-100'];
                                    if(deemphasizedCells.includes(`${params.data?.part}-${params.data?.key}`)) {
                                        classes.push('deemphasized-table-cell');
                                    }

                                    if(params.data?.type == 'percentage' || params.data?.type == 'numeric' || params.data?.type == 'date') {
                                        classes.push('text-right');
                                    } 
                                    return classes;
                                }}
                                valueGetter={params => {
                                    if(params.data?.type == 'numeric') {
                                        return params.data?.operativeAnswer
                                            ? numeral(params.data?.operativeAnswer).format('0,000.00')
                                            : (params.data?.blankForSKNoPU && props.issue?.pu_calc?.sk_no_pu ? '' : '0.00');
                                    }
                                    else if(params.data?.type == 'percentage') {
                                        return params.data?.operativeAnswer
                                            ? numeral(params.data?.operativeAnswer).format('0.0%')
                                            : (params.data?.blankForSKNoPU && props.issue?.pu_calc?.sk_no_pu ? '' : '0.0%');
                                    }
                                    else if(params.data?.type == 'yesno') {
                                        return params.data?.operativeAnswer || '';
                                    }
                                    else{
                                        return params.data?.operativeAnswer || '';
                                    }
                                }} />
                            <AgGridColumn
                                field="fyNotes"
                                headerName={`FY${fy} Notes`}
                                width={250}
                                editable
                                cellEditor="agTextCellEditor"
                                valueGetter={params => {
                                    return params.data?.fyNotes || '';
                                }} />
                            <AgGridColumn
                                field="generalNotes"
                                headerName="General Notes"
                                width={250}
                                editable
                                cellEditor="agTextCellEditor"
                                valueGetter={params => {
                                    return params.data?.generalNotes || '';
                                }} />
                            <AgGridColumn
                                field="priorFyAnswer"
                                headerName={`FY${fy - 1} Answer`}
                                width={250}
                                valueGetter={params => {
                                    if(params.data?.type == 'numeric') {
                                        return params.data?.priorFyAnswer ? numeral(params.data?.priorFyAnswer).format('0,000.00') : '0.00';
                                    }
                                    else if(params.data?.type == 'percentage') {
                                        return params.data?.priorFyAnswer ? numeral(params.data?.priorFyAnswer).format('0.0%') : '0.0%';
                                    }
                                    else if(params.data?.type == 'yesno') {
                                        return params.data?.priorFyAnswer || '';
                                    }
                                    else{
                                        return params.data?.priorFyAnswer || '';
                                    }
                                }} />
                        </AgGridReact>
                    </div>
                </div>
                <div className="col-4">
                    <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border card-bg font-weight-bold text-muted text-uppercase">
                        Part VI
                    </div>
                    {
                        !props.issueIsLoading && (
                            <Part6List
                                onPart6TextChange={onPart6TextChange}
                                onPart6FieldBlur={onPart6FieldBlur}
                                onDeletePart6OtherItem={onDeletePart6OtherItem}
                                onAddPart6OtherItem={onAddPart6OtherItem}
                                onDragEnd={onDragEnd}
                                newPart6ItemInput={newPart6ItemInput}
                                part6Items={part6Items} />
                        )
                    }
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        puCalcFy: state.client.puCalcFy,
        issue: state.issues.detailView?.issue,
        priorSKCalc: state.issues.detailView?.prior_sk_calc,
        issueIsLoading: state.issues.detailViewIsLoading,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        updateAnalystSkAnswers: (issueId, data) => dispatch(IssueActionCreators.updateAnalystSkAnswers(issueId, data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueSKInput);
