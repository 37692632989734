import * as NoteApi from '../api/NoteApi';

const LIST_IS_LOADING = 'notes/LIST_IS_LOADING';
const LIST_HAS_LOADED = 'notes/LIST_HAS_LOADED';
const LIST_HAS_ERRORED = 'notes/LIST_HAS_ERRORED';

const initialState = {
    notes: null,
    notesListIsLoading: false,
    notesListHasErrored: false,
    notesListErrorMessage: null,
};

export default function notesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LIST_IS_LOADING:
            return {
                ...state,
                notesListIsLoading: true,
                notesListHasErrored: false,
                notesListErrorMessage: null,
            };
        case LIST_HAS_LOADED:
            return {
                ...state,
                notes: action.notes,
                notesListIsLoading: false,
                notesListHasErrored: false,
                notesListErrorMessage: null,
            };
        case LIST_HAS_ERRORED:
            return {
                ...state,
                notes: null,
                notesListIsLoading: false,
                notesListHasErrored: true,
                notesListErrorMessage: action.errorMessage,
            };
        default:
            return state;
    }
}

export function notesListIsLoading() {
    return { type: LIST_IS_LOADING };
}

export function notesListHasLoaded(notes) {
    return { type: LIST_HAS_LOADED, notes: notes };
}

export function notesListHasErrored(errorMessage) {
    return { type: LIST_HAS_ERRORED, errorMessage: errorMessage };
}

export function loadNotes(clientId, onLoad) {
    return (dispatch) => {
        dispatch(notesListIsLoading());
        //dispatch(showLoading());

        NoteApi.list(clientId)
            .then(data => {
                dispatch(notesListHasLoaded(data.notes));
                //dispatch(hideLoading());
                if(typeof onLoad === 'function') {
                    onLoad(data.notes);
                }
            })
            .catch(error => {
                dispatch(notesListHasErrored(error));
            });
    }
}
