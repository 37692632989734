import { runApiCall } from './api-config';

function formatData(data) {
    return {
        asset_id: data.assetId ? data.assetId : null,
        space_id: data.spaceId ? data.spaceId : null,
        type: 'vacancy',
        size: data.size ? data.size : null,
        start_date: data.startDate,
        end_date: data.endDate,
    };
}

export async function create(data) {
    const res = await runApiCall('POST', 'uses/', formatData(data));
    return res.data;
}

export async function update(useId, data) {
    const res = await runApiCall('PATCH', 'uses/' + useId, formatData(data));
    return res.data;
}

export async function remove(useId) {
    const res = await runApiCall('DELETE', 'uses/' + useId);
    return res.data;
}
