import moment from 'moment';
import numeral from 'numeral';
import { maxBy as _maxBy, minBy as _minBy } from 'lodash';

export function daysInMonth(monthNumber) {
    if (monthNumber < 10) {
        monthNumber = '0' + monthNumber;
    }
    return moment('2018-' + monthNumber + '-01').daysInMonth();
}

export function currentYear() {
    return (new Date()).getFullYear();
}

export function daysBetween(date1, date2) {
    date1 = moment(date1);
    date2 = moment(date2);

    return date2.diff(date1, 'days');
}

export function clientFyEnd(client, fy) {
    return fyEndDate(fy, client.fy_end_month, client.fy_end_day);
}

export function fyEndDate(fy, fyEndMonth, fyEndDay) {
    const endMonth = fyEndMonth.toString().length < 2 ? `0${fyEndMonth}` : fyEndMonth;
    const endDay = fyEndDay.toString().length < 2 ? `0${fyEndDay}` : fyEndDay;

    return moment(`${fy}-${endMonth}-${endDay}`);
}

export function fyIsClosed(fy, fyEndMonth, fyEndDay) {
    return fyEndDate(fy, fyEndMonth, fyEndDay).isBefore(moment(), 'day');
}

export function numDaysDescription(numDays) {
    numDays = numeral(numDays);

    if(numDays.value() === 1) {
        return '1 day';
    }

    return `${numDays.format('0,00')} days`;
}

export function fySequence(dateFrom, dateTo, fyEndMonth, fyEndDay) {
    if(!dateFrom || !dateTo) {
        return [];
    }

    dateFrom = moment(dateFrom);
    dateTo = moment(dateTo);

    if(dateTo.isBefore(dateFrom)) {
        return [
            dateFrom,
            dateTo
        ];
    }

    let nextFy = fyEndDate(dateFrom.format('YYYY'), fyEndMonth, fyEndDay)
    if(nextFy.isBefore(dateFrom)) {
        nextFy.add(1, 'year');
    }

    let dates = [dateFrom]
    while(nextFy.isBefore(dateTo)) {
        dates.push(moment(nextFy));
        nextFy.add(1, 'year');
    }
    dates.push(dateTo);

    return dates;
}


export function minDate(dates) {
    if(!Array.isArray(dates)) {
        return null;
    }

    return _minBy(
        dates.map(d => {
            return moment(d);
        }),
        d => {
            return d.unix();
        }
    );
}

export function maxDate(dates) {
    if(!Array.isArray(dates)) {
        return null;
    }

    return _maxBy(
        dates.map(d => {
            return moment(d);
        }),
        d => {
            return d.unix();
        }
    );
}