import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import UseListing from './UseListing';
import * as ModelUtil from '../../lib/ModelUtil';

class AssetUserListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };
    }

    toggleExpansion = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const uses = this.props.user.uses;
        
        if(this.state.expanded || uses.length < 2) {
            return this.renderExpanded(uses);
        }
        else {
            return this.renderCollapsed(uses);
        }
    }

    renderCollapsed = (uses) => {
        const baseCellClass = 'top-dotted-border ';
        const user = this.props.user;
        const sizeNumeraire = ModelUtil.usageNumeraireForAsset(this.props.asset, this.props.numeraires);
        
        const totalLine4Size = uses
            .map((use) => {
                return use.pu_calc.sk_line4_usage_size;
            })
            .reduce((accumulator, current) => { return accumulator + current; });
        
        const totalLine5Size = uses
            .map((use) => {
                return use.pu_calc.sk_line5_usage_size;
            })
            .reduce((accumulator, current) => { return accumulator + current; });
        
        let timeDescription = '';
        const daysOfUse = uses
            .reduce((accumulator, use) => {
                return accumulator + use.pu_calc.time_numerator;
            }, 0);
        
        if(daysOfUse / (uses[0].pu_calc.time_denominator * uses.length) === 1) {
            timeDescription = 'full FY';
        }
        else {
            timeDescription = daysOfUse.toFixed(0) + ' total days of use';
        }

        var seen = {};
        const spaceCount = uses.filter((use, i) => {
            const spaceId = ModelUtil.assetUseContainerIsSpace(use) ? use.container_id : 'asset';
            return seen.hasOwnProperty(spaceId) ? false : (seen[spaceId] = true);
        }).length;

        return (
            <tr key={"user" + user.asset_user_id}>
                <td className="pl-2">
                    <span onClick={this.toggleExpansion} className="clickable">
                        <i className={"icon icon-chevron-" + (this.state.expanded ? 'down' : 'right')}></i>
                    </span>
                </td>
                <td className={baseCellClass + "text-secondary font-weight-bold"}>
                    <span onClick={this.toggleExpansion} className="clickable">
                        {user.name}
                    </span>
                    &nbsp;&nbsp;
                    <a onClick={() => { this.props.showAssetUserForm(this.props.user); }}>
                        <i className="icon icon-edit" />
                    </a>
                </td>
                <td className={baseCellClass + "text-muted"} colSpan="6">
                    {uses.length} uses; {timeDescription} in {spaceCount.toFixed(0)} spaces
                </td>
                <td className={baseCellClass + "text-right"}>
                    {numeral(totalLine4Size).format('0,0.00')} {sizeNumeraire.symbol}
                </td>
                <td className={baseCellClass + "text-right"}>
                    {numeral(totalLine5Size).format('0,0.00')} {sizeNumeraire.symbol}
                </td>
                <td className={baseCellClass + "text-right"}>
                    {numeral(totalLine4Size + totalLine5Size).format('0,0.00')} {sizeNumeraire.symbol}
                </td>
                <td className={baseCellClass + "text-right"}>
                    &nbsp;
                </td>
            </tr>
        );
    }

    renderExpanded = (uses) => {
        let userName = (
            <span className="text-secondary font-weight-bold">
                {
                    uses.length > 1
                        ? (
                            <span onClick={this.toggleExpansion} className="clickable">
                                <i className={"icon icon-chevron-" + (this.state.expanded ? 'down' : 'right')}></i>
                                {this.props.user.name}
                            </span>
                        )
                        : this.props.user.name
                }
                &nbsp;&nbsp;
                <a onClick={() => { this.props.showAssetUserForm(this.props.user); }}>
                    <i className="icon icon-edit" />
                </a>
            </span>
        );

        let useRows = uses.map((u, i) => (
            <UseListing
                key={"use" + u.asset_use_id}
                use={u}
                asset={this.props.asset}
                user={this.props.user}
                userUseCount={uses.length}
                numeraires={this.props.numeraires}
                firstCell={(i === 0 ? userName : null)} 
                showUseForm={this.props.showUseForm}
                showAssetUserForm={this.props.showAssetUserForm}
                toggleExpansion={this.toggleExpansion} />
        ));

        return useRows;
    }
}

AssetUserListing.propTypes = {
    user: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    showAssetUserForm: PropTypes.func.isRequired,
    showUseForm: PropTypes.func,
};

export default AssetUserListing;