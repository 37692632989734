import React from 'react';
import PropTypes from 'prop-types';
import * as FormUtil from '../../lib/FormUtil';
import AddAssetPopoverForm from '../AssetForms/AddAssetPopoverForm';
import AddSpacePopoverForm from '../SpaceForms/AddSpacePopoverForm';

const VacancyFormFields = (props) => {
    let assetOptions;
    if(props.assets) {
        assetOptions = FormUtil.assetOptions(props.assets, props.numeraires);
    }

    let spaceOptions;
    if(props.selectedAsset) {
        spaceOptions = FormUtil.spaceOptions(props.selectedAsset.spaces, props.assets, props.numeraires);
    }

    return (
        <div>
            <div className="form-group row">
                <label className="col-2 col-form-label text-muted text-uppercase">Asset</label>
                <div className="col-8">
                    <select
                        className="custom-select rounded-0 w-100"
                        name="assetId"
                        value={
                            props.selectedAsset ? props.selectedAsset.asset_id :
                                (props.selectedSpace ? props.selectedSpace.asset_id : '')
                        }
                        onChange={props.handleInputChange}>
                        <option></option>
                        {assetOptions}
                    </select>
                </div>
                <div className="col-2 text-right">
                    <AddAssetPopoverForm
                        clientId={props.client.client_id}
                        onSave={props.selectNewAsset}
                        numeraires={props.numeraires}
                        client={props.client} />
                </div>
            </div>

            <div className="form-group row">
                <label className="col-2 col-form-label text-muted text-uppercase">Space</label>
                <div className="col-8">
                    <select
                        className="custom-select rounded-0 w-100"
                        name="spaceId"
                        value={props.fieldValues.spaceId ? props.fieldValues.spaceId : ''}
                        onChange={props.handleInputChange}>
                        <option value="">General Asset</option>
                        {spaceOptions}
                    </select>
                </div>
                <div className="col-2 text-right">
                    {
                        props.selectedAsset
                            ? <AddSpacePopoverForm
                                clientId={props.client.client_id}
                                client={props.client}
                                onSave={props.selectNewSpace}
                                numeraires={props.numeraires}
                                assets={props.assets}
                                selectedAssetId={props.selectedAsset.asset_id}
                                sizeNumeraireSymbol={""}/>
                            : ''
                    }
                </div>
            </div>

            <div className="form-group row">
                <label className="col-2 col-form-label text-muted text-uppercase">Dates</label>
                <div className="col-8">
                    <div className="input-group rounded-0">
                        <div className="input-group-prepend rounded-0">
                            <span className="input-group-text">Start</span>
                        </div>
                        <input
                            className="form-control rounded-0"
                            type="text"
                            name="startDate"
                            value={props.fieldValues.startDate ? props.fieldValues.startDate : ''}
                            onChange={props.handleInputChange} />
                        <div className="input-group-prepend rounded-0">
                            <span className="input-group-text">End</span>
                        </div>
                        <input
                            className="form-control ounded-0"
                            type="text"
                            name="endDate"
                            value={props.fieldValues.endDate ? props.fieldValues.endDate : ''}
                            onChange={props.handleInputChange} />
                    </div>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-2 col-form-label text-muted text-uppercase">Size</label>
                <div className="col-3">
                    <div className="form-group">
                        <input
                            type="checkbox"
                            name="usesEntireSpace"
                            id="entireSpaceCheckbox"
                            checked={props.fieldValues.usesEntireSpace}
                            onChange={props.handleInputChange} />
                        <label className="form-check-label" htmlFor="entireSpaceCheckbox">
                            &nbsp;&nbsp;Entire Space
                        </label>
                    </div>
                </div>
                <div className="col-5">
                    <div className="input-group input-group-sm rounded-0">
                        <input
                            className="form-control form-control-sm rounded-0 text-right"
                            type="text"
                            name="size"
                            value={props.fieldValues.size ? props.fieldValues.size : ''}
                            disabled={props.fieldValues.usesEntireSpace}
                            onChange={props.handleInputChange} />
                    </div>
                </div>
            </div>
        </div>
    )
    
    
    
    
    /*
    (
        <div className="row">
            <div className="col-12">

                <div className="mt-3 mb-3">
                    <label className="font-weight-bold"></label>
                    
                </div>

                <div className="row mt-3 mb-3">
                    <div className="col-12">
                        <label className="font-weight-bold">Vacancy Size</label>
                    </div>
                    
                </div>
            </div>
        </div>
    );
    */
}

VacancyFormFields.propTypes = {
    handleInputChange: PropTypes.func.isRequired,
    numeraires: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired,
    selectNewAsset: PropTypes.func.isRequired,
    selectNewSpace: PropTypes.func.isRequired,
    assets: PropTypes.array.isRequired,
    selectedAsset: PropTypes.object,
    fieldValues: PropTypes.object.isRequired,
}

export default VacancyFormFields;