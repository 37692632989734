import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/783aa708-6112-42a7-b855-c7296ee31b8b',
        clientId: '18bee751-3165-4fdb-9d87-12ea95863834',
        redirectUri: window.location.href,
        postLogoutRedirectUri: window.location.origin
    },
    cache: {
        cacheLocation: 'localStorage'
    }
};

const authenticationParameters = {
    scopes: ['api://1f197e45-be50-47c5-896f-cb1f579b2630/PICAPIAccess'],
};

const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: `${window.location.origin}/auth.html`,
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);