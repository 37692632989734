import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NoteListing from './components/NoteListing';
import PostNoteForm from './components/PostNoteForm';

class Notepad extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingReviewerNotes: false,
            collapsed: false,
        };
    }

    scrollNotesToBottom = (params) => {
        //const node = ReactDOM.findDOMNode(this.notesEnd);
        //node.scrollIntoView(params);
    }

    componentDidMount() {
        this.scrollNotesToBottom();
    }

    showReviewerNotes = () => {
        this.setState({ showingReviewerNotes: true });
    }

    showAllNotes = () => {
        this.setState({ showingReviewerNotes : false });
    }

    toggle = () => {
        this.setState({ collapsed: !collapsed });
    }

    render() {
        let notesList;
        if(this.props.notes) {
            notesList = this.props.notes
                .filter(note => {
                    if(this.props.showAllEntities) {
                        return true;
                    }

                    if(!this.props.entityType && !this.props.entityId) {
                        return !note.entity_type && !note.entity_id;
                    }
                    if (!this.props.entityId) {
                        return note.entity_type === this.props.entityType;
                    }

                    return note.entity_type === this.props.entityType && note.entity_id === this.props.entityId && (!this.props.entitySection || note.entity_section === this.props.entitySection);
                })
                .filter(note => {
                    return !this.state.showingReviewerNotes || note.user_id === this.props.client.reviewer_user_id;
                })
                .map(note =>
                    <NoteListing
                        key={note.note_id}
                        note={note}
                        showEntityName={this.props.showAllEntities} />
                );
        }

        const listHeight = (this.props.listHeight ? this.props.listHeight : 300);

        /*
        <div className="d-flex">
                <div className="card-bg border-right-1" style={{ height: '100%', width: '10%' }}>
                    <i className="icon icon-chevron-right" />
                </div>    
        </div>
        style={{ width: '90%' }}
        */
        return (
            
                <div className="card rounded-0 border-bottom-0 border-left-0 border-right-0">
                    <div className="card-header">
                        <button className={`btn btn-sm${!this.state.showingReviewerNotes ? ' btn-primary' : ''}`} onClick={this.showAllNotes}>All</button>&nbsp;
                        <button className={`btn btn-sm${this.state.showingReviewerNotes ? ' btn-primary' : ''}`} onClick={this.showReviewerNotes}>Reviewer</button>
                    </div>
                    <div className="card-body pt-1 pb-3 pl-0 pr-0">
                        <ul className="list-group rounded-0 m-0 pt-0 pl-3" style={{height: listHeight + 'px', overflowY: 'scroll'}}>
                            {notesList}
                            <div ref={(e) => { this.notesEnd = e; }}></div>
                        </ul>
                    </div>
                    <div className="card-footer">
                        <ul className="list-group rounded-0 m-0 pt-0">
                            <li className="list-group-item border-left-0 border-0 pl-0 pr-0">
                                <PostNoteForm
                                    entityType={this.props.entityType}
                                    entityId={this.props.entityId}
                                    entitySection={this.props.entitySection}
                                    onPosting={() => {
                                        this.scrollNotesToBottom({ behavior: 'smooth'})
                                    }} />
                            </li>
                        </ul>
                    </div>
                </div>
        );
    }
}

Notepad.propTypes = {
    notes: PropTypes.array,
    showAllEntities: PropTypes.bool,
    entityType: PropTypes.string,
    entityId: PropTypes.number,
    entitySection: PropTypes.string,
    listHeight: PropTypes.number,
}

const mapStateToProps = state => (
    {
        client: state.client.client,
        notes: state.notes.notes,
    }
);

export default connect(mapStateToProps)(Notepad);
