import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import * as UserActionCreators from '../../ducks/users';
import * as ClientApi from '../../api/ClientApi';
import ClientFormFields from './ClientFormFields';

const ClientModalForm = props => {
    const [dataIsSaving, setDataIsSaving] = useState(false);
    const [dataSavingErrorText, setDataSavingErrorText] = useState(false);

    if(
        !props.users
        && !props.usersListIsLoading
        && !props.usersListHasErrored
    ) {
        props.loadUsersList();
    }

    if(!props.users) {
        return '';
    }

    const saveFunc = data => {
        return new Promise((resolve, reject) => {
            if(!props.client) {
                ClientApi
                    .create(data)
                    .then(result => resolve(result))
                    .catch(error => reject(error));
            }
            else {
                ClientApi
                    .update(props.client.client_id, data)
                    .then(result => resolve(result))
                    .catch(error => reject(error));
            }
        });
    }

    const toggle = () => {
        let hiding = props.show;

        if(hiding)
            props.onHide();
    }

    const onSubmit = data => {
        setDataIsSaving(true);
        setDataSavingErrorText(null);

        saveFunc(data)
            .then(() => {
                setDataIsSaving(false);
                setDataSavingErrorText(null);
                toggle();
            })
            .catch(error => {
                setDataIsSaving(false);
                setDataSavingErrorText(error);
            });
    }

    /*
    TODO: decide on delete functionality
    handleDeleteClick = (event) => {
        let confirmed = window.confirm('Are you sure you want to delete ' + this.state.client.name + '?');

        if(confirmed) {
            this.setState({ submittingForm: true });
        }
    }

    /*
    TODO: decide on delete functionality
    (props.client) ? 
        <div className="pt-0 pb-2 pl-1">
            <button type="button" className="btn btn-link" onClick={this.handleDeleteClick}>
                <span className="icon icon-circle-with-cross"></span>&nbsp;
                Delete {props.client.name}
            </button>
        </div>
        : ''
    */

    return (
        <Modal isOpen={props.show} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle}>
                <span className="font-weight-bold">
                    {
                        (props.client)
                            ? 'Edit ' + props.client.name
                            : 'Add Client'
                    }
                </span>
            </ModalHeader>
            <ClientFormFields
                client={props.client}
                users={props.users}
                onSubmit={onSubmit}>
                <div className="modal-actions">
                    <button type="button" className="btn-link modal-action" onClick={props.onHide}>
                        Cancel
                    </button>
                    <button type="submit" className="btn-link modal-action font-weight-bold">
                        {
                            dataIsSaving
                                ? 'Saving...'
                                : props.client
                                    ? 'Save Changes'
                                    : 'Add Client'
                        }
                    </button>
                </div>
                {
                    dataSavingErrorText ? <div className="text-danger">{ dataSavingErrorText }</div> : <Fragment />
                }
            </ClientFormFields>
        </Modal>
    );
}

ClientModalForm.propTypes = {
    client: PropTypes.object,
    onHide: PropTypes.func,
};

const mapStateToProps = state => (
    {
        users: state.users.list,
        usersListIsLoading: state.users.listIsLoading,
        usersListHasErrored: state.users.listHasErrored,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadUsersList: () => dispatch(UserActionCreators.loadUsersList()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientModalForm);
