import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import AssetUserFormFields from './AssetUserFormFields';
import AssetUserForm from './AssetUserForm';
import * as AssetUserActionCreators from '../../ducks/asset_users';

class AddAssetUserPopoverForm extends AssetUserForm {
    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: false,
            submittingForm: false,
            fieldValues: {
                clientId: this.props.client.client_id,
                type: 'private',
            },
        };
    }

    toggle = () => {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    }

    render() {
        return (
            <div>
                <button
                    type="button"
                    id="AddAssetUserPopoverForm"
                    onClick={this.toggle}
                    className="btn-link small m-0 p-0 border-0">
                    Add Asset User
                </button>
                <Popover
                    placement="right"
                    isOpen={this.state.popoverOpen}
                    target="AddAssetUserPopoverForm"
                    toggle={this.toggle}>
                    <PopoverHeader className="font-weight-bold">Add Asset User</PopoverHeader>
                    <PopoverBody className="p-0">
                        <form>
                            <div className="p-3">
                                <AssetUserFormFields handleInputChange={this.handleInputChange} fieldValues={this.state} />
                            </div>
                            <button type="button" className="btn-link modal-action p-2" onClick={this.toggle}>
                                Cancel
                            </button>
                            <button type="submit" className="btn-link modal-action p-2 font-weight-bold" onClick={this.handleFormSubmit}>
                                {
                                    this.state.submittingForm
                                        ? 'Loading...'
                                        : 'Add Asset User'
                                }
                            </button>
                        </form>
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}

AddAssetUserPopoverForm.propTypes = {
    onSave: PropTypes.func,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        createAssetUser: (data) => dispatch(AssetUserActionCreators.create(data)),
        updateAssetUser: (id, data) => dispatch(AssetUserActionCreators.update(id, data)),
        removeAssetUser: (id) => dispatch(AssetUserActionCreators.remove(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAssetUserPopoverForm);