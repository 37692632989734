import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FileTreeViewFile from './FileTreeViewFile';
import FileTreeViewFolder from './FileTreeViewFolder';

class FileTreeView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFileId: null
        };
    }

    onFileSelect = (file) => {
        this.setState({ selectedFileId: file.nd_document_id });
        if(typeof this.props.onFileSelect === 'function') {
            this.props.onFileSelect(file);
        }
    }
    
    render() {
        let contentsList = this.props.contents.map((item, i) => {
            if(item.nd_container_id) {
                return <FileTreeViewFolder folder={item} key={i} onFileSelect={this.onFileSelect} selectedFileId={this.state.selectedFileId} />
            }
            else {
                return <FileTreeViewFile file={item} key={i} onFileSelect={this.onFileSelect} selected={ this.state.selectedFileId === item.nd_document_id } />
            }
        });

        return (
            <div>
                {contentsList}
            </div>
        );
    }
}

FileTreeView.propTypes = {
    contents: PropTypes.array.isRequired,
    onFileSelect: PropTypes.func
};

export default FileTreeView;