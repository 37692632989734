import * as AssetActionCreators from './assets';
import * as AssetUserActionCreators from './asset_users';
import * as ClientActionCreators from './client';
import * as DataImportActionCreators from './data_imports';
import * as IssueActionCreators from './issues';
import * as NoteActionCreators from '../ducks/notes';
import * as NumeraireActionCreators from '../ducks/numeraires';

export function loadClientAndDependencies(dispatch, clientId) {
    dispatch(ClientActionCreators.loadClient(clientId));
    reloadLists(dispatch, null, clientId);
    dispatch(NumeraireActionCreators.loadNumeraires());
    dispatch(NoteActionCreators.loadNotes(clientId));
}

export function reloadDetailViews(dispatch, state, puFy) {
    if(state.client) {
        dispatch(ClientActionCreators.loadClient(state.client.client.client_id));
    }

    puFy = puFy ? puFy : (state.client && state.client.puCalcFy ? state.client.puCalcFy : 'latest');
    
    if (state.assets.detailView) {
        dispatch(AssetActionCreators.loadAssetDetailView(
            state.assets.detailView.asset.asset_id,
            puFy
        ));
    }

    if (state.asset_users.detailView) {
        dispatch(AssetUserActionCreators.loadAssetUserDetailView(
            state.asset_users.detailView.asset_user.asset_user_id,
            puFy
        ));
    }

    if (state.issues.detailView) {
        dispatch(IssueActionCreators.loadIssueDetailView(
            state.issues.detailView.issue.issue_id,
            puFy
        ));
    }

    if (state.issues.fullTMPCalc) {
        dispatch(IssueActionCreators.loadFullTMPCalc(
            state.issues.detailView.issue.issue_id,
            puFy
        ));
    }
}

export function reloadLists(dispatch, state, clientId) {
    reloadAssetList(dispatch, state, clientId);
    reloadAssetUserList(dispatch, state, clientId);
    reloadIssueList(dispatch, state, clientId);
}

export function reloadAssetList(dispatch, state, clientId) {
    dispatch(AssetActionCreators.loadAssets(clientId ? clientId : state.client.client.client_id));
}

export function reloadAssetUserList(dispatch, state, clientId) {
    dispatch(AssetUserActionCreators.loadAssetUsers(clientId ? clientId : state.client.client.client_id));
}

export function reloadIssueList(dispatch, state, clientId) {
    dispatch(IssueActionCreators.loadIssues(clientId ? clientId : state.client.client.client_id));
}

export function reloadDataImportList(dispatch, state, clientId) {
    dispatch(DataImportActionCreators.loadDataImports(clientId ? clientId : state.client.client.client_id));
}