import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';
import { sortBy as _sortBy } from 'lodash';

import * as ModelUtil from '../../lib/ModelUtil';

const AssetExcludedUseTable = (props) => {
    if (!props.asset || !props.asset.pu_calc) {
        return '';
    }

    const sizeNumeraireId = props.asset.usage_numeraire_id ? props.asset.usage_numeraire_id : props.asset.size_numeraire_id;
    const sizeNumeraire = ModelUtil.numeraireForNumeraireId(sizeNumeraireId, props.numeraires);

    const excludedSizes = _sortBy(
        props.asset.pu_calc.dates_excluded_overlapping_use,
        ['date', 'space_id']
    );
    
    const excludedRows = excludedSizes
        .map((u, i) => {
            let space;
            if (u.space_id) {
                space = ModelUtil.spaceForSpaceId(u.space_id, props.asset.spaces);
            }

            return (
                <tr key={i}>
                    <td className="pl-3">{moment(u.date).format('MM/DD/YY')}</td>
                    <td>{space ? space.name : 'General Asset'}</td>
                    <td className="text-right">{numeral(u.container_size).format('0.00')} {sizeNumeraire.symbol}</td>
                    <td className="text-right">{numeral(u.total_size).format('0.00')} {sizeNumeraire.symbol}</td>
                    <td className="text-right">{numeral(u.over_size).format('0.00')} {sizeNumeraire.symbol}</td>
                    <td className="text-right">{numeral(u.line4).format('0.00')} {sizeNumeraire.symbol}</td>
                    <td className="text-right">{numeral(u.line5).format('0.00')} {sizeNumeraire.symbol}</td>
                    <td className="text-right">{numeral(u.line4 + u.line5).format('0.00')} {sizeNumeraire.symbol}</td>
                </tr>
            );
        }
    );

    const line4Total = excludedSizes.reduce((acc, u) => {
        return acc + u.line4;
    }, 0);

    const line5Total = excludedSizes.reduce((acc, u) => {
        return acc + u.line5;
    }, 0);
    
    return (
        <table className="table table-data">
            <thead>
                <tr className="bottom-dotted-border">
                    <th className="pl-3">Date</th>
                    <th>Space</th>
                    <th className="text-right">Container</th>
                    <th className="text-right">Usage</th>
                    <th className="text-right">Oversize</th>
                    <th className="text-right">Line 4</th>
                    <th className="text-right">Line 5</th>
                    <th className="text-right">Line 6</th>
                </tr>
            </thead>
            <tbody>
                { excludedRows }
            </tbody>
            <tfoot>
                <tr className="text-right text-muted font-weight-bold text-uppercase">
                    <td className="text-right" colSpan={5}>Total Overlapping Use</td>
                    <td className="text-right">{numeral(line4Total).format('0.00')} {sizeNumeraire.symbol}</td>
                    <td className="text-right">{numeral(line5Total).format('0.00')} {sizeNumeraire.symbol}</td>
                    <td className="text-right">{numeral(line4Total + line5Total).format('0.00')} {sizeNumeraire.symbol}</td>
                </tr>
            </tfoot>
        </table>
    );
}

AssetExcludedUseTable.propTypes = {
    asset: PropTypes.object.isRequired,
    assets: PropTypes.array.isRequired,
    numeraires: PropTypes.array.isRequired,
};

const mapStateToProps = state => (
    {
        assets: state.assets.assets,
        numeraires: state.numeraires.numeraires,
    }
);

export default connect(mapStateToProps)(AssetExcludedUseTable);