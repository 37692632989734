import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import * as _ from 'lodash';
import ProjectListing from './ProjectListing';
import ProjectsTableSummaryBlock from './ProjectsTableSummaryBlock';

const TMPTable = (props) => {
    let totalProceedsSpent = 0;
    let totalEquitySpent = 0;
    let totalProjectsCost = 0;

    const projectRows = props.issue.projects.map((p, i) => {
        totalProceedsSpent += numeral(p.pu_calc.proceeds).value();
        totalEquitySpent += numeral(p.pu_calc.equity).value();
        totalProjectsCost += numeral(p.pu_calc.cost).value();

        return <ProjectListing project={p} key={i} tmp={true} showProjectForm={props.showProjectForm} />
    });

    const coiUses = props.issue.uses.filter((u) => {
        return u.is_coi;
    });

    let coiRows = [];

    coiUses.forEach((u) => {
        const ch = u.cost_history.find((h) => {
            return h.as_of_date === props.issue.pu_calc.fy_end_date;
        });

        if (ch) {
            const allProceedsExpended = _.round(numeral(ch.proceeds_expended).value() + numeral(ch.earnings_expended).value(), 2);

            totalProceedsSpent += allProceedsExpended;
            totalProjectsCost += allProceedsExpended;

            if (allProceedsExpended !== 0) {
                coiRows.push(
                    <tr key={`use-${u.issue_use_id}`}>
                        <td className="pl-3">&nbsp;</td>
                        <td>{ u.name }</td>
                        <td>&nbsp;</td>
                        <td className="text-right">${ numeral(allProceedsExpended).format('0,000.00') }</td>
                        <td className="text-right">$0.00</td>
                        <td className="text-right">${ numeral(allProceedsExpended).format('0,000.00') }</td>
                        <td className="text-right" colSpan={props.issue.legacy_equity ? 2 : 1}>100.00%</td>
                        <td className="text-right">${ numeral(props.issue.pu_calc.is_fully_defeased ? 0 : allProceedsExpended).format('0,000.00') }</td>
                        <td>&nbsp;</td>
                    </tr>
                );
            }
        }
    });
    
    const escrowRows = props.issue.pu_calc.refunded_issue_pu_details
        .map((puDetails) => {
            const use = props.issue.uses.find((u) => {
                return u.issue_use_id === puDetails.issue_use_id;
            });
            const refundedIssue = props.issues.find((i) => {
                return i.issue_id === puDetails.refunded_issue_id;
            });

            totalProceedsSpent += puDetails.proceeds_expended;
            totalEquitySpent += puDetails.equity_expended;
            totalProjectsCost += puDetails.proceeds_expended + puDetails.equity_expended;

            return (
                <tr key={`use-${puDetails.issue_use_id}-${refundedIssue.issue_id}`}>
                    <td className="pl-3">&nbsp;</td>
                    <td>
                        { use.name }
                    </td>
                    <td>
                        <Link to={ "/clients/" + props.issue.client_id + "/issues/" + puDetails.refunded_issue_id }>
                            { refundedIssue.short_name }
                        </Link>
                    </td>
                    <td className="text-right">
                        { numeral(puDetails.proceeds_expended).format('$0,000.00') }
                    </td>
                    <td className="text-right">
                        { numeral(puDetails.equity_expended).format('$0,000.00') }
                    </td>
                    <td className="text-right">
                        { numeral(puDetails.proceeds_expended + puDetails.equity_expended).format('$0,000.00') }
                    </td>
                    <td className="text-right" colSpan={props.issue.legacy_equity ? 2 : 1}>
                        { numeral(puDetails.pu_factor).format('0.00%') }
                    </td>
                    <td className="text-right">
                        { numeral(puDetails.pu_cost).format('$0,000.00') }
                    </td>
                    <td>&nbsp;</td>
                </tr>
            );
        });
    
    // proceeds spent, equity spent, total project cost, line 4 %, line 4 cost, line 5 %, line 5 cost, total %, total cost
    return (
        <table className="table table-data table-hover">
            <thead>
                <tr className="bottom-dotted-border">
                    <th style={{width: '2%'}} className="pl-3">&nbsp;</th>
                    <th style={{width: '19%'}}>Project</th>
                    <th style={{width: '20%'}}>Beneficiaries</th>
                    <th style={{width: '11%'}} className="text-right">
                        Tot. Proc. Spent a/o
                        <br />{ moment(props.issue.pu_calc.fy_end_date).format('MM/DD/YY') }
                    </th>
                    <th style={{width: '11%'}} className="text-right">
                        Tot. Eq. Spent a/o
                        <br />{ moment(props.issue.pu_calc.fy_end_date).format('MM/DD/YY') }
                    </th>
                    <th style={{width: '11%'}} className="text-right">
                        Total Project Cost a/o
                        <br />{ moment(props.issue.pu_calc.fy_end_date).format('MM/DD/YY') }
                    </th>
                    {
                        props.issue.legacy_equity
                            ? <th style={{width: '6%'}} className="text-right">Unadj.<br />PBU %</th>
                            : <Fragment />
                    }
                    <th style={{width: props.issue.legacy_equity ? '6%' : '12%'}} className="text-right">PBU %</th>
                    <th style={{width: '11%'}} className="text-right">PBU Cost</th>
                    <th style={{width: '3%'}}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                { projectRows }
                { escrowRows }
                { coiRows }
            </tbody>
            <tfoot>
                <tr className="text-right text-muted font-weight-bold">
                    <td className="text-right" colSpan={3}>
                        Total
                    </td>
                    <td className="text-right">
                        { numeral(totalProceedsSpent).format('$0,000.00') }
                    </td>
                    <td className="text-right">
                        { numeral(totalEquitySpent).format('$0,000.00') }
                    </td>
                    <td className="text-right">
                        { numeral(totalProjectsCost).format('$0,000.00') }
                    </td>
                    <td colSpan={props.issue.legacy_equity ? 2 : 1}>&nbsp;</td>
                    <td className="text-right">
                        { numeral(props.issue.pu_calc.total_pu_cost).format('$0,000.00') }
                    </td>
                    <td>&nbsp;</td>
                </tr>

                <ProjectsTableSummaryBlock
                    label="Capital Project Private Use Cost Figure:"
                    value={props.issue.pu_calc.projects_pu_cost}
                    numberFormat="$0,000.00"
                    colCount={8 + props.issue.legacy_equity}
                    paddingTop={3} />
                
                <ProjectsTableSummaryBlock
                    label="Refunding Private Use Cost Figure:"
                    value={props.issue.pu_calc.refundings_pu_cost}
                    numberFormat="$0,000.00"
                    colCount={8 + props.issue.legacy_equity}
                    paddingTop={0} />
                
                <ProjectsTableSummaryBlock
                    label="COI Private Use Cost Figure:"
                    value={props.issue.pu_calc.coi_pu_cost}
                    numberFormat="$0,000.00"
                    colCount={8 + props.issue.legacy_equity}
                    paddingTop={0} />
                
                {
                    !props.issue.legacy_equity
                        ? (<Fragment>
                            <ProjectsTableSummaryBlock
                                label="Total Capital Project Cost:"
                                value={props.issue.pu_calc.capex_cost}
                                numberFormat="$0,000.00"
                                colCount={8 + props.issue.legacy_equity}
                                paddingTop={3} />
                            
                            <ProjectsTableSummaryBlock
                                label="Total Qualified Equity:"
                                value={props.issue.pu_calc.qualified_equity_amount}
                                numberFormat="$0,000.00"
                                colCount={8 + props.issue.legacy_equity}
                                paddingTop={0} />
                            
                            <ProjectsTableSummaryBlock
                                label="Qualified Equity % of Project Cost:"
                                value={props.issue.pu_calc.qualified_equity_factor}
                                numberFormat="0.00%"
                                colCount={8 + props.issue.legacy_equity}
                                paddingTop={0} />
                            
                            <ProjectsTableSummaryBlock
                                label="Capital Project Private Use %:"
                                value={props.issue.pu_calc.projects_pu_factor}
                                numberFormat="0.00%"
                                colCount={8 + props.issue.legacy_equity}
                                paddingTop={0} />
                            
                            <ProjectsTableSummaryBlock
                                label="Capital Project Private Use % Allocable to the Bonds:"
                                value={props.issue.pu_calc.projects_equity_adjusted_factor}
                                numberFormat="0.00%"
                                colCount={8 + props.issue.legacy_equity}
                                paddingTop={0} />
                    </Fragment>) : <Fragment />
                }
                
                <ProjectsTableSummaryBlock
                    label="Net Sale Proceeds:"
                    value={props.issue.pu_calc.net_sale_proceeds}
                    numberFormat="$0,000.00"
                    colCount={8 + props.issue.legacy_equity}
                    paddingTop={3} />
                
                <ProjectsTableSummaryBlock
                    label="Costs of Issuance Private Use %:"
                    value={props.issue.pu_calc.coi_pu_factor}
                    numberFormat="0.00%"
                    colCount={8 + props.issue.legacy_equity}
                    paddingTop={0} />
                
                <ProjectsTableSummaryBlock
                    label="Refunding Private Use %:"
                    value={props.issue.pu_calc.refundings_pu_factor}
                    numberFormat="0.00%"
                    colCount={8 + props.issue.legacy_equity}
                    paddingTop={0} />
                
                <ProjectsTableSummaryBlock
                    label="Total Tax Measurement Period Private Use %:"
                    value={props.issue.pu_calc.total_equity_adjusted_factor}
                    numberFormat="0.00%"
                    colCount={8 + props.issue.legacy_equity}
                    paddingTop={3} />
            </tfoot>
        </table>
    );
}

TMPTable.propTypes = {
    issue: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired,
    showProjectForm: PropTypes.func,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        numeraires: state.numeraires.numeraires,
        issues: state.issues.list,
    }
);

export default connect(mapStateToProps)(TMPTable);