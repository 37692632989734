import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as TextFunctions from '../../lib/TextFunctions';

const itemLink = (clientId, type, id, name, active, count) => {
    return (
        <Link
            to={ `/clients/${clientId}/notebook/${type}s/${id}` }
            className={ `list-group-item entity-list-item${active ? ' active' : ''}` }
            key={`${type}-${id}`}>
            <div className="d-flex w-100 justify-content-between">
                <span className="font-weight-bold">
                    { name }
                </span>
                {
                    count ? (
                        <span className="badge badge-primary" style={{fontSize: '100%'}}>{ count }</span>
                    ) : <Fragment />
                }
            </div>
        </Link>
    );
}

const MainNav = props => {
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ showEntitiesWithoutNotes, setShowEntitiesWithoutNotes ] = useState(false);

    const client = props.client;
    if(!client) {
        return <Fragment />;
    }

    const entityNoteCounts = (props.notes || []).reduce((carry, note) => {
        if(!carry[`${note.entity_type}-${note.entity_id}`]) {
            carry[`${note.entity_type}-${note.entity_id}`] = 0;
        }
        carry[`${note.entity_type}-${note.entity_id}`]++;
        return carry;
    }, {});

    const issues = props.issueList || [];
    const assets = props.assetList || [];
    const assetUsers = props.assetUserList || [];

    const issueItems = issues
        .filter(issue => !searchTerm || TextFunctions.nameMatchesSearch(searchTerm, issue.short_name))
        .filter(issue => showEntitiesWithoutNotes || entityNoteCounts[`Issue-${issue.issue_id}`] > 0)
        .map(issue => itemLink(
            client.client_id,
            'issue',
            issue.issue_id,
            issue.short_name,
            props.selectedEntityType === 'Issue' && props.selectedEntityId === issue.issue_id,
            entityNoteCounts[`Issue-${issue.issue_id}`]
        ));

    const assetItems = assets
        .filter(asset => !searchTerm || TextFunctions.nameMatchesSearch(searchTerm, asset.name))
        .filter(asset => showEntitiesWithoutNotes || entityNoteCounts[`Asset-${asset.asset_id}`] > 0)
        .map(asset => itemLink(
            client.client_id,
            'asset',
            asset.asset_id,
            asset.name,
            props.selectedEntityType === 'Asset' && props.selectedEntityId === asset.asset_id,
            entityNoteCounts[`Asset-${asset.asset_id}`]
        ));
    
    const assetUserItems = assetUsers
        .filter(user => !searchTerm || TextFunctions.nameMatchesSearch(searchTerm, user.name))
        .filter(user => showEntitiesWithoutNotes || entityNoteCounts[`AsserUser-${user.asset_user_id}`] > 0)
        .map(user => itemLink(
            client.client_id,
            'asset_user',
            user.asset_user_id,
            user.name,
            props.selectedEntityType === 'AssetUser' && props.selectedEntityId === user.asset_user_id,
            entityNoteCounts[`AsserUser-${user.asset_user_id}`]
        ));

    return (
        <Fragment>
            <ul className="list-group rounded-0 list-group-flush p-0">      
                <li className="list-group-item rounded-0 entity-search-item p-2 w-100 d-flex">
                    <div style={{width: '62%'}}>
                        <input
                            className="form-control rounded-0"
                            type="text"
                            placeholder="Search Entities" 
                            onChange={ (event) => { setSearchTerm(event.target.value.trim()); } } />
                    </div>
                    <div style={{width: '38%', paddingTop: '2px'}}>
                        &nbsp;
                        <button
                            className={`btn btn-sm ${!showEntitiesWithoutNotes ? 'btn-primary' : 'btn-secondary'}`}
                            onClick={() => setShowEntitiesWithoutNotes(false)}>
                                w/ Notes
                        </button>
                        &nbsp;
                        <button
                            className={`btn btn-sm ${showEntitiesWithoutNotes ? 'btn-primary' : 'btn-secondary'}`}
                            onClick={() => setShowEntitiesWithoutNotes(true)}>
                                All
                        </button>
                    </div>
                </li>
                <Link
                    to={ `/clients/${client.client_id}/notebook/` }
                    className={ `list-group-item entity-list-item${!props.selectedEntityType ? ' active' : ''}` }
                    key={`general`}>
                    <div className="d-flex w-100 justify-content-between">
                        <span className="font-weight-bold">
                            General Notes
                        </span>
                        {
                            entityNoteCounts['null-null'] ? (
                                <span className="badge badge-primary" style={{fontSize: '100%'}}>{ entityNoteCounts['null-null'] }</span>
                            ) : <Fragment />
                        }
                    </div>
                </Link>
                <Link
                    to={ `/clients/${client.client_id}/notebook/research/` }
                    className={ `list-group-item entity-list-item${props.selectedEntityType === 'Research' ? ' active' : ''}` }
                    key={`research`}>
                    <div className="d-flex w-100 justify-content-between">
                        <span className="font-weight-bold">
                            Research Notes
                        </span>
                        {
                            entityNoteCounts['Research-null'] ? (
                                <span className="badge badge-primary" style={{fontSize: '100%'}}>{ entityNoteCounts['Research-null'] }</span>
                            ) : <Fragment />
                        }
                    </div>
                </Link>
                <Link
                    to={ `/clients/${client.client_id}/notebook/all/` }
                    className={ `list-group-item entity-list-item${props.selectedEntityType === 'All' ? ' active' : ''}` }
                    key={`all`}>
                    <div className="d-flex w-100 justify-content-between">
                        <span className="font-weight-bold">
                            All Client Notes
                        </span>
                        {
                            entityNoteCounts['null-null'] ? (
                                <span className="badge badge-primary" style={{fontSize: '100%'}}>{ (props.notes || []).length }</span>
                            ) : <Fragment />
                        }
                    </div>
                </Link>
                { !issueItems.length || <li className="text-muted font-weight-bold text-uppercase bg-light list-group-item">Issues</li> }
                { issueItems }
                { !assetItems.length || <li className="text-muted font-weight-bold text-uppercase bg-light list-group-item">Assets</li> }
                { assetItems }
                { !assetUserItems.length || <li className="text-muted font-weight-bold text-uppercase bg-light list-group-item">Asset Users</li> }
                { assetUserItems }
            </ul>
        </Fragment>
    );
}

const mapStateToProps = state => (
    {
        client: state.client.client,
        assetList: state.assets.assets,
        assetUserList: state.asset_users.list,
        issueList: state.issues.list,
        notes: state.notes.notes,
    }
);

export default connect(mapStateToProps)(MainNav);