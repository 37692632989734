import React, { Component } from 'react';
import TitleBar from '../components/TitleBar';
import { Link } from 'react-router-dom';

import Constants from '../lib/Constants';
import * as ClientApi from '../api/ClientApi';

import ClientModalForm from '../components/ClientForms/ClientModalForm';

class ClientList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clients: null,
            showingClientForm: false,
            editingClient: null,
        };
    }

    componentDidMount() {
        ClientApi.list()
            .then(data => {
                this.setState({ clients: data.clients });
            })
            .catch(error => {
                
            });
        this.setPageTitle();
    }

    setPageTitle() {
        document.title = Constants.pageTitleBase;
    }

    showClientForm = (client) => {
        this.setState({ showingClientForm: true, editingClient: client ? client : null });
    }

    hideClientForm = () => {
        this.setState({ showingClientForm: false, editingClient: null });
    }

    render() {
        let clientList;
        if(this.state.clients) {
            clientList = this.state.clients.map((client, index) =>
                <tr key={client.client_id}>
                    <td>
                        <Link
                            className="nav-link"
                            to={ "/clients/" + client.client_id }>
                            {client.name}
                        </Link>
                    </td>
                    <td className="text-right">
                        {client.fy_end_month} - {client.fy_end_day}
                    </td>
                    <td className="text-right">
                        {client.first_pu_fy}
                    </td>
                    <td className="text-right">
                        {client.last_pu_fy}
                    </td>
                    <td className="text-center">
                        <a onClick={() => {this.showClientForm(client);}}>
                            <i className="icon icon-edit" />
                        </a>
                    </td>
                </tr>
            );
        }

        return (
            <div>
                <TitleBar pageTitle="Clients" />
                
                <div className="clear-title-bar">
                    <table className="table table-data table-hover table-condensed">
                        <thead>
                            <tr className="bottom-dotted-border">
                                <th style={{width: '38%'}} className="pl-3">Client</th>
                                <th style={{width: '20%'}} className="text-right">FY End</th>
                                <th style={{width: '20%'}} className="text-right">First PBU FY</th>
                                <th style={{width: '20%'}} className="text-right">Last PBU FY</th>
                                <th style={{width: '2%'}}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            { clientList }
                        </tbody>
                    </table>
                    <a onClick={() => { this.showClientForm(); }}>Add Client</a>
                </div>
                <ClientModalForm
                    client={this.state.editingClient}
                    show={this.state.showingClientForm}
                    onHide={this.hideClientForm} />
            </div>
        );
    }
}

export default ClientList;
