import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import UsageDetailFieldSet from './UsageDetailFieldSet';
import AddAssetUserPopoverForm from '../../../components/AssetUserForms/AddAssetUserPopoverForm';
import AddAssetPopoverForm from '../../../components/AssetForms/AddAssetPopoverForm';
import AddSpacePopoverForm from '../../../components/SpaceForms/AddSpacePopoverForm';
import * as AssetActionCreators from '../../../ducks/assets';
import * as AssetUseActionCreators from '../../../ducks/asset_uses';
import * as AssetUserActionCreators from '../../../ducks/asset_users';
import * as FormUtil from '../../../lib/FormUtil';
import * as ModelUtil from '../../../lib/ModelUtil';

class SpaceUseForm extends Component {
    constructor(props) {
        super(props);

        let asset, space;
        if (this.props.use) {
            asset = ModelUtil.assetForUse(this.props.use, this.props.assets);
            space = ModelUtil.spaceForUse(this.props.use, this.props.assets);
        }
        else if (this.props.preselectedAssetId) {
            asset = ModelUtil.assetForAssetId(this.props.preselectedAssetId, this.props.assets);
        }

        this.state = {
            useId: this.props.use ? this.props.use.asset_use_id : null,
            contractType: this.props.use ? this.props.use.contract_type : 'lease-non-exempt',
            contractName: this.props.use ? this.props.use.contract_name : null,
            assetId: asset ? asset.asset_id : null,
            spaceId: space ? space.space_id : null,
            assetUserId: this.props.use ? this.props.use.asset_user_id : (this.props.preselectedAssetUserId ? this.props.preselectedAssetUserId : null),
            usesEntireSpace: this.props.use ? (this.props.use.size ? false : true) : true,
            usageNumeraireId: this.props.use ? this.props.use.usage_numeraire_id : null,
            fyUsageDetail: this.props.use ? (this.props.use.fy_usage_detail ? this.props.use.fy_usage_detail : {}) : {},
            fyDaysOfUse: this.props.use ? (this.props.use.fy_days_of_use ? this.props.use.fy_days_of_use : {}) : {},
            size: this.props.use && this.props.use.size ? numeral(this.props.use.size).format('0,0.00') : null,
            startDate: this.props.use && this.props.use.start_date ? moment(this.props.use.start_date).format('MM/DD/YYYY') : '',
            endDate: this.props.use && this.props.use.end_date ? moment(this.props.use.end_date).format('MM/DD/YYYY') : '',
            submittingForm: false,
        };
    }

    componentDidMount() {
        this.loadStateDependencies();
    }

    componentDidUpdate() {
        this.loadStateDependencies();
    }

    loadStateDependencies = () => {
        if (!this.props.assets && !this.props.assetsListIsLoading && !this.props.assetsListHasErrored) {
            this.props.loadAssets(this.props.client.client_id);
        }

        if (!this.props.assetUsers && !this.props.assetUsersListIsLoading && !this.props.assetUsersListHasErrored) {
            this.props.loadAssetUsers(this.props.client.client_id);
        }
    }

    saveFunc = () => {
        this.setState({ submittingForm: false });
        this.props.onHide();
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ submittingForm: true });
        if(this.state.useId) {
            this.props.updateAssetUse(this.state.useId, this.state).then(this.saveFunc);
        }
        else {
            this.props.createAssetUse(this.state).then(this.saveFunc);
        }
    }

    selectAssetUser = (user) => {
        this.setState({ assetUserId: user.asset_user_id });
    }

    selectAsset = (asset) => {
        asset.spaces = [];

        this.setState({
            assetId: asset.asset_id,
            spaceId: null,
        });
    }

    selectSpace = (space) => {
        this.setState({
            spaceId: space.space_id,
            assetId: space.asset_id
        });
    }

    getNumeraireById = (numeraireId) => {
        if(typeof numeraireId === 'string')
            numeraireId = parseInt(numeraireId, 0);

        let numeraire;
        numeraire = this.props.numeraires.find((item) => {
            return item.numeraire_id === numeraireId;
        });

        return numeraire;
    }

    getNumeraireSymbolById = (numeraireId) => {
        let numeraire = this.getNumeraireById(numeraireId);
        return numeraire ? numeraire.symbol : '';
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(name === 'usesEntireSpace') {
            if(value === true)
                this.setState({ [name]: value, size: null });
            else
                this.setState(
                    { [name]: value, size: null },
                    () => { this.sizeInput.focus(); }
                );
        }
        else if(name === 'assetId') {
            this.setState({
                [name]: value,
                spaceId: null,
            });
        }
        else if(name === 'spaceId') {
            let space, found;
            if(value) {
                found = this.props.assets.find((item) => {
                    space = item.spaces.find((s) => {
                        return s.space_id === parseInt(value, 0);
                    });

                    return space ? true : false;
                });
            }

            this.setState({
                [name]: value,
                assetId: (value && found) ? found.asset_id : this.state.assetId,
            });
        }
        else if(name === 'contractType' && value === 'unrelated-income') {
            this.setState({ [name]: value, usageNumeraireId: 2 });
        }
        else {
            this.setState({ [name]: value });
        }
    }

    handleUsageDetailInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const arr = name.split('-');
        const fy = arr[1];
        const type = arr[2];

        let fyUsageDetail = this.state.fyUsageDetail;
        if(fyUsageDetail[fy]) {
            fyUsageDetail[fy][type] = value;
        }
        else {
            fyUsageDetail[fy] = {};
            fyUsageDetail[fy][type] = value;
        }

        this.setState({ fyUsageDetail: fyUsageDetail });
    }

    handleDeleteClick = (event) => {
        let confirmed = window.confirm('Are you sure you want to delete this use?');

        if(confirmed) {
            this.setState({ submittingForm: true });

            this.props.removeAssetUse(this.props.use.asset_use_id).then(this.saveFunc);
        }
    }

    useIsUBI = () => {
        return this.state.contractType === 'unrelated-income';
    }

    render() {
        if (!this.props.assets || !this.props.assetUsers) {
            return '';
        }

        const assetOptions = FormUtil.assetOptions(this.props.assets, this.props.numeraires);
        const userOptions = FormUtil.assetUserOptions(this.props.assetUsers);
        const numeraireOptions = FormUtil.numeraireOptions(this.props.numeraires, 'Dynamic - ');

        const selectedAsset = this.state.assetId ? ModelUtil.assetForAssetId(this.state.assetId, this.props.assets) : null;
        const selectedSpace = (selectedAsset && this.state.spaceId) ? ModelUtil.spaceForSpaceId(this.state.spaceId, selectedAsset.spaces) : null;
        const spaceOptions = selectedAsset ? FormUtil.spaceOptions(selectedAsset.spaces, this.props.assets, this.props.numeraires) : [];

        let fys = this.props.client.pu_fys;

        let usageDetailFieldSets = fys.map((fy) =>
            <UsageDetailFieldSet
                key={fy}
                fy={fy}
                onInputChange={this.handleUsageDetailInputChange}
                hidden={this.state.usageNumeraireId ? false : true}
                numeraireSymbol={this.state.usageNumeraireId ? this.getNumeraireSymbolById(this.state.usageNumeraireId) : ''}
                badUsage={this.state.fyUsageDetail[fy] ? this.state.fyUsageDetail[fy].bad : null}
                totalUsage={this.state.fyUsageDetail[fy] ? this.state.fyUsageDetail[fy].total : null} />
        );
        /*
        
        */

        return (
            <form onSubmit={this.handleFormSubmit}>
                <div className="modal-body">
                    <div className="form-group row">
                        <label className="col-3 col-form-label text-muted text-uppercase">User</label>
                        <div className="col-7">    
                            <select
                                className="custom-select rounded-0 w-100 form-control"
                                name="assetUserId"
                                value={this.state.assetUserId ? this.state.assetUserId : ''}
                                onChange={this.handleInputChange}
                                disabled={userOptions.length === 0}>
                                <option></option>
                                {userOptions}
                            </select>
                        </div>
                        <div className="col-2 col-form-label text-right pl-0">
                            <AddAssetUserPopoverForm onSave={this.selectAssetUser} />
                        </div>
                    </div>

                    <div className="form-group row mt-4">
                        <label className="col-3 col-form-label text-muted text-uppercase">Contract Type</label>
                        <div className="col-7">
                            <select
                                className="custom-select form-control rounded-0 w-100"
                                name="contractType"
                                onChange={this.handleInputChange}
                                value={ this.state.contractType ? this.state.contractType : 'lease' }>
                                <option value="lease-non-exempt">[Line 4] Non-Exempt Lessee</option>
                                <option value="management-contract">[Line 4] Non-Compliant Management Contract</option>
                                <option value="research-non-exempt">[Line 4] Non-Qualified Corporate-Sponsored Research</option>
                                <option value="other-line-4">[Line 4] Other</option>
                                <option value="unrelated-income">[Line 5] Unrelated Income</option>
                                <option value="lease-exempt">[Line 5] 501c3/Government Lessee</option>
                                <option value="research-exempt">[Line 5] Non-Qualified Research by 501c3/Government</option>
                                <option value="other-line-5">[Line 5] Other</option>
                                <option value="non-pu">[Non PU] Non Private Use</option>
                                <option value="research-other">[Non PU] Other Research</option>
                                <option value="management-contract-compliant">[Non PU] Compliant Management Contract</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row mt-4">
                        <div className="col-3 col-form-label text-muted text-uppercase">
                            <div>Contract Name</div>
                            <div className="small">Optional</div>
                        </div>
                        <div className="col-7">
                            <input
                                className="form-control rounded-0"
                                type="text"
                                name="contractName"
                                value={this.state.contractName ? this.state.contractName : ''}
                                onChange={this.handleInputChange}
                                autoComplete="off" />
                        </div>
                    </div>

                    <hr />

                    <div className="form-group row">
                        <label className="col-3 col-form-label text-muted text-uppercase">Asset</label>
                        <div className="col-7">
                            <select
                                className="custom-select rounded-0 w-100 form-control"
                                name="assetId"
                                value={this.state.assetId ? this.state.assetId : ''}
                                onChange={this.handleInputChange}
                                disabled={assetOptions.length === 0}>
                                <option></option>
                                {assetOptions}
                            </select>
                        </div>
                        <div className="col-2 col-form-label text-right">
                            <AddAssetPopoverForm onSave={this.selectAsset} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-3 col-form-label text-muted text-uppercase">Space</label>
                        <div className="col-7">
                            <select
                                className="custom-select rounded-0 w-100 form-control"
                                name="spaceId"
                                value={this.state.spaceId ? this.state.spaceId : ''}
                                onChange={this.handleInputChange}>
                                <option value="">General Asset</option>
                                {spaceOptions}
                            </select>
                        </div>
                        <div className="col-2 col-form-label text-right">
                            <AddSpacePopoverForm
                                onSave={this.selectSpace}
                                selectedAssetId={parseInt(this.state.assetId, 10)} />
                        </div>
                    </div>

                    <hr />

                    <div className="form-group row">
                        <label className="col-3 col-form-label text-muted text-uppercase">Size</label>
                        <div className="col-3 col-form-label">
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    name="usesEntireSpace"
                                    id="spaceUseEntireSpace"
                                    checked={this.state.usesEntireSpace}
                                    onChange={this.handleInputChange} />
                                <label className="form-check-label" htmlFor="spaceUseEntireSpace">
                                    &nbsp;Entire Space
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="input-group rounded-0">
                                <input
                                    className="form-control rounded-0 text-right"
                                    type="text"
                                    name="size"
                                    ref={(input) => { this.sizeInput = input; }}
                                    value={this.state.size ? this.state.size : ''}
                                    disabled={this.state.usesEntireSpace}
                                    onChange={this.handleInputChange}
                                    autoComplete="off" />
                                <div className="input-group-append rounded-0">
                                    <span className="input-group-text">
                                        {selectedSpace
                                            ? (selectedSpace.usage_numeraire_id
                                                ? this.getNumeraireSymbolById(selectedSpace.usage_numeraire_id)
                                                : this.getNumeraireSymbolById(selectedAsset.usage_numeraire_id ? selectedAsset.usage_numeraire_id : selectedAsset.size_numeraire_id))
                                            : (selectedAsset
                                                ? this.getNumeraireSymbolById(selectedAsset.usage_numeraire_id ? selectedAsset.usage_numeraire_id : selectedAsset.size_numeraire_id)
                                                : '')
                                            }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-3 col-form-label text-muted text-uppercase">
                            <div>Term</div>
                            { !this.useIsUBI() || <div className="small">Optional</div> }
                        </label>
                        <div className="col-9">
                            <div className="input-group rounded-0">
                                <div className="input-group-prepend rounded-0">
                                    <span className="input-group-text rounded-0">Start</span>
                                </div>
                                <input
                                    className="form-control rounded-0 text-right"
                                    type="text"
                                    name="startDate"
                                    ref={(input) => { this.startDateInput = input; }}
                                    value={this.state.startDate ? this.state.startDate : ''}
                                    onChange={this.handleInputChange}
                                    autoComplete="off" />
                                <div className="input-group-prepend rounded-0">
                                    <span className="input-group-text">End</span>
                                </div>
                                <input
                                    className="form-control rounded-0 text-right"
                                    type="text"
                                    name="endDate"
                                    ref={(input) => { this.endDateInput = input; }}
                                    value={this.state.endDate ? this.state.endDate : ''}
                                    onChange={this.handleInputChange}
                                    autoComplete="off" />
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-3 col-form-label text-muted text-uppercase">Usage</label>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-12">
                                    <select
                                        className="custom-select rounded-0 w-100 form-control"
                                        name="usageNumeraireId"
                                        onChange={this.handleInputChange}
                                        value={this.state.usageNumeraireId ? this.state.usageNumeraireId : ''}>
                                        <option value="">Static</option>
                                        {numeraireOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    {usageDetailFieldSets}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (this.props.use) ? 
                        <div className="pt-0 pb-2 pl-1">
                            <button type="button" className="btn btn-link" onClick={this.handleDeleteClick}>
                                <span className="icon icon-circle-with-cross"></span>&nbsp;
                                Delete Space Use
                            </button>
                        </div>
                        : ''
                }
                <div className="modal-actions">
                    <button type="button" className="btn-link modal-action" onClick={this.props.onHide}>
                        Cancel
                    </button>
                    <button type="submit" className="btn-link modal-action font-weight-bold">
                        {
                            this.state.submittingForm
                                ? 'Loading...'
                                : this.props.use
                                    ? 'Save Changes'
                                    : 'Add Space Use'
                        }
                    </button>
                </div>
            </form>
        );
    }
}

SpaceUseForm.propTypes = {
    use: PropTypes.object,
    assets: PropTypes.array,
    assetUsers: PropTypes.array,
    numeraires: PropTypes.array.isRequired,
    onHide: PropTypes.func,
    preselectedAssetUserId: PropTypes.number,
    preselectedAssetId: PropTypes.number,
};

const mapStateToProps = state => (
    {
        numeraires: state.numeraires.numeraires,
        client: state.client.client,
        assets: state.assets.assets,
        assetsListIsLoading: state.assets.assetsListIsLoading,
        assetsListHasErrored: state.assets.assetsListHasErrored,
        assetUsers: state.asset_users.list,
        assetUsersListIsLoading: state.asset_users.listIsLoading,
        assetUsersListHasErrored: state.asset_users.listHasErrored,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadAssets: (clientId) => dispatch(AssetActionCreators.loadAssets(clientId)),
        loadAssetUsers: (clientId) => dispatch(AssetUserActionCreators.loadAssetUsers(clientId)),
        createAssetUse: (data) => dispatch(AssetUseActionCreators.create(data)),
        removeAssetUse: (id) => dispatch(AssetUseActionCreators.remove(id)),
        updateAssetUse: (id, data) => dispatch(AssetUseActionCreators.update(id, data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceUseForm);
