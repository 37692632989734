import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DataExceptionListing = (props) => {
    const exception = props.exception;
    const client = props.client;

    let linkPath = "/clients/" + client.client_id;
    switch (exception.entity_type) {
        case 'Asset':
            linkPath += '/assets/' + exception.entity_id;
            break;
        case 'Issue':
            linkPath += '/issues/' + exception.entity_id;
            break;
        default:
            break;
    }

    return (
        <li className="list-group-item rounded-0 border-left-0 border-right-0 d-flex justify-content-between">
            <span className="text-danger w-75">
                <i className="icon icon-circle-with-cross"></i> { exception.message }
            </span>
            <span className="text-right">
            {
                !props.hideLink
                    ? (
                        <Link to={ linkPath }>
                            View { exception.entity_name } <i className="icon icon-arrow-with-circle-right"></i>
                        </Link>
                    )
                    : ''
            }
            </span>
        </li>
    );
}

DataExceptionListing.propTypes = {
    exception: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    hideLink: PropTypes.bool,
};

export default DataExceptionListing;
