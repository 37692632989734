import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';

const FullTMPTable = (props) => {
    if(!props.issue) {
        return '';
    }

    if(props.fullTMPCalcIsLoading) {
        return (<p>Loading...</p>);
    }

    if(props.fullTMPCalcErrorMessage) {
        return (<p>Error: { props.fullTMPCalcErrorMessage }</p>);
    }

    if(!props.fullTMPCalc) {
        return '';
    }

    const fullTMPPU = props.fullTMPCalc.full_tmp_pu;

    const periodRows = fullTMPPU.map((period, i) => {
        return (
            <tr key={ period.start_date } className="border-bottom">
                <td>{ i + 1 }</td>
                <td className="text-right">
                    { moment(period.start_date).format('MM/DD/YY') }
                </td>
                <td className="text-right">
                    { moment(period.end_date).format('MM/DD/YY') }
                </td>
                <td className="text-right">
                    { numeral(period.period_length).format('0.00') }
                </td>
                <td className="text-right">
                    { numeral(period.pu_factor_refunding).format('0.00%') }
                </td>
                <td className="text-right">
                    { numeral(period.pu_factor).format('0.00%') }
                </td>
            </tr>
        );
    });

    const grossTimePeriod = fullTMPPU.reduce((acc, period) => {
        return acc + period.period_length;
    }, 0);

    const issueWeightedPUFactor = fullTMPPU.reduce((acc, period) => {
        return acc + (period.pu_factor * period.period_length);
    }, 0);

    const refundingWeightedPUFactor = fullTMPPU.reduce((acc, period) => {
        return acc + (period.pu_factor_refunding * period.period_length);
    }, 0);

    return (
        <table className="table table-data table-condensed table-hover">
            <thead>
                <tr className="bottom-dotted-border">
                    <th className="text-left" style={{width: '6%'}}>Measurement Period</th>
                    <th className="text-right" style={{width: '20%'}}>Year Begin</th>
                    <th className="text-right" style={{width: '20%'}}>Year End</th>
                    <th className="text-right" style={{width: '14%'}}>Period Length</th>
                    <th className="text-right" style={{width: '20%'}}>No-COI PBU %</th>
                    <th className="text-right" style={{width: '20%'}}>PBU %</th>
                </tr>
            </thead>
            <tbody>
                { periodRows }
            </tbody>
            <tfoot>
                <tr className="font-weight-bold">
                    <td colSpan={4} className="text-right">
                        Cumulative Private Use for Tax Measurement Period:
                    </td>
                    <td className="text-right">
                        { numeral(refundingWeightedPUFactor / grossTimePeriod).format('0.00%') }
                    </td>
                    <td className="text-right">
                        { numeral(issueWeightedPUFactor / grossTimePeriod).format('0.00%') }
                    </td>
                </tr>
            </tfoot>
        </table>
    );
}

const mapStateToProps = state => (
    {
        client: state.client.client,
        numeraires: state.numeraires.numeraires,
        issues: state.issues.list,
        issue: state.issues.detailView,
        fullTMPCalcIsLoading: state.issues.fullTMPCalcIsLoading,
        fullTMPCalcHasErrored: state.issues.fullTMPCalcHasErrored,
        fullTMPCalcErrorMessage: state.issues.fullTMPCalcErrorMessage,
        fullTMPCalc: state.issues.fullTMPCalc,
    }
);

export default connect(mapStateToProps)(FullTMPTable);