import moment from 'moment';
import numeral from 'numeral';
import { PDFDocument } from 'pdf-lib';
import { keys as _keys } from 'lodash';
import ExcelJS from 'exceljs';
import Constants from './Constants';
import wrap from 'word-wrap';

export const formatValuesForSkForm = (values) => {
  return Constants.skQuestions.reduce((carry, question) => {
    if(typeof values[`${question.part}-${question.key}`] != 'undefined') {
      let value = values[`${question.part}-${question.key}`];
      if(question.date) {
        value = value === null ? '' : moment(value).format('MM/DD/YYYY');
      }
      else if(question.numeric) {
        value = value === null ? '' : numeral(value).format('0,000');
      }
      else if(question.percentage) {
        value = value === null ? '' : numeral(numeral(value).value() * 100).format('0.0');
      }
      else if(question.yesno) {
        value = (value === 'Yes' || value === true) ? true : ((value === 'No' || value === false) ? false: null);
      }
      carry[`${question.part}-${question.key}`] = value;
    }
    return carry;
  }, { part6: values.part6 });
};

const structureSkFormFields = (csv) => {
  const lines = csv.split('\n');
  return lines.reduce((carry, lineText) => {
    const line = lineText.split(',');
    if(!carry[line[0]]) {
      carry[line[0]] = {};
    }
    carry[line[0]][line[1]] = [
      line[2],
      line[3],
      line[4],
      line[5],
    ];
    return carry;
  }, {});
};

export const fillSkFormExcel = (issuesAnswers, issueData, year, organizationInfo = {}) => {
  const questionRows = Constants.skQuestions.map(q => {
    return {
      q,
      data: [
        q.part,
        q.key,
        q.question,
      ].concat(issuesAnswers.map(answers => processAnswerForExcel(answers[`${q.part}-${q.key}`], q)))
    };
  });

  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'BLX PIC Application';
  const sheet = workbook.addWorksheet(`SK Export ${year}`);

  sheet.addRow([
    'Org.',
    organizationInfo.organization_name || null
  ]);

  sheet.addRow([
    'EIN',
    organizationInfo.organization_ein || null
  ]);

  sheet.addRow([
    'Year',
    year
  ]);

  sheet.addRow([]);

  sheet.addRow(
    [
      'Part',
      'Key',
      'Question'
    ].concat(issueData.map(issue => issue.short_name))
  );
  
  sheet.getColumn(1).width = 10;
  sheet.getColumn(2).width = 10;
  sheet.getColumn(3).width = 40;
  for (let i = 0; i < issueData.length; i++) {
    sheet.getColumn(4 + i).width = 15;
  }

  for (const qr of  questionRows) {
    const row = sheet.addRow(qr.data);
    let numFmt = null;
    if (qr.q.numeric) {
      numFmt = '#,###'
    }
    if (qr.q.percentage) {
      numFmt = '0.0%';
    }
    for (let i = 4; i < row.cellCount + 1; i++) {
      if (numFmt) {
        row.getCell(i).numFmt = numFmt;
      }
      row.getCell(i).alignment = { vertical: 'middle', horizontal: 'right' };
    }
  }

  const part6StartRow = 6 + questionRows.length; // 5 metadata + header + spacing rows
  sheet.getCell(part6StartRow, 1).value = 6;

  issuesAnswers.map((answers, i) => {
    let notes = [];
    if (Array.isArray(answers.part6)) {
      notes = part6NotesArrayFromArrayStructure(answers.part6);
    }
    else if (answers.part6) {
      notes = part6NotesArrayFromObjectStructure(answers.part6);
    }
    
    notes.map((p6a, j) => {
      sheet.getCell(part6StartRow + j, 4 + i).value = p6a;
    });
  });
  
  return workbook;
};

const processAnswerForExcel = (answer, question) => {
  if (answer === null || answer === undefined) {
    return null;
  }
  if (question.numeric) {
    return numeral(answer).value();
  }
  if (question.percentage) {
    return numeral(answer).value() / 100;
  }
  if (question.yesno) {
    return answer ? 'Yes' : 'No';
  }
  return answer;
};

export const fillSkForm = async (formYear, issuesAnswers, organizationInfo = {}) => {
  formYear = parseInt(formYear, 10);
  if(formYear !== 2019 && formYear !== 2020 && formYear != 2021 && formYear != 2022 && formYear != 2023) {
    alert(`SK form not available for form year ${formYear}`);
    return;
  }
  const fieldsCsv = await fetch(`/sk_forms/f990_sk_fields_${formYear}.csv`).then(res => res.text());
  const fields = structureSkFormFields(fieldsCsv);
  const formPdfBytes = await fetch(`/sk_forms/f990sk-${formYear}.pdf`).then(res => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(formPdfBytes);

  const form = pdfDoc.getForm();

  // const formFields = form.getFields();

  // exclude any issues beyond the 4th one since a single form only fits 4 bond issues
  issuesAnswers = issuesAnswers.filter((answers, issueI) => issueI < 4);

  let questionAnswers = Constants.skQuestions.reduce((carry, q) => {
    return carry.concat(issuesAnswers.map((answers, issueI) => {
      return {
        fieldName: skFormField(fields, issueI, q.part, q.key, q.yesno, answers[`${q.part}-${q.key}`], formYear),
        answer: typeof answers[`${q.part}-${q.key}`] == 'undefined' ? null : answers[`${q.part}-${q.key}`],
        yesno: q.yesno,
      };
    }));
  }, []);

  questionAnswers.push({
    fieldName: skFormField(fields, 0, 'org_name', '1', false, null, formYear),
    answer: organizationInfo.organization_name || null,
    yesno: false,
  });
  questionAnswers.push({
    fieldName: skFormField(fields, 0, 'ein', '1', false, null, formYear),
    answer: organizationInfo.organization_ein || null,
    yesno: false,
  });

  // add part 6 notes
  const allPart6Notes = issuesAnswers.reduce((carry, answers, issueI) => {
    const columnDesc = `Column ${String.fromCharCode(65 + issueI)}: `;
    if(Array.isArray(answers.part6)) {
      return carry.concat(part6NotesArrayFromArrayStructure(answers.part6, columnDesc));
    }
    return carry.concat(part6NotesArrayFromObjectStructure(answers.part6, columnDesc));
  }, []);

  const part6Lines = allPart6Notes.reduce((carry, note) => {
    return carry.concat(`${wrap(note, { width: 185, newline: '||', trim: true }).trim()}||`.split('||'));
  }, []);

  for(let i = 0; i < part6Lines.length; i++) {
    if(i > 63) { // the SK form has 64 lines in part 6
      break;
    }
    const page = i > 21 ? 4 : 3;
    const lineNumber = (page === 3 ? (17 + i) : (i - 21));
    questionAnswers.push({
      fieldName: `topmostSubform[0].Page${page}[0].f${page}_${lineNumber}[0]`,
      answer: part6Lines[i],
      yesno: false,
    });
  }

  for(const qa of questionAnswers) {
      const field = qa.yesno ? form.getCheckBox(qa.fieldName) : form.getTextField(qa.fieldName);
      if(!field) {
          console.error('field not found', qa.fieldName);
          continue;
      }
      if(qa.yesno && qa.answer !== null) {
          field.check();
      }
      else if(qa.answer !== null) {
          field.setText(`${qa.answer}`);
      }
  }

  return pdfDoc;
};

export const skFormField = (fields, issueI, part, key, yesno, answer, formYear) => {
  const issueChar = String.fromCharCode(65 + issueI);
  const keyIntVal = parseInt(`${key}`.replace(/[^0-9]/g, ''), 10);
  let nonStandardCbSuffix = false;
  let prefix = 'topmostSubform[0]';
  let partKey;
  switch(part) {
      case 1:
          partKey = formYear == 2023 ? 'PartI' : 'Part1';
          prefix += `.Page1[0].Table_${partKey}[0].Row${issueChar}[0]`;
          break;
      case 2:
          partKey = formYear == 2023 && keyIntVal <= 13 ? 'PartII' : 'Part2';
          prefix += `.Page1[0].Table_${partKey}_${keyIntVal > 13 ? '14-17' : '1-13'}[0].Line${key}[0]`;
          break;
      case 3:
          prefix += `.Page2[0].PartIIITable[0].Line${key}[0]`;
          if(key !== '8c') {
              prefix += `.Line${key}${keyIntVal >= 3 ? '_' : ''}${issueChar}[0]`;
          }
          if(yesno && keyIntVal === 1) {
              prefix += `.Line${key}${issueChar}${answer === true ? '_Yes[0]' : '_No[0]'}`;
              nonStandardCbSuffix = true;
          }
          break;
      case 4:
          if(keyIntVal < 4) {
              prefix += `.Page2[0].Part4_Table[0].Line${key}[0]`;
          }
          else {
              prefix += `.Page3[0].PartIVTable[0].Line${key}[0]`;
          }
          if(yesno) {
              nonStandardCbSuffix = (key === '4a' || key === '1');
              if(nonStandardCbSuffix) {
                  prefix += `.Line${key}_${issueChar}[0].Line${key}${issueChar}${answer === true ? '_Yes[0]' : '_No[0]'}`;
              }
              else {
                  prefix += `.Line${key}_${issueChar}[0]`;
              }
          }
          break;
      case 5:
          prefix += `.Page3[0].PartVTable[0].BodyRow[0].PartV_${issueChar}[0].PartV${issueChar}${answer === true ? '_Yes[0]' : '_No[0]'}`;
          nonStandardCbSuffix = true;
          break;
      default:
          prefix += '.Page1[0]';
          break;
  }
  let suffix = '[0]';
  if(yesno && !nonStandardCbSuffix) {
      suffix = answer === true ? '[0]' : '[1]';
  }

  return `${prefix}.${fields[part][key][issueI]}${suffix}`;
};

export const part6NotesArrayFromObjectStructure = (part6Object, prefix = '') => {
  if(!part6Object) {
    return [];
  }

  const notes = _keys(part6Object)
    .filter(key => key.includes('-include-') && part6Object[key])
    .map(includeKey => `${prefix}${part6Object[includeKey.replace('-include-', '-text-')]}`);

  if(part6Object['part6-text-other']) {
    notes.push(`${prefix}${part6Object['part6-text-other']}`);
  }
  
  return notes;
}

export const part6NotesArrayFromArrayStructure = (part6Array, prefix = '') => {
  if(!part6Array) {
    return [];
  }

  return part6Array.filter(i => !i.question || i.include).map(i => `${prefix}${i.text}`);
}
