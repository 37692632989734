import moment from 'moment';
import * as DateFunctions from './DateFunctions';
import * as TextFunctions from './TextFunctions';

export function entityTypeForTableName(tableName) {
    if(typeof tableName !== 'string') {
        return null;
    }

    tableName = tableName.trim().replace(/s{1}$/, '');

    return tableName.split('_').map(p => TextFunctions.ucFirst(p)).join('');
}

export function entityNameForTypeAndId(entityType, entityId, assets, assetUsers, issues, defaultName = '') {
    if(!entityType || !entityId) {
        return defaultName;
    }

    entityType = entityType.toLowerCase();
    let entity;
    switch(entityType) {
        case 'asset':
            entity = assetForAssetId(entityId, assets);
            return !entity ? '' : entity.name;
        case 'assetuser':
            entity = assetUserForAssetUserId(entityId, assetUsers);
            return !entity ? '' : entity.name;
        case 'issue':
            entity = issueForIssueId(entityId, issues);
            return !entity ? '' : entity.short_name;
        default:
            return '';
    }
}

export function assetForUse(use, assets) {
    if (assetUseContainerIsSpace(use)) {
        return assetForSpaceId(use.container_id, assets);
    }
    else {
        return assetForAssetId(use.container_id, assets);
    }
}

export function spaceForUse(use, assets) {
    if (assetUseContainerIsSpace(use)) {
        return spaceForSpaceIdFromAssets(use.container_id, assets);
    }

    return null;
}

export function containerForUse(use, assets) {
    if (assetUseContainerIsSpace(use)) {
        return spaceForUse(use, assets);
    }
    
    return assetForUse(use, assets);
}

export function assetUseContainerIsSpace(use) {
    return use.container_type === 'App\\Space';
}

export function assetForSpaceId(spaceId, assets) {
    if (typeof spaceId !== 'number') {
        spaceId = parseInt(spaceId, 10);
    }

    return assets.find((asset) => {
        const space = asset.spaces.find((space) => {
            return space.space_id === spaceId;
        });

        return !!space;
    });
}

export function assetForAssetId(assetId, assets) {
    if (typeof assetId !== 'number') {
        assetId = parseInt(assetId, 10);
    }

    return (assets || []).find((asset) => {
        return asset.asset_id === assetId;
    });
}

export function assetUserForAssetUserId(assetUserId, assetUsers) {
    if (typeof assetUserId !== 'number') {
        assetUserId = parseInt(assetUserId, 10);
    }

    return (assetUsers || []).find((item) => {
        return item.asset_user_id === assetUserId;
    });
}

export function spaceForSpaceId(spaceId, spaces) {
    if (typeof spaceId !== 'number') {
        spaceId = parseInt(spaceId, 10);
    }

    return (spaces || []).find((space) => {
        return space.space_id === spaceId;
    });
}

export function spaceForSpaceIdFromAssets(spaceId, assets) {
    if (typeof spaceId !== 'number') {
        spaceId = parseInt(spaceId, 10);
    }

    let space;
    (assets || []).find((asset) => {
        space = asset.spaces.find((space) => {
            return space.space_id === spaceId;
        });

        return !!space;
    });

    return space;
}

export function issueForIssueId(issueId, issues) {
    if (typeof issueId !== 'number') {
        issueId = parseInt(issueId, 10);
    }

    return (issues || []).find((issue) => {
        return issue.issue_id === issueId;
    });
}

export function issueForIssueShortName(shortName, issues) {
    return (issues || []).find(issue => {
        return issue.short_name === shortName;
    });
}

export function numeraireForNumeraireId(numeraireId, numeraires) {
    if (typeof numeraireId !== 'number') {
        numeraireId = parseInt(numeraireId, 10);
    }

    return (numeraires || []).find((numeraire) => {
        return numeraire.numeraire_id === numeraireId;
    });
}

export function usageNumeraireForAsset(asset, numeraires) {
    return numeraireForNumeraireId(
        asset.usage_numeraire_id ? asset.usage_numeraire_id : asset.size_numeraire_id,
        numeraires
    );
}

export function usersFromUses(uses) {
    if (!uses) {
        return [];
    }
    
    let users = [];
    let userIds = [];
    uses.forEach((use) => {
        if (!use.asset_user_id) {
            return;
        }

        let idx = userIds.indexOf(use.asset_user_id);
        if (idx < 0) {
            idx = users.length;

            users[idx] = use.user;
            userIds[idx] = use.asset_user_id;

            users[idx].uses = [];
        }

        users[idx].uses.push(use);
    });

    return users;
}

export function earliestStartDateForUses(uses) {
    if(!uses) {
        return null;
    }
    return moment.min(uses
        .filter((use) => {
            return !!use.start_date;
        })
        .map((use) => {
            return moment(use.start_date);
        })
    ); 
}

export function latestEndDateForUses(uses) {
    if(!uses) {
        return null;
    }
    return moment.max(uses
        .filter((use) => {
            return !!use.end_date;
        })
        .map((use) => {
            return moment(use.end_date);
        })
    ); 
}

export function effectivePUCalcFYForClient(puCalcFy, client) {
    if(puCalcFy === 'latest') {
        return client.pu_fys[0];
    }
    else {
        return puCalcFy;
    }
}

export function effectivePUCalcFYForClientFromProps(props) {
    return effectivePUCalcFYForClient(props.puCalcFy, props.client);
}

export function effectivePUCalcFYEndDateForClient(puCalcFy, client) {
    const fy = effectivePUCalcFYForClient(puCalcFy, client);
    return DateFunctions.clientFyEnd(client, fy);
}

export function issueHasRefundings(issueDetailView) {
    return !!issueDetailView.issue.uses.find(u => {
        return u.refundings && u.refundings.length > 0;
    });
}
