import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import * as ModelUtil from '../../lib/ModelUtil';
import * as DateFunctions from '../../lib/DateFunctions';

const RedemptionSchedule = (props) => {
    if(!props.issueDetailView) {
        return <Fragment />;
    }

    const issue = props.issueDetailView.issue;
    let detail = issue.redemption_detail;

    if(!detail) {
        detail = {};
    }

    const scheduled = detail.scheduled || [];
    const optional_refunded = detail.optional_refunded || [];

    const scheduledRows = scheduled.map((item, i) => (
        <tr key={`scheduled-${i}`}>
            <td>{ moment(item.date).format('MM/DD/YYYY') }</td>
            <td className="text-right">{ numeral(item.principal_amount).format('0,000.00') }</td>
            <td className="text-center">{ item.cusip }</td>
        </tr>
    ));

    const optionalRefundedRows = optional_refunded.map((item, i) => {
        let issue;
        if(item.refunding_issue_id) {
            issue = ModelUtil.issueForIssueId(item.refunding_issue_id, props.issues);
        }
        return (<tr key={`optional-refunded-${i}`}>
            <td>{ moment(item.defeasance_date).format('MM/DD/YYYY') }</td>
            <td>{ moment(item.redemption_date).format('MM/DD/YYYY') }</td>
            <td className="text-right">{ numeral(item.principal_amount).format('0,000.00') }</td>
            <td className="text-center">{ item.cusip }</td>
            <td>{ issue ? <Link to={`/clients/${issue.client_id}/issues/${issue.issue_id}`}>{ issue.short_name }</Link> : '' }</td>
            <td>{ item.refunding_notes }</td>
        </tr>);
    });

    const originalPar = issue.par_amount || 0;

    const fySequence = DateFunctions.fySequence(
        issue.dated_date,
        moment(),
        props.client.fy_end_month,
        props.client.fy_end_day
    );

    const totalRows = fySequence.slice(0, -1).map((date, i) => {
        const dateStr = moment(date).format('YYYY-MM-DD');
        const dateOriginal = scheduled
            .filter(r => r.date <= dateStr)
            .reduce((carry, r) => carry + r.principal_amount, 0);
        
        const dateOptional = optional_refunded
            .filter(r => r.redemption_date <= dateStr)
            .reduce((carry, r) => carry + r.principal_amount, 0);

        const dateDefeased = optional_refunded
            .filter(r => r.defeasance_date <= dateStr)
            .reduce((carry, r) => carry + r.principal_amount, 0);

        const dateRedemptions = dateOriginal + dateOptional;
        const currentOutstandingPar = originalPar - (dateRedemptions);

        return (
            <tr key={`total-${i}`}>
                <td>{ moment(date).format('MM/DD/YYYY') }</td>
                <td className="text-right">{ numeral(dateDefeased).format('0,000.00') }</td>
                <td className="text-right">{ numeral(dateRedemptions).format('0,000.00') }</td>
                <td className="text-right">{ numeral(currentOutstandingPar).format('0,000.00') }</td>
            </tr>
        );
    });

    return (
        <div className="row">
            <div className="col-3">
                <table className="table grid-style-table table-hover table-condensed">
                    <thead>
                        <tr className="bottom-dotted-border">
                            <th>Date</th>
                            <th className="text-right">Principal</th>
                            <th className="text-center">CUSIP</th>
                        </tr>
                    </thead>
                    <tbody>
                        { scheduledRows }
                    </tbody>
                </table>
            </div>

            <div className="col-5">
                <table className="table grid-style-table table-hover table-condensed">
                    <thead>
                        <tr className="bottom-dotted-border">
                            <th>Def. Date</th>
                            <th>Red. Date</th>
                            <th className="text-right">Principal</th>
                            <th className="text-center">CUSIP</th>
                            <th>Ref. Issue</th>
                            <th>Ref. Notes</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        { optionalRefundedRows }
                    </tbody>
                </table>
            </div>

            <div className="col-4">
                <table className="table grid-style-table table-hover table-condensed">
                    <thead>
                        <tr className="bottom-dotted-border">
                            <th>Date</th>
                            <th className="text-right">Total Defeased</th>
                            <th className="text-right">Total Redeemed</th>
                            <th className="text-right">Total Outstanding</th>
                        </tr>
                    </thead>
                    <tbody>
                        { totalRows }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const mapStateToProps = state => (
    {
        issueDetailView: state.issues.detailView,
        issues: state.issues.list,
        client: state.client.client,
    }
);

export default connect(mapStateToProps)(RedemptionSchedule);