import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

const ProjectBenefitListing = (props) => {
    if(!props.projectBenefit || !props.projectBenefit.project || !props.projectBenefit.project.issue) {
        return '';
    }
    const fy = props.fy;
    const projectBenefit = props.projectBenefit;
    const project = projectBenefit.project;

    const costDetail = project && project.fy_cost_detail && project.fy_cost_detail[fy] ? numeral(project.fy_cost_detail[fy]).value() : 0;
    const equityDetail = project && project.fy_equity_detail && project.fy_equity_detail[fy] ? numeral(project.fy_equity_detail[fy]).value() : 0;

    return (
        <tr>
            <td className="pl-3 text-left">
                <Link to={ `/clients/${project.issue.client_id}/issues/${project.issue.issue_id}` }>
                    { project.issue.short_name }
                </Link>
            </td>
            <td className="text-left">
                { project.name }
            </td>
            <td>{ numeral(costDetail).format('$0,000.00') }</td>
            <td>{ numeral(equityDetail).format('$0,000.00') }</td>
            <td>{ numeral(costDetail + equityDetail).format('$0,000.00') }</td>
        </tr>
    );
}

ProjectBenefitListing.propTypes = {
    fy: PropTypes.number.isRequired,
    projectBenefit: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
};

export default ProjectBenefitListing;