import { runApiCall } from './api-config';

function formatData(data) {
    return {
        asset_id: data.asset_id,
        parent_space_id: data.parent_space_id,
        name: data.name,
        space_code: data.space_code,
        total_size: data.total_size,
        common_size: data.common_size,
        usage_numeraire_id: data.usage_numeraire_id,
        usage_denominator: data.usage_numeraire_id ? data.usage_denominator : null,
        total_size_detail: data.total_size_detail ? JSON.stringify(data.total_size_detail) : null,
        common_size_detail: data.common_size_detail ? JSON.stringify(data.common_size_detail) : null,
    };
}

export async function create(data) {
    const res = await runApiCall('POST', 'spaces/', formatData(data));
    return res.data;
}

export async function update(spaceId, data) {
    const res = await runApiCall('PATCH', 'spaces/' + spaceId, formatData(data));
    return res.data;
}

export async function remove(spaceId) {
    const res = await runApiCall('DELETE', 'spaces/' + spaceId);
    return res.data;
}

export async function importCommitStructured(data) {
    const res = await runApiCall('POST', 'spaces/import/commit_structured/', {
        client_id: data.client_id,
        spaces: data.spaces,
        input_data: data.inputData ? JSON.stringify(data.inputData) : null,
    });
    return res.data;
}
