import { runApiCall } from './api-config';

function formatData(data) {
    return {
        ...data
    };
}

export async function create(data) {
    const res = await runApiCall('POST', 'clients/', formatData(data));
    return res.data;
}

export async function update(clientId, data) {
    const res = await runApiCall('PATCH', 'clients/' + clientId, formatData(data));
    return res.data;
}

export async function remove(clientId) {
    const res = await runApiCall('DELETE', 'clients/' + clientId);
    return res.data;
}

export async function list() {
    const res = await runApiCall('GET', 'clients/');
    return res.data;
}

export async function issuesWithPuCalcs(clientId, puCalcFy) {
    const res = await runApiCall('GET', `views/client-issues-pu-calcs/${clientId}?pu_calc_fy=${puCalcFy}`);
    return res.data;
}