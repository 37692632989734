import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UseListing from './UseListing';

const UsesTable = (props) => {
    let useRows = props.user.uses.map((u) => (
        <UseListing
            key={u.asset_use_id}
            use={u}
            user={props.user}
            showUseForm={props.showUseForm}
            showAssetUserForm={props.showAssetUserForm} />
        )
    );
    return (
        <table className="table table-data">
            <thead>
                <tr className="bottom-dotted-border">
                    <th style={{width: '18%'}} className="pl-3">Contract</th>
                    <th style={{width: '18%'}}>Asset</th>
                    <th style={{width: '18%'}}>Space</th>
                    <th style={{width: '9%'}} className="text-right">Start</th>
                    <th style={{width: '9%'}} className="text-right">End</th>
                    <th style={{width: '13%'}} className="text-right">Size</th>
                    <th style={{width: '13%'}} className="text-right">Weighted Bad</th>
                    <th style={{width: '2%'}}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                { useRows }
            </tbody>
            <tfoot>

            </tfoot>
        </table>
    );
}

UsesTable.propTypes = {
    user: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired,
    showUseForm: PropTypes.func,
    showAssetUserForm: PropTypes.func,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        numeraires: state.numeraires.numeraires
    }
);

export default connect(mapStateToProps)(UsesTable);