import React, { Component } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import MainNav from './components/MainNav';
import ClientAssets from './pages/ClientAssets';
import ClientAssetUsers from './pages/ClientAssetUsers';
import ClientIssues from './pages/ClientIssues';
import ClientList from './pages/ClientList';
import ClientSettings from './pages/ClientSettings';
import LoadingBar from 'react-redux-loading-bar';
import './App.css';
import ClientResearch2 from './pages/ClientResearch2';
import ClientDashboard from './pages/ClientDashboard';
import ClientNotebook from './pages/ClientNotebook';

class App extends Component {
    render() {
        return (
            <div className="with-iconav">
                <LoadingBar
                    style={{zIndex: 3000, backgroundColor: '#638da2'}}
                    updateTime={25}
                    progressIncrease={20} />
                <MainNav />
                <div className="container-fluid h-100">
                    <Switch>
                        <Route path="/clients/:client_id/assets/:asset_id" component={ClientAssets} />
                        <Route path="/clients/:client_id/assets/" component={ClientAssets} />
                        <Route path="/clients/:client_id/asset_users/:asset_user_id" component={ClientAssetUsers} />
                        <Route path="/clients/:client_id/asset_users/" component={ClientAssetUsers} />
                        <Route path="/clients/:client_id/issues/:issue_id" component={ClientIssues} />
                        <Route path="/clients/:client_id/issues/" component={ClientIssues} />
                        <Route path="/clients/:client_id/research2/" component={ClientResearch2} />
                        <Route path="/clients/:client_id/notebook/:entity_type/:entity_id" component={ClientNotebook} />
                        <Route path="/clients/:client_id/notebook/:entity_type/" component={ClientNotebook} />
                        <Route path="/clients/:client_id/notebook/" component={ClientNotebook} />
                        <Route path="/clients/:number/settings/" component={ClientSettings} />
                        <Route path="/clients/:client_id" component={ClientDashboard} />
                        <Route path="/clients/" component={ClientList} />
                        <Route exact path="/"><Redirect to="/clients/" /></Route>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default App;
