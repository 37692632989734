import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

import Constants from '../lib/Constants';

import TitleBar from '../components/TitleBar';
import AssetDetail from '../components/AssetDetail/AssetDetail';
import SpaceUseModalForm from '../apps/SpaceUseModalForm/SpaceUseModalForm';
import AssetUserModalForm from '../components/AssetUserForms/AssetUserModalForm';
import SpaceModalForm from '../components/SpaceForms/SpaceModalForm';
import AssetModalForm from '../components/AssetForms/AssetModalForm';
import VacancyModalForm from '../components/VacancyForms/VacancyModalForm';
import UseImporterModal from '../apps/UseImporterModal/UseImporterModal';

import UtilityPlantDetail from '../components/UtilityPlantDetail/UtilityPlantDetail';
import UtilityModalForm from '../components/UtilityForms/UtilityModalForm';

import * as AssetActionCreators from '../ducks/assets';
import * as CommonStateActions from '../ducks/common';

class ClientAssets extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingAssetForm: false,
            editingAsset: null,
            showingAssetUserForm: false,
            editingAssetUser: null,
            showingSpaceUseForm: false,
            editingSpaceUse: null,
            showingSpaceForm: false,
            editingSpace: null,
            showingVacancyForm: false,
            editingVacancyUse: null,
            searchTerm: null,
            spacePreselectedAssetId: null,
            spaceUsePreselectedAssetUserId: null,
            spaceUsePreselectedAssetId: null,
            showingUseImporterModal: false,
            showingUtilityForm: false,
            editingUtility: null,
        };
    }

    handleSearchInputChange = (event) => {
        const target = event.target;
        const value = target.value;

        this.setState({ searchTerm: value });
    }

    componentDidMount() {
        const urlClientId = parseInt(this.props.match.params.client_id, 10);
        const urlAssetId = this.props.match.params.asset_id ? parseInt(this.props.match.params.asset_id, 10) : null;

        if(!this.props.client || this.props.client.client_id !== urlClientId) {
            this.props.loadClientAndDependencies(urlClientId);
        }

        if(urlAssetId && (!this.props.assetDetailView || this.props.assetDetailView.asset.asset_id !== urlAssetId)) {
            this.props.loadAssetDetailView(urlAssetId, this.props.puCalcFy);
        }
        this.setPageTitle();
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.asset_id !== prevProps.match.params.asset_id) {
            this.props.loadAssetDetailView(this.props.match.params.asset_id, this.props.puCalcFy);
        }
        this.setPageTitle();
    }

    setPageTitle() {
        let title = `${Constants.pageTitleBase} ${Constants.pageTitleSeparator} Assets`;
        if (this.props.assetDetailView) {
            title += ` ${Constants.pageTitleSeparator} ${this.props.assetDetailView.asset.name}`;
        }
        document.title = title;
    }

    numeraireSymbolForNumeraireId = (numeraireId) => {
        let numeraire = this.props.numeraires.find((item) => {
            return item.numeraire_id === numeraireId;
        });

        if (!numeraire) {
            return '';
        }

        return numeraire.symbol;
    }

    showAssetForm = (asset) => {
        this.setState({ showingAssetForm: true, editingAsset: asset ? asset : null });
    }

    hideAssetForm = () => {
        this.setState({ showingAssetForm: false, editingAsset: null });
    }

    showUseForm = (use, preselectedAssetUserId, preselectedAssetId) => {
        this.setState({
            showingSpaceUseForm: true,
            editingSpaceUse: use ? use : null,
            spaceUsePreselectedAssetUserId: preselectedAssetUserId ? preselectedAssetUserId : null,
            spaceUsePreselectedAssetId: preselectedAssetId ? preselectedAssetId : null
        });
    }

    hideUseForm = () => {
        this.setState({
            showingSpaceUseForm: false,
            editingSpaceUse: null,
            spaceUsePreselectedAssetUserId: null,
            spaceUsePreselectedAssetId: null 
        });
    }

    showAssetUserForm = (user) => {
        this.setState({ showingAssetUserForm: true, editingAssetUser: user ? user : null });
    }

    hideAssetUserForm = () => {
        this.setState({ showingAssetUserForm: false, editingAssetUser: null });
    }

    showSpaceForm = (space, preselectedAssetId) => {
        this.setState({
            showingSpaceForm: true,
            editingSpace: space ? space : null,
            spacePreselectedAssetId: preselectedAssetId ? preselectedAssetId : null
        });
    }

    hideSpaceForm = () => {
        this.setState({ showingSpaceForm: false, editingSpace: null });
    }

    showUseImporterModal = () => {
        this.setState({ showingUseImporterModal: true });
    }

    onHideUseImporterModal = () => {
        this.setState({ showingUseImporterModal: false });
    }

    showVacancyForm = (use) => {
        this.setState({ showingVacancyForm: true, editingVacancyUse: use ? use : null });
    }

    hideVacancyForm = () => {
        this.setState({ showingVacancyForm: false, editingVacancyUse: null });
    }

    showUtilityForm = (utility) => {
        this.setState({ showingUtilityForm: true, editingUtility: utility ? utility : null });
    }

    hideUtilityForm = () => {
        this.setState({ showingUtilityForm: false, editingUtility: null });
    }

    currentFy = () => {
        return this.props.puCalcFy && this.props.puCalcFy !== 'latest' ? this.props.puCalcFy : this.props.client.pu_fys[0];
    }

    render() {
        if (!this.props.client || !this.props.numeraires || this.props.numeraires.length === 0 || !this.props.assets)
            return '';

        let assets = this.props.assets;

        if(this.state.searchTerm) {
            let term = this.state.searchTerm.toLowerCase();

            assets = assets.filter((asset, index) => {
                if(
                    asset.name.toLowerCase().includes(term) || 
                    (asset.asset_id.toString() === term.replace(/\D/g, ''))
                ) {
                    return true;
                }
                else {
                    return false;
                }
            });
        }

        let showingAsset = this.props.assetDetailView ? this.props.assetDetailView.asset : null;
        let showingAssetId = showingAsset ? showingAsset.asset_id : null;

        let assetListings = assets.map((asset) => {
            return (
                <Link
                    to={ "/clients/" + this.props.client.client_id + "/assets/" + asset.asset_id }
                    className={ "list-group-item entity-list-item" + (asset.asset_id === showingAssetId ? " active" : '')}
                    key={ asset.asset_id }>
                    <div className="d-flex w-100 justify-content-between">
                        <span className="font-weight-bold">
                            { asset.name }
                        </span>
                        <span className="text-muted">
                            {
                                asset.is_utility_plant
                                    ? 'Utility Plant'
                                    : ''
                            }
                            {
                                !asset.is_utility_plant
                                    ? `${numeral(asset.total_size).format('0,0')} ${this.numeraireSymbolForNumeraireId(asset.size_numeraire_id)}`
                                    : ''
                            }
                        </span>
                    </div>
                    <div className="">
                        &nbsp;
                    </div>
                </Link>
            );
        });

        return (
            <div>
                <TitleBar pageTitle="Assets" clientName={this.props.client.name} />
                
                <AssetModalForm
                    show={this.state.showingAssetForm}
                    onHide={this.hideAssetForm}
                    asset={this.state.editingAsset}
                    showSizeInputs={true} />
                
                <AssetUserModalForm
                    show={this.state.showingAssetUserForm}
                    onHide={this.hideAssetUserForm}
                    clientId={this.props.client.client_id}
                    user={this.state.editingAssetUser} />

                <SpaceModalForm
                    show={this.state.showingSpaceForm}
                    onHide={this.hideSpaceForm}
                    clientId={this.props.client.client_id}
                    assets={this.props.assets}
                    numeraires={this.props.numeraires}
                    space={this.state.editingSpace}
                    client={this.props.client}
                    asset={showingAsset ? showingAsset : null} />

                <SpaceUseModalForm
                    show={this.state.showingSpaceUseForm}
                    assets={this.props.assets}
                    assetUsers={this.props.assetUsers}
                    numeraires={this.props.numeraires}
                    use={this.state.editingSpaceUse}
                    clientId={this.props.client.client_id}
                    onHide={this.hideUseForm}
                    preselectedAssetUserId={this.state.spaceUsePreselectedAssetUserId}
                    preselectedAssetId={showingAsset ? showingAsset.asset_id : null} />
                
                <UseImporterModal
                    show={this.state.showingUseImporterModal}
                    onHide={this.onHideUseImporterModal}
                    asset={showingAsset ? showingAsset : null} />
                
                <VacancyModalForm
                    show={this.state.showingVacancyForm}
                    asset={showingAsset ? showingAsset : null}
                    assets={this.props.assets}
                    numeraires={this.props.numeraires}
                    use={this.state.editingVacancyUse}
                    client={this.props.client}
                    onHide={this.hideVacancyForm} />
                
                <UtilityModalForm
                    show={this.state.showingUtilityForm}
                    onHide={this.hideUtilityForm}
                    utility={this.state.editingUtility}
                    assetDetailView={this.props.assetDetailView}
                    client={this.props.client} />
                
                <div className="clear-title-bar">
                    <div className="entity-list-column" style={{ width: '17%', marginLeft: '-15px' }}>
                        <ul className="list-group rounded-0 list-group-flush p-0">
                            <li className="list-group-item rounded-0 entity-search-item p-2 d-flex w-100 justify-content-between">
                                <input
                                    className="form-control rounded-0"
                                    type="text"
                                    placeholder="Search Assets" 
                                    onChange={ this.handleSearchInputChange } />
                                
                                <a className="btn btn-outline" onClick={() => { this.showAssetForm(); }}>
                                    <i className="icon icon-plus" /> Add Asset
                                </a>
                            </li>
                            { assetListings }
                        </ul>
                    </div>

                    <div className="clear-entity-list-column">
                        <div className="pl-3 pr-3 pb-3">
                            {
                                showingAsset && !showingAsset.is_utility_plant
                                    ? <AssetDetail 
                                        fy={this.currentFy()}
                                        assetDetailView={this.props.assetDetailView}
                                        numeraires={this.props.numeraires}
                                        showUseForm={this.showUseForm}
                                        showVacancyForm={this.showVacancyForm}
                                        showAssetUserForm={this.showAssetUserForm}
                                        showAssetForm={this.showAssetForm}
                                        showSpaceForm={this.showSpaceForm}
                                        showResearchContainerForm={this.showResearchContainerForm}
                                        showUseImporterModal={this.showUseImporterModal}
                                        client={this.props.client} />
                                    : ''
                            }
                            {
                                showingAsset && showingAsset.is_utility_plant
                                    ? <UtilityPlantDetail
                                        assets={this.props.assets}
                                        assetDetailView={this.props.assetDetailView}
                                        showAssetForm={this.showAssetForm}
                                        showUtilityForm={this.showUtilityForm} />
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ClientAssets.propTypes = {
    client: PropTypes.object,
    numeraires: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = state => (
    {
        assets: state.assets.assets,
        assetUsers: state.asset_users.list,
        assetDetailView: state.assets.detailView,
        client: state.client.client,
        clientIsLoading: state.client.clientIsLoading,
        clientHasErrored: state.client.clientHasErrored,
        clientErrorMessage: state.client.clientErrorMessage,
        puCalcFy: state.client.puCalcFy,
        numeraires: state.numeraires.numeraires,
        notes: state.notes.notes,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadClientAndDependencies: (clientId) => CommonStateActions.loadClientAndDependencies(dispatch, clientId),
        loadAssetDetailView: (assetId, puCalcFy) => dispatch(AssetActionCreators.loadAssetDetailView(assetId, puCalcFy)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAssets);
