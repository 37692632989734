import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FileTreeViewFile from './FileTreeViewFile';

class FileTreeViewFolder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false
        };
    }

    toggle = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const leftMargin = this.props.leftMargin ? this.props.leftMargin : 0;

        let contentsList = [];
        if(this.state.expanded) {
            contentsList = this.props.folder.contents.map((item, i) => {
                if(item.nd_container_id) {
                    return (
                        <FileTreeViewFolder
                            folder={item}
                            leftMargin={leftMargin + 15}
                            selectedFileId={this.props.selectedFileId}
                            onFileSelect={this.props.onFileSelect}
                            key={i} />
                    );
                }
                else {
                    return (
                        <FileTreeViewFile
                            file={item}
                            leftMargin={30}
                            key={i}
                            onFileSelect={this.props.onFileSelect}
                            selected={item.nd_document_id === this.props.selectedFileId} />
                    );
                }
            });
        }

        return (
            <div style={{marginLeft: leftMargin + 'px'}}>
                <div onClick={this.toggle} className="pointer-hover">
                    <i className={"icon " + (this.state.expanded ? "icon-chevron-down" : "icon-chevron-right") }></i>&nbsp;
                    <i className="icon icon-folder"></i>&nbsp;
                    { this.props.folder.name }
                </div>
                { contentsList }
            </div>
        );
        
    }
}

FileTreeViewFolder.propTypes = {
    folder: PropTypes.object.isRequired,
    leftMargin: PropTypes.number,
    onFileSelect: PropTypes.func,
    selectedFileId: PropTypes.string,
};

export default FileTreeViewFolder;