import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { runApiCall } from '../api/api-config';
import * as AssetActionCreators from './assets';
import * as AssetUserActionCreators from './asset_users';
import * as IssueActionCreators from './issues';
import * as DataImportActionCreators from './data_imports';
import * as Common from './common';

const IS_LOADING = 'client/IS_LOADING';
const HAS_LOADED = 'client/HAS_LOADED';
const HAS_ERRORED = 'client/HAS_ERRORED';
const SET_PU_FY = 'client/SET_PU_FY';
const CLEAR_DATA_EXCEPTIONS = 'client/CLEAR_DATA_EXCEPTIONS';

const initialState = {
    client: null,
    data_exceptions: null,
    clientIsLoading: false,
    clientHasErrored: false,
    clientErrorMessage: null,
    puCalcFy: 'latest',
};

export default function clientReducer(state = initialState, action = {}) {
    switch (action.type) {
        case IS_LOADING:
            return {
                ...state,
                clientIsLoading: true,
                clientHasErrored: false,
                clientErrorMessage: null,
            };
        case HAS_LOADED:
            return {
                ...state,
                client: action.client,
                data_exceptions: action.exceptions,
                puCalcFy: action.puCalcFy,
                clientIsLoading: false,
                clientHasErrored: false,
                clientErrorMessage: null,
            };
        case HAS_ERRORED:
            return {
                ...state,
                client: null,
                data_exceptions: null,
                clientIsLoading: false,
                clientHasErrored: true,
                clientErrorMessage: action.errorMessage,
            };
        case SET_PU_FY:
            return {
                ...state,
                puCalcFy: action.fy,
            };
        case CLEAR_DATA_EXCEPTIONS:
            return {
                ...state,
                data_exceptions: null
            };
        default:
            return state;
    }
}

export function clientIsLoading() {
    return { type: IS_LOADING };
}

export function clientHasLoaded(client, puCalcFy, exceptions) {
    return { type: HAS_LOADED, client: client, puCalcFy: puCalcFy, exceptions: exceptions };
}

export function clientHasErrored(errorMessage) {
    return { type: HAS_ERRORED, errorMessage: errorMessage };
}

export function setPuFy(fy) {
    return { type: SET_PU_FY, fy: parseInt(fy, 10) };
}

export function setPuFyAndLoadClient(fy, clientId) {
    return (dispatch, getState) => {
        dispatch(setPuFy(fy));
        dispatch(loadClient(clientId));
        Common.reloadDetailViews(dispatch, getState(), fy);
    }
}

export function clearDataExceptions() {
    return { type: CLEAR_DATA_EXCEPTIONS };
}

export function loadClient(clientId, onLoad) {
    return (dispatch, getState) => {
        dispatch(clientIsLoading());
        dispatch(showLoading());
        dispatch(clearDataExceptions());

        const state = getState();

        let puCalcFy;
        if (state.client && state.client.client && (parseInt(clientId, 10) !== state.client.client.client_id)) {
            puCalcFy = 'latest';
            // TODO: add common task for this
            dispatch(AssetActionCreators.switchClient());
            dispatch(AssetUserActionCreators.switchClient());
            dispatch(IssueActionCreators.switchClient());
            dispatch(DataImportActionCreators.switchClient());
        }
        else {
            puCalcFy = state.client.puCalcFy ? state.client.puCalcFy : 'latest';
        }

        runApiCall('GET', 'views/client-with-exceptions/' + clientId)
            .then(response => {
                dispatch(clientHasLoaded(response.data.client, puCalcFy, response.data.data_exceptions));
                dispatch(hideLoading());
                
                //dispatch(loadIssues(clientId));
                if(typeof onLoad === 'function') {
                    onLoad(response.data.client);
                }
            })
            .catch(error => {
                dispatch(hideLoading());
                dispatch(clientHasErrored(error));
            });
    }
}
