import React, { Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ModalWrapper = props => {
  return (
    <Modal isOpen={ props.modalIsVisible } toggle={ props.toggle } className={ props.className } backdrop="static" keyboard={ false }>
      <ModalHeader toggle={ props.toggle }>
        <span className="font-weight-bold">{ props.title }</span>
      </ModalHeader>
      <ModalBody>
        { props.children }
      </ModalBody>
      {
        props.actionButtons ? <div className="modal-actions">{ props.actionButtons }</div> : <Fragment />
      }
    </Modal>
  );
  
};

export default ModalWrapper;