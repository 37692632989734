import React from 'react';
import PropTypes from 'prop-types';

const IssueFormFields = (props) => {
    return (
        <div className="row">
            <div className="col-6">
                <div className="form-group row">
                    <label className="col-3 col-form-label text-muted text-uppercase">Short Name</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.short_name}
                            name="short_name" />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-3 col-form-label text-muted text-uppercase">C/M #</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.client_matter_number}
                            name="client_matter_number" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Par Amount</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.par_amount}
                            name="par_amount" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Trans 1</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.trans_1}
                            name="trans_1" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Trans 2</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.trans_2}
                            name="trans_2" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Trans 3</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.trans_3}
                            name="trans_3" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Trans 4</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.trans_4}
                            name="trans_4" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Trans 5</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.trans_5}
                            name="trans_5" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Trans 6</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.trans_6}
                            name="trans_6" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Equity Method</label>
                    <div className="col-9">
                        <select
                            className="custom-select rounded-0 w-100 form-control"
                            name="legacy_equity"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.legacy_equity}>
                            <option value="0">Floating</option>
                            <option value="1">Discrete</option>
                        </select>
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Org. Type</label>
                    <div className="col-9">
                        <select
                            className="custom-select rounded-0 w-100 form-control"
                            name="governmental"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.governmental}>
                            <option value="0">501 (c) (3)</option>
                            <option value="1">Governmental</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="form-group row">
                    <label className="col-3 col-form-label text-muted text-uppercase">Delivery Date</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.dated_date}
                            name="dated_date" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Final Maturity</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.maturity_date}
                            name="maturity_date" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Original Prem./Disc.</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.original_premium}
                            name="original_premium" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Issuer</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.issuer_name}
                            name="issuer_name" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">Issuer EIN</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.issuer_ein}
                            name="issuer_ein" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">OS/Othr Box Link</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.box_link_os}
                            name="box_link_os" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">TC Box Link</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.box_link_tc}
                            name="box_link_tc" />
                    </div>
                </div>

                <div className="form-group row mt-4">
                    <label className="col-3 col-form-label text-muted text-uppercase">8038 Box Link</label>
                    <div className="col-9">
                        <input
                            type="text"
                            className="form-control rounded-0"
                            onChange={props.handleInputChange}
                            value={props.fieldValues.box_link_8038}
                            name="box_link_8038" />
                    </div>
                </div>
            </div>
        </div>
        
    );
}

IssueFormFields.propTypes = {
    handleInputChange: PropTypes.func.isRequired,
    fieldValues: PropTypes.object.isRequired,
    puFys: PropTypes.array.isRequired,
}

export default IssueFormFields;
