import { runApiCall } from './api-config';

function formatData(data) {
    return {
        issue_id: data.issueId,
        name: data.name,
        blending_mode: data.blending_mode,
        fy_cost_detail: data.costDetail ? JSON.stringify(data.costDetail) : null,
        fy_equity_detail: data.equityDetail ? JSON.stringify(data.equityDetail) : null,
        benefits: data.benefits,
        delete_benefits: data.deleteBenefits,
    };
}

export async function create(data) {
    const res = await runApiCall('POST', 'projects/', formatData(data));
    return res.data;
}

export async function update(projectId, data) {
    const res = await runApiCall('PATCH', 'projects/' + projectId, formatData(data));
    return res.data;
}

export async function remove(projectId) {
    const res = await runApiCall('DELETE', 'projects/' + projectId);
    return res.data;
}

export async function importCommitStructured(data) {
    const res = await runApiCall('POST', 'projects/import/commit_structured/', {
        client_id: data.client_id,
        project_benefits: data.project_benefits,
        input_data: data.inputData ? JSON.stringify(data.inputData) : null,
    });
    return res.data;
}

export async function updateExpenditureData(data) {
    const res = await runApiCall('POST', 'projects/import/expenditures/', data);
    return res.data;
}
