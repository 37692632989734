import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as NoteActionCreators from '../../../ducks/notes';
import * as NoteApi from '../../../api/NoteApi';

class PostNoteForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submittingForm: false,
            fieldValues: {},
        };
    }

    handleFormSubmit = (event) => {
        event.preventDefault();

        if(!this.state.submittingForm) {
            this.setState({ submittingForm: true });
            NoteApi.create({
                ...this.state.fieldValues,
                client_id: this.props.client.client_id,
                entity_type: this.props.entityType,
                entity_section: this.props.entitySection,
                entity_id: this.props.entityId,
            }).then(() => {
                this.props.loadNotes(this.props.client.client_id, (foo) => {
                    this.setState({ submittingForm: false, fieldValues: {} });
                    if(typeof this.props.onPosting === 'function')
                        this.props.onPosting();
                });
            });
        }

        
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let fieldValues = this.state.fieldValues;
        fieldValues[name] = value;

        this.setState({ fieldValues: fieldValues });
    }

    render() {
        /*
        <button className="btn btn-sm btn-outline-secondary rounded-0" type="button">
            <span className="icon icon-plus"></span>
            Add Attachment
        </button>
        */

        return (
            <form onSubmit={this.handleFormSubmit}>
                <div>
                    <textarea
                        className="form-control form-control-sm w-100 rounded-0"
                        placeholder="Write something..."
                        name="text"
                        onChange={this.handleInputChange}
                        value={this.state.fieldValues.text ? this.state.fieldValues.text : ''}
                        rows="3"></textarea>
                </div>
                <div className="justify-content-between w-100 d-flex mt-1">
                    <div></div>
                    <button
                        className="btn btn-secondary btn-sm rounded-0"
                        type="submit"
                        disabled={this.state.submittingForm}>
                        { this.state.submittingForm ? 'Posting...' : 'Post'}
                    </button>
                </div>
            </form>
        );
    }

}

PostNoteForm.propTypes = {
    entityType: PropTypes.string,
    entityId: PropTypes.number,
    entitySection: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        client: state.client.client,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadNotes: (clientId, onLoad) => dispatch(NoteActionCreators.loadNotes(clientId, onLoad)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PostNoteForm);
