import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import Constants from '../../lib/Constants';
import * as IssueActionCreators from '../../ducks/issues';

class EscrowDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableContent: [[]],
            importing: false,
            justImported: false,
            show: false,
            inputError: null,
        };
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps.show !== this.props.show) {
            this.setState({ show: this.props.show });
        }

        if(this.props.escrowUse &&
            (!prevProps.escrowUse || prevProps.escrowUse.issue_use_id !== this.props.escrowUse.issue_use_id)) {
            this.setTableContentFromProps();
        }
    }

    setTableContentFromProps = () => {
        if(this.props.escrowUse && this.props.escrowUse.refundings) {
            let tableContent = [[]];
            
            if(this.props.escrowUse.refundings.length > 0) {
                tableContent = this.props.escrowUse.refundings.map((r) => {
                    return [
                        r.issue.short_name,
                        r.par_refunded,
                        r.refunding_type,
                        r.id
                    ];
                });
            }
            
            this.setState({ tableContent });
        }
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding && typeof this.props.onHide === 'function')
            this.props.onHide();
    }

    handleTableChange = (event, source) => {
        if(!event) {
            return;
        }

        let tableContent = this.state.tableContent;

        event.map((change) => {
            let i = change[0];
            let j = change[1];

            if(!tableContent[i]) {
                tableContent[i] = [];
            }

            tableContent[i][j] = change[3];

            return null;
        });

        this.setState({ tableContent: tableContent });
    }

    structureRefundingObjects = (tableContent) => {
        return tableContent
            .filter((row) => {
                return row[0];
            })
            .map((row) => {
                const issue = this.props.issues.find((i) => {
                    return i.short_name === row[0];
                });
                return {
                    issue_id: issue ? issue.issue_id : null,
                    par_refunded: row[1],
                    refunding_type: row[2],
                    id: row[3]
                };
            });
    }

    hasContentInTable = () => {
        return this.state.tableContent.length > 0 && this.state.tableContent[0].length > 0;
    }

    importRefundings = () => {
        
        if(this.hasContentInTable() && !this.state.importing) {
            //console.log('import refundings', this.structureRefundingObjects(this.state.tableContent));
            //return;
            this.setState({ importing: true });

            this.props
                .updateRefundings(
                    this.props.escrowUse.issue_id,
                    this.props.escrowUse.issue_use_id,
                    this.structureRefundingObjects(this.state.tableContent)
                )
                .then(() => {
                    this.setState({ importing: false, justImported: true });
                    this.toggle();
                    if (typeof this.props.onHide === 'function') {
                        this.props.onHide();
                    }
                })
                .catch((error) => {
                    this.setState({ importing: false });
                    alert(error);
                });
        }
    }

    clearForm = () => {
        this.setState({
            tableContent: [[]],
            importing: false,
            justImported: false,
        });
    }

    render() {
        if(!this.props.escrowUse) {
            return '';
        }

        const use = this.props.escrowUse;

        let issueOptions = this.props.issues
            .filter((i) => {
                return i.issue_id !== use.issue_id;
            })
            .map((i) => {
                return i.short_name;
            });
        issueOptions.unshift('');

        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">{use.name} Escrow Details</span>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-12">
                            <div style={{height: '250px', overflowY: 'hidden'}}>
                                <HotTable
                                    root={ "hotEscrowDetails" }
                                    data={ this.state.tableContent }
                                    contextMenu={true}
                                    columns={[
                                        { type: 'dropdown', source: issueOptions, width: 130 },
                                        { type: 'numeric', numericFormat: { pattern: '0,0.00' }, correctFormat: true, className: "htRight", width: 130 },
                                        { type: 'dropdown', source: ['current', 'advance'], width: 130 },
                                        { type: 'text' }
                                    ]}
                                    colHeaders={[
                                        'Bond Issue',
                                        'Par Refunded',
                                        'Type',
                                        ''
                                    ]}
                                    rowHeaders={ true }
                                    minRows={ 5 }
                                    afterChange={this.handleTableChange}
                                    licenseKey={Constants.hotLicenseKey}
                                    hiddenColumns={{ columns: [3], indicators: false }} />
                            </div>
                        </div>
                    </div>
                    <div className="text-right">
                        <button className="btn btn-outline-secondary rounded-0" onClick={this.toggle}>
                            Close
                        </button>
                        &nbsp;&nbsp;
                        <button className="btn btn-success rounded-0" onClick={this.importRefundings} disabled={!this.hasContentInTable}>
                            {
                                this.state.importing
                                    ? 'Saving...'
                                    : 'Save'
                            }
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

EscrowDetailsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
    escrowUse: PropTypes.object,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        issues: state.issues.list,
        puCalcFy: state.client.puCalcFy,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        updateRefundings: (issueId, issueUseId, refundings) => dispatch(IssueActionCreators.updateUseRefundings(issueId, issueUseId, refundings))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EscrowDetailsModal);