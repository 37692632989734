import { Component } from 'react';

class SpaceForm extends Component {
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let fieldValues = this.state.fieldValues;
        fieldValues[name] = value;
        
        if (name === 'asset_id') {
            fieldValues.parent_space_id = null;
        }
        
        this.setState({ fieldValues: fieldValues });
    }

    saveFunc = (data) => {
        this.toggle();
        this.setState({ submittingForm: false });
        if (typeof this.props.onSave === 'function') {
            this.props.onSave(data.space);
        }
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ submittingForm: true });

        if(!this.props.space) {
            this.props.createSpace(this.state.fieldValues).then(this.saveFunc);
        }
        else {
            this.props.updateSpace(this.props.space.space_id, this.state.fieldValues).then(this.saveFunc);
        }
    }

    handleDeleteClick = (event) => {
        let confirmed = window.confirm('Are you sure you want to delete ' + this.props.space.name + '?');

        if(confirmed) {
            this.setState({ submittingForm: true });

            this.props.removeSpace(this.props.space.space_id).then(this.saveFunc);
        }
    }

    handleSizeDetailInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const arr = name.split('-');
        const fy = arr[1];
        const type = arr[2];
                
        let fieldValues = this.state.fieldValues;
        
        if(type === 'total') {
            let totalSizeDetail = this.state.fieldValues.totalSizeDetail;
            totalSizeDetail[fy] = value;

            fieldValues.totalSizeDetail = totalSizeDetail;
        }
        
        if(type === 'common') {
            let commonSizeDetail = this.state.fieldValues.commonSizeDetail;
            commonSizeDetail[fy] = value;
            
            fieldValues.commonSizeDetail = commonSizeDetail;
        }
        
        this.setState({ fieldValues: fieldValues });
    }

    selectAsset = (asset_id) => {
        let fieldValues = this.state.fieldValues;
        fieldValues.asset_id = asset_id;
        fieldValues.parent_space_id = null;
        this.setState({ fieldValues: fieldValues });
    }
}

export default SpaceForm;