import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import _ from 'lodash';
import moment from 'moment';
import { extendMoment } from 'moment-range';
import numeral from 'numeral';
import * as AssetUseActionCreators from '../../ducks/asset_uses';
import Constants from '../../lib/Constants';
import * as ModelUtil from '../../lib/ModelUtil';

class UseImporterModal extends Component {
    constructor(props) {
        extendMoment(moment);

        super(props);

        this.state = {
            tableContent: [[]],
            parsedUsers: [],
            importing: false,
            justImported: false,
            pastedTsv: null,
            preview: [],
            show: false,
            originalUsesCount: null,
            consolidatedUsesCount: null,
        };
    }

    toggle = () => {
        if(this.props.show && typeof this.props.onHide === 'function')
            this.props.onHide();
    }

    collapseContiguousUses = (uses) => {
        let consolidated = [];
        let consolidatedKeys = [];

        // consolidate uses that use the same space, that are also the same size, into single objects
        uses = uses.map((use) => {
            if (!use.space) {
                use.space = null;
            }
            if (!use.size) {
                use.size = null;
            }

            return use;
        });

        uses.forEach((use) => {
            const key = [use.asset, use.space, use.subspace, use.sub_subspace, use.size].join('||');
            let index = consolidatedKeys.indexOf(key);

            if(index < 0 || use.days_of_use) {
                index = consolidatedKeys.length;

                consolidatedKeys[index] = key;
                consolidated[index] = {
                    user_name: use.user_name,
                    contract_type: use.contract_type,
                    asset: use.asset,
                    space: use.space,
                    subspace: use.subspace,
                    sub_subspace: use.sub_subspace,
                    size: use.size,
                    size_numeraire: use.size_numeraire,
                    date_ranges: [
                        { start: use.start_date, end: use.end_date },
                    ],
                    days_of_use: use.days_of_use
                };
            }
            else {
                consolidated[index].date_ranges.push({ start: use.start_date, end: use.end_date });
            }
        });

        // turn the date strings into moment objects so we only have to do this once
        consolidated = consolidated.map((use) => {
            use.date_ranges = use.date_ranges.map((range) => {
                return { start: moment(range.start), end: moment(range.end) };
            });

            return use;
        });

        consolidated = consolidated.map((use) => {
            let newDateRanges = [];

            use.date_ranges.forEach((range) => {
                const overlappingRangeIdx = newDateRanges.findIndex((existing) => {
                    const existingRange = moment.range(existing.start, existing.end);
                    // add 1 day on either end of range we're testing to widen adjancency that's considered "overlapping"
                    let start = new moment(range.start);
                    let end = new moment(range.end);
                    start.subtract(1, 'days');
                    end.add(1, 'days');
                    const newRange = moment.range(start, end);

                    return existingRange.adjacent(newRange, { adjacent: true });
                });
                
                if(overlappingRangeIdx < 0) {
                    newDateRanges.push({ start: range.start, end: range.end });
                }
                else {
                    const overlappingStart = newDateRanges[overlappingRangeIdx].start;
                    const overlappingEnd = newDateRanges[overlappingRangeIdx].end;

                    if(range.start.isBefore(overlappingStart)) {
                        newDateRanges[overlappingRangeIdx].start = range.start;
                    }

                    if(range.end.isAfter(overlappingEnd)) {
                        newDateRanges[overlappingRangeIdx].end = range.end;
                    }
                }
            });

            use.new_date_ranges = newDateRanges;

            return use;
        });

        let collapsed = [];
        consolidated.forEach((use) => {
            use.new_date_ranges.forEach((range) => {
                collapsed.push({
                    user_name: use.user_name,
                    contract_type: use.contract_type,
                    asset: use.asset,
                    space: use.space,
                    subspace: use.subspace,
                    sub_subspace: use.sub_subspace,
                    size: use.size,
                    start_date: range.start,
                    end_date: range.end,
                    size_numeraire: use.size_numeraire,
                    days_of_use: use.days_of_use,
                });
            });
        });
        
        return collapsed;
    }

    parseUsersUses = () => {
        let tableContent = this.state.tableContent;

        let userKeys = [];
        let users = [];
        let originalUsesCount = 0;
        let consolidatedUsesCount = 0;

        tableContent.forEach((row) => {
            if (!row[0])
                return;
            
            const key = [row[0], row[1]].join('||');
            let index = userKeys.indexOf(key);

            // add user if it hasn't already been seen
            if(index < 0) {
                index = userKeys.length;

                userKeys[index] = key;
                users[index] = {
                    key: key,
                    name: row[0],
                    type: row[1],
                    uses: [],
                };
            }
            
            // add use to user
            users[index].uses[users[index].uses.length] = {
                user_name: row[0],
                contract_type: row[1],
                start_date: row[2],
                end_date: row[3],
                asset: row[4],
                space: row[5],
                subspace: row[6],
                sub_subspace: row[7],
                size: row[8] !== '' ? numeral(row[8]).value() : null,
                size_numeraire: row[9] ? row[9] : null,
                days_of_use: row[10] ? numeral(row[10]).value() : null
            };

            originalUsesCount++;
        });

        users.forEach((user) => {
            user.uses = this.collapseContiguousUses(user.uses);
            consolidatedUsesCount += user.uses.length;
        });
        
        const uses = _.flatMap(users, (user) => {
            return user.uses;
        });

        this.setState({
            parsedUsers: users,
            parsedUses: uses,
            originalUsesCount: originalUsesCount,
            consolidatedUsesCount: consolidatedUsesCount
        });
    }

    handleTableChange = (event, source) => {
        if(!event) {
            return;
        }

        //console.log(event, source);
        // event change format: [0, 0, null, "Aloha Tortilla"]
        let tableContent = this.state.tableContent;

        event.map((change) => {
            let i = change[0];
            let j = change[1];

            if(!tableContent[i]) {
                tableContent[i] = [];
            }

            tableContent[i][j] = change[3];

            return null;
        });

        this.setState({ tableContent: tableContent }, () => { this.parseUsersUses(); });
    }

    objDateDiff = (a, b) => {
        let diff = 0;

        if(a.start_date && b.start_date){
            diff = moment(a.start_date).diff(b.start_date, 'days');

            if(diff === 0 && a.end_date && b.end_date){
                diff = moment(a.end_date).diff(b.end_date, 'days');
            }
        }

        return diff;
    }

    importUses = () => {
        if(this.state.parsedUsers.length > 0) {
            //console.log(this.state.parsedUses);
            //return;
            this.setState({ importing: true });

            this.props
                .importUsers({
                    client_id: this.props.client.client_id,
                    fy: ModelUtil.effectivePUCalcFYForClient(this.props.puCalcFy, this.props.client),
                    uses: this.state.parsedUses,
                    input_data: this.state.tableContent,
                })
                .then(() => {
                    this.setState({ importing: false, justImported: true });
                    this.toggle();
                })
                .catch((error) => {
                    this.setState({ importing: false });
                    alert(error);
                });
        }
    }

    clearForm = () => {
        this.setState({
            tableContent: [[]],
            parsedUsers: [],
            importing: false,
            justImported: false,
        });
    }

    render() {
        let userRows = [];
        /*this.state.parsedUsers.map((user, index) => (
            <ContractPreviewRows key={index} user={user} client={this.props.client} />
        ));*/

        let spaceCellOptions = [];
        if (this.props.asset) {
            spaceCellOptions = this.props.asset.spaces.map((s) => {
                //return this.props.client.acronym.toLowerCase() + '.a' + s.asset_id + '.s' + s.space_id + ': ' + s.name;
                return s.name;
            });
            spaceCellOptions.unshift('');
        }

        let contractTypeOptions = [
            'lease-non-exempt',
            'management-contract',
            'research-non-exempt',
            'other-line-4',
            'unrelated-income',
            'lease-exempt',
            'research-exempt',
            'other-line-5',
            'non-pu',
            'research-other',
            'management-contract-compliant',
        ];

        let numeraireOptions = this.props.numeraires.map((numeraire) => {
            return numeraire.symbol;
        });

        return (
            <Modal isOpen={this.props.show} toggle={this.toggle} className="modal-xl" backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">Import Uses</span>
                </ModalHeader>
                <ModalBody>
                    <p className="font-weight-bold">
                        Please enter every one of the client's contracts, regardless of whether it includes private use
                    </p>
                    <div className="row">
                        <div className="col-12">
                            <div style={{height: '550px', overflowY: 'hidden'}}>
                                <HotTable
                                    root={ "hotImportUses" }
                                    data={ this.state.tableContent }
                                    contextMenu={true}
                                    columns={[
                                        { type: 'text', width: 110 },
                                        { type: 'dropdown', source: contractTypeOptions, width: 130 },
                                        { type: 'text', className: "htRight", width: 75 },
                                        { type: 'text', className: "htRight", width: 75 },
                                        { type: 'text', width: 125 },
                                        { type: 'text', width: 110 },
                                        { type: 'text', width: 110 },
                                        { type: 'text', width: 110 },
                                        { type: 'numeric', numericFormat: { pattern: '0,0.00' }, correctFormat: true },
                                        { type: 'dropdown', source: numeraireOptions, width: 105 },
                                        { type: 'text', width: 80 },
                                    ]}
                                    minRows={20}
                                    colHeaders={[
                                        'User',
                                        'Contract Type',
                                        'Start Date',
                                        'End Date',
                                        'Asset Name or Code',
                                        'Space (opt.)',
                                        'Subspace (opt.)',
                                        'Sub-subspace (opt.)',
                                        'Use Size (opt.)',
                                        'Numeraire (opt.)',
                                        '# Days (opt.)'
                                    ]}
                                    rowHeaders={ true }
                                    //afterPaste={ (event, source) => { console.log('paste', event, source); } }
                                    afterChange={this.handleTableChange}
                                    licenseKey={Constants.hotLicenseKey} />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-6 offset-6">
                            {
                            this.state.parsedUsers.length > 0
                                ? (
                                    <ul className="list-group">
                                        <li className="list-group-item d-flex justify-content-between rounded-0">
                                            <span>Uses Entered</span>
                                            <span>{this.state.originalUsesCount}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between rounded-0">
                                            <span>Consolidated Uses</span>
                                            <span>{this.state.consolidatedUsesCount}</span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between rounded-0">
                                            <span>Users Detected</span>
                                            <span>{this.state.parsedUsers.length}</span>
                                        </li>
                                    </ul>
                                ) : ''
                            }
                            <div className="text-right">
                                {
                                this.state.parsedUsers.length > 0
                                    ? (<button className="btn btn-success rounded-0" onClick={this.importUses}>
                                        {
                                            this.state.importing
                                                ? 'Importing...'
                                                : 'Import Uses'
                                        }
                                    </button>)
                                    : '' 
                                }
                            </div>
                        </div>
                    </div>

                    <div style={{height: 200 + "px", overflowY: "scroll", display: 'none'}} className="mt-5">
                        <table className="table table-condensed w-100 table-hover">
                            <thead>
                                <tr>
                                    <th>User Name</th>
                                    <th>Type</th>
                                    <th>User Type</th>
                                    <th className="text-right">Use Start</th>
                                    <th className="text-right">Use End</th>
                                    <th className="text-right">Days of Use</th>
                                    <th className="text-right">Use Size</th>
                                    <th>Space</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userRows}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

UseImporterModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
    asset: PropTypes.object,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        numeraires: state.numeraires.numeraires,
        puCalcFy: state.client.puCalcFy
    }
);

const mapDispatchToProps = dispatch => {
    return {
        importUsers: (importData) => dispatch(AssetUseActionCreators.importStructured(importData)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UseImporterModal);