import * as AssetUseApi from '../api/AssetUseApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Common from './common';

const CREATE = 'asset_uses/CREATE';
const REMOVE = 'asset_uses/REMOVE';
const UPDATE = 'asset_uses/UPDATE';

const initialState = {

};

export default function assetUsesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case CREATE:
            return state;
        case REMOVE:
            return state;
        case UPDATE:
            return state;
        default:
            return state;
    }
}

export function create(assetUseData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        AssetUseApi.create(assetUseData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, getState());
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function update(assetUseId, assetUseData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        AssetUseApi.update(assetUseId, assetUseData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, getState());
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function remove(assetUseId) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        AssetUseApi.remove(assetUseId)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, getState());
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function importStructured(importData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());
        const state = getState();
        AssetUseApi.importCommitStructured(importData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                Common.reloadAssetList(dispatch, state);
                Common.reloadAssetUserList(dispatch, state);
                Common.reloadDataImportList(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}
