import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Part6List = ({
    onPart6TextChange,
    onPart6FieldBlur,
    onDeletePart6OtherItem,
    onAddPart6OtherItem,
    onDragEnd,
    newPart6ItemInput,
    part6Items
}) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        <ul className="list-group border-0">
                            <li className="list-group-item rounded-0 border-left-0 border-right-0 d-flex justify-content-between bg-light">
                                <textarea
                                    rows={4}
                                    style={{width: '90%', marginRight: '10px'}}
                                    name={`part6-text-other`}
                                    ref={newPart6ItemInput}></textarea>
                                <div className="text-right">
                                    <button type="button" className="btn btn-sm btn-primary" onClick={ onAddPart6OtherItem }>Add</button>
                                </div>
                            </li>
                            {
                            part6Items.map((item, index) => (
                                <Draggable key={`part6-${item.key}`} draggableId={`part6-${item.key}`} index={index}>
                                    {(provided, snapshot) => (
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="list-group-item rounded-0 border-left-0 border-right-0 bg-white"
                                            key={`part6-li-${item.index}`}>
                                            {
                                                item.question ? <div className="text-muted font-weight-bold small text-uppercase">System Prompt</div> : <></>
                                            }
                                            <div className="d-flex justify-content-between">
                                                <textarea
                                                    className="part6-item-textarea"
                                                    defaultValue={item.text}
                                                    onChange={onPart6TextChange}
                                                    onBlur={onPart6FieldBlur}
                                                    style={{width: '90%'}}
                                                    rows={4}
                                                    data-question={item.question}
                                                    data-index={index}></textarea>
                                                <div className="text-right">
                                                    {
                                                        item.question ? (
                                                            <input
                                                                type="checkbox"
                                                                id={`part6-include-${item.question}`}
                                                                style={{marginTop: '5px', marginRight: '5px'}}
                                                                defaultChecked={item.include}
                                                                onClick={onPart6FieldBlur} />
                                                        ) : <></>
                                                    }
                                                    {
                                                        !item.question ? (
                                                            <button
                                                                onClick={() => onDeletePart6OtherItem(index)}
                                                                type="button"
                                                                className="btn btn-sm btn-secondary">
                                                                    Remove
                                                            </button>
                                                        ) : <></>
                                                    }
                                                    <i className="fa-solid fa-grip-vertical" />
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </Draggable>
                            ))
                            }
                        {provided.placeholder}
                    </ul>
                </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default Part6List;
