import React from 'react';
import PropTypes from 'prop-types';
import SizeDetailFieldSet from './SizeDetailFieldSet';
import DaysInOperationFieldSet from './DaysInOperationFieldSet';

const AssetFormFields = (props) => {
    let numeraireOptions = props.numeraires.map((numeraire, index) =>
        <option key={numeraire.numeraire_id} value={numeraire.numeraire_id}>
            {numeraire.name} ({numeraire.symbol})
        </option>
    );
    
    const fys = props.puFys;

    let sizeDetailFieldSets;
    if(props.showSizeDetailInputs) {
        let sizeNumeraire;
        if (props.fieldValues.sizeNumeraireId) {
            sizeNumeraire = props.numeraires.find((n) => {
                return (n.numeraire_id === parseInt(props.fieldValues.sizeNumeraireId, 10));
            });
        }

        sizeDetailFieldSets = fys.map((fy) =>
            <SizeDetailFieldSet
                key={fy}
                fy={fy}
                onInputChange={props.handleSizeDetailInputChange}
                hidden={false}
                numeraireSymbol={sizeNumeraire ? sizeNumeraire.symbol : "sqft"}
                totalSize={props.fieldValues.totalSizeDetail[fy] ? props.fieldValues.totalSizeDetail[fy] : null}
                commonSize={props.fieldValues.commonSizeDetail[fy] ? props.fieldValues.commonSizeDetail[fy] : null} />
        );
    }

    let daysInOperationFieldSets;
    if(props.showDaysInOperationInputs) {
        daysInOperationFieldSets = fys.map((fy) => 
            <DaysInOperationFieldSet
                key={fy}
                fy={fy}
                onInputChange={props.handleDaysInOperationInputChange}
                daysInOperation={props.fieldValues.daysInOperationOverride[fy] ? props.fieldValues.daysInOperationOverride[fy] : null} />
        );
    }

    return (
        <div>
            <div className="form-group row">
                <label className="col-3 col-form-label text-muted text-uppercase">Asset Name</label>
                <div className="col-9">
                    <input
                        type="text"
                        className="form-control rounded-0"
                        onChange={props.handleInputChange}
                        value={props.fieldValues.name ? props.fieldValues.name : ''}
                        name="name" />
                </div>
            </div>

            <div className="form-group row">
                <label className="col-3 col-form-label text-muted text-uppercase">Asset Code</label>
                <div className="col-9">
                    <input
                        type="text"
                        className="form-control rounded-0"
                        onChange={props.handleInputChange}
                        value={props.fieldValues.assetCode ? props.fieldValues.assetCode : ''}
                        name="assetCode" />
                </div>
            </div>

            <div className="form-group row">
                <label className="col-3 col-form-label text-muted text-uppercase">Asset Type</label>
                <div className="col-9">
                    <select
                        className="custom-select rounded-0 w-100"
                        name="isUtilityPlant"
                        onChange={props.handleInputChange}
                        value={props.fieldValues.isUtilityPlant}>
                        <option value="0">Asset</option>
                        <option value="1">Utility Plant</option>
                    </select>
                </div>
            </div>

            {
                !props.fieldValues || !props.fieldValues.isUtilityPlant || props.fieldValues.isUtilityPlant === "0"
                    ? <div>
                        <div className="form-group row mt-4">
                            <label className="col-3 col-form-label text-muted text-uppercase">Size Numeraire</label>
                            <div className="col-9">
                                <select
                                    className="custom-select rounded-0 w-100"
                                    name="sizeNumeraireId"
                                    onChange={props.handleInputChange}
                                    value={props.fieldValues.sizeNumeraireId}>
                                    {numeraireOptions}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row mt-4">
                            <label className="col-3 col-form-label text-muted text-uppercase">Total Size</label>
                            <div className="col-9">
                                <input
                                    type="text"
                                    className="form-control rounded-0"
                                    onChange={props.handleInputChange}
                                    value={props.fieldValues.totalSize ? props.fieldValues.totalSize : ''}
                                    name="totalSize"
                                    autoComplete="off" />
                            </div>
                        </div>

                        <div className="form-group row mt-4">
                            <label className="col-3 col-form-label text-muted text-uppercase">Common Size</label>
                            <div className="col-9">
                                <input
                                    type="text"
                                    className="form-control rounded-0"
                                    onChange={props.handleInputChange}
                                    value={props.fieldValues.commonSize ? props.fieldValues.commonSize : ''}
                                    name="commonSize"
                                    autoComplete="off" />
                            </div>
                        </div>
                        {
                            props.showSizeDetailInputs
                                ?
                                    <div className="form-group row mt-4">
                                        <label className="col-3 col-form-label text-muted text-uppercase">Size Override</label>
                                        <div className="col-9">
                                            { sizeDetailFieldSets }
                                        </div>
                                    </div>
                                : ''
                        }

                        <div className="form-group row mt-4">
                            <label className="col-3 col-form-label text-muted text-uppercase">Usage Numeraire</label>
                            <div className="col-9">
                                <select
                                    className="custom-select rounded-0 w-100"
                                    name="usageNumeraireId"
                                    onChange={props.handleInputChange}
                                    value={props.fieldValues.usageNumeraireId}>
                                    <option value="">Same as Size</option>
                                    {numeraireOptions}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row mt-4">
                            <label className="col-3 col-form-label text-muted text-uppercase">Usage Denominator</label>
                            <div className="col-9">
                                <input
                                    type="text"
                                    className="form-control rounded-0"
                                    onChange={props.handleInputChange}
                                    disabled={!props.fieldValues.usageNumeraireId}
                                    value={props.fieldValues.usageDenominator ? props.fieldValues.usageDenominator : ''}
                                    name="usageDenominator"
                                    autoComplete="off" />
                            </div>
                        </div>

                        {
                            props.showDaysInOperationInputs
                                ?
                                    <div className="form-group row mt-4">
                                        <label className="col-3 col-form-label text-muted text-uppercase">Days In Operation Override</label>
                                        <div className="col-9">
                                            { daysInOperationFieldSets }
                                        </div>
                                    </div>
                                : ''
                        }
                    </div>
                    : ''
            }
        </div>
    );
}

AssetFormFields.propTypes = {
    handleInputChange: PropTypes.func.isRequired,
    handleSizeDetailInputChange: PropTypes.func,
    handleDaysInOperationInputChange: PropTypes.func,
    numeraires: PropTypes.array.isRequired,
    showSizeDetailInputs: PropTypes.bool.isRequired,
    fieldValues: PropTypes.object.isRequired,
    puFys: PropTypes.array.isRequired,
}

export default AssetFormFields;
