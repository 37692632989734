import React from 'react';

const UsageDetailFieldSet = (props) => {
    if (props.hidden)
        return '';
    /*
    <span className="rounded-0 input-group-addon border-0 bg-white">
                    
                </span>
    */
    return (
        <div>
            <div className="input-group rounded-0">
                <div className="input-group-prepend" style={{width: 15 + '%'}}>
                    <span className="input-group-text bg-white border-0">FY{props.fy}</span>
                </div>
                <div className="input-group-prepend rounded-0">
                    <span className="input-group-text">Bad {props.numeraireSymbol}</span>
                </div>
                <input
                    className="form-control rounded-0 text-right"
                    type="text"
                    name={"usageDetail-" + props.fy + "-bad"}
                    value={props.badUsage ? props.badUsage : ''}
                    disabled={props.disabled}
                    fy={props.fy}
                    onChange={props.onInputChange}
                    autoComplete="off" />
                <div className="input-group-prepend rounded-0">
                    <span className="input-group-text">Total {props.numeraireSymbol}</span>
                </div>
                <input
                    className="form-control rounded-0 text-right"
                    type="text"
                    name={"usageDetail-" + props.fy + "-total"}
                    value={props.totalUsage ? props.totalUsage : ''}
                    disabled={props.disabled}
                    fy={props.fy}
                    onChange={props.onInputChange}
                    autoComplete="off" />
            </div>
        </div>
    );
}

export default UsageDetailFieldSet;
