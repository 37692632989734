import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import * as ModelUtil from '../../lib/ModelUtil';
import ResearchActivityListing from './ResearchActivityListing';

const AssetResearchTable = (props) => {
    const sizeNumeraire = ModelUtil.numeraireForNumeraireId(props.asset.size_numeraire_id, props.numeraires);
    let activityRows = [];

    if(props.asset.research_detail && props.asset.research_detail[props.fy]) {
        activityRows.push(
            <ResearchActivityListing
                key={'asset'}
                fy={props.fy}
                container={props.asset}
                asset={props.asset}
                numeraires={props.numeraires} />
        );
    }

    return (
        <table className="table table-data">
            <thead>
                <tr className="bottom-dotted-border">
                    <th style={{width: '15%'}} className="pl-3">Space</th>
                    <th style={{width: '14%'}} className="text-right">FY{props.fy} Line 4 Rev.</th>
                    <th style={{width: '14%'}} className="text-right">FY{props.fy} Line 5 Rev.</th>
                    <th style={{width: '14%'}} className="text-right">FY{props.fy} Total Rev.</th>
                    <th style={{width: '14%'}} className="text-right">Size</th>
                    <th style={{width: '10%'}} className="text-right">Line 4</th>
                    <th style={{width: '10%'}} className="text-right">Line 5</th>
                </tr>
            </thead>
            <tbody>
                { activityRows }
            </tbody>
            <tfoot>
                <tr className="text-right text-muted font-weight-bold text-uppercase">
                    <td colSpan="5">
                        Total Weighted Bad Research Use
                    </td>
                    <td>
                        { numeral(props.asset.pu_calc.research_line4_usage).format('0,000.00') } {sizeNumeraire.symbol}
                    </td>
                    <td>
                        { numeral(props.asset.pu_calc.research_line5_usage).format('0,000.00') } {sizeNumeraire.symbol}
                    </td>
                </tr>
            </tfoot>
        </table>
    );
}

AssetResearchTable.propTypes = {
    fy: PropTypes.number.isRequired,
    asset: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
};

export default AssetResearchTable;