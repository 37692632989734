import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import * as SpaceActionCreators from '../../ducks/spaces';
import SpaceForm from './SpaceForm';
import SpaceFormFields from './SpaceFormFields';

class AddSpacePopoverForm extends SpaceForm {
    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: false,
            submittingForm: false,
            fieldValues: {
                asset_id: props.selectedAssetId
            },
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            fieldValues: {
                asset_id: props.selectedAssetId,
            }
        });
    }

    toggle = () => {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    }

    render() {
        return (
            <div>
                <button
                    type="button"
                    id="AddSpacePopoverForm"
                    onClick={this.toggle}
                    className="btn-link small m-0 p-0 border-0">
                    Add Space
                </button>
                <Popover
                    placement="right"
                    isOpen={this.state.popoverOpen}
                    target="AddSpacePopoverForm"
                    toggle={this.toggle}>
                    <PopoverHeader className="font-weight-bold">Add Space</PopoverHeader>
                    <PopoverBody className="p-0">
                        <form>
                            <div className="p-3">
                                <SpaceFormFields
                                    assets={this.props.assets}
                                    client={this.props.client}
                                    fieldValues={this.state.fieldValues}
                                    handleInputChange={this.handleInputChange}
                                    includeAddAssetPopoverForm={false}
                                    numeraires={this.props.numeraires}
                                    showSizeDetailInputs={false} />
                            </div>
                            <button type="button" className="btn-link modal-action p-2" onClick={this.toggle}>
                                Cancel
                            </button>
                            <button type="submit" className="btn-link modal-action p-2 font-weight-bold" onClick={this.handleFormSubmit}>
                                {
                                    this.state.submittingForm
                                        ? 'Loading...'
                                        : 'Add Space'
                                }
                            </button>
                        </form>
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}

AddSpacePopoverForm.propTypes = {
    client: PropTypes.object,
    numeraires: PropTypes.array,
    onSave: PropTypes.func,
    selectedAssetId: PropTypes.number.isRequired,
    assets: PropTypes.array,
};

const mapStateToProps = state => (
    {
        numeraires: state.numeraires.numeraires,
        client: state.client.client,
        assets: state.assets.assets,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        createSpace: (data) => dispatch(SpaceActionCreators.create(data)),
        updateSpace: (id, data) => dispatch(SpaceActionCreators.update(id, data)),
        removeSpace: (id) => dispatch(SpaceActionCreators.remove(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSpacePopoverForm);