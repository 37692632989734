import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import * as ModelUtil from '../../../lib/ModelUtil';

const NoteListing = props => {
    const tz = moment.tz.guess();
    const note = props.note;
    const fromReviewer = props.note.user_id === props.client.reviewer_user_id;

    return (
        <li className="list-group-item pl-0 border-0 rounded-0">
            
            <div className="text-muted">
                <div>
                    { note.user ? `${note.user.first_name} ${note.user.last_name}` : <Fragment /> }
                    &nbsp;{ fromReviewer ? <span className="badge badge-primary">Reviewer</span> : <Fragment /> }
                    {
                        props.showEntityName ? (
                            <Fragment>
                                &nbsp;
                                <span className="badge badge-secondary">
                                    { note.entity_type ? `${note.entity_type}: ` : 'General' } { ModelUtil.entityNameForTypeAndId(note.entity_type, note.entity_id, props.assets, props.assetUsers, props.issues) }
                                </span>
                            </Fragment>
                        ) : <Fragment />
                    }
                    &nbsp;{ note.entity_section ? <span className="badge badge-secondary">{ note.entity_section }</span> : <Fragment /> }
                </div>
                <div>
                    {moment.tz(note.created_at, 'UTC').tz(tz).format('M/D/YYYY h:mm a')}
                </div>
            </div>
            <div>
                <p>
                    {note.text.split('\n').map((item, key) => {
                        return <span key={key}>{item}<br /></span>
                    })}
                </p>
            </div>
        </li>
    );
}
// TODO: connect client, indicate reviewer messages
NoteListing.propTypes = {
    note: PropTypes.object.isRequired,
    showEntityName: PropTypes.bool,
}

const mapStateToProps = state => (
    {
        client: state.client.client,
        assets: state.assets.assets,
        assetUsers: state.asset_users.list,
        issues: state.issues.list,
    }
);

export default connect(mapStateToProps)(NoteListing);
