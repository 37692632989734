import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FileTreeViewFile extends Component {
    fireFileSelect = () => {
        if(typeof this.props.onFileSelect === 'function') {
            this.props.onFileSelect(this.props.file);
        }
    }

    render() {
        const leftMargin = this.props.leftMargin ? this.props.leftMargin : 0;
        return (
            <div onClick={this.fireFileSelect} style={{ marginLeft: leftMargin + 'px' }} className="pointer-hover">
                <span style={{backgroundColor: this.props.selected ? '#ddd' : 'inherit'}}>
                    <i className="icon icon-news"></i>&nbsp;
                    {this.props.file.name}
                </span>
            </div>
        )
    }
}

FileTreeViewFile.propTypes = {
    file: PropTypes.object.isRequired,
    onFileSelect: PropTypes.func,
    leftMargin: PropTypes.number,
    selected: PropTypes.bool
};

export default FileTreeViewFile;