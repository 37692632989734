import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeral from 'numeral';
import * as ModelUtil from '../../lib/ModelUtil';
import AssetUserListing from './AssetUserListing';

const AssetUsesTable = (props) => {
    if (!props.uses) {
        return '';
    }

    const usageNumeraire = ModelUtil.usageNumeraireForAsset(props.asset, props.numeraires);
    const usageNumeraireSymbol = usageNumeraire ? usageNumeraire.symbol : '';

    let relevantUses = props.uses;
    if (!props.showAllFyUses) {
        relevantUses = relevantUses.filter((use) => {
            return (use.pu_calc.time_numerator > 0 || !use.start_date || !use.end_date);
        });
    }
    const relevantUsers = ModelUtil.usersFromUses(relevantUses);
    
    const userRows = relevantUsers.map((user) => (
            <AssetUserListing
                key={user.asset_user_id}
                user={user}
                asset={props.asset}
                numeraires={props.numeraires}
                showUseForm={props.showUseForm}
                showAssetUserForm={props.showAssetUserForm} />
        )
    );

    const totalWeightedLine4 = relevantUsers
        .reduce((sum, user) => {
            return sum + user.uses.reduce((usesSum, u) => {
                return usesSum + u.pu_calc.sk_line4_usage_size;
            }, 0);
        }, 0);
    
    const totalWeightedLine5 = relevantUsers
        .reduce((sum, user) => {
            return sum + user.uses.reduce((usesSum, u) => {
                return usesSum + u.pu_calc.sk_line5_usage_size;
            }, 0);
        }, 0);
    
    let line4ExcludedTotal, line5ExcludedTotal;
    if (props.shortTerm) {
        const excludedSizes = props.asset.pu_calc.dates_excluded_overlapping_use;

        line4ExcludedTotal = excludedSizes.reduce((acc, u) => {
            return acc + u.line4;
        }, 0);
    
        line5ExcludedTotal = excludedSizes.reduce((acc, u) => {
            return acc + u.line5;
        }, 0);
    }
    
    
    return (
        <table className="table table-data">
            <thead>
                <tr className="bottom-dotted-border">
                    <th style={{width: '2%'}} className="pl-2">&nbsp;</th>
                    <th style={{width: '13%'}}>User</th>
                    <th style={{width: '13%'}}>Space Use</th>
                    <th style={{width: '8%'}} className="text-right">Start</th>
                    <th style={{width: '8%'}} className="text-right">End</th>
                    <th style={{width: '11%'}} className="text-right">Size</th>
                    <th style={{width: '8%'}} className="text-right">Time</th>
                    <th style={{width: '8%'}} className="text-right">Bad %</th>
                    <th style={{width: '9%'}} className="text-right">Line 4</th>
                    <th style={{width: '9%'}} className="text-right">Line 5</th>
                    <th style={{width: '9%'}} className="text-right">Line 6</th>
                    <th style={{width: '2%'}}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                { userRows }
            </tbody>
            <tfoot>
                <tr className="text-right text-muted font-weight-bold text-uppercase">
                    <td colSpan="8">
                        Gross Weighted Bad Use
                    </td>
                    <td>
                        { numeral(totalWeightedLine4).format('0,000.00') } {usageNumeraireSymbol}
                    </td>
                    <td>
                        { numeral(totalWeightedLine5).format('0,000.00') } {usageNumeraireSymbol}
                    </td>
                    <td>
                        { numeral(totalWeightedLine4 + totalWeightedLine5).format('0,000.00') } {usageNumeraireSymbol}
                    </td>
                    <td>&nbsp;</td>
                </tr>
                {
                    props.shortTerm ? (
                        <tr className="text-right text-muted font-weight-bold text-uppercase">
                            <td colSpan="8">
                                Less: Overlapping Use
                            </td>
                            <td>
                                { numeral(line4ExcludedTotal).format('0,000.00') } {usageNumeraireSymbol}
                            </td>
                            <td>
                                { numeral(line5ExcludedTotal).format('0,000.00') } {usageNumeraireSymbol}
                            </td>
                            <td>
                                { numeral(line4ExcludedTotal + line5ExcludedTotal).format('0,000.00') } {usageNumeraireSymbol}
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    ) : ''
                }
                {
                    props.shortTerm ? (
                        <tr className="text-right text-muted font-weight-bold text-uppercase">
                            <td colSpan="8">
                                Net Weighted Bad Use
                            </td>
                            <td>
                                { numeral(totalWeightedLine4 - line4ExcludedTotal).format('0,000.00') } {usageNumeraireSymbol}
                            </td>
                            <td>
                                { numeral(totalWeightedLine5 - line5ExcludedTotal).format('0,000.00') } {usageNumeraireSymbol}
                            </td>
                            <td>
                                { numeral(totalWeightedLine4 + totalWeightedLine5 - line4ExcludedTotal - line5ExcludedTotal).format('0,000.00') } {usageNumeraireSymbol}
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    ) : ''
                }
            </tfoot>
        </table>
    );
}

AssetUsesTable.propTypes = {
    asset: PropTypes.object.isRequired,
    uses: PropTypes.array.isRequired,
    numeraires: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired,
    showUseForm: PropTypes.func,
    showAssetUserForm: PropTypes.func,
    showAllFyUses: PropTypes.bool.isRequired,
    shortTerm: PropTypes.bool.isRequired,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        numeraires: state.numeraires.numeraires,
    }
);

export default connect(mapStateToProps)(AssetUsesTable);