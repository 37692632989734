import { Component } from 'react';

class AssetUserForm extends Component {
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let fieldValues = this.state.fieldValues;
        fieldValues[name] = value;

        this.setState({ fieldValues: fieldValues });
    }

    saveFunc = (data) => {
        this.toggle();
        this.setState({ submittingForm: false });
        if (typeof this.props.onSave === 'function') {
            this.props.onSave(data.asset_user);
        }
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ submittingForm: true });
        
        if(!this.props.user) {
            this.props.createAssetUser(this.state.fieldValues).then(this.saveFunc);
        }
        else {
            this.props.updateAssetUser(this.props.user.asset_user_id, this.state.fieldValues).then(this.saveFunc);
        }
    }

    handleDeleteClick = (event) => {
        let confirmed = window.confirm('Are you sure you want to delete ' + this.props.user.name + '?');

        if(confirmed) {
            this.setState({ submittingForm: true });

            this.props.removeAssetUser(this.props.user.asset_user_id).then(this.saveFunc);
        }
    }
}

export default AssetUserForm;
