import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Constants from '../lib/Constants';

import TitleBar from '../components/TitleBar';
import AssetUserDetail from '../components/AssetUserDetail/AssetUserDetail';
import SpaceUseModalForm from '../apps/SpaceUseModalForm/SpaceUseModalForm';
import AssetUserModalForm from '../components/AssetUserForms/AssetUserModalForm';

import * as AssetUserActionCreators from '../ducks/asset_users';
import * as CommonStateActions from '../ducks/common';

class ClientAssetUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingAssetUserForm: false,
            editingAssetUser: null,
            showingSpaceUseForm: false,
            editingSpaceUse: null,
            searchTerm: null,
            spaceUsePreselectedAssetUserId: null,
        };
    }

    handleSearchInputChange = (event) => {
        const target = event.target;
        const value = target.value;

        this.setState({ searchTerm: value });
    }

    componentDidMount() {
        const urlClientId = parseInt(this.props.match.params.client_id, 10);
        const urlAssetUserId = this.props.match.params.asset_user_id ? parseInt(this.props.match.params.asset_user_id, 10) : null;

        if(!this.props.client || this.props.client.client_id !== urlClientId) {
            this.props.loadClientAndDependencies(urlClientId);
        }

        if(urlAssetUserId && (!this.props.assetUserDetailView || this.props.assetUserDetailView.asset_user.asset_user_id !== urlAssetUserId)) {
            this.props.loadAssetUserDetailView(urlAssetUserId, this.props.puCalcFy);
        }
        this.setPageTitle();
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.asset_user_id !== prevProps.match.params.asset_user_id) {
            this.props.loadAssetUserDetailView(this.props.match.params.asset_user_id, this.props.puCalcFy);
        }
        this.setPageTitle();
    }

    setPageTitle() {
        let title = `${Constants.pageTitleBase} ${Constants.pageTitleSeparator} Asset Users`;
        if (this.props.assetUserDetailView) {
            title += ` ${Constants.pageTitleSeparator} ${this.props.assetUserDetailView.asset_user.name}`;
        }
        document.title = title;
    }

    showUseForm = (use, preselectedAssetUserId) => {
        this.setState({
            showingSpaceUseForm: true,
            editingSpaceUse: use ? use : null,
            spaceUsePreselectedAssetUserId: preselectedAssetUserId ? preselectedAssetUserId : null,
        });
    }

    hideUseForm = () => {
        this.setState({
            showingSpaceUseForm: false,
            editingSpaceUse: null,
            spaceUsePreselectedAssetUserId: null,
        });
    }

    showAssetUserForm = (user) => {
        this.setState({ showingAssetUserForm: true, editingAssetUser: user ? user : null });
    }

    hideAssetUserForm = () => {
        this.setState({ showingAssetUserForm: false, editingAssetUser: null });
    }

    showUseImporterModal = () => {
        this.setState({ showingUseImporterModal: true });
    }

    onHideUseImporterModal = () => {
        this.setState({ showingUseImporterModal: false });
    }

    render() {
        //if (!this.props.client || !this.props.numeraires || this.props.numeraires.length === 0 || !this.props.assets)

        if (!this.props.client || !this.props.numeraires || this.props.numeraires.length === 0 || !this.props.assetUsers)
            return '';

        let users = this.props.assetUsers;

        if(this.state.searchTerm) {
            let term = this.state.searchTerm.toLowerCase();

            users = users.filter((user) => {
                if(
                    (user.name && user.name.toLowerCase().includes(term)) || 
                    (user.asset_user_id.toString() === term.replace(/\D/g, ''))
                ) {
                    return true;
                }
                else {
                    return false;
                }
            });
        }

        let showingAssetUser = this.props.assetUserDetailView ? this.props.assetUserDetailView.asset_user : null;
        let showingAssetUserId = showingAssetUser ? showingAssetUser.asset_user_id : null;

        let userListings = users.map((user) => {
            return (
                <Link
                    to={ "/clients/" + this.props.client.client_id + "/asset_users/" + user.asset_user_id }
                    className={ "list-group-item entity-list-item" + (user.asset_user_id === showingAssetUserId ? " active" : '')}
                    key={ user.asset_user_id }>

                    <div className="font-weight-bold">{ user.name }</div>

                    <div className="d-flex w-100 justify-content-between">
                        <span className="text-muted">{ user.type }</span>
                    </div>
                </Link>
            );
        });

        return (
            <div>
                <TitleBar pageTitle="Asset Users" clientName={this.props.client.name} />
                
                <AssetUserModalForm
                    show={this.state.showingAssetUserForm}
                    onHide={this.hideAssetUserForm}
                    clientId={this.props.client.client_id}
                    user={this.state.editingAssetUser} />

                <SpaceUseModalForm
                    show={this.state.showingSpaceUseForm}
                    assets={this.props.assets}
                    assetUsers={users}
                    numeraires={this.props.numeraires}
                    use={this.state.editingSpaceUse}
                    clientId={this.props.client.client_id}
                    onHide={this.hideUseForm}
                    preselectedAssetUserId={this.state.spaceUsePreselectedAssetUserId}
                    preselectedAssetId={null} />
                
                <div className="clear-title-bar">
                    <div className="entity-list-column" style={{ width: '17%', marginLeft: '-15px' }}>
                        <ul className="list-group rounded-0 list-group-flush p-0">
                            <li className="list-group-item rounded-0 entity-search-item p-2 d-flex w-100 justify-content-between">
                                <input
                                    className="form-control rounded-0"
                                    type="text"
                                    placeholder="Search Users" 
                                    onChange={ this.handleSearchInputChange } />
                                
                                <a className="btn btn-outline" onClick={() => { this.showAssetUserForm(); }}>
                                    <i className="icon icon-plus" /> Add User
                                </a>
                            </li>
                            { userListings }
                        </ul>
                    </div>

                    <div className="clear-entity-list-column">
                        <div className="pl-3 pr-3 pb-3">
                            {
                                showingAssetUser
                                    ? <AssetUserDetail 
                                        assetUserDetailView={this.props.assetUserDetailView}
                                        numeraires={this.props.numeraires}
                                        showUseForm={this.showUseForm} 
                                        showAssetUserForm={this.showAssetUserForm} />
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ClientAssetUsers.propTypes = {
    client: PropTypes.object,
    numeraires: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = state => (
    {
        assets: state.assets.assets,
        client: state.client.client,
        clientIsLoading: state.client.clientIsLoading,
        clientHasErrored: state.client.clientHasErrored,
        clientErrorMessage: state.client.clientErrorMessage,
        assetUsers: state.asset_users.list,
        assetUsersListIsLoading: state.asset_users.listIsLoading,
        assetUsersListHasErrored: state.asset_users.listHasErrored,
        assetUserDetailView: state.asset_users.detailView,
        numeraires: state.numeraires.numeraires,
        notes: state.notes.notes,
        puCalcFy: state.client.puCalcFy,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadClientAndDependencies: (clientId) => CommonStateActions.loadClientAndDependencies(dispatch, clientId),
        loadAssetUserDetailView: (userId, puCalcFy) => dispatch(AssetUserActionCreators.loadAssetUserDetailView(userId, puCalcFy)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAssetUsers);
