import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import * as ModelUtil from '../../lib/ModelUtil';
import moment from 'moment';

const RefundingBlendingTable = (props) => {
    if(!props.issue) {
        return '';
    }

    if(props.fullTMPCalcIsLoading) {
        return (<p>Loading...</p>);
    }

    if(props.fullTMPCalcErrorMessage) {
        return (<p>Error: { props.fullTMPCalcErrorMessage }</p>);
    }

    if(!props.fullTMPCalc) {
        return '';
    }

    const refundingBlending = props.fullTMPCalc.refunding_blending;

    const refundedIssueIds = refundingBlending.reduce((acc, period) => {
        period.issues.forEach((iss) => {
            if(acc.indexOf(iss.issue_id) < 0) {
                acc.push(iss.issue_id);
            };
        });

        return acc;
    }, []);

    const refundedIssues = refundedIssueIds.map((id) => {
        return ModelUtil.issueForIssueId(id, props.issues);
    });

    const issueHeaders = refundedIssues.map((i) => {
        return (<th key={i.issue_id} className="text-right">{ i.short_name }</th>);
    });

    const periodRows = refundingBlending.map((period) => {
        //let costTotal = 0;

        const issueCells = refundedIssues.map((iss, i) => {
            const periodIssue = period.issues.find((pI) => {
                return pI.issue_id === iss.issue_id;
            });

            if (!periodIssue) {
                return (<td key={iss.issue_id} className="text-right">&nbsp;</td>);
            }

            //costTotal += periodIssue.cost_allocable;

            return (
                <td key={iss.issue_id} className="text-right">
                    { moment(period.start_date).format('MM/DD/YY') }<br />
                    { moment(period.end_date).format('MM/DD/YY') }<br />
                    { numeral(periodIssue.par_refunded).format('0,000.00') }<br />
                    { numeral(periodIssue.pu_cost).format('0,000.00') }<br />
                    { numeral(periodIssue.net_sale_proceeds).format('0,000.00') }<br />
                    { numeral(periodIssue.pu_factor).format('0.00%') }<br />
                    { numeral(periodIssue.cost_allocable).format('0,000.00') }
                </td>
            );
        });
        
        return (
            <tr key={ period.start_date } className="border-bottom">
                <td className="font-weight-bold">{ moment(period.start_date).format('MM/DD/YY') }</td>
                <td style={{'width': '23%'}}>
                    Period Start Date<br />
                    Period End Date<br />
                    Principal Refunded by the { props.issue.short_name } Bonds<br />
                    Private Use Dollars<br />
                    Net Sale Proceeds<br />
                    Private Use %<br />
                    Private Use Allocable to the { props.issue.short_name } Bonds
                </td>
                { issueCells }
                <td className="text-right">
                    &nbsp;<br />
                    &nbsp;<br />
                    { numeral(period.total_par_refunded).format('0,000.00') }<br />
                    &nbsp;<br />
                    &nbsp;<br />
                    &nbsp;<br />
                    { numeral(period.pu_factor).format('0.00%') }
                </td>
            </tr>
        );
    });

    return (
        <table className="table table-data table-condensed table-hover">
            <thead>
                <tr className="bottom-dotted-border">
                    <th className="text-left">Period Start Date</th>
                    <th>&nbsp;</th>
                    { issueHeaders }
                    <th className="text-right">Total</th>
                </tr>
            </thead>
            <tbody>
                { periodRows }
            </tbody>
        </table>
    );
}

RefundingBlendingTable.propTypes = {
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        numeraires: state.numeraires.numeraires,
        issues: state.issues.list,
        issue: state.issues.detailView,
        fullTMPCalcIsLoading: state.issues.fullTMPCalcIsLoading,
        fullTMPCalcHasErrored: state.issues.fullTMPCalcHasErrored,
        fullTMPCalcErrorMessage: state.issues.fullTMPCalcErrorMessage,
        fullTMPCalc: state.issues.fullTMPCalc,
    }
);

export default connect(mapStateToProps)(RefundingBlendingTable);