import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import moment from 'moment';
import Constants from '../../lib/Constants';
import * as IssueActionCreators from '../../ducks/issues';
import * as ModelUtil from '../../lib/ModelUtil';
import TableForm from '../TableForm';

class IssueEditRedemptionScheduleModal extends TableForm {
    componentDidUpdate(prevProps) {        
        if(this.props.show !== this.state.show) {
            this.setState({ show: this.props.show, userSelectedFy: null });
        }

        if(
            this.issue()
            && (
                !prevProps.issueDetailView
                || prevProps.issueDetailView.issue.issue_id !== this.issue().issue_id
                || !this.state.redemptionDetail
            )
        ) {
            this.state.redemptionDetail = this.issue().redemption_detail;
        }
    }

    handleTableChange = (event, source, type) => {
        if(!event) {
            return;
        }

        let keys = [];
        if(type === 'scheduled') {
            keys = [
                'date',
                'principal_amount',
                'cusip'
            ];
        }
        else {
            keys = [
                'defeasance_date',
                'redemption_date',
                'principal_amount',
                'cusip',
                'refunding_issue_id',
                'refunding_notes'
            ];
        }

        let detail = this.state.redemptionDetail;
        if(!detail) {
            detail = {};
        }
        if(!detail[type]) {
            detail[type] = [];
        }
        let typeDetail = detail[type];

        event.forEach(change => {
            const i = change[0];
            const j = change[1];

            if(!typeDetail[i]) {
                typeDetail[i] = {};
                keys.forEach(k => { typeDetail[i][k] = null });
            }

            if(keys[j] === 'refunding_issue_id') {
                const issue = ModelUtil.issueForIssueShortName(change[3], this.props.issues);
                typeDetail[i][keys[j]] = issue ? issue.issue_id : null;
            }
            else {
                typeDetail[i][keys[j]] = change[3];
            }
        });

        detail[type] = typeDetail;

        this.setState({ redemptionDetail: detail });
    }

    issue = () => {
        return (
            this.props.issueDetailView
            && this.props.issueDetailView.issue
        ) ? this.props.issueDetailView.issue : null;
    }

    saveRedemptionSchedule = () => {
        if(this.issue() && !this.state.saving) {
            this.setState({ saving: true });
            
            this.props
                .updateIssue(
                    this.issue().issue_id,
                    { redemption_detail: this.state.redemptionDetail }
                )
                .then(() => {
                    this.setState({ saving: false, justSaved: true });
                    this.toggle();
                    if(typeof this.props.onHide === 'function') {
                        this.props.onHide();
                    }
                })
                .catch((error) => {
                    this.setState({ saving: false });
                    alert(error);
                });
        }
    }

    originalTableDataFormat = redemptions => {
        if(!redemptions) {
            return [];
        }

        return redemptions.map(r => {
            return [
                r.date ? moment(r.date).format('MM/DD/YYYY') : null,
                r.principal_amount,
                r.cusip,
            ];
        });
    }

    optionalTableDataFormat = redemptions => {
        if(!redemptions) {
            return [];
        }

        return redemptions.map(r => {
            const refundingIssue = ModelUtil.issueForIssueId(r.refunding_issue_id, this.props.issues);
            return [
                r.defeasance_date ? moment(r.defeasance_date).format('MM/DD/YYYY') : null,
                r.redemption_date ? moment(r.redemption_date).format('MM/DD/YYYY') : null,
                r.principal_amount,
                r.cusip,
                refundingIssue ? refundingIssue.short_name : null,
                r.refunding_notes,
            ];
        })
    }

    render() {
        if(!this.issue() || !this.props.issues) {
            return <Fragment />;
        }

        const redemptionDetail = this.state.redemptionDetail || {};

        let issueOptions = this.props.issues
            .filter((i) => {
                return i.issue_id !== this.issue().issue_id;
            })
            .map((i) => {
                return i.short_name;
            });
        issueOptions.unshift('');

        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} className="modal-xl" backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">Edit Redemption Schedule</span>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-4">
                            <div className="card-bg text-muted text-uppercase font-weight-bold p-2 mb-2 text-center">
                                Original Redemption Schedule
                            </div>
                            <div style={{height: '550px', overflowY: 'hidden'}}>
                                <HotTable
                                    root={ "hotRedemptionsOriginalSchedule" }
                                    data={ this.originalTableDataFormat(redemptionDetail.scheduled) }
                                    contextMenu={true}
                                    rowHeaders={false}
                                    columns={[
                                        { type: 'text', width: 105, className: 'htRight', },
                                        {
                                            type: 'numeric',
                                            numericFormat: { pattern: '0,00.00' },
                                            correctFormat: true,
                                            className: 'htRight',
                                            width: 125,
                                        },
                                        { type: 'text', width: 105 },
                                    ]}
                                    colHeaders={[
                                        'Date',
                                        'Principal Amount',
                                        'CUSIP',
                                    ]}
                                    minRows={25}
                                    afterChange={(event, source) => this.handleTableChange(event, source, 'scheduled')}
                                    licenseKey={Constants.hotLicenseKey} />
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="card-bg text-muted text-uppercase font-weight-bold p-2 mb-2 text-center">
                                DEFEASED/REFUNDED
                            </div>
                            <div style={{height: '550px', overflowY: 'hidden'}}>
                                <HotTable
                                    root={ "hotRedemptionsOptionalRefunding" }
                                    data={ this.optionalTableDataFormat(redemptionDetail.optional_refunded) }
                                    contextMenu={true}
                                    rowHeaders={false}
                                    columns={[
                                        { type: 'text', width: 105, className: 'htRight', },
                                        { type: 'text', width: 105, className: 'htRight', },
                                        {
                                            type: 'numeric',
                                            numericFormat: { pattern: '0,00.00' },
                                            correctFormat: true,
                                            className: 'htRight',
                                            width: 125,
                                        },
                                        { type: 'text', width: 105 },
                                        { type: 'dropdown', source: issueOptions, width: 165 },
                                        { type: 'text', width: 150 },
                                    ]}
                                    colHeaders={[
                                        'Defeasance Date',
                                        'Redemption Date',
                                        'Principal Amount',
                                        'CUSIP',
                                        'TE Ref. Issue (if applicable)',
                                        'Refunding Notes',
                                    ]}
                                    minRows={25}
                                    afterChange={(event, source) => this.handleTableChange(event, source, 'optional_refunded')}
                                    licenseKey={Constants.hotLicenseKey} />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-actions">
                    <button type="button" className="btn-link modal-action" onClick={this.toggle}>
                        Cancel
                    </button>
                    <button type="submit" className="btn-link modal-action font-weight-bold" onClick={this.saveRedemptionSchedule}>
                        {
                            this.state.saving
                                ? 'Saving...'
                                : 'Save Redemption Schedule'
                        }
                    </button>
                </div>
            </Modal>
        );
    }
}

IssueEditRedemptionScheduleModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
};

const mapStateToProps = state => (
    {
        issueDetailView: state.issues.detailView,
        issues: state.issues.list,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        updateIssue: (issueId, issueData) => dispatch(IssueActionCreators.update(issueId, issueData)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueEditRedemptionScheduleModal);