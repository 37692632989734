import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import AssetForm from './AssetForm';
import AssetFormFields from './AssetFormFields';
import * as AssetActionCreators from '../../ducks/assets';

class AddAssetPopoverForm extends AssetForm {
    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: false,
            submittingForm: false,
            fieldValues: {
                clientId: this.props.client.client_id,
                sizeNumeraireId: this.props.numeraires[0].numeraire_id,
                name: '',
                assetCode: '',
                inServiceDate: '',
                outServiceDate: '',
                totalSize: '',
                commonSize: '',
                usageNumeraireId: '',
                usageDemoninator: '',
            },
        };
    }

    toggle = () => {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    }

    render() {
        return (
            <div>
                <button
                    type="button"
                    id="AddAssetPopoverForm"
                    onClick={this.toggle}
                    className="btn-link small m-0 p-0 border-0">
                    Add Asset
                </button>
                <Popover
                    placement="right"
                    isOpen={this.state.popoverOpen}
                    target="AddAssetPopoverForm"
                    toggle={this.toggle}>
                    <PopoverHeader className="font-weight-bold">Add Asset</PopoverHeader>
                    <PopoverBody className="p-0">
                        <form>
                            <div className="p-3">
                                <AssetFormFields
                                    handleInputChange={this.handleInputChange}
                                    numeraires={this.props.numeraires}
                                    showSizeDetailInputs={false}
                                    puFys={this.props.client.pu_fys}
                                    fieldValues={this.state.fieldValues} />
                            </div>
                            <button type="button" className="btn-link modal-action p-2" onClick={this.toggle}>
                                Cancel
                            </button>
                            <button type="submit" className="btn-link modal-action p-2 font-weight-bold" onClick={this.handleFormSubmit}>
                                {
                                    this.state.submittingForm
                                        ? 'Loading...'
                                        : 'Add Asset'
                                }
                            </button>
                        </form>
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}

AddAssetPopoverForm.propTypes = {
    client: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    onSave: PropTypes.func,
};

const mapStateToProps = state => (
    {
        numeraires: state.numeraires.numeraires,
        client: state.client.client,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        createAsset: (assetData) => dispatch(AssetActionCreators.create(assetData)),
        updateAsset: (assetId, assetData) => dispatch(AssetActionCreators.update(assetId, assetData)),
        removeAsset: (assetId) => dispatch(AssetActionCreators.remove(assetId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAssetPopoverForm);
