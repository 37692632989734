import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import thunk from 'redux-thunk';
import { AzureAD, AuthenticationState } from 'react-aad-msal';

import authStateReducer from './ducks/auth_state';
import assetsReducer from './ducks/assets';
import assetUsesReducer from './ducks/asset_uses';
import assetUsersReducer from './ducks/asset_users';
import clientReducer from './ducks/client';
import dataImportsReducer from './ducks/data_imports';
import issuesReducer from './ducks/issues';
import numerairesReducer from './ducks/numeraires';
import notesReducer from './ducks/notes';
import projectsReducer from './ducks/projects';
import spacesReducer from './ducks/spaces';
import usersReducer from './ducks/users';
import modalsReducer from './ducks/modals';

import { authProvider } from './lib/AuthProvider';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('CompanyName=Blx Group LLC,LicensedGroup=BLX Group,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-018974,ExpiryDate=15_October_2022_[v2]_MTY2NTc4ODQwMDAwMA==cfb85db6054ab832e6742352270854c4');

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    combineReducers({
        auth_state: authStateReducer,
        assets: assetsReducer,
        asset_uses: assetUsesReducer,
        client: clientReducer,
        asset_users: assetUsersReducer,
        data_imports: dataImportsReducer,
        issues: issuesReducer,
        numeraires: numerairesReducer,
        projects: projectsReducer,
        spaces: spacesReducer,
        loadingBar: loadingBarReducer,
        notes: notesReducer,
        users: usersReducer,
        modals: modalsReducer,
    }),
    composeEnhancers(
        applyMiddleware(thunk),
    )
);

ReactDOM.render((
    <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
        {
            ({login, logout, authenticationState, error, accountInfo}) => {
                switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                        return (
                            <Provider store={store}>
                                <BrowserRouter>
                                    <App />
                                </BrowserRouter>
                            </Provider>
                        );
                    case AuthenticationState.Unauthenticated:
                        return (
                            <div>
                            {error && <p><span>An error occured during authentication, please try again! ({error.errorMessage})</span></p>}
                            <p>
                                <button onClick={login}>Login</button>
                            </p>
                            </div>
                        );
                    case AuthenticationState.InProgress:
                        return (<p>Authenticating...</p>);
                    default:
                        return <p>Auth?</p>;
                }
            }
        }
    </AzureAD>
), document.getElementById('root'));
registerServiceWorker();
