import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as IssueApi from '../api/IssueApi';
import * as Common from './common';

const LIST_IS_LOADING = 'issues/LIST_IS_LOADING';
const LIST_HAS_LOADED = 'issues/LIST_HAS_LOADED';
const LIST_HAS_ERRORED = 'issues/HAS_ERRORED';

const DETAIL_VIEW_IS_LOADING = 'issues/DETAIL_VIEW_IS_LOADING';
const DETAIL_VIEW_HAS_LOADED = 'issues/DETAIL_VIEW_HAS_LOADED';
const DETAIL_VIEW_HAS_ERRORED = 'issues/DETAIL_VIEW_HAS_ERRORED';

const FULL_TMP_CALC_IS_LOADING = 'issues/FULL_TMP_CALC_IS_LOADING';
const FULL_TMP_CALC_HAS_LOADED = 'issues/FULL_TMP_CALC_HAS_LOADED';
const FULL_TMP_CALC_HAS_ERRORED = 'issues/FULL_TMP_CALC_HAS_ERRORED';

const CREATE = 'issues/CREATE';
const REMOVE = 'issues/REMOVE';
const UPDATE = 'issues/UPDATE';
const UPDATE_SOURCES = 'issues/UPDATE_SOURCES';
const UPDATE_USES = 'issues/UPDATE_USES';

const CLEAR = 'issues/CLEAR';

const SWITCH_CLIENT = 'issues/SWITCH_CLIENT';

const initialState = {
    list: null,
    listIsLoading: false,
    listHasErrored: false,
    listErrorMessage: null,
    detailView: null,
    detailViewIsLoading: false,
    detailViewHasErrored: false,
    detailViewErrorMessage: null,
    fullTMPCalc: null,
    fullTMPCalcIsLoading: false,
    fullTMPCalcHasErrored: false,
    fullTMPCalcErrorMessage: null,
};

export default function issuesReducer(state = initialState, action = {}) {
    switch (action.type) {
        case LIST_IS_LOADING:
            return {
                ...state,
                listIsLoading: true,
                listHasErrored: false,
                listErrorMessage: null,
            };
        case LIST_HAS_LOADED:
            return {
                ...state,
                list: action.issues,
                listIsLoading: false,
                listHasErrored: false,
                listErrorMessage: null,
            };
        case LIST_HAS_ERRORED:
            return {
                ...state,
                list: null,
                listIsLoading: false,
                listHasErrored: true,
                listErrorMessage: action.errorMessage,
            };
        case DETAIL_VIEW_IS_LOADING:
            return {
                ...state,
                detailViewIsLoading: true,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
                fullTMPCalc: null,
            };
        case DETAIL_VIEW_HAS_LOADED:
            return {
                ...state,
                detailView: action.detailView,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
                fullTMPCalc: null,
            };
        case DETAIL_VIEW_HAS_ERRORED:
            return {
                ...state,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: true,
                detailViewErrorMessage: action.errorMessage,
                fullTMPCalc: null,
            };
        case FULL_TMP_CALC_IS_LOADING:
            return {
                ...state,
                fullTMPCalcIsLoading: true,
                fullTMPCalcHasErrored: false,
                fullTMPCalcErrorMessage: null,
            };
        case FULL_TMP_CALC_HAS_LOADED:
            return {
                ...state,
                fullTMPCalc: action.fullTMPCalc,
                fullTMPCalcIsLoading: false,
                fullTMPCalcHasErrored: false,
                fullTMPCalcErrorMessage: null,
            };
        case FULL_TMP_CALC_HAS_ERRORED:
            return {
                ...state,
                fullTMPCalc: null,
                fullTMPCalcIsLoading: false,
                fullTMPCalcHasErrored: true,
                fullTMPCalcErrorMessage: action.errorMessage,
            };
        case CREATE:
            return state;
        case REMOVE:
            return {
                ...state,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
            };
        case UPDATE:
        case UPDATE_SOURCES:
        case UPDATE_USES:
            return state;
        case CLEAR:
        case SWITCH_CLIENT:
            return {
                ...state,
                list: null,
                listIsLoading: false,
                listHasErrored: false,
                listErrorMessage: null,
                detailView: null,
                detailViewIsLoading: false,
                detailViewHasErrored: false,
                detailViewErrorMessage: null,
                fullTMPCalc: null,
                fullTMPCalcIsLoading: false,
                fullTMPCalcHasErrored: false,
                fullTMPCalcErrorMessage: null,
            };
        default:
            return state;
    }
}

export function issuesListIsLoading() {
    return { type: LIST_IS_LOADING };
}

export function issuesListHasLoaded(issues) {
    return { type: LIST_HAS_LOADED, issues: issues };
}

export function issuesListHasErrored(errorMessage) {
    return { type: LIST_HAS_ERRORED, errorMessage: errorMessage };
}

export function issueDetailViewIsLoading() {
    return { type: DETAIL_VIEW_IS_LOADING };
}

export function issueDetailViewHasLoaded(detailView) {
    return { type: DETAIL_VIEW_HAS_LOADED, detailView: detailView };
}

export function issueDetailViewHasErrored(errorMessage) {
    return { type: DETAIL_VIEW_HAS_ERRORED, errorMessage: errorMessage };
}


export function issueFullTMPCalcIsLoading() {
    return { type: FULL_TMP_CALC_IS_LOADING };
}

export function issueFullTMPCalcHasLoaded(fullTMPCalc) {
    return { type: FULL_TMP_CALC_HAS_LOADED, fullTMPCalc: fullTMPCalc };
}

export function issueFullTMPCalcHasErrored(errorMessage) {
    return { type: FULL_TMP_CALC_HAS_ERRORED, errorMessage: errorMessage };
}

export function issuesClear() {
    return { type: CLEAR };
}

export function switchClient() {
    return { type: SWITCH_CLIENT };
}

export function loadIssues(clientId, onLoad) {
    return (dispatch) => {
        dispatch(issuesListIsLoading());
        dispatch(showLoading());

        IssueApi.listForClient(clientId)
            .then(data => {
                dispatch(issuesListHasLoaded(data.issues));
                dispatch(hideLoading());
                if (typeof onLoad === 'function')
                    onLoad(data.issues);
            })
            .catch(error => {
                dispatch(hideLoading());
                dispatch(issuesListHasErrored(error));
            });
    }
}

export function loadIssueDetailView(issueId, puCalcFy) {
    return (dispatch) => {
        dispatch(issueDetailViewIsLoading());
        dispatch(showLoading());
        
        IssueApi.issueDetailView(issueId, puCalcFy)
            .then(data => {
                dispatch(issueDetailViewHasLoaded(data));
                dispatch(hideLoading());
            })
            .catch(error => {
                dispatch(issueDetailViewHasErrored(error));
                dispatch(hideLoading());
            }
        );
    };
}

export function loadFullTMPCalc(issueId, puCalcFy) {
    return (dispatch) => {
        dispatch(issueFullTMPCalcIsLoading());
        dispatch(showLoading());

        IssueApi.fullTMPCalc(issueId, puCalcFy)
            .then(data => {
                dispatch(issueFullTMPCalcHasLoaded(data));
                dispatch(hideLoading());
            })
            .catch(error => {
                dispatch(issueFullTMPCalcHasErrored(error));
                dispatch(hideLoading());
            });
    }
}

export function create(issueData) {
    return (dispatch) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        IssueApi.create(issueData)
            .then(data => {
                dispatch(hideLoading());
                dispatch(loadIssues(data.issue.client_id));
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function update(issueId, issueData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueId = parseInt(issueId, 10);

        IssueApi.update(issueId, issueData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                dispatch(loadIssues(data.issue.client_id));
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function createSource(useData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        IssueApi.createSource(useData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function updateSource(issueSourceId, useData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueSourceId = parseInt(issueSourceId, 10);

        IssueApi.updateSource(issueSourceId, useData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function deleteSource(issueSourceId) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueSourceId = parseInt(issueSourceId, 10);

        IssueApi.deleteSource(issueSourceId)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function updateSources(issueId, sourcesData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueId = parseInt(issueId, 10);

        IssueApi.updateSources(issueId, sourcesData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function createUse(useData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        IssueApi.createUse(useData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function updateUse(issueUseId, useData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueUseId = parseInt(issueUseId, 10);

        IssueApi.updateUse(issueUseId, useData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function deleteUse(issueUseId) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueUseId = parseInt(issueUseId, 10);

        IssueApi.deleteUse(issueUseId)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function updateUses(issueId, usesData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueId = parseInt(issueId, 10);

        IssueApi.updateUses(issueId, usesData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function updateUseCostHistory(issueId, useId, costHistoryData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueId = parseInt(issueId, 10);
        useId = parseInt(useId, 10);

        IssueApi.updateUseCostHistory(issueId, useId, costHistoryData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}


export function updateUseRefundings(issueId, useId, refundingsData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueId = parseInt(issueId, 10);
        useId = parseInt(useId, 10);

        IssueApi.updateUseRefundings(issueId, useId, { refundings: refundingsData })
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);

                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function updatePUOverride(issueId, puOverrideData) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueId = parseInt(issueId, 10);

        IssueApi.updatePUOverride(issueId, puOverrideData)
            .then(data => {
                dispatch(hideLoading());
                Common.reloadDetailViews(dispatch, state);

                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function updateAnalystSkAnswers(issueId, data) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        issueId = parseInt(issueId, 10);

        IssueApi.updateAnalystSkAnswers(issueId, data)
            .then(data => {
                dispatch(hideLoading());
                //Common.reloadDetailViews(dispatch, getState());

                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}

export function remove(issueId) {
    return (dispatch, getState) => new Promise(function(resolve, reject) {
        dispatch(showLoading());

        const state = getState();

        issueId = parseInt(issueId, 10);

        IssueApi.remove(issueId)
            .then(data => {
                dispatch(hideLoading());
                dispatch({ type: REMOVE });
                if (state.client && state.client.client) {
                    dispatch(loadIssues(state.client.client.client_id));
                }
                
                resolve(data);
            })
            .catch(error => {
                dispatch(hideLoading());
                reject(error);
            });
    });
}
