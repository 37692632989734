import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const IssueSummary = (props) => {
    //console.log(props.issueDetailView);
    const issue = props.issueDetailView.issue;
    return (
        <div className="row">
            <div className="col-6">
                <table className="table table-data">
                    <thead>
                        <tr>
                            <th colSpan={2} className="card-bg text-muted text-uppercase font-weight-bold pl-3">
                                Issue Details
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="pl-3">
                                Issuer Name
                            </td>
                            <td className="text-right">
                                { issue.issuer_name }
                            </td>
                        </tr>
                        <tr>
                            <td className="pl-3">
                                Trans 1
                            </td>
                            <td className="text-right">
                                { issue.trans_1 }
                            </td>
                        </tr>
                        <tr>
                            <td className="pl-3">
                                Trans 2
                            </td>
                            <td className="text-right">
                                { issue.trans_2 }
                            </td>
                        </tr>
                        <tr>
                            <td className="pl-3">
                                Trans 3
                            </td>
                            <td className="text-right">
                                { issue.trans_3 }
                            </td>
                        </tr>
                        <tr>
                            <td className="pl-3">
                                Trans 4
                            </td>
                            <td className="text-right">
                                { issue.trans_4 }
                            </td>
                        </tr>
                        <tr>
                            <td className="pl-3">
                                Trans 5
                            </td>
                            <td className="text-right">
                                { issue.trans_5 }
                            </td>
                        </tr>
                        <tr>
                            <td className="pl-3">
                                Trans 6
                            </td>
                            <td className="text-right">
                                { issue.trans_6 }
                            </td>
                        </tr>
                        <tr>
                            <td className="pl-3">
                                Delivery Date
                            </td>
                            <td className="text-right">
                                { issue.dated_date ? moment(issue.dated_date).format('MM/DD/YYYY') : '' }
                            </td>
                        </tr>
                        <tr>
                            <td className="pl-3">
                                Final Maturity Date
                            </td>
                            <td className="text-right">
                                { issue.maturity_date ? moment(issue.maturity_date).format('MM/DD/YYYY') : '' }
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

IssueSummary.propTypes = {
    issueDetailView: PropTypes.object.isRequired,
};

export default IssueSummary;