import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

const ProjectsTableSummaryBlock = (props) => {
    return (
        <tr className="text-right text-muted font-weight-bold">
            <td className={`border-0 pt-${props.paddingTop}`}>&nbsp;</td>
            <td colSpan={props.colCount - 2} className={`text-right border-0 pt-${props.paddingTop}`}>
                { props.label }
            </td>
            <td className={`text-right border-0 pt-${props.paddingTop}`}>
                { numeral(props.value).format(props.numberFormat) }
            </td>
        </tr>
    );
}

ProjectsTableSummaryBlock.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    numberFormat: PropTypes.string,
    colCount: PropTypes.number.isRequired,
    paddingTop: PropTypes.number.isRequired,
};

export default ProjectsTableSummaryBlock;