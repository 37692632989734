const skQuestions = [
    {
        part: 1,
        key: '(a)',
        question: 'Issuer name',
        autofillEntity: 'issue',
        autofillKey: 'issuer_name',
    },
    {
        part: 1,
        key: '(b)',
        question: 'Issuer EIN',
        autofillEntity: 'issue',
        autofillKey: 'issuer_ein',
    },
    {
        part: 1,
        key: '(c)',
        question: 'CUSIP #',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.1-(c)',
    },
    {
        part: 1,
        key: '(d)',
        question: 'Date issued',
        autofillEntity: 'issue',
        autofillKey: 'dated_date',
        date: true,
    },
    {
        part: 1,
        key: '(e)',
        question: 'Issue price',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.1-(e)',
        numeric: true,
    },
    {
        part: 1,
        key: '(f)',
        question: 'Description of purpose',
        autofillEntity: 'issue',
        autofillKey: 'purpose_description',
    },
    {
        part: 1,
        key: '(g)',
        question: 'Defeased',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.1-(g)',
        yesno: true,
    },
    {
        part: 1,
        key: '(h)',
        question: 'On behalf of issuer',
        autofillEntity: 'issue',
        autofillKey: 'issuer_on_behalf',
        yesno: true,
    },
    {
        part: 1,
        key: '(i)',
        question: 'Pooled financing',
        autofillEntity: 'issue',
        autofillKey: 'pooled_financing',
        yesno: true,
    },
    {
        part: 2,
        key: '1',
        question: 'Amount of bonds retired',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-1',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '2',
        question: 'Amount of bonds legally defeased',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-2',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '3',
        question: 'Total proceeds of issue',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-3',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '4',
        question: 'Gross proceeds in reserve funds',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-4',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '5',
        question: 'Capitalized interest from proceeds',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-5',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '6',
        question: 'Proceeds in refunding escrows',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-6',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '7',
        question: 'Issuance costs from proceeds',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-7',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '8',
        question: 'Credit enhancement from proceeds',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-8',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '9',
        question: 'Working capital expenditures from proceeds',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-9',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '10',
        question: 'Capital expenditures from proceeds',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-10',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '11',
        question: 'Other spent proceeds',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-11',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '12',
        question: 'Other unspent proceeds',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.2-12',
        yesno: false,
        numeric: true,
    },
    {
        part: 2,
        key: '13',
        question: 'Year of substantial completion',
        yesno: false,
    },
    {
        part: 2,
        key: '14',
        question: 'Were the bonds issued as part of a refunding issue of tax-exempt bonds (or, if issued prior to 2018, a current refunding issue)?',
        yesno: true,
    },
    {
        part: 2,
        key: '15',
        question: 'Were the bonds issued as part of a refunding issue of taxable bonds (or, if issued prior to 2018, an advance refunding issue)?',
        yesno: true,
    },
    {
        part: 2,
        key: '16',
        question: 'Has the final allocation of proceeds been made?',
        yesno: true,
    },
    {
        part: 2,
        key: '17',
        question: 'Does the organization maintain adequate books and records to support the final allocation of proceeds?',
        yesno: true,
    },
    {
        part: 3,
        key: '1',
        question: 'Was the organization a partner in a partnership, or a member of an LLC, which owned property financed by tax-exempt bonds?',
        yesno: true,
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '2',
        question: 'Are there any lease arrangements that may result in private business use of bond-financed property?',
        yesno: true,
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.3-2',
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '3a',
        question: 'Are there any management or service contracts that may result in private business use of bond-financed property?',
        yesno: true,
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.3-3a',
        onNo: {
            deemphasize: [
                '3-3b',
            ]
        },
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '3b',
        question: 'If "Yes" to line 3a, does the organization routinely engage bond counsel or other outside counsel to review any management or service contracts relating to the financed property?',
        yesno: true,
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '3c',
        question: 'Are there any research agreements that may result in private business use of bond-financed property?',
        yesno: true,
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.3-3c',
        onNo: {
            deemphasize: [
                '3-3d',
            ]
        },
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '3d',
        question: 'If "Yes" to line 3c, does the organization routinely engage bond counsel or other outside counsel to review any research agreements relating to the financed property?',
        yesno: true,
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '4',
        question: 'Enter the percentage of financed property used in a private business use by entities other than a section 501(c)(3) organization or a state or local government',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.3-4',
        yesno: false,
        percentage: true,
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '5',
        question: 'Enter the percentage of financed property used in a private business use as a result of unrelated trade or business activity carried on by your organization, another section 501(c)(3) organization, or a state or local government',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.3-5',
        yesno: false,
        percentage: true,
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '6',
        question: 'Total of lines 4 and 5',
        autofillEntity: 'issue',
        autofillKey: 'sk_calc.3-6',
        yesno: false,
        percentage: true,
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '7',
        question: 'Does the bond issue meet the private security or payment test?',
        yesno: true,
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '8a',
        question: 'Has there been a sale or disposition of any of the bond-financed property to a nongovernmental person other than a 501(c)(3) organization since the bonds were issued?',
        yesno: true,
        onNo: {
            deemphasize: [
                '3-8b',
                '3-8c',
            ]
        },
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '8b',
        question: 'If "Yes" to line 8a, enter the percentage of bond-financed property sold or disposed of',
        yesno: false,
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '8c',
        question: 'If "Yes" to line 8a, was any remedial action taken pursuant to Regulations sections 1.141-12 and 1.145-2?',
        yesno: true,
        blankForSKNoPU: true,
    },
    {
        part: 3,
        key: '9',
        question: 'Has the organization established written procedures to ensure that all nonqualified bonds of the issue are remediated in accordance with the requirements under Regulations sections 1.141-12 and 1.145-2?',
        yesno: true,
        blankForSKNoPU: true,
    },
    {
        part: 4,
        key: '1',
        question: 'Has the issuer filed Form 8038-T, Arbitrage Rebate, Yield Reduction and Penalty in Lieu of Arbitrage Rebate?',
        yesno: true,
    },
    /*{
        part: 4,
        key: '2',
        question: 'If "No" to line 1, did the following apply?',
        yesno: true,
    },*/
    {
        part: 4,
        key: '2a',
        question: 'Rebate not due yet?',
        yesno: true,
    },
    {
        part: 4,
        key: '2b',
        question: 'Exception to rebate?',
        yesno: true,
    },
    {
        part: 4,
        key: '2c',
        question: 'No rebate due?',
        yesno: true,
    },
    /*{
        part: 4,
        key: '2d',
        question: 'If "Yes" to line 2c, provide in Part VI the date the rebate computation was performed',
        yesno: false,
    },*/
    {
        part: 4,
        key: '3',
        question: 'Is the bond issue a variable rate issue?',
        yesno: true,
    },
    {
        part: 4,
        key: '4a',
        question: 'Has the organization or the governmental issuer entered into a qualified hedge with respect to the bond issue?',
        yesno: true,
        onNo: {
            deemphasize: [
                '4-4b',
                '4-4c',
                '4-4d',
                '4-4e',
            ]
        },
    },
    {
        part: 4,
        key: '4b',
        question: 'Name of provider',
        yesno: false,
    },
    {
        part: 4,
        key: '4c',
        question: 'Term of hedge',
        yesno: false,
    },
    {
        part: 4,
        key: '4d',
        question: 'Was the hedge superintegrated?',
        yesno: true,
    },
    {
        part: 4,
        key: '4e',
        question: 'Was the hedge terminated?',
        yesno: true,
    },
    {
        part: 4,
        key: '5a',
        question: 'Were gross proceeds invested in a guaranteed investment contract (GIC)?',
        yesno: true,
        onNo: {
            deemphasize: [
                '4-5b',
                '4-5c',
                '4-5d',
            ]
        },
    },
    {
        part: 4,
        key: '5b',
        question: 'Name of provider',
        yesno: false,
    },
    {
        part: 4,
        key: '5c',
        question: 'Term of GIC',
        yesno: false,
    },
    {
        part: 4,
        key: '5d',
        question: 'Was the regulatory safe harbor for establishing the fair market value of the GIC satisfied?',
        yesno: true,
    },
    {
        part: 4,
        key: '6',
        question: 'Were any gross proceeds invested beyond an available temporary period?',
        yesno: true,
    },
    {
        part: 4,
        key: '7',
        question: 'Has the organization established written procedures to monitor the requirements of section 148?',
        yesno: true,
    },
    {
        part: 5,
        key: '1',
        question: 'Has the organization established written procedures to ensure that violations of federal tax requirements are timely identified and corrected through the voluntary closing agreement program if self-remediation isn’t available under applicable regulations?',
        yesno: true,
    },
];

const Constants = {
    hotLicenseKey: '7ed8f-1400f-86033-f433e-d6041',
    pageTitleBase: 'PIC',
    pageTitleSeparator: '/',
    skQuestions: skQuestions,
};

export default Constants;