import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import AssetUserFormFields from './AssetUserFormFields';
import AssetUserForm from './AssetUserForm';
import * as AssetUserActionCreators from '../../ducks/asset_users';

class AssetUserModalForm extends AssetUserForm {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            submittingForm: false,
            fieldValues: {
                clientId: this.props.client.client_id,
                type: 'lease',
                userType: 'private',
            },
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            show: props.show,
            fieldValues: {
                clientId: props.client.client_id,
                name: props.user ? props.user.name : '',
                type: props.user ? props.user.type : 'private',
            }
        });
    }

    toggle = () => {
        let hiding = this.state.show;
        this.setState({ show: !this.state.show });

        if(hiding)
            this.props.onHide();
    }

    render() {
        return (
            <Modal isOpen={this.state.show} toggle={this.toggle} backdrop="static">
                <ModalHeader toggle={this.toggle}>
                    <span className="font-weight-bold">
                        {
                            (this.props.user)
                                ? 'Edit ' + this.props.user.name
                                : 'Add Asset User'
                        }
                    </span>
                </ModalHeader>
                <form onSubmit={this.handleFormSubmit}>
                    <div className="pt-3 pl-3 pr-3">
                        <AssetUserFormFields
                            handleInputChange={this.handleInputChange}
                            fieldValues={this.state.fieldValues} />
                    </div>
                    {
                        (this.props.user) ? 
                            <div className="pt-0 pb-2 pl-1">
                                <button type="button" className="btn btn-link" onClick={this.handleDeleteClick}>
                                    <span className="icon icon-circle-with-cross"></span>&nbsp;
                                    Delete {this.props.user.name}
                                </button>
                            </div>
                            : ''
                    }
                    <div className="modal-actions">
                        <button type="button" className="btn-link modal-action" onClick={this.props.onHide}>
                            Cancel
                        </button>
                        <button type="submit" className="btn-link modal-action font-weight-bold">
                            {
                                this.state.submittingForm
                                    ? 'Loading...'
                                    : this.props.user
                                        ? 'Save Changes'
                                        : 'Add Asset User'
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

AssetUserModalForm.propTypes = {
    user: PropTypes.object,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        createAssetUser: (data) => dispatch(AssetUserActionCreators.create(data)),
        updateAssetUser: (id, data) => dispatch(AssetUserActionCreators.update(id, data)),
        removeAssetUser: (id) => dispatch(AssetUserActionCreators.remove(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetUserModalForm);