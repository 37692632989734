import React from 'react';
import PropTypes from 'prop-types';

import * as ModelUtil from '../../lib/ModelUtil';
import * as FormUtil from '../../lib/FormUtil';
import AddAssetPopoverForm from '../AssetForms/AddAssetPopoverForm';
import SizeDetailFieldSet from './SizeDetailFieldSet';

const SpaceFormFields = (props) => {
    if (!props.assets || !props.numeraires || !props.fieldValues.asset_id) {
        return '';
    }

    const asset = ModelUtil.assetForAssetId(props.fieldValues.asset_id, props.assets);
    
    const parentSpace = props.fieldValues.parent_space_id ? ModelUtil.spaceForSpaceId(props.fieldValues.parent_space_id, asset.spaces) : null;
    
    let sizeNumeraire;
    if (parentSpace && parentSpace.usage_numeraire_id) {
        sizeNumeraire = ModelUtil.numeraireForNumeraireId(parentSpace.usage_numeraire_id, props.numeraires);
    }
    else {
        //sizeNumeraire = ModelUtil.numeraireForNumeraireId(asset.size_numeraire_id, props.numeraires);
        sizeNumeraire = ModelUtil.usageNumeraireForAsset(asset, props.numeraires);
    }

    const usageNumeraire = props.fieldValues.usage_numeraire_id
        ? ModelUtil.numeraireForNumeraireId(props.fieldValues.usage_numeraire_id, props.numeraires)
        : sizeNumeraire;

    const numeraireOptions = FormUtil.numeraireOptions(props.numeraires);
    const assetOptions = FormUtil.assetOptions(props.assets, props.numeraires);
    const parentSpaceOptions = FormUtil.spaceOptions(
        asset.spaces,
        props.assets,
        props.numeraires,
        props.space ? props.space.space_id : null
    );

    let sizeDetailFieldSets;
    if(props.showSizeDetailInputs) {
        sizeDetailFieldSets = props.client.pu_fys.map((fy) =>
            <SizeDetailFieldSet
                key={fy}
                fy={fy}
                onInputChange={props.handleSizeDetailInputChange}
                hidden={false}
                numeraireSymbol={props.sizeNumeraireSymbol}
                size={props.total_size_detail && props.total_size_detail[fy] ? props.total_size_detail[fy] : null} />
        );
    }

    return (
        <div>
            <div className="form-group row">
                <label className="col-3 col-form-label text-muted text-uppercase">
                    Asset
                </label>
                <div className="col-7">    
                    <select
                        className="custom-select rounded-0 w-100 small"
                        name="asset_id"
                        value={props.fieldValues.asset_id ? props.fieldValues.asset_id : ''}
                        onChange={props.handleInputChange}>
                        {assetOptions}
                    </select>
                </div>
                <div className="col-2 col-form-label text-right">
                    {
                        props.includeAddAssetPopoverForm
                            ? <AddAssetPopoverForm onSave={props.selectAsset} />
                            : ''
                    }
                </div>
            </div>

            <div className="form-group row">
                <label className="col-3 col-form-label text-muted text-uppercase">
                    Parent Space
                </label>
                <div className="col-7">    
                    <select
                        className="custom-select rounded-0 w-100 small"
                        name="parent_space_id"
                        value={props.fieldValues.parent_space_id ? props.fieldValues.parent_space_id : ''}
                        onChange={props.handleInputChange}>
                        <option>General Asset</option>
                        {parentSpaceOptions}
                    </select>
                </div>
            </div>
            
            <div className="form-group row mt-4">
                <label className="col-3 col-form-label text-muted text-uppercase">
                    Name
                </label>
                <div className="col-9">
                    <input
                        type="text"
                        className="form-control rounded-0"
                        onChange={props.handleInputChange}
                        value={props.fieldValues.name ? props.fieldValues.name : ''}
                        name="name"
                        autoComplete="off" />
                </div>
            </div>

            <div className="form-group row mt-4">
                <label className="col-3 col-form-label text-muted text-uppercase">
                    Space Code
                </label>
                <div className="col-9">
                    <input
                        type="text"
                        className="form-control rounded-0"
                        onChange={props.handleInputChange}
                        value={props.fieldValues.space_code ? props.fieldValues.space_code : ''}
                        name="space_code"
                        autoComplete="off" />
                </div>
            </div>

            <div className="form-group row mt-4">
                <label className="col-3 col-form-label text-muted text-uppercase">
                    Total Size
                </label>
                <div className="col-9">
                    <div className="input-group rounded-0">
                        <input
                            className="form-control rounded-0 text-right"
                            type="text"
                            name="total_size"
                            value={props.fieldValues.total_size ? props.fieldValues.total_size : ''}
                            onChange={props.handleInputChange}
                            autoComplete="off" />
                        <span className="input-group-append rounded-0">
                            <span className="input-group-text">
                                {sizeNumeraire.symbol}
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <div className="form-group row mt-4">
                <label className="col-3 col-form-label text-muted text-uppercase">
                    Common Size
                </label>
                <div className="col-9">
                    <div className="input-group rounded-0">
                        <input
                            className="form-control rounded-0 text-right"
                            type="text"
                            name="common_size"
                            value={props.fieldValues.common_size ? props.fieldValues.common_size : ''}
                            onChange={props.handleInputChange}
                            autoComplete="off" />
                        <span className="input-group-append rounded-0">
                            <span className="input-group-text">
                                {sizeNumeraire.symbol}
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <div className="form-group row mt-4">
                <label className="col-3 col-form-label text-muted text-uppercase">
                    Usage Numeraire
                </label>
                <div className="col-9">
                    <select
                        className="custom-select rounded-0 w-100 small"
                        name="usage_numeraire_id"
                        onChange={props.handleInputChange}
                        value={props.fieldValues.usage_numeraire_id ? props.fieldValues.usage_numeraire_id : ''}>
                        <option value="">Same as { props.fieldValues.parent_space_id ? 'Parent Space' : 'Asset' } ({sizeNumeraire.symbol})</option>
                        {numeraireOptions}
                    </select>
                </div>
            </div>

            <div className="form-group row mt-4">
                <label className="col-3 col-form-label text-muted text-uppercase">
                    Usage Denominator
                </label>
                <div className="col-9">
                    <div className="input-group rounded-0">
                        <input
                            type="text"
                            className="form-control rounded-0 text-right"
                            onChange={props.handleInputChange}
                            name="usage_denominator"
                            disabled={props.fieldValues.usage_numeraire_id ? false : true}
                            value={props.fieldValues.usage_denominator ? props.fieldValues.usage_denominator : ''}
                            autoComplete="off" />
                        <span className="input-group-append rounded-0">
                            <span className="input-group-text">
                                {usageNumeraire.symbol}
                            </span>
                        </span>
                    </div>
                    <small className="form-text text-muted">
                        If using a different numeraire than the asset, this is the total available size of this space measured in the specified usage numeraire
                    </small>
                </div>
            </div>
            {
                props.showSizeDetailInputs
                    ?
                        <div className="form-group row mt-4">
                            <label className="col-3 col-form-label text-muted text-uppercase">
                                Size Override
                            </label>
                            <div className="col-9">
                                { sizeDetailFieldSets }
                            </div>
                        </div>
                    : ''
            }
        </div>
    );
}

SpaceFormFields.propTypes = {
    assets: PropTypes.array,
    client: PropTypes.object.isRequired,
    fieldValues: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleSizeDetailInputChange: PropTypes.func,
    includeAddAssetPopoverForm: PropTypes.bool,
    numeraires: PropTypes.array.isRequired,
    selectAsset: PropTypes.func,
    showSizeDetailInputs: PropTypes.bool,
    space: PropTypes.object,
}

export default SpaceFormFields;