import React from 'react';
import PropTypes from 'prop-types';
import CostDetailFieldSet from './CostDetailFieldSet';
import BenefitFieldSet from './BenefitFieldSet';

const ProjectFormFields = (props) => {
    let costDetailFieldSets;
    if(props.showCostDetailInputs) {
        let fys = props.puFys;

        costDetailFieldSets = fys.map((fy) =>
            <CostDetailFieldSet
                key={fy}
                fy={fy}
                onInputChange={props.handleCostDetailInputChange}
                onEquityInputChange={props.handleEquityDetailInputChange}
                hidden={false}
                cost={(props.fieldValues.costDetail && props.fieldValues.costDetail[fy]) ? props.fieldValues.costDetail[fy] : null}
                equity={(props.fieldValues.equityDetail && props.fieldValues.equityDetail[fy]) ? props.fieldValues.equityDetail[fy] : null} />
        );
    }

    let benefitFieldSets;
    //let benefitAssetIds = []; 
    //let benefitSpaceIds = [];
    if (props.fieldValues.benefits) {
        /*
        benefitAssetIds = props.fieldValues.benefits
            .filter((b) => {
                return b.beneficiary_type === 'App\\Asset';
            })
            .map((b) => {
                return b.asset_id;
            });
        
        benefitAssetIds = props.fieldValues.benefits
            .filter((b) => {
                return b.beneficiary_type === 'App\\Space';
            })
            .map((b) => {
                return b.space_id;
            });
        */
        benefitFieldSets = props.fieldValues.benefits.map((b) => (
            <BenefitFieldSet
                key={b.project_benefit_id}
                benefit={b} 
                onInputChange={(event) => { props.handleBenefitInputChange(event, b); }}
                handleDeleteBenefit={props.handleDeleteBenefit} />
        ));
    }

    let beneficiaryOptions = [];
    for (let asset of props.assets) {
        //if (!benefitAssetIds.includes(asset.asset_id)) {
            beneficiaryOptions[beneficiaryOptions.length] = (
                <option key={"asset-" + asset.asset_id} value={"asset-" + asset.asset_id}>{asset.name}</option>
            );
        //}
        
        for (let space of asset.spaces) {
            beneficiaryOptions[beneficiaryOptions.length] = (
                <option key={"space-" + space.space_id} value={"space-" + space.space_id}>-- {space.name}</option>
            );
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <div className="font-weight-bold text-muted text-uppercase">Project Info</div>
                    <div className="form-group row mt-4">
                        <label className="col-3 col-form-label text-muted text-uppercase">Name</label>
                        <div className="col-9">
                            <input
                                type="text"
                                className="form-control rounded-0"
                                onChange={props.handleInputChange}
                                value={props.fieldValues.name}
                                name="name" />
                        </div>
                    </div>
                    <div className="form-group row mt-4">
                        <label className="col-3 col-form-label text-muted text-uppercase">Blending</label>
                        <div className="col-9">
                            <select
                                className="custom-select rounded-0 w-100 form-control form-control-sm"
                                onChange={props.handleInputChange}
                                value={props.fieldValues.blending_mode}
                                name="blending_mode">
                                <option value="weighted-average">Weighted Average</option>
                                <option value="additive">Additive</option>
                            </select>
                        </div>
                    </div>

                    {
                        props.showCostDetailInputs
                            ?
                                <div className="form-group row mt-4">
                                    <label className="col-3 col-form-label text-muted text-uppercase">Cost</label>
                                    <div className="col-9">
                                        { costDetailFieldSets }
                                    </div>
                                </div>
                            : ''
                    }
                </div>

                <div className="col-6">
                    <div className="font-weight-bold text-muted text-uppercase">Benefits</div>

                    <div className="row mt-2">
                        <div className="col-12">
                            <table className="table table-data">
                                <thead>
                                    <tr>
                                        <th>Beneficiary</th>
                                        <th>In-Service Date</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {benefitFieldSets}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="form-group row mt-4">
                        <div className="col-2">Add:</div>
                        <div className="col-10">
                            <select
                                className="custom-select rounded-0 w-100 form-control form-control-sm"
                                onChange={(event) => { props.handleAddBenefit(event); }}
                                value={props.fieldValues.newBeneficiaryId}>
                                <option></option>
                                {beneficiaryOptions}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ProjectFormFields.propTypes = {
    assets: PropTypes.array.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleBenefitInputChange: PropTypes.func.isRequired,
    handleAddBenefit: PropTypes.func.isRequired,
    handleDeleteBenefit: PropTypes.func.isRequired,
    handleCostDetailInputChange: PropTypes.func,
    showCostDetailInputs: PropTypes.bool.isRequired,
    fieldValues: PropTypes.object.isRequired,
    puFys: PropTypes.array.isRequired,
}

export default ProjectFormFields;
