import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import SpaceListing from './SpaceListing';

const AssetSpacesTable = (props) => {
    let assetSizeNumeraire = props.numeraires.find((n) => {
        return (n.numeraire_id === props.asset.size_numeraire_id);
    });

    let spaceRows = props.asset.spaces
        .filter((space) => {
            return !space.parent_space_id;
        })
        .map((space) => (
            <SpaceListing
                key={space.space_id}
                asset={props.asset}
                space={space}
                numeraires={props.numeraires}
                showSpaceForm={props.showSpaceForm}
                parentIsSubspace={false} />
        ));

    return (
        <table className="table table-data">
            <thead>
                <tr className="bottom-dotted-border">
                    <th style={{width: '68%'}} className="pl-3">Space</th>
                    <th style={{width: '30%'}} className="text-right">Net Size</th>                    
                    <th style={{width: '2%'}}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                { spaceRows }
                <tr className="font-italic">
                    <td className="pl-3">General Asset</td>
                    <td className="text-right">
                        { numeral(props.asset.pu_calc.general_size).format('0,0.00') }&nbsp;
                        { assetSizeNumeraire.symbol }&nbsp;
                        <i className="icon icon-triangle-left" />
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr><td colSpan="3">&nbsp;</td></tr>
            </tfoot>
        </table>
    );
}

AssetSpacesTable.propTypes = {
    asset: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    showSpaceForm: PropTypes.func.isRequired,
};

export default AssetSpacesTable;