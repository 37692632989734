import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeral from 'numeral';
//import moment from 'moment';
import NeutralUseListing from './NeutralUseListing';

const AssetNeutralUsesTable = (props) => {
    let assetSizeNumeraire = props.numeraires.find((item) => {
        return item.numeraire_id === props.asset.size_numeraire_id;
    });

    let assetSizeNumeraireSymbol = assetSizeNumeraire ? assetSizeNumeraire.symbol : '';

    let uses = props.uses.filter((u) => {
        return u.type === 'common' || u.type === 'vacancy';
    });

    let useRows = uses
        .filter((u) => {
            return props.showAllFyUses || (u.pu_calc.time_numerator > 0) || !u.start_date || !u.end_date;
        })
        .map((u) => (
            <NeutralUseListing
                key={u.asset_use_id}
                use={u}
                asset={props.asset}
                numeraires={props.numeraires}
                showVacancyForm={props.showVacancyForm} />
        )
    );
    
    return (
        <table className="table table-data">
            <thead>
                <tr className="bottom-dotted-border">
                    <th style={{width: '14%'}} className="pl-3">Type</th>
                    <th style={{width: '16%'}}>Space</th>
                    <th style={{width: '13%'}} className="text-right">Start</th>
                    <th style={{width: '13%'}} className="text-right">End</th>
                    <th style={{width: '15%'}} className="text-right">Size</th>
                    <th style={{width: '15%'}} className="text-right">Time</th>
                    <th style={{width: '13%'}} className="text-right">Weighted Neutral</th>
                    <th style={{width: '2%'}}>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                { useRows }
                <tr>
                    <td className="pl-3">
                        Common
                    </td>
                    <td>
                        <i>General Asset</i>
                    </td>
                    <td className="text-right">
                    </td>
                    <td className="text-right">
                    </td>
                    <td className="text-right">
                        { numeral(props.asset.pu_calc.reported_common_size).format('0,00.00') } {assetSizeNumeraire.symbol}
                    </td>
                    <td className="text-right">
                        <span className="text-muted">365 days</span>
                    </td>
                    <td className="text-right">
                        {numeral(props.asset.pu_calc.reported_common_size).format('0,0.00')} {assetSizeNumeraire.symbol}
                    </td>
                    <td className="text-right">
                        <a onClick={() => { props.showAssetForm(props.asset); }}>
                            <i className="icon icon-edit" />
                        </a>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr className="text-right text-muted font-weight-bold text-uppercase">
                    <td colSpan="6">
                        Total Weighted Neutral Use
                    </td>
                    <td>
                        { numeral(props.asset.pu_calc.neutral_usage_size + props.asset.pu_calc.reported_common_size).format('0,00.00') } {assetSizeNumeraireSymbol}
                    </td>
                    <td>&nbsp;</td>
                </tr>
            </tfoot>
        </table>
    );
}

AssetNeutralUsesTable.propTypes = {
    asset: PropTypes.object.isRequired,
    numeraires: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired,
    showUseForm: PropTypes.func,
    showAssetUserForm: PropTypes.func,
    showAssetForm: PropTypes.func.isRequired,
    showAllFyUses: PropTypes.bool.isRequired,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        numeraires: state.numeraires.numeraires,
    }
);

export default connect(mapStateToProps)(AssetNeutralUsesTable);